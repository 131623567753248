import CoreService from "../../service/CoreService";

export default class Service extends CoreService {
  constructor(props) {
    super(props);
  }

  async getUserByIdService(userId) {
    try {
      const user = await this.getUserById(userId);
      return user;
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
      throw error;
    }
  }

  async editUserService(
    email,
    nome,
    telefone,
    userId,
    emailCorrida,
    emailCorridaResp,
    emailCorridaAcomp,
    emailCorridaAcompResp
  ) {
    // console.log("------------------- USER RECEBIDO DENTRO DO SERVICE ------------------>", userId);
      const result = await this.editUser({
        email,
        nome,
        telefone,
        userId,
        email_corrida: emailCorrida,
        email_corrida_resp: emailCorridaResp,
        email_corrida_acomp: emailCorridaAcomp,
        email_corrida_acomp_resp: emailCorridaAcompResp,
      });
      console.log('-----------------------> Resultado da edição de usuário:', result)
      return result;
      
  }

  async editSenhaUserService(senha, userId) {
    try {
      await this.editSenhaUser(senha, userId);
      return "Senha alterada com sucesso!";
    } catch (error) {
      console.error("Erro ao alterar senha:", error);
      throw error;
    }
  }

  async saveFuncFavoritoService(funcId, favoritoSelected) {
    try {
      await this.saveFuncFavorito(funcId, favoritoSelected);
      return "Configurações salvas com sucesso!";
    } catch (error) {
      console.error("Erro ao salvar configurações:", error);
      throw error;
    }
  }

  async getFavoritosNewService() {
    try {
      const result = await this.getFavoritosNew();
      const fav = result.map((m, i) => ({ ...m, text: m.endereco }));
      fav.push({ id: 0, text: "Não quero pré-selecionar um endereço!" });
      return fav;
    } catch (error) {
      console.error("Erro ao buscar favoritos:", error);
      throw error;
    }
  }
}
