import CoreService from "../service/CoreService";

export default class Service extends CoreService {
  async calculaValorApi(funcionario, departamento, waypoints) {
    let token = this.getCookie("portal-token");
    let centralID = this.getCookie("central-id");
    let empresa = this.getCookie("empresa-id");

    let data = {
      central: centralID,
      empresa: empresa,
      funcionario: funcionario,
      departamento: departamento,
      waypoints: waypoints,
    };

    let response = await this.fetchOn("corrida/valores", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
        "x-empresa-id": empresa,
        "x-central-id": centralID,
      },
      body: JSON.stringify(data),
    });
    return response.result;
  }
  async getCidadeAtuacao() {
    let result = await this.fetchOn("cidadeatuacao");
    return result.result;
  }
  async getDepartamentoslist(id) {
    let result = await this.fetchOn(
      "voucher_empresa/" + id + "/departamentolist"
    );
    return result.result;
  }
  async criarFavorito(favorito) {
    let info = await JSON.parse(JSON.stringify(favorito));

    if (!info.destino_latitude) {
      delete info["destino_cidade"];
      delete info["destino_endereco"];
      delete info["destino_latitude"];
      delete info["destino_longitude"];
      delete info["destino_numero"];
    }
    info.formadepagamento = 10;
    let result = await this.fetchOn("favorito", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    });

    return result.result;
  }
  async getOptions(filter) {
    let response = await this.fetchOn("codigos?filter=" + filter);
    response = response.result;

    let result = response.map((option) => {
      return {
        id: option.codigo,
        text: option.descricao,
      };
    });

    return result.result;
  }
  async alterarFavorito(favorito) {
    let result = await this.fetchOn("favorito/" + favorito.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...favorito,
      }),
    });

    return result.result;
  }

  driversAround(latitude, longitude) {
    return this.callEndpointWithPayload('motoristas-proximos', {
      latitude: latitude,
      longitude: longitude,
    });
  }

  async getFuncsByName(name) {
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetchOn('voucher_empresa/' + empresa + '/funcionario?voucher_usuarios.nome=' + name);
    return result.result;
  }

  async getFavoritosNew() {
    let result = await this.fetchOn('favoritos');
    return result.result;
  }

  async solicitarApi(funcionario, solicitante, departamento, waypoints, pgto, telefone, nome, email, carros, passageios, observacoes, dataCompleta, uuid_portal, pix_pagseguro) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');

    let data = {
      central: centralID,
      empresa: empresa,
      funcionario: funcionario,
      solicitante: solicitante,
      departamento: departamento,
      waypoints: waypoints,
      email: email,
      telefone: telefone,
      nome: nome,
      data_hora_agendamento: dataCompleta,
      observacoes: observacoes,
      numero_carros: carros,
      passageiros: passageios,
      atendente: "portal",
      cdpagamento: pgto,
      uuid_portal: uuid_portal,
      pix_pagseguro: pix_pagseguro
    }

    let response = await this.fetchOn('corrida/solicitar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
        'x-central-id': centralID,
      },
      body: JSON.stringify(data)
    })
    return response.result;
  
  }
}
