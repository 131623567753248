import Service from "./Service";
import moment from "moment";
import "moment/locale/pt-br.js";
import History from "./History.js";
import AlertService from './AlertService';
const alertService = AlertService.getInstance();

const { versao, url } = require("../../go-versao.js");
const serverUrl = url;
const server1 = "portal/";
const serverUrl2 = url + server1;

export default class CoreModel {
  constructor() {
    this.serverUrl = serverUrl;
    this.serverUrl2 = serverUrl2;
    this.state = {};
    this.props = {};
    this.mirrorState = (s) => {};
    this.getState = this.getState.bind(this);
    this.setState = this.setState.bind(this);
    this.getCentralSubDominio = this.getCentralSubDominio.bind(this);
    this.getCentralTitle = this.getCentralTitle.bind(this);
    this.estounaprod = this.estounaprod.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getTokenCallBack = this.getTokenCallBack.bind(this);
    this.serviceBase = new Service();
  }

  showAlert(message, type) {
    if (!type) { type = "info" }
    alertService.showAlert(message, type)
  }

  navigateTo(path) {
    History.push(path);
  }

  getState() {
    return this.state;
  }

  setState(states) {
    this.state = { ...this.state, ...states };
    this.mirrorState(this.state);
  }

  hideAlert() {
    this.setState({ alertDisplay: "display-hide" });
  }

  getCentralTitle() {
    return (
      "2023 © " + this.getCentralSubDominio().toUpperCase() + "! / V" + versao
    );
  }

  getCentralSubDominio() {
    let url = window.location.href
      .toLowerCase()
      .replace("http://", "")
      .replace("https://", "")
      .split("/")[0]
      .replace(/[0-9]/g, "")
      .replace(":", "")
      .split(".");
    let saida = null;
    let ex = ["com", "br", "www", "localhost", "s-portal", "portal", "portalgoapp", "sportalgoapp"];
    //console.log("getDadosCentralCores", url);
    for (let index = url.length - 1; index >= 0; index--) {
      const element = url[index];

      if (!ex.includes(element)) {
      //  console.log("getDadosCentralCores", element);
        saida = element;
      }
    }
    //console.log("getDadosCentralCores saida", saida);

    return !!saida ? saida : "gosolucoes";
  }

  getTokenCallBack(callback) {
    let token = this.serviceBase.getCookie("portal-token");
    if (token) {
      callback(token);
    }
  }

  estounaprod() {
    if (serverUrl.indexOf("localhost") > 0) {
      return false;
    } else if (serverUrl.indexOf("stag") > 0) {
      return false;
    } else if (serverUrl.indexOf("s-") > 0) {
      return false;
    } else if (serverUrl.indexOf("sportals") > 0) {
      return false;
    } else {
      return true;
    }
  }

  retornaHoraApenas(dado) {
    return moment(dado).format("HH:mm:ss");
  }
  retornaHora(dado) {
    return moment(dado).format("DD/MM/YYYY HH:mm");
  }
}
