import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import ReactTable from "react-table";
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import WS from '../../../../services/webservice.js';
import { Modal } from 'react-bootstrap';

import Workbook from 'react-excel-workbook-emptystring'


const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 0}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);
class EmpregadosV2View extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataFinal: moment(),
      dataInicial: moment().startOf('month'),
      showExportar: 'none',
      vouchers: [],
      auth: [],
      open1: false,
      pdf: false,

    };

    this.getDados = this.getDados.bind(this);
    this.escolhecorrida = this.escolhecorrida.bind(this);
    this.dateInicialHandleChange = this.dateInicialHandleChange.bind(this);
    this.dateFinalHandleChange = this.dateFinalHandleChange.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.modalHide1 = this.modalHide1.bind(this);
    this.fetchData = this.fetchData.bind(this)
    this.criaExcel = this.criaExcel.bind(this);


    this.webservice = new WS();
  }
  criaExcel(vetor) {
    console.log(vetor)
    return (
    
      <Workbook element={<button type="button" className="link-button"  onClick={(e) => { e.preventDefault(); }} style={{ margin: 5 }}><i className="fa fa-file-excel-o"></i>Excel Completo</button>}>
        <Workbook.Sheet data={vetor} name="Relatorio">
          <Workbook.Column label="Nº da corrida" value="t12_num_corrida" width={20} />
          <Workbook.Column label="Origem" value="t12_endereco" width={20} />
          <Workbook.Column label="Data" value="t12_dthr_corrida" width={20} />
          <Workbook.Column label="Data inicio" value="t12_dthr_embarques" width={20} />
          <Workbook.Column label="Data fim" value="t12_dthr_dembarques" width={20} />
          <Workbook.Column label="Nome solicitante" value="voucher_usuarios_nome" width={20} />
          <Workbook.Column label="Nome passageiro" value="t12_nome" width={20} />
          <Workbook.Column label="Email" value="voucher_usuarios_email" width={20} />
          <Workbook.Column label="Centro de Custo" value="voucher_departamento_identificacao" width={20} />
          <Workbook.Column label="Destino" value="taxi333$enderecodestino" width={20} />
          <Workbook.Column label="Forma de pagamento" value="t12_cd_tp_pgto_a" width={20} />
          <Workbook.Column label="Valor" value="valorcalculado" width={20} />
          <Workbook.Column label="Nome motorista" value="t08_motoristas_t08_nomemotorista" width={20} />
          <Workbook.Column label="Carro" value="modelo" width={20} />
          <Workbook.Column label="Placa" value="t07_carro_t07_placa_detran" width={20} />
          <Workbook.Column label="Ano" value="t07_carro_t07_ano_fabricacao" width={20} />
        </Workbook.Sheet>
      </Workbook>
    )
  }

  criaExcelResumo(vetor) {
    return (
      <Workbook element={<button type="button" className="link-button" href onClick={(e) => { e.preventDefault(); }} style={{ margin: 5 }}><i className="fa fa-file-excel-o"></i>Excel Completo</button>}>
        <Workbook.Sheet data={vetor} name="Relatorio">
          <Workbook.Column label="Nome" value="voucher_usuarios_nome" width={20} />
          <Workbook.Column label="Número de corridas" value="count" width={20} />
          <Workbook.Column label="Total" value="sum" width={20} />
          <Workbook.Column label="Valor Médio" value="avg" width={20} />
        </Workbook.Sheet>
      </Workbook>
    )
  }



  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    this.getDados()

  }

  modalHide1() {
    this.setState({ open1: false, showvet1: [], pdf: false })
  }

  async getDados() {
    let testeaqui = await this.webservice.fetch2('corridas')
    let jtesteaqui = await testeaqui.json()
    console.log("opaa", jtesteaqui)
    let result2 = await this.webservice.fetch2('corridas?t12_dthr_corrida={"gte": " ' + moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") + ' " }&voucher_eletronico_validado=true&per_page=200')
    let pgto = await this.webservice.fetch2('codigos?filter=pagto')

    //console.log("RESULTADO GRAFICO", testin.json())
    let jresult2 = await result2.json()
    let jpgto = await pgto.json()
    let newvet1 = []
    let achou
    for (let i in jresult2) {
      console.log("OPA MARILENE", jresult2[i])
      achou = newvet1.map(e => { return e.voucher_func }).indexOf(jresult2[i].voucher_func)
      if (achou !== -1) {
        console.log("valor achou", achou)
        console.log("achei carai", newvet1[achou])
        newvet1[achou].sum = Number(jresult2[i].valorcalculado) + Number(newvet1[achou].sum);
        newvet1[achou].count = Number(newvet1[achou].count) + 1;
        newvet1[achou].avg = Number(newvet1[achou].sum) / Number(newvet1[achou].count);
      } else {
        let item = {};
        item.voucher_func = jresult2[i].voucher_func;
        item.sum = Number(jresult2[i].valorcalculado);
        item.count = 1;
        item.voucher_usuarios_nome = jresult2[i].voucher_usuarios_nome;
        item.avg = Number(jresult2[i].valorcalculado);
        newvet1.push(item)
      }
    }


    console.log("jsao", newvet1)
    this.setState({
      graf1: jresult2,
      load: true,
      newvet1: newvet1,
      codigos_pgto: jpgto,

    })
  }
  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ dados, instance });
    this.setState({ loading: true });
    //let empresa = this.webservice.getCookie('empresa-id');
    // fetch your data
    let order = dados.sorted;
    if (order.length <= 0) {
      order = [{ id: "t12_num_corrida", desc: true }]
    }
    let pages = dados.page + 1;
    let result;
    result = await this.webservice.getTipo2('corridas?faturamentovoucher_id={"eq": null}&voucher_eletronico_validado={"eq": true}&t12_st_corrida={"eq":"E"}&t12_dthr_tele={"gte": " ' + moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") + ' " }', '', order, pages, dados.pageSize, dados.filtered);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      auth: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false,
    })
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  dateInicialHandleChange(date) {
    this.setState({
      dataInicial: date
    });
  }

  //Save the date selected from DatePicker
  dateFinalHandleChange(date) {
    this.setState({
      dataFinal: date
    });
  }

  escolhecorrida(corrida) {
    let showvet = []
    let graf1 = this.state.graf1
    for (let i in graf1) {
      if (graf1[i].voucher_func === corrida.voucher_func) {
        for (let j in this.state.codigos_pgto) {
          if (this.state.codigos_pgto[j].codigo === graf1[i].t12_cd_tp_pgto_a[0]) {
            graf1[i].t12_cd_tp_pgto_a = this.state.codigos_pgto[j].descricao
          }
        }
        showvet.push(graf1[i])
      }
    }
    this.setState({
      showvet1: showvet
    })
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js');
    moment.locale('pt-br');

    return (
      <div className="page-content-wrapper">

        <div className="page-content">
          <div className="container">
            
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="rowAjust">
                  <div className="col-md-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div className="portlet">
                      
                      <div className="portlet-title">
                        <div className="table-actions-wrapper" style={{ marginBottom: 10 }}>
                          <div className="form-inline ng-pristine ng-valid" >
                            <label>Período:&nbsp;&nbsp;</label>
                            <div className="input-group input-large date-picker input-daterange">
                              <DatePicker
                                className="form-control"
                                dateFormat="DD/MM/YYYY"
                                maxDate={this.state.dataFinal}
                                selected={this.state.dataInicial}
                                onChange={this.dateInicialHandleChange}
                              />
                              <span className="input-group-addon" style={{ border: 0, background: 0 }}>
                                Até
                              </span>
                              <DatePicker
                                className="form-control"
                                minDate={this.state.dataInicial}
                                maxDate={moment()}
                                dateFormat="DD/MM/YYYY"
                                selected={this.state.dataFinal}
                                onChange={this.dateFinalHandleChange}
                              />
                            </div>&nbsp;&nbsp;&nbsp;
                           
                            <button onClick={() => { this.getDados() }} className="btn round7 bgColor2 fontColor5 hmargin5">
                              <i className="fa fa-check"></i>
                              Pesquisar
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.criaExcelResumo(this.state.newvet1)}
                      <div className="portlet-body">
                        <ReactTable
                          data={this.state.newvet1}
                          previousText='Anterior'
                          nextText='Próxima'
                          loadingText='Carregando...'
                          noDataText='Nenhum dado encontrado'
                          pageText='Página'
                          ofText='de'
                          rowsText='linhas'
                          // onFetchData={this.fetchDataTimeOut}
                          filterable
                          getTdProps={(state, props, columns) => {
                            return {
                              onClick: (e) => {
                                if (props) {
                                  this.escolhecorrida(props.row._original); this.setState({ open1: true });
                                }
                              }
                            }
                          }}
                          columns={[
                            {
                              Header: "Nome",
                              accessor: "voucher_usuarios_nome",
                            },
                            {
                              Header: "Numero de Corridas",
                              id: "count",
                              accessor: d => <NumberFormat value={d.count} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={''} />,
                            },
                            {
                              Header: "Valor total",
                              id: "sum",
                              accessor: d => <NumberFormat value={d.sum} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
                            },
                            {
                              Header: "Valor médio",
                              id: "avg",
                              accessor: d => <NumberFormat value={d.avg} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />,
                            },
                          ]}
                          defaultPageSize={5}
                          className="-striped -highlight round7"
                          style={{
                            border: 'none'
                          }}
                          PaginationComponent={CustomPaginationComponent}
                        />
                      </div>
                      <Modal
                        keyboard
                        show={this.state.open1}
                        onHide={this.modalHide1}
                        container={this}
                        aria-labelledby="contained-modal-title"
                        dialogClassName="custom-modal"
                      >
                        <Modal.Body>
                          <div style={{ width: '100%' }} >
                            <div>
                              <ReactTable
                                manual
                                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                                data={this.state.showvet1}
                                onFetchData={this.fetchDataTimeOut}
                                previousText='Anterior'
                                className='custom-modal'
                                nextText='Próxima'
                                loadingText='Carregando...'
                                noDataText='Nenhum dado encontrado'
                                pageText='Página'
                                ofText='de'
                                rowsText='linhas'
                                columns={[
                                  {
                                    Header: "Voucher",
                                    accessor: "numero_voucher",
                                  },
                                  {
                                    Header: "Nº da corrida",
                                    accessor: "t12_num_corrida",
                                  },
                                  {
                                    Header: "Origem",
                                    accessor: "t12_endereco",
                                    width: 300,
                                  },
                                  {
                                    Header: "Data",
                                    accessor: "t12_dthr_corrida",
                                    width: 150,
                                    Cell: props => <div>
                                      {props.original.t12_dthr_corrida && moment(props.original.t12_dthr_corrida).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  },
                                  {
                                    Header: "Data inicio",
                                    accessor: "t12_dthr_embarques",
                                    width: 150,
                                    Cell: props => <div>
                                      {props.original.t12_dthr_embarques && moment(props.original.t12_dthr_embarques).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  },
                                  {
                                    Header: "Data fim",
                                    accessor: "t12_dthr_dembarques",
                                    width: 150,
                                    Cell: props => <div>
                                      {props.original.t12_dthr_dembarques && moment(props.original.t12_dthr_dembarques).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  },
                                  {
                                    Header: "Nome solicitante",
                                    accessor: "voucher_usuarios_nome",
                                  },
                                  {
                                    Header: "Nome passageiro",
                                    accessor: "t12_nome",
                                  },
                                  {
                                    Header: "Email",
                                    accessor: "voucher_usuarios_email",
                                    width: 200,

                                  },
                                  {
                                    Header: "Centro de Custo",
                                    accessor: "voucher_departamento_identificacao",
                                    width: 200,

                                  },
                                  {
                                    Header: "Destino",
                                    accessor: "taxi333$enderecodestino",
                                    width: 300,

                                  },
                                  {
                                    Header: "Forma de pagamento",
                                    accessor: "t12_cd_tp_pgto_a",
                                  },
                                  {
                                    Header: "Valor",
                                    accessor: "valorcalculado",
                                    Cell: props => <div>
                                      <NumberFormat value={props.original.valorcalculado} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />
                                    </div>

                                  },
                                  {
                                    Header: "Nome motorista",
                                    accessor: "t08_motoristas_t08_nomemotorista",
                                    width: 200,
                                  },
                                  {
                                    Header: "Carro",
                                    accessor: "modelo",
                                  },
                                  {
                                    Header: "Placa",
                                    accessor: "t07_carro_t07_placa_detran",
                                    width: 150,
                                  },
                                  {
                                    Header: "Ano",
                                    accessor: "t07_carro_t07_ano_fabricacao",
                                  }
                                ]}
                                defaultPageSize={5}
                                PaginationComponent={CustomPaginationComponent}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>

                          {this.criaExcel(this.state.showvet1)}

                            

                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EmpregadosV2View;
