import CoreModel from "../../service/CoreModel";
import readXlsxFile from "read-excel-file";
import Dialog from "react-bootstrap-dialog-v2";
import Service from "./Service";
import { validaCPF } from "./validadorCPF";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendexcel = this.sendexcel.bind(this);
    this.pegarDepartamentos = this.pegarDepartamentos.bind(this);
    this.handleResponsavelChange = this.handleResponsavelChange.bind(this);

    this.createJson = this.createJson.bind(this);
    this.removeCalen = this.removeCalen.bind(this);
    this.saveAutorizado = this.saveAutorizado.bind(this);
    this.getAuthHistorico = this.getAuthHistorico.bind(this);
    this.dateFinalHandleChange = this.dateFinalHandleChange.bind(this);
    this.dateInicialHandleChange = this.dateInicialHandleChange.bind(this);
    this.getSelectedOptionsValue = this.getSelectedOptionsValue.bind(this);
    this.emailhandleChange = this.emailhandleChange.bind(this);

    this.state = {
      // fullData: [],
      // data: [],
      // pages: null,
      // loading: true,
      // currentPage: 0,
      // pageSize: 12,
      // successMessage: "",
      // errorMessage: "",
      // showModal: false,
      // usuarioSelecionado: null,
      // editData: {
      //   username: "",
      //   senha: "",
      //   confirmSenha: "",
      //   voucher_usuarios_email: "",
      //   cpf: "",
      //   nome: "",
      //   telefone: "",
      //   email_corrida: false,
      //   email_corrida_resp: false,
      //   email_corrida_acomp: false,
      //   email_corrida_acomp_resp: false,
      //   email_nova_corrida_gestor: false,
      //   email_nova_corrida_gestor_valor: 0,
      //   status: "0",
      //   departamentos: [],
      //   departamentoSelecionado: [],
      //   perfil: [],
      //   responsavel: [],
      //   horaInicio: "",
      //   horaFim: "",
      //   diasDaSemana: {
      //     segCheck: false,
      //     terCheck: false,
      //     quaCheck: false,
      //     quiCheck: false,
      //     sexCheck: false,
      //     sabCheck: false,
      //     domCheck: false,
      //     ferCheck: false,
      //   },
      //   calen: [],
      //   selected: null,
      // },
      // status: "0",
      departamentos: [],
      departamentoSelecionado: [],
      // perfil: [],
      // responsavel: [],
      // listaResponsaveis: [],
      // indiceResponsavelSelecionado: 0,
      // horaInicio: "",
      // horaFim: "",
      // diasDaSemana: {
      //   segCheck: false,
      //   terCheck: false,
      //   quaCheck: false,
      //   quiCheck: false,
      //   sexCheck: false,
      //   sabCheck: false,
      //   domCheck: false,
      //   ferCheck: false,
      // },
      // calen: [],
      // showCalendar: "none",
      // showCotaGastos: "none",
      // cotaGastos: "00,00",

      // auth: [],
      // funclist: [],
      // central_data: [],
      // perfilSelected: 0,
      // statusSelected: 0,
      // email_nova_corrida_gestor: false,
      // email_nova_corrida_gestor_valor: 0,
      // nome: "",
      // email: "",
      // celular: "",
      // telefoneMask: "(99) 9999-9999",
      // cpf: "",
      // dep: [],

      showExportar: "none",
      showImportar: "none",
      selected: null,
      pageSize: 8,
      fullData: [],
      data: [],
      pages: null,
      loading: true,

      authHistorico: [
        {
          data: "",
          usuario: "",
          status: "",
          obs: "",
        },
      ],
      acima3Meses: "",
      ate3Meses: "active",
      nome: "",
      email: "",
      calen: [],
      celular: "",
      responsavel: 0,
      perfil: "Usuário",
      departamento: [
        { id: 1, text: "oi" },
        { id: 2, text: "oeqwewqi" },
        { id: 3, text: "oertttri" },
      ],
      dep: [],
      perfilSelected: 1,
      saldo: "",
      matricula: "",
      senha: "",
      email_nova_corrida_gestor: false,
      email_nova_corrida_gestor_valor: 0,
      confirmSenha: "",
      showSenha: "inherit",
      showCalendar: "none",
      cpf: "",
      telefoneMask: "(99) 9999-9999",
      moneyMask: " ,00",
      showSelect: "none",
      showCotaGastos: "none",
      cotaGastos: "00,00",
      statusSelected: 0,
      horaFim: "",
      horaInicio: "",
      noCheck: false,
      yesCheck: true,
      segCheck: false,
      terCheck: false,
      quaCheck: false,
      quiCheck: false,
      sexCheck: false,
      sabCheck: false,
      domCheck: false,
      ferCheck: false,
      dataFinal: null,
      dataInicial: null,

      showModal: false,
      modalMessage: "",

      editar: false,

      userData: {
        nome: "",
        email: "",
        celular: "",
        cpf: "",
        perfil: "Usuário",
        departamento: [],
        responsavel: 0,
      },
    };
  }

  modalHide = () => {
    this.setState({ showModal: false });
  };

  //!ok
  getSelectedOptionsValue(selectedOptions) {
    var values = [];
    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value);
    }
    return values;
  }

  //!ok
  getAuthHistorico() {
    if (this.props.ate3Meses === "active") {
      this.setState({
        authHistorico: [
          {
            data: "11/06 15:27",
            usuario: "leandro.fvc",
            status: "ATIVO",
            obs: "Inserido",
          },
          {
            data: "27/06 09:57",
            usuario: "jbetim442@gmail.com",
            status: "ATIVO",
            obs: "Editado",
          },
        ],
      });
    } else if (this.props.acima3Meses === "active") {
      this.setState({
        authHistorico: [
          {
            data: "19/04 15:52",
            usuario: "EDUARDO.FVC",
            status: "ATIVO",
            obs: "Inserido",
          },
        ],
      });
    }
  }

  //!ok
  saveAutorizado() {
    let saldo;
    let jcalen;
    let s = this.state;

    try {
      if (s.nome === "" || s.email === "" || s.celular === "" || s.cpf === "") {
        throw new Error("Preencha todos os campos");
      }

      saldo =
        s.showCotaGastos === "inherit"
          ? s.cotaGastos.replace(/[.]+/g, "").replace(",", ".")
          : "0";
      jcalen = s.calen?.length > 0 ? s.calen : null;

      //if (!validaCPF(s.cpf)) {
        //throw new Error("CPF Inválido");
      //}

      let senhapadrao = this.state.central_data?.senhapadrao || "go";

      this.service
        .cadastroAutorizado(
          s.cpf,
          jcalen,
          "3",
          s.cpf,
          s.perfilSelected,
          s.email,
          parseFloat(saldo),
          s.nome,
          s.celular,
          s.statusSelected,
          s.dep,
          s.responsavel,
          s.email_nova_corrida_gestor,
          s.email_nova_corrida_gestor_valor,
          senhapadrao
        )
        .then((result) => {
          console.log("--------------------RESULT----------------", result);
          if (result.status === 409) {
            throw new Error("CPF ou e-mail já cadastrado");
          }
        
          this.setState({ showModal: false });
          this.props.history.push("/usuarios");
        })
        .catch((error) => {
          console.error("Erro durante o cadastro:", error.message);
          
        });
    } catch (error) {
      console.error("Erro na validação:", error.message);
      
    }
  }

  //!ok
  removeCalen(index) {
    let edit = this.state.calen;
    // console.log("EDIT COM CALEN", edit)
    edit.splice(index, 1);
    this.setState({
      calen: edit,
    });
  }

  emailhandleChange(event, maskedvalue, floatvalue) {
    this.setState({
      email_nova_corrida_gestor_valor: floatvalue,
    });
  }

  handleResponsavelChange(event) {
    this.setState({
      indiceResponsavelSelecionado: parseInt(event.target.value, 10),
    });
  }

  async sendexcel() {
    const input = document.getElementById("input");
    //console.log("WATAFOCK MAN", input.files[0])
    var vetfunc = [];
    let obj = {};
    let email, celular, nome, cpf, centrodecusto, superior;
    //let self
    readXlsxFile(input.files[0]).then(async (rows) => {
      console.log("rows", rows);
      for (let i in rows) {
        console.log("ohla o i", i);
        if (i > 0) {
          status = rows[i][0];
          nome = rows[i][1];
          email = rows[i][2];
          celular = rows[i][3];
          cpf = rows[i][5];
          centrodecusto = rows[i][4];
          superior = rows[i][4];
          obj = {
            status: status,
            nome: nome,
            email: email ? email : "",
            telefone: celular,
            matricula: cpf,
            cpf: cpf,
            centrodecusto: centrodecusto,
            superior: superior,
          };
          vetfunc.push(obj);
          console.log("inside", vetfunc);
        }
      }
      await this.service.enviaexcel(vetfunc);
      alert("Importação Finalizada com Sucesso!");
      this.setState({
        showImportar: this.state.showImportar === "none" ? "block" : "none",
      });
      // this.fetchData(this.state.dados, this.state.instance);
    });
  }

  async handleSubmit() {
    const {
      nome,
      email,
      celular,
      cpf,
      showCotaGastos,
      cotaGastos,
      calen,
      central_data,
      perfilSelected,
      statusSelected,
      dep,
      indiceResponsavelSelecionado,
      email_nova_corrida_gestor,
      email_nova_corrida_gestor_valor,
    } = this.state;

    console.log({ email, celular, cpf });

    if (email && celular && cpf) {
      let saldo =
        showCotaGastos === "inherit"
          ? parseFloat(cotaGastos.replace(/[.]+/g, "").replace(",", "."))
          : 0;
      let jcalen = calen.length > 0 ? calen : null;

      if (true) {
        let senhapadrao =
          central_data && central_data.senhapadrao
            ? central_data.senhapadrao
            : "go";
        try {
          const result = await this.service.criar(
            cpf,
            jcalen,
            "3",
            cpf,
            perfilSelected,
            email,
            saldo,
            nome,
            celular,
            statusSelected,
            dep,
            indiceResponsavelSelecionado,
            email_nova_corrida_gestor,
            email_nova_corrida_gestor_valor,
            senhapadrao
          );

          console.log("---------------------------> método criar", result);

          if (result.status !== 409) {
            alert("Cadastro realizado");
            this.props.history.push("/usuarios");
          } else {
            alert("CPF ou e-mail já cadastrado");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Ocorreu um erro ao realizar o cadastro");
        }
      } else {
        alert("CPF Inválido");
      }
    } else {
      alert("Preencha todos os campos");
    }
  }

  pegarDepartamentos() {
    this.service.pegaDep().then((result) => {
      console.log("-------------------------------------->result", result);
      this.setState({
        departamentos: result.departamento,
        funclist: result.funclist,
        central_data: result.central_data,
      });
    });
  }

  //Save the date selected from DatePicker
  dateInicialHandleChange(date) {
    this.setState({
      dataInicial: date,
    });
  }

  //Save the date selected from DatePicker
  dateFinalHandleChange(date) {
    this.setState({
      dataFinal: date,
    });
  }

  createJson() {
    let calen2 = [];
    calen2 = this.state.editData.calen;
    calen2.push({
      ini: this.state.editData.horaInicio,
      fim: this.state.editData.horaFim,
      seg: this.state.editData.diasDaSemana.segCheck,
      ter: this.state.editData.diasDaSemana.terCheck,
      qua: this.state.editData.diasDaSemana.quaCheck,
      qui: this.state.editData.diasDaSemana.quiCheck,
      sex: this.state.editData.diasDaSemana.sexCheck,
      sab: this.state.editData.diasDaSemana.sabCheck,
      dom: this.state.editData.diasDaSemana.domCheck,
      fer: this.state.editData.diasDaSemana.ferCheck,
    });
    // console.log(calen2);
    this.setState({
      calen: calen2,
    });
  }

  handleDiaChange = (event, dia) => {
    const diaKey = dia.toLowerCase() + "Check";
    const isChecked = event.target.checked;

    this.setState((prevState) => ({
      diasDaSemana: {
        ...prevState.editData.diasDaSemana,
        [diaKey]: isChecked,
      },
    }));
  };

  async initData() {
    try {
      const lista = await this.service.listar();

      const departamentosUnicos = [
        ...new Set(
          lista.flatMap((item) =>
            item.departamentosz.map((dep) => ({
              id: dep.id,
              text: dep.identificacao,
            }))
          )
        ),
      ];
      const statusUnicos = [...new Set(lista.map((item) => item.status))];
      const voucher_roles_descricao = [
        ...new Set(
          lista
            .map((item) => item.voucher_roles_descricao)
            .filter((descricao) => descricao != null)
        ),
      ];
      const responsavelUnicos = [
        ...new Set(
          lista
            .map((item) => item.voucher_usuarios_nome)
            .filter((nome) => nome != null)
        ),
      ];

      this.setState({
        departamentos: departamentosUnicos,
        status: statusUnicos,
        perfil: voucher_roles_descricao,
        listaResponsaveis: responsavelUnicos,
      });
    } catch (error) {
      console.error(
        "Erro ao inicializar departamentos, status, perfil e responsável:",
        error
      );
    }
  }

  handleEditInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      editData: {
        ...this.state.editData,
        [name]: value,
      },
    });
  };

  setDepartamentos = (departamentos) => {
    this.setState({ departamentos });
  };

  setDepartamentoSelecionado = (departamentoSelecionado) => {
    this.setState({ departamentoSelecionado });
  };

  onDepartamentoChange = (selectedOptions) => {
    const departamentosSelecionados = selectedOptions.map(
      (option) => option.value
    );
    this.props.viewModel.setDepartamentoSelecionado(departamentosSelecionados);
  };

  getStatusTitle(key) {
    const statusTitles = { 0: "ATIVO", 1: "INATIVO", 2: "BLOQUEADO" };
    return statusTitles[key] || "Selecione um Status";
  }

  handleStatusSelect = (evtKey, evt) => {
    this.handleStatusChange(evtKey);
  };

  toggleCreateUsuario = () => {
    this.setState({
      showModal: true,
      usuarioSelecionado: {},
      editData: {
        username: "",
        password: "",
        email: "",
        cpf: "",
        nome: "",
        telefone: "",
        email_corrida: false,
        email_corrida_resp: false,
        email_corrida_acomp: false,
        email_corrida_acomp_resp: false,
      },
    });
  };

  resetState = (newItem) => {
    return {
      fullData: [...this.state.fullData, newItem],
      data: [...this.state.data, newItem].slice(0, this.state.pageSize),
      successMessage: "Usuário criado com sucesso!",
      errorMessage: "",
      showModal: false,
      username: "",
      password: "",
      email: "",
      cpf: "",
      nome: "",
      telefone: "",
      email_corrida: false,
      email_corrida_resp: false,
      email_corrida_acomp: false,
      email_corrida_acomp_resp: false,
      editData: {
        username: "",
        password: "",
        email: "",
        cpf: "",
        nome: "",
        telefone: "",
        email_corrida: false,
        email_corrida_resp: false,
        email_corrida_acomp: false,
        email_corrida_acomp_resp: false,
      },
      status: "0",
      departamentos: [],
      departamentoSelecionado: [],
      perfil: [],
      responsavel: [],
    };
  };

  handleError = (error) => {
    console.error("Erro ao criar:", error);
    this.setState({
      errorMessage: "Erro ao criar.",
    });
  };

  handleUpdate = async (dadosAtualizados, id) => {
    console.log("------------------------>dadosAtualizados:", dadosAtualizados);
    console.log("------------------------>id:", id);

    if (!dadosAtualizados.calendar) {
      dadosAtualizados.calendar = null;
    }
    try {
      if (!!id) {
        await this.service.atualizar({ ...dadosAtualizados, id: id });
      } else {
        await this.service.criar(dadosAtualizados);
      }

      // await this.fetchData();
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error.message);
    }
  };

  handleDelete = async (id) => {
    try {
      await this.service.deletar(id);
      this.fetchData();
    } catch (error) {
      console.error("Erro ao deletar usuário:", error.message);
    }
  };

  // ------ OK ---------
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  // ------ OK ---------
  handleEditInputChange = (event) => {
    const { name, type, checked, value } = event.target;

    this.setState({
      editData: {
        ...this.state.editData,
        [name]: type === "checkbox" ? checked : value,
      },
    });
  };

  // ------ OK ---------
  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const fullData = await this.service.listar();

      let auth = fullData.map(function (el) {
        return {
          ...el,
          statuss:
            el.status === 0
              ? "ATIVO"
              : el.status === 1
              ? "INATIVO"
              : "BLOQUEADO",
          svalorlimit: "R$ " + (el.valorlimit - el.saldo_gasto).toFixed(2),
        };
      });

      // console.log("----------------------------------->fullData:", fullData);
      this.setState({
        auth: auth,
        showModal: false,
        fullData,
        data: fullData.slice(0, this.state.pageSize),
        pages: Math.ceil(fullData.length / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  // ------ OK ---------
  onPageChange = (pageIndex) => {
    const { fullData, pageSize } = this.state;
    const startRow = pageIndex * pageSize;
    const endRow = startRow + pageSize;
    // console.log("Mudando para a página:", pageIndex, "Dados da página:", fullData.slice(startRow, endRow));
    this.setState({
      currentPage: pageIndex,
      data: fullData.slice(startRow, endRow),
    });
  };
}
