import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ViewModel  from "../controllers/ViewModel.js";
import SubHeader from "../../header/SubHeaderView.js";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 1}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 1}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class List extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.fetchData();
  }

  render() {
    const { data, pages, loading, pageSize, currentPage } =
      this.viewModel.state;
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Painel" textoCentral="Tipos de Pagamentos" />
        <div className="page-content">
          <div className="container">
            <ReactTable
              manual
              data={data}
              pages={pages}
              loading={loading}
              onFetchData={this.viewModel.fetchData}
              onPageChange={this.viewModel.onPageChange}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              columns={[
                {
                  Header: "Nome",
                  accessor: "nome",
                },
                {
                  Header: "Sigla",
                  accessor: "sigla",
                },
                {
                  Header: "STATUS",
                  accessor: "ativo",
                  Cell: (row) => <div className="btn round7 bgColor1" onClick={() => this.viewModel.manageTipo(row.original)}>{row.original.ativo ? "Desativar" : "Ativar"}</div>,

                  filterMethod: (filter, row) => {
                    if (filter.value === '') {
                      return true
                    }
                    if (filter.value === 'true') {
                      return row[filter.id]
                    }
                    return !row[filter.id]
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : ''}
                    >
                      <option value=''>Todos</option>
                      <option value='true'>Ativado</option>
                      <option value='false'>Desativado</option>
                    </select>
                  )
                }
              ]}
              defaultPageSize={pageSize}
              page={currentPage}
              className="-striped -highlight round7"
              style={{
                border: "none",
              }}
              getTheadProps={() => {
                return {
                  className: "round7",
                  style: {
                    backgroundColor: "white",
                    color: "black",
                  },
                };
              }}
              getTheadFilterProps={() => {
                return {className: "round7"}}}
              getTheadFilterTrProps={() => {
                return {className: "round7"}}}
              getTbodyProps={() => {
                return {className: "round7"}}}
              getTdProps={() => {
                return { className: "alignCenter" }
              }}
              getTheadThProps={() => {
                return {
                  style: {
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: "bold",
                  },
                };
              }}
              getTrProps={(state, rowInfo) => {
                if (!!rowInfo) {
                  return {
                    className: "round7",
                    style: {
                      backgroundColor:
                        rowInfo.row.ativo
                          ? "#DFF0D8"
                          : "#F2DEDE",
                      color:
                        rowInfo.row.ativo
                          ? "#3C763D"
                          : "#A94442",
                    },
                  };
                } else {
                  return {};
                }
              }}
              PaginationComponent={CustomPaginationComponent}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default List;
