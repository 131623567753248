import Workbook from "react-excel-workbook-emptystring";
import CoreModel from "../../service/CoreModel.js";
import Service from "./Service.js";
import moment from "moment";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      auth: [],
      pages: null,
      loading: true,
      selection: false,
      my_corridas: [],
      my_voucher: "",
      show: false,
      corr: "",
      colunas: [],
      dados: [],
      instance: {},
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pesquisa = this.pesquisa.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.geraBoleto = this.geraBoleto.bind(this);
    this.cancelaFatura = this.cancelaFatura.bind(this);
    this.criaExcel = this.criaExcel.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  criaExcel() {
    return (
      <Workbook
        element={
          <button
            type="button"
            className="link-button"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ margin: 5 }}
          >
            <i className="fa fa-file-excel-o"></i>Excel Completo
          </button>
        }
      >
        <Workbook.Sheet data={this.state.my_corridas} name="Relatorio">
          <Workbook.Column
            label="Número da Corrida"
            value="t12_num_corrida"
            width={20}
          />
          <Workbook.Column
            label="Data corrida"
            value={(row) => moment(row.t12_dthr_corrida).format("DD/MM/YYYY")}
            width={20}
          />
          <Workbook.Column
            label="Solicitante"
            value="voucher_usuarios_nome"
            width={20}
          />
          <Workbook.Column label="Passageiro" value="t12_nome" width={20} />
          <Workbook.Column
            label="Observação"
            value={(row) => (row.t12_obs ? row.t12_obs : "-")}
            width={20}
          />
          <Workbook.Column label="Origem" value="origem" width={20} />
          <Workbook.Column label="Destino" value="destino" width={20} />
          <Workbook.Column
            label="Centro de Custo"
            value="voucher_departamento_nome"
            width={20}
          />
          <Workbook.Column
            label="Centro de Custo (descrição)"
            value="voucher_departamento_descricao"
            width={20}
          />
          <Workbook.Column
            label="Valor"
            value={(row) =>
              Number(row.valorcalculado).toFixed(2).replace(".", ",")
            }
            width={20}
          />
        </Workbook.Sheet>
      </Workbook>
    );
  }

  async fetchData(dados, instance) {
    // Early return if dados or service is not defined
    if (!dados || !this.service) {
      console.error("Dados está vazio ou service não foi inicializado");
      return;
    }

    // Set initial state
    this.setState({
      dados,
      instance,
      loading: true,
    });

    // Define pages and order
    let pages = dados.page ? dados.page + 1 : 1;
    let order =
      dados.sorted.length > 0
        ? dados.sorted
        : [{ id: "datavencimento", desc: true }];

    // Fetch data from service
    let result = await this.service.getTipo2(
      `voucher_faturamento?baixa=${this.state.selection}`,
      "",
      order,
      pages,
      dados.pageSize,
      dados.filtered
    );

    // console.log("-------------------------------->result", result);

    // Process result
    if (result.result && result.count) {
      let count = result.count;
      let auth = await result.result;

      // Update state with fetched data
      this.setState({
        auth,
        pages: Math.ceil(count / dados.pageSize),
        loading: false,
      });
    } else {
      console.error("Failed to fetch data");
      this.setState({ loading: false });
    }
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => {
        this.fetchData(dados, instance);
      }, 300),
    });
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }

  pesquisa() {
    this.fetchData(this.state.dados, this.state.instance);
    this.setState({
      my_corridas: [],
    });
  }

  async pegacorrida(id) {
    let result = await this.service.pegarCorridas(id);
    var corrid = result.result;
    console.log("corridaaaa escolhida", corrid);
    this.setState(
      {
        corr: corrid,
      },
      () => this.handleShow()
    );
  }

  escolhecorrida(corrida) {
    this.setState({
      my_corridas: corrida.corridas,
      my_voucher: corrida,
    });
  }

  cancelaFatura() {
    console.log("my_voucher", this.state.my_voucher.id);
    this.service.cancelaFatura(this.state.my_voucher.id);
  }

  async geraBoleto() {
    let corridas = this.state.my_corridas;
    console.log("MAS FIM", corridas);
    console.log("OPA", this.props);
    console.log(
      "TESTE",
      this.props.childProps.empresaData.telefone.split(" ")[1].replace("-", "")
    );
    let itens = [];
    for (const c of corridas) {
      let item = {
        description: "Corrida na rua " + c.t12_endereco,
        quantity: 1,
        price_cents: Number(
          (c.valorcalculado.toFixed(2) * 100).toFixed(2).split(".")[0]
        ),
      };
      itens.push(item);
    }

    let boleto = {
      email: `${this.props.childProps.user.email}`,
      ensure_workday_due_date: "true",
      due_date: `${moment().add(3, "days").format("YYYY-MM-DD")}`,
      items: [itens],
      payer: {
        cpf_cnpj: `${this.props.childProps.empresaData.cnpj}`,
        name: `${this.props.childProps.empresaData.razaosocial}`,
        phone_prefix: `${this.props.childProps.empresaData.telefone
          .split(" ")[0]
          .replace("(", "")
          .replace(")", "")}`,
        phone: `${this.props.childProps.empresaData.telefone
          .split(" ")[1]
          .replace("-", "")}`,
        email: `${this.props.childProps.user.email}`,
        address: {
          zip_code: `${this.props.childProps.empresaData.cep}`,
          street: `${this.props.childProps.empresaData.endereco}`,
          number: `${this.props.childProps.empresaData.numero}`,
          district: `${this.props.childProps.empresaData.bairro}`,
          city: `${this.props.childProps.empresaData.cidade}`,
          state: `${this.props.childProps.empresaData.uf}`,
          country: "Brasil",
        },
      },
    };
    this.service.gerarBoleto(boleto, this.props.childProps.empresaData.central);
  }
}
