import CoreService from "../../service/CoreService";

export default class Service extends CoreService {


  async getTipo2(tipo, id_tipo, sorted, page, pageSize, filtered) {
    let response;
    let filtros = filtered.map((m) => m.id + "=" + m.value).join("&");
    if (id_tipo !== "") {
      response = await this.fetchOn(tipo + "/" + id_tipo);
    } else if (sorted.length > 0) {
      let order = sorted
        .map(
          (m) =>
            (m.desc ? "-" : "") +
            (m.id.search("voucher_usuarios")
              ? m.id.replace("voucher_roles_", "voucher_roles.")
              : m.id.replace("voucher_usuarios_", "voucher_usuarios."))
        )
        .join(",");
      response = await this.fetchOn(
        tipo +
          "&per_page=" +
          pageSize +
          "&page=" +
          page +
          "&order=" +
          order +
          "&" +
          filtros
      );
    } else {
      response = await this.fetchOn(
        tipo + "&per_page=" + pageSize + "&page=" + page + "&" + filtros
      );
    }

    return response;
  }

  pegarCorridas(id) {
    return this.fetchOn("corridas?t12_num_corrida=" + id);
  }

  cancelaFatura(id2) {
    let result = this.fetchOn("cancelafatura", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id2,
      }),
    });

    return result.result;
  }

  async gerarBoleto(boleto, central) {
    let token = this.getCookie("portal-token");
    let centralID = central;
    let empresa = this.getCookie("empresa-id");
    let result = await this.fetchOn("criarboleto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
        "x-empresa-id": empresa,
      },
      body: JSON.stringify({
        "x-central-id": centralID,
        email: boleto.email,
        ensure_workday_due_date: boleto.ensure_workday_due_date,
        due_date: boleto.due_date,
        items: boleto.items,
        payer: {
          cpf_cnpj: boleto.payer.cpf_cnpj,
          name: boleto.payer.name,
          phone_prefix: boleto.payer.phone_prefix,
          phone: boleto.payer.phone,
          email: boleto.payer.email,
          address: {
            zip_code: boleto.payer.address.zip_code,
            street: boleto.payer.address.street,
            number: boleto.payer.address.number,
            district: boleto.payer.address.district,
            city: boleto.payer.address.city,
            state: boleto.payer.address.state,
            country: "Brasil",
          },
        },
      }),
    });

    return result;
  }
}
