import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import "../styles/CustomFormControl.css";


function mascara(tipo, filtro, text) {
  text = text.replace(/[^+\d]/g, '');
  console.log('', filtro)
  if (!filtro) {
    filtro = '001';
  }
  if (!tipo) {
    tipo = 'cc';
  }
  switch (filtro) {
    case '001':
      if (tipo === 'ag') {
        return text.substring(0, 5).replace(/(\d{4})(\d{1})/, '$1-$2');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '237':
      if (tipo === 'ag') {
        return text.substring(0, 5).replace(/(\d{4})(\d{1})/, '$1-$2');
      }
      if (tipo === 'cc') {
        return text.substring(0, 8).replace(/(\d{7})(\d{1})/, '$1-$2');
      }
      break;
    case '341':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 10).replace(/(\d{9})(\d{1})/, '$1-$2');
      }
    case '041':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 6).replace(/(\d{5})(\d{1})/, '$1-$2');
      }
      break;
    case '033':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '290':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '422':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '021':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '389':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '376':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '655':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '212':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    case '104':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text
          .substring(0, 15)
          .replace(/(\d{11})(\d{1})(\d{3})/, '$1-$2 op:$3');
      }
      break;
    case '748':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 7).replace(/(\d{6})(\d{1})/, '$1-$2');
      }
      break;
    case '085':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 12).replace(/(\d{11})(\d{1})/, '$1-$2');
      }
      break;
    case '260':
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 11).replace(/(\d{10})(\d{1})/, '$1-$2');
      }
      break;
    case '274':
      if (tipo === 'ag') {
        return text.substring(0, 1).replace(/(\d{1})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 9).replace(/(\d{8})(\d{1})/, '$1-$2');
      }
      break;
    default:
      if (tipo === 'ag') {
        return text.substring(0, 4).replace(/(\d{4})/, '$1');
      }
      if (tipo === 'cc') {
        return text.substring(0, 10).replace(/(\d{9})(\d{1})/, '$1-$2');
      }
      break;
  }
}

const CustomFormControlAgencia = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  tipo, 
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      <FormControl key={"fc"+name}
        className="custom-form-control"
        type={type}
        name={name}
        onChange={(e) => {
          let saida = mascara(tipo, formik.values['banco_nome'], e.target.value); 
          formik.setFieldValue(name, saida)
        }}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={placeholder}
        bSsize={bSsize}
      />
      {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
      {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
    </FormGroup>
  );
};

export default CustomFormControlAgencia;
