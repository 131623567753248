import CoreService from "../service/CoreService";

export default class Service extends CoreService {
  async directionsDestinos(originLat, originLng, destLat, destLng, destinos) {
    let stringLatLng = "";
    for (let i in destinos) {
      if (Number(i) !== Number(destinos.length - 1)) {
        stringLatLng =
          stringLatLng + destinos[i].lng + "," + destinos[i].lat + ";";
      } else {
        stringLatLng = stringLatLng + destinos[i].lng + "," + destinos[i].lat;
      }
    }
    return this.callEndpointWithPayload("directions-route-directions", {
      originLat: originLat,
      originLng: originLng,
      destLng: destLng,
      destLat: destLat,
      destinos: stringLatLng,
    });
  }

  async updateAgenda(data, id, obs) {
    let result = await this.fetchOn('corrida/agendamento/', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        datahoraagendamento: data,
        id: id,
        obs: obs
      })
    })

    return result.result;
  }

  async getMsg(corrida) {
    let result = await this.fetchOn('postmsg/' + corrida);
    var msges = result.result;
    return msges;
  }

  async postMsg(corrida, msg) {
    console.log('msg',[corrida, msg])
    let result = await this.fetchOn('postmsg/' + corrida, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': this.getCookie('portal-token')
      },
      body: JSON.stringify({msg: msg})
    })
    return result.result;
  }

  async cancelTaxi(id, motivo, id_cancel) {
    //console.log("MOTIVO", motivo)
    let result = await this.fetchOn('corrida/cancelar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idCorrida: id,
        obscancelamento: motivo,
        idcancelamento: id_cancel,
        atendente: 'Portal',
        tipocancelamento: 'C',

      })
    })

    return result.result;
  }
  statusmotorista(id_corrida) {
    return this.callEndpointWithPayload("status-motorista", {
      id_corrida: id_corrida,
    });
  }
  driversAround(latitude, longitude) {
    return this.callEndpointWithPayload("motoristas-proximos", {
      latitude: latitude,
      longitude: longitude,
    });
  }

  async directions(originLat, originLng, destLat, destLng, destinos) {
    return this.callEndpointWithPayload('directions-route', {
      originLat: originLat,
      originLng: originLng,
      destLng: destLng,
      destLat: destLat,
      destinos: destinos,
    });
  }
}
