import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';

class AutoComplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
    };

  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() { }

  onChange = (event, { newValue }) => {
    this.props.changeTxt(newValue)
  };

  onSuggestionsFetchRequested = ({ value }) => {
      if (this.props.autocomplete) {
        this.props.autocomplete(value, this.props.center).then(result => {
            this.props.changeList(result);
          })
      }

  };

  onSuggestionsClearRequested = () => {
    this.props.changeList([]);
  };

  render() {
    return (
      <FormGroup className="autoComp">
        <Autosuggest
          ref={"auto_complete_helper"}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
          suggestions={this.props.address}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(e, { suggestion, method }) => {
            this.props.changeTxt(suggestion.fullAddress);
            if (this.props.Selected) {
              this.props.Selected(suggestion);
            }
          }}
          getSuggestionValue={item => item.endereco}
          renderSuggestion={item =>
            <div style={{padding: '6px 3px'}}>
              <i className="fa fa-map-marker"></i>&nbsp;
              {item.fullAddress}
            </div>
          }
          inputProps={{
            className: "form-control",
            value: this.props.endereco,
            onChange: this.onChange,
            placeholder: "Endereço",
            autoFocus:this.props.autoFocus ? true : false
          }}
        />
      </FormGroup>
    );
  }
}
export default AutoComplete;
