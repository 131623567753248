import React, { Component } from "react";
import Detail from "./view/Detail.js";
import List from "./view/List.js";
import ViewModel from "./controllers/ViewModel.js";
import SubHeader from "../header/SubHeaderView.js";
import Workbook from "react-excel-workbook-emptystring";

export default class CorridasAndamento extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.fetchData();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Corridas Em Andamento" textoCentral="Corridas" />
        <div className="page-content">
          <div className="container">

          <div className="rowAjust">
            {this.viewModel.state.showModal && (
              <Detail {...this.props} viewModel={this.viewModel} />
            )}
            <List {...this.props} viewModel={this.viewModel} />
          </div></div>
        </div>
      </div>
    );
  }
}
