class AlertService {
    static instance = null;
    alerts = [];
  
    static getInstance() {
      if (!AlertService.instance) {
        AlertService.instance = new AlertService();
      }
      return AlertService.instance;
    }
  
    showAlert(message, type = 'info') {
      this.alerts.push({ message, type });
      this.updateListeners();
    }
  
    updateListeners() {
      if (this.alerts.length > 0 && this.onAlert) {
        this.onAlert(this.alerts[0]);
      }
    }
  
    clearAlert() {
      this.alerts.shift();
      this.updateListeners();
    }
  
    setAlertListener(callback) {
      this.onAlert = callback;
    }
  }
  
  export default AlertService;
  