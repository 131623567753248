import Webservice from "../core/Webservice";


export class PreCadastroService extends Webservice {

  async realizarCadastro(dados, central) {
    console.log("dados", dados);
    let dadosCadastro = JSON.parse(JSON.stringify(dados));

    delete dadosCadastro.fotobase64;
    delete dadosCadastro.fotobase64_1;
    delete dadosCadastro.fotobase64_2;
    delete dadosCadastro.fotobase64_3;
    delete dadosCadastro.foto_carro_1;
    delete dadosCadastro.foto_carro_2;
    delete dadosCadastro.foto_carro_3;
    delete dadosCadastro.foto_carro_4;
    delete dadosCadastro.foto_carro_5;
    delete dadosCadastro.foto_carro_6;
    delete dadosCadastro.foto_carro_7;

    let cadastro = await this.SendRealizarCadastro(dadosCadastro, central);

    if (cadastro.result) {

      let arrayFotos = [ "fotobase64", "fotobase64_1", "fotobase64_2", "fotobase64_3", "foto_carro_1", "foto_carro_2", "foto_carro_3", "foto_carro_4", "foto_carro_5", "foto_carro_6", "foto_carro_7"];

      for (let index = 0; index < arrayFotos.length; index++) {
        let e = arrayFotos[index];

        if (!dados[e] || dados[e] == "") {
          continue;
        }
        await this.realizarCadastroFoto({
          campo: e,
          foto: dados[e],
          id: cadastro.result.id,
        }, central, cadastro.result.id);
      }
      
    }
  }

  async SendRealizarCadastro(dados, central) {
    dados.central = central;
    let result = await this.fetchOff("precadastro_new", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dados),
    }, central);
    return result;
  }

  async realizarCadastroFoto(dados, central, id) {
    dados.central = central;
    dados.id = id;


    let result = await this.fetchOff("precadastro_new_foto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dados),
    }, central);
    return result;
  }

  async getCidades(central) {
    let result = await this.fetchOff("precadastro_new/cidades", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }, central);
    return result.result;

  }

  async getCentral() {
    let sub = this.getCentralSubDominio()
    let centralData = this.getCentralInfosCores(sub)
    return centralData;
  }

  getCentralSubDominio() {
    let url = window.location.href
      .toLowerCase()
      .replace("http://", "")
      .replace("https://", "")
      .split("/")[0]
      .replace(/[0-9]/g, "")
      .replace(":", "")
      .split(".");
    let saida = null;
    let ex = ["com", "br", "www", "localhost", "s-portal", "portal", "portalgoapp", "sportalgoapp"];
    //console.log("getDadosCentralCores", url);
    for (let index = url.length - 1; index >= 0; index--) {
      const element = url[index];

      if (!ex.includes(element)) {
      //  console.log("getDadosCentralCores", element);
        saida = element;
      }
    }
    //console.log("getDadosCentralCores saida", saida);

    return !!saida ? saida : "gosolucoes";
  }
}
