import CoreService from "../service/CoreService";

export default class Service extends CoreService {
  async getFavoritosNew() {
    let result = await this.fetchOn("favoritos");
    return result.result;
  }

  async place(placeid, texto, lat, lon) {
    let response = await this.fetchOn(
      "google/place?place=" +
        placeid +
        "&texto=" +
        texto +
        "&lat=" +
        lat +
        "&lon=" +
        lon
    );
    return response.result;
  }

  async autocomplete(address, coords) {
    if (!coords) {
      coords = {
        lat: -25.450587,
        lng: -49.231315,
      };
    }
    let latitude = !!coords.lat
      ? !isNaN(coords.lat)
        ? coords.lat
        : -25.450587
      : -25.450587;
    let longitude = !!coords.lng
      ? !isNaN(coords.lng)
        ? coords.lng
        : -49.231315
      : -49.231315;

    let response = await this.fetchOn(
      "google/autocomplete?allTypes=1&radius=100000&strictbounds=1&latitude=" +
        latitude +
        "&longitude=" +
        longitude +
        "&address=" +
        address
    );
    return response.result;
  }
}
