import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import ReactTable from "react-table";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 0}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class ListFaturas extends Component {
  constructor(props) {
    super(props);
  }
  //! manter aqui
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  //! manter aqui
  componentDidMount() {
    console.log("----------------Aqui estão as minhas Props", this.props);

    let colunas = [];
    colunas.push({
      Header: "No.Fatura",
      accessor: "numerofaturamento",
    });
    colunas.push({
      Header: "Vencimento",
      accessor: "datavencimento",
      Cell: (row) => {
        return (
          <div>{moment(row.original.datavencimento).format("DD-MM-YYYY")}</div>
        );
      },
    });
    colunas.push({
      Header: "No.Vouchers",
      accessor: "corridas",
      Cell: (row) => {
        return <div>{row.original.corridas.length}</div>;
      },
    });
    colunas.push({
      Header: "Valor Bruto",
      accessor: "valor",
      Cell: (row) => {
        return (
          <div>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={Number(row.original.valor)}
              displayType={"text"}
              decimalSeparator={","}
              thousandSeparator={"."}
              prefix={"R$"}
            />
          </div>
        );
      },
    });

    if (!this.props.childProps.central.faturamanual) {
      colunas.push({
        Header: "Situação",
        accessor: "status",
        Cell: (row) => {
          return (
            <div>
              {row.original.status === 0
                ? "Nova"
                : row.original.status === 1
                ? "Boleto Enviado"
                : row.original.status === 2
                ? "Pago"
                : row.original.status === 9
                ? "Cancelada"
                : ""}
            </div>
          );
        },
      });
    }
    if (this.props.childProps.central.faturamanual) {
      colunas.push({
        Header: "Nota Fiscal",
        accessor: "numeronf",
      });
      colunas.push({
        Header: "Situação",
        accessor: "statusnf",
        Cell: (row) => {
          return (
            <div>
              {row.original.statusnf === 0
                ? "Nova"
                : row.original.statusnf === 1
                ? "Pendente"
                : row.original.statusnf === 3
                ? "Pago"
                : row.original.statusnf === 9
                ? "Cancelada"
                : ""}
            </div>
          );
        },
      });
    }

    this.props.viewModel.setState({ colunas: colunas });
  }

  render() {
    console.log("props", this.props.childProps.central);
    return (
      <div>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div
                    className="col-md-12"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div>
                      <div>
                        <ReactTable
                          manual
                          pages={this.props.viewModel.state.pages}
                          loading={this.props.viewModel.state.loading}
                          onFetchData={this.props.viewModel.fetchDataTimeOut}
                          data={this.props.viewModel.state.auth}
                          previousText="Anterior"
                          nextText="Próxima"
                          loadingText="Carregando..."
                          noDataText="Nenhum dado encontrado"
                          pageText="Página"
                          ofText="de"
                          rowsText="linhas"
                          getTdProps={(state, props, columns) => {
                            return {
                              className: "alignCenter",
                              onClick: (e) => {
                                this.props.viewModel.escolhecorrida(
                                  props.row._original
                                );
                                this.props.viewModel.setState({ open: true });
                              },
                            };
                          }}
                          columns={this.props.viewModel.state.colunas}
                          defaultPageSize={5}
                          className="-striped -highlight round7"
                          style={{
                            border: "none",
                          }}
                          getTheadProps={() => {
                            return {
                              className: "round7",
                              style: {
                                backgroundColor: "white",
                                color: "black",
                              },
                            };
                          }}
                          getTheadFilterProps={() => {
                            return { className: "round7" };
                          }}
                          getTheadFilterTrProps={() => {
                            return { className: "round7" };
                          }}
                          getTbodyProps={() => {
                            return { className: "round7" };
                          }}
                          getTheadThProps={() => {
                            return {
                              style: {
                                fontFamily: '"Roboto", sans-serif',
                                fontWeight: "bold",
                              },
                            };
                          }}
                          getTrProps={(state, rowInfo) => {
                            if (!!rowInfo) {
                              return {
                                className: "round7",
                              };
                            } else {
                              return {};
                            }
                          }}
                          PaginationComponent={CustomPaginationComponent}
                        />
                      </div>
                      {this.props.viewModel.state.my_corridas.length !== 0 &&
                        this.props.viewModel.state.my_voucher &&
                        this.props.viewModel.state.my_voucher.recibo &&
                        false && (
                          <div>
                            <button
                              id="btnPesquisar"
                              onClick={() =>
                                window.open(
                                  this.props.viewModel.state.my_voucher.recibo
                                    .secure_url,
                                  "_blank"
                                )
                              }
                              className="btn btn-sm yellow"
                            >
                              <i className="fa fa-check"></i> Fatura
                            </button>
                          </div>
                        )}

                      {this.props.viewModel.state.my_corridas.length !== 0 && (
                        <div>
                          <div
                            style={{
                              marginTop: 5,
                            }}
                          >
                            <button type="button" className="btn btn-default ">
                              {this.props.viewModel.criaExcel()}
                            </button>
                          </div>
                          <Row
                            style={{
                              border: "solid",
                              borderWidth: "1px",
                              paddingTop: 5,
                              paddingBottom: 10,
                              marginTop: 15,
                            }}
                          >
                            <Col md={1} xs={1}>
                              <b>Número da corrida</b>
                            </Col>
                            <Col md={1} xs={1}>
                              <b>Data da corrida</b>
                            </Col>
                            <Col md={1} xs={2}>
                              <b>Solicitante</b>
                            </Col>
                            <Col md={1} xs={2}>
                              <b>Passageiro</b>
                            </Col>
                            <Col md={1} xs={2}>
                              <b>Obs</b>
                            </Col>
                            <Col md={2} xs={2}>
                              <b>Origem</b>
                            </Col>
                            <Col md={2} xs={2}>
                              <b>Destino</b>
                            </Col>
                            <Col md={1} xs={1}>
                              <b>Centro de Custo</b>
                            </Col>
                            <Col md={1} xs={2}>
                              <b>Centro de Custo</b>
                            </Col>
                            <Col md={1} xs={1}>
                              <b>Valor</b>
                            </Col>
                          </Row>
                          {this.props.viewModel.state.my_corridas.map(
                            (e, i) => {
                              return (
                                <Row
                                  style={{
                                    backgroundColor:
                                      i % 2 === 0 ? "#eeeeee" : "#ffffff",
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    border: "solid",
                                    borderWidth: "1px",
                                    borderColor: "#eeefff",
                                  }}
                                  onClick={() => {
                                    this.props.viewModel.pegacorrida(
                                      e.t12_num_corrida
                                    );
                                  }}
                                  key={i}
                                >
                                  <Col md={1} xs={1}>
                                    {e.t12_num_corrida}
                                  </Col>
                                  <Col md={1} xs={1}>
                                    {moment(e.t12_dthr_corrida).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Col>
                                  <Col md={1} xs={2}>
                                    {e.voucher_usuarios_nome
                                      ? e.voucher_usuarios_nome
                                      : "-"}
                                  </Col>
                                  <Col md={1} xs={2}>
                                    {e.t12_nome ? e.t12_nome : "-"}
                                  </Col>
                                  <Col md={1} xs={2}>
                                    {e.t12_obs ? e.t12_obs : "-"}
                                  </Col>
                                  <Col md={2} xs={2}>
                                    {e.origem ? e.origem : "-"}
                                  </Col>
                                  <Col md={2} xs={2}>
                                    {e.destino ? e.destino : "-"}
                                  </Col>
                                  <Col md={1} xs={1}>
                                    {e.voucher_departamento_nome
                                      ? e.voucher_departamento_nome
                                      : "-"}
                                  </Col>
                                  <Col md={1} xs={2}>
                                    {e.voucher_departamento_descricao
                                      ? e.voucher_departamento_descricao
                                      : "-"}
                                  </Col>
                                  <Col md={1} xs={1}>
                                    {e.valorcalculado &&
                                      "R$ " +
                                        Number(e.valorcalculado)
                                          .toFixed(2)
                                          .replace(".", ",")}
                                  </Col>
                                </Row>
                              );
                            }
                          )}

                          {false && !this.props.viewModel.state.selection && (
                            <button
                              onClick={() => this.props.viewModel.geraBoleto()}
                            >
                              {" "}
                              Gerar fatura
                            </button>
                          )}
                        </div>
                      )}
                      {false && (
                        <button
                          onClick={() => this.props.viewModel.cancelaFatura()}
                        >
                          Cancela Fatura
                        </button>
                      )}
                      <Modal
                        show={this.props.viewModel.state.show}
                        onHide={this.props.viewModel.handleClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {this.props.viewModel.state.corr[0] &&
                              this.props.viewModel.state.corr[0].dados_corrida
                                .id}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col md={6} xs={6}>
                              <h4>
                                <b>Passageiro </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.cliente}
                              </p>
                              <h4>
                                <b>Origem </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.endereco}
                              </p>
                              <h4>
                                <b>Destino</b>{" "}
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.enderecodestino}
                              </p>
                            </Col>
                            <Col md={6} xs={6}>
                              <h4>
                                <b>Solicitante </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.voucher_usuarios_nome}
                              </p>
                              <h4>
                                <b>Passageiro</b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.t12_nome}
                              </p>

                              <h4>
                                <b>Centro de Custo </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida.voucher_departamento_nome}
                              </p>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  this.props.viewModel.state.corr[0]
                                    .dados_corrida
                                    .voucher_departamento_descricao}
                              </p>
                            </Col>
                            <Col md={6} xs={6}>
                              <h4>
                                <b>Hora embarque </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  moment(
                                    this.props.viewModel.state.corr[0]
                                      .dados_corrida.datahora
                                  ).format("DD/MM/YYYY HH:mm")}
                              </p>
                              <h4>
                                <b>Hora chegada </b>
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] &&
                                  moment(
                                    this.props.viewModel.state.corr[0]
                                      .dados_corrida.dataHoraQrv
                                  ).format("DD/MM/YYYY HH:mm")}
                              </p>
                              <h4>
                                <b>Valor</b>{" "}
                              </h4>
                              <p>
                                {this.props.viewModel.state.corr[0] && (
                                  <CurrencyFormat
                                    value={Number(
                                      this.props.viewModel.state.corr[0]
                                        .dados_corrida.valorcalculado
                                    ).toFixed(2)}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    prefix={"R$"}
                                  />
                                )}
                              </p>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button onClick={this.props.viewModel.handleClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListFaturas;
