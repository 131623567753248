import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import "../styles/CustomFormControl.css";

const CustomFormControlTelefone = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      <FormControl key={"fc"+name}
        className="custom-form-control"
        type={type}
        name={name}
        onChange={(e) => {
          let text = e.target.value.replace(/[^+\d]/g, "");
                    let x = "";

                    text = text.slice(0, 11);
                    if (text.length > 10) {
                      x = text
                        .replace(/\D+/g, "")
                        .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
                    } else {
                      x = text
                        .replace(/\D+/g, "")
                        .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
                    }
            formik.setFieldValue(name, x)
        }}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={placeholder}
        bSsize={bSsize}
      />
      {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
      {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
    </FormGroup>
  );
};

export default CustomFormControlTelefone;
