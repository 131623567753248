import { Component } from "react";
import SubHeader from "../header/SubHeaderView";
import ListFaturas from "./view/List";
import ViewModel from "./controllers/ViewModel";

export default class FaturasV2 extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.fetchData();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader
          textoEsquerda="Faturas"
          textoCentral="Faturas"
          textoDireita="Faturas"
        />
        <div className="page-content">
          <div className="container">
            <ListFaturas {...this.props} viewModel={this.viewModel} />
          </div>
        </div>
      </div>
    );
  }
}
