import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import "react-table/react-table.css";
import moment from "moment";
import Dialog from "react-bootstrap-dialog-v2";
import WS from "../../../services/webservice.js";
import readXlsxFile from "read-excel-file";
import Workbook from "react-excel-workbook-emptystring";
import { withRouter } from "react-router-dom";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 0}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class ListarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      showExportar: "none",
      showImportar: "none",
      showLogs: "none",
      auth: [],
      pages: null,
      checked: true,
      loading: true,
    };
    this.getUser = this.getUser.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendexcel = this.sendexcel.bind(this);

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  async getUser(email) {
    let result = await this.webservice.searchUser(email);
    result = await result.json();
    return result;
  }

  componentDidMount() {}

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ dados, instance });
    this.setState({ loading: true });
    let empresa = this.webservice.getCookie("empresa-id");
    // fetch your data
    let pages = dados.page + 1;
    let result = await this.webservice.getTipo(
      "voucher_empresa/" + empresa + "/funcionario",
      "",
      dados.sorted,
      pages,
      dados.pageSize,
      dados.filtered
    );
    let count = result.headers.get("X-Total-Count");
    result = await result.json();
    console.log("LIST", result);

    let auth = result.map(function (el) {
      return {
        ...el,
        statuss:
          el.status === 0 ? "ATIVO" : el.status === 1 ? "INATIVO" : "BLOQUEADO",
        svalorlimit: "R$ " + (el.valorlimit - el.saldo_gasto).toFixed(2),
      };
    });

    console.log("LIST", auth);
    this.setState({
      auth: auth,
      pages: Math.ceil(count / dados.pageSize),
      loading: false,
    });
  }

  toggleRow(autorizado) {
    this.setState({ selected: autorizado });
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => {
        this.fetchData(dados, instance);
      }, 300),
    });
  }

  handleInputChange(event) {
    this.setState({
      selected: event,
    });
  }

  activateUser() {
    Dialog.resetOptions();
    if (this.state.selected) {
      if (
        parseInt(this.state.selected.usuario, 10) ===
        this.props.childProps.user.id
      ) {
        Dialog.resetOptions();
        this.dialog.showAlert(
          "Você não pode ativar o usuário que está logado!"
        );
        return;
      }
      this.webservice
        .changeStatus(
          this.state.selected.voucher_usuarios_email,
          0,
          this.state.selected.id
        )
        .then(() => {
          this.fetchData(this.state.dados, this.state.instance);
          this.dialog.showAlert("Autorizado ativado com sucesso!");
        });
    } else {
      this.dialog.showAlert("Por favor selecione um autorizado!");
    }
  }

  inativeUser() {
    Dialog.resetOptions();
    if (this.state.selected) {
      if (
        parseInt(this.state.selected.usuario, 10) ===
        this.props.childProps.user.id
      ) {
        Dialog.resetOptions();
        this.dialog.showAlert(
          "Você não pode inativar o usuário que está logado!"
        );
        return;
      }
      this.webservice
        .changeStatus(
          this.state.selected.voucher_usuarios_email,
          1,
          this.state.selected.id
        )
        .then(() => {
          this.fetchData(this.state.dados, this.state.instance);
          this.dialog.showAlert("Autorizado inativado com sucesso!");
        });
    } else {
      this.dialog.showAlert("Por favor selecione um autorizado!");
    }
  }

  blockUser() {
    Dialog.resetOptions();
    if (this.state.selected) {
      if (
        parseInt(this.state.selected.usuario, 10) ===
        this.props.childProps.user.id
      ) {
        Dialog.resetOptions();
        this.dialog.showAlert(
          "Você não pode bloquear o usuário que está logado!"
        );
        return;
      }
      this.webservice
        .changeStatus(
          this.state.selected.voucher_usuarios_email,
          2,
          this.state.selected.id
        )
        .then(() => {
          this.fetchData(this.state.dados, this.state.instance);
          this.dialog.showAlert("Autorizado bloqueado com sucesso!");
        });
    } else {
      this.dialog.showAlert("Por favor selecione um autorizado!");
    }
  }

  findperfil(perfil) {
    if (perfil === "Administrador") return 1;
    else if (perfil === "Gestor") return 2;
    else if (perfil === "Gestor Aprovador") return 3;
    else if (perfil === "Contas a Pagar") return 4;
    else if (perfil === "Usuário Solicitante") return 5;
    else if (perfil === "Sub-Gestor") return 6;
    else if (perfil === "Usuário") return 7;
  }

  async sendexcel() {
    const input = document.getElementById("input");
    //console.log("WATAFOCK MAN", input.files[0])
    var vetfunc = [];
    let obj = {};
    let email, celular, nome, cpf, centrodecusto, superior;
    //let self
    readXlsxFile(input.files[0]).then(async (rows) => {
      console.log("rows", rows);
      for (let i in rows) {
        console.log("ohla o i", i);
        if (i > 0) {
          status = rows[i][0];
          email = rows[i][1];
          celular = rows[i][2];
          nome = rows[i][4];
          centrodecusto = rows[i][3];
          superior = rows[i][3];
          cpf = rows[i][5];
          obj = {
            status: status,
            email: email ? email : "",
            telefone: celular,
            nome: nome,
            matricula: cpf,
            cpf: cpf,
            centrodecusto: centrodecusto,
            superior: superior,
          };
          vetfunc.push(obj);
          console.log("inside", vetfunc);
        }
      }
      await this.webservice.enviaexcel(vetfunc);
      this.dialog.showAlert("Importação Finalizada com Sucesso!");
      this.setState({
        showImportar: this.state.showImportar === "none" ? "block" : "none",
      });
      this.fetchData(this.state.dados, this.state.instance);
    });
  }

  render() {
    //Change the current language to portuguese BR
    require("moment/locale/pt-br.js");
    moment.locale("pt-br");

    return (
      <div>
        <div>
          <div>
            <div>
              <div className="container">
                <div className="row">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <button
                        type="button"
                        className="btn round7 bgColor2 fontColor5 hmargin5"
                        onClick={() =>
                          this.props.history.push("/cadastrar-usuario")
                        }
                      >
                        + Novo
                      </button>
                    </div>

                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn round7 bgColor2 fontColor5 hmargin5"
                        onClick={() =>
                          this.setState({
                            showExportar:
                              this.state.showExportar === "none"
                                ? "block"
                                : "none",
                          })
                        }
                      >
                        <i className="fa fa-share"></i>&nbsp;
                        <span className="hidden-480">Exportar</span>&nbsp;
                        <i className="fa fa-angle-down"></i>
                      </button>
                      <ul
                        className="dropdown-menu pull-right"
                        style={{ display: this.state.showExportar }}
                      >
                        <li style={{ padding: 5 }}>
                          {
                            <Workbook
                              element={
                                <button
                                  type="button"
                                  className="link-button"
                                  href
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  style={{ margin: 5 }}
                                >
                                  <i className="fa fa-file-excel-o"></i>
                                  Excel Completo
                                </button>
                              }
                            >
                              <Workbook.Sheet
                                data={this.state.auth}
                                name="Cadastros Autorizados"
                              >
                                <Workbook.Column
                                  label="Status"
                                  value="statuss"
                                  width={20}
                                />
                                <Workbook.Column
                                  label="Email"
                                  value="voucher_usuarios_email"
                                  width={20}
                                />
                                <Workbook.Column
                                  label="Celular"
                                  value="voucher_usuarios_telefone"
                                  width={20}
                                />
                                <Workbook.Column
                                  label="Perfil"
                                  value="voucher_roles_descricao"
                                  width={20}
                                />
                                <Workbook.Column
                                  label="Nome"
                                  value="voucher_usuarios_nome"
                                  width={20}
                                />
                                <Workbook.Column
                                  label="CPF"
                                  value="matricula"
                                  width={20}
                                />
                              </Workbook.Sheet>
                            </Workbook>
                          }
                        </li>
                      </ul>
                      <button
                        type="button"
                        className="btn round7 bgColor2 fontColor5 hmargin5"
                        onClick={() =>
                          this.setState({
                            showImportar:
                              this.state.showImportar === "none"
                                ? "block"
                                : "none",
                          })
                        }
                      >
                        <i className="fa fa-share"></i>&nbsp;
                        <span className="hidden-480">Importar</span>&nbsp;
                        <i className="fa fa-angle-down"></i>
                      </button>
                      <ul
                        className="dropdown-menu pull-right"
                        style={{ display: this.state.showImportar }}
                      >
                        <li style={{ padding: 5 }}>
                          <input
                            type="file"
                            id="input"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const allowedExtensions = ["xlsx", "csv"];
                              const fileExtension = file.name.split(".").pop();

                              if (!allowedExtensions.includes(fileExtension)) {
                                alert(
                                  "Só é permitido arquivos do tipo csv ou xlsx"
                                );
                                return;
                              }
                            }}
                          />
                          <button onClick={() => this.sendexcel()}>
                            Salvar
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <ReactTable
                      manual
                      pages={this.state.pages}
                      pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                      loading={this.state.loading}
                      onFetchData={this.fetchDataTimeOut}
                      data={this.state.auth}
                      previousText="Anterior"
                      nextText="Próxima"
                      loadingText="Carregando..."
                      noDataText="Nenhum dado encontrado"
                      pageText="Página"
                      ofText="de"
                      rowsText="linhas"
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      columns={[
                        {
                          id: "checkbox",
                          filterable: false,
                          accessor: "",
                          width: 20,
                          sortable: false,
                          Cell: ({ original }) => (
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={
                                this.state.selected &&
                                this.state.selected.id === original.id
                                  ? true
                                  : false
                              }
                              onChange={() => this.handleInputChange(original)}
                            ></input>
                          ),
                          Header: (
                            <div className="header-table-left">
                              <i className="fa fa-pencil-square-o"></i>
                            </div>
                          ),
                        },
                        {
                          Header: "Status",
                          filterable: false,
                          accessor: "status",
                          width: 130,
                          Cell: (row) => (
                            <div>
                              {row.value === 0 && (
                                <div className="label lable-sm label-success">
                                  {" "}
                                  <i className="fa fa-smile-o" /> ATIVO
                                </div>
                              )}
                              {row.value === 1 && (
                                <div className="label lable-sm label-danger">
                                  <i className="fa fa-frown-o" /> INATIVO
                                </div>
                              )}
                              {row.value === 2 && (
                                <div className="label lable-sm label-warning">
                                  <i className="fa fa-meh-o" /> BLOQUEADO
                                </div>
                              )}
                            </div>
                          ),
                        },
                        {
                          Header: "Email",
                          accessor: "voucher_usuarios_email",
                          width: 220,
                        },
                        {
                          Header: "N Celular",
                          accessor: "voucher_usuarios_telefone",
                        },
                        {
                          id: "voucher_roles.descricao",
                          Header: "Perfil",
                          accessor: "voucher_roles_descricao",
                          width: 160,
                          filterMethod: (filter, row) => {
                            if (filter.value === "") {
                              return true;
                            }
                            if (filter.value === "Administrador") {
                              return row[filter.id] === "Administrador";
                            }
                            if (filter.value === "Gestor") {
                              return row[filter.id] === "Gestor";
                            }
                            if (filter.value === "Gestor Aprovador") {
                              return row[filter.id] === "Gestor Aprovador";
                            }
                            if (filter.value === "Secretário") {
                              return row[filter.id] === "Secretário";
                            }
                            if (filter.value === "Usuário Solicitante") {
                              return row[filter.id] === "Usuário Solicitante";
                            }
                            if (filter.value === "Sub-Gestor") {
                              return row[filter.id] === "Sub-Gestor";
                            }
                            if (filter.value === "Usuário") {
                              return row[filter.id] === "Usuário";
                            }
                            return !row[filter.id];
                          },
                          Filter: ({ filter, onChange }) => (
                            <select
                              onChange={(event) => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : ""}
                            >
                              <option value="">...</option>
                              <option value="Administrador">
                                Administrador
                              </option>
                              <option value="Gestor">Gestor</option>
                              {/*  <option value="Gestor Aprovador">Gestor Aprovador</option>*/}
                              {/*<option value="Contas a Pagar">Contas a Pagar</option>*/}
                              <option value="Usuário Solicitante">
                                Usuário Solicitante
                              </option>
                              {/*<option value="Sub-Gestor">Sub-Gestor</option>*/}
                              <option value="Usuário">Usuário</option>
                            </select>
                          ),
                        },
                        {
                          Header: "Nome",
                          accessor: "voucher_usuarios_nome",
                        },
                        {
                          Header: "CPF",
                          accessor: "matricula",
                        },

                        {
                          Header: "Ações",

                          filterable: false,
                          sortable: false,
                          style: {
                            cursor: "pointer",
                          },
                          Cell: (props) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-between",
                              }}
                            >
                              <div
                                className="btn round7 bgColor2 fontColor5 hmargin5"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: "/editar-usuario",
                                    state: {
                                      autorizado: props.original,
                                    },
                                  })
                                }
                              >
                                Editar
                              </div>
                              <div
                                className="btn round7 bgColor2 fontColor5 hmargin5"
                                onClick={() => {
                                  console.log(
                                    "---------------------->this.state.selected",
                                    this.state.selected
                                  );
                                  console.log(
                                    "---------------------->this.props.childProps",
                                    this.props.childProps
                                  );

                                  if (
                                    this.state.selected
                                    // && this.props.childProps &&
                                    // this.props.childProps.user
                                  ) {
                                    // if (
                                    //   parseInt(
                                    //     this.state.selected.usuario,
                                    //     10
                                    //   ) === this.props.childProps.user.id
                                    // ) {
                                    //   Dialog.resetOptions();
                                    //   this.dialog.showAlert(
                                    //     "Você não pode deletar o usuário que está logado!"
                                    //   );
                                    // } else {
                                    Dialog.setOptions({
                                      defaultOkLabel: "Sim",
                                      defaultCancelLabel: "Não",
                                      primaryClassName: "btn-danger ",
                                    });
                                    this.dialog.show({
                                      body: (
                                        <div>
                                          Você tem certeza que deseja deletar
                                          esse autorizado?
                                          <br />
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 12,
                                            }}
                                          >
                                            <b>
                                              Obs: Ao deletar esse autorizado,
                                              todos os dados dele serão
                                              perdidos!
                                            </b>
                                          </span>
                                        </div>
                                      ),
                                      actions: [
                                        Dialog.CancelAction(() => {}),
                                        Dialog.OKAction(() => {
                                          Dialog.resetOptions();
                                          this.webservice
                                            .deleteUser(this.state.selected.id)
                                            .then(() => {
                                              this.fetchData(
                                                this.state.dados,
                                                this.state.instance
                                              );
                                              this.dialog.showAlert(
                                                "Autorizado deletado com sucesso!"
                                              );
                                            });
                                        }),
                                      ],
                                      bsSize: "large",
                                      onHide: (dialog) => {
                                        dialog.hide();
                                      },
                                    });
                                    // }
                                  } else {
                                    Dialog.resetOptions();
                                    this.dialog.showAlert(
                                      "Por favor selecione um autorizado!"
                                    );
                                  }
                                }}
                              >
                                Excluir
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight round7"
                      style={{
                        border: "none",
                      }}
                      getTheadProps={() => {
                        return {
                          className: "round7",
                          style: {
                            backgroundColor: "white",
                            color: "black",
                          },
                        };
                      }}
                      getTheadFilterProps={() => {
                        return { className: "round7" };
                      }}
                      getTheadFilterTrProps={() => {
                        return { className: "round7" };
                      }}
                      getTbodyProps={() => {
                        return { className: "round7" };
                      }}
                      getTdProps={() => {
                        return { className: "alignCenter" };
                      }}
                      getTheadThProps={() => {
                        return {
                          style: {
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: "bold",
                          },
                        };
                      }}
                      getTrProps={(state, rowInfo) => {
                        if (!!rowInfo) {
                          return {
                            className: "round7",
                          };
                        } else {
                          return {};
                        }
                      }}
                      PaginationComponent={CustomPaginationComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          ref={(el) => {
            this.dialog = el;
          }}
        />
      </div>
    );
  }
}
export default ListarUsuarios;
