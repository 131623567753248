import CoreModel from "../../service/CoreModel";
import Service from "./Service";

export default class ViewModel extends CoreModel {
  constructor(props) {
    super(props);
    this.service = new Service();
    this.state = {
      alertDisplay: "display-hide",
      alertText: "",
      alertDisplay2: "display-hide",
      alertText2: "",
      showExportar: "none",
      favoritos: [],
      favorito: {},
      favoritoSelected: null,
      telefone: "",
      nome: "",
      email_corrida: false,
      email_corrida_resp: false,
      email_corrida_acomp: false,
      email_corrida_acomp_resp: false,
      senha: "",
      senhaConfirm: "",
      fotoPerfil: "",
    };

    this.saveInfos = this.saveInfos.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.getFavoritos = this.getFavoritos.bind(this);
    this.savePortalPass = this.savePortalPass.bind(this);

    this.init = this.init.bind(this);
    this.cleanup = this.cleanup.bind(this);
  }

  cleanup() {
    console.log("ViewModel cleanup");
    clearInterval(this.timerId);
  }

  async init() {
    // console.log("this.props.childProps", this.props.childProps);
    if (this.props.childProps.func.favorito_id === null) {
      this.setState({ favoritoSelected: 0 });
    } else {
      this.setState({ favoritoSelected: this.props.childProps.func.favorito_id });
    }
    this.getFavoritos();

    if (this.props.childProps.user.user) {
      let user = this.props.childProps.user.user.id;
      let dados = await this.service.getUserByIdService(user);
      // console.log("------------------------->dados", dados);
      if (dados) {
        this.setState({
          nome: dados.nome,
          telefone: dados.telefone,
          email_corrida: dados.email_corrida,
          email_corrida_resp: dados.email_corrida_resp,
          email_corrida_acomp: dados.email_corrida_acomp,
          email_corrida_acomp_resp: dados.email_corrida_acomp_resp,
        });
      }
    }
  }

  async saveInfos() {
    
      const { nome, telefone, email_corrida, email_corrida_resp, email_corrida_acomp, email_corrida_acomp_resp } =
        this.state;
      const user = this.props.childProps.user.user;

      // console.log('---------------------- USER -------------------------->', user.id)

      console.log("Antes da chamada editUserService");
      const editResult = await this.service.editUserService(
        user.email,
        nome,
        telefone,
        user.id,
        email_corrida,
        email_corrida_resp,
        email_corrida_acomp,
        email_corrida_acomp_resp
      );
      console.log("Após a chamada editUserService");

      user.nome = nome;
      user.telefone = telefone;
      this.props.childProps.user.user = user;

      if (!!editResult) {
        this.setState({ alertDisplay2: "alert-success", alertText2: "Informações salvas com sucesso!"});
      } else {
        this.setState({ alertDisplay2: "alert-danger", alertText2:  "Erro ao salvar informações!"});
      }
    
  }

  async savePortalPass() {
    if (this.state.senhaConfirm !== "" && this.state.senha !== "") {
      if (this.state.senhaConfirm === this.state.senha) {
        try {
          await this.service.editSenhaUserService(this.state.senha, this.props.childProps.user.id);
          alert("Senha alterada com sucesso!");
        } catch (error) {
          console.error("Erro ao alterar a senha:", error);
          this.setState({ alertDisplay: "alert-danger", alertText: "Erro ao alterar a senha!" });
        }
      } else {
        this.setState({ alertDisplay: "alert-danger", alertText: "As senhas não conferem!" });
      }
    } else {
      this.setState({ alertDisplay: "alert-danger", alertText: "Por favor preencha sua nova senha!" });
    }
  }

  //! creio que pensaram em criar essa função, mas desistiram no meio do caminho
  //saveAuthPass() {}

  async saveConfig() {
    this.props.childProps.func.favorito_id = this.state.favoritoSelected;
    try {
      await this.service.saveFuncFavoritoService(this.props.childProps.func.id, this.state.favoritoSelected);
      this.setState({ alertDisplay: "alert-success", alertText: "Configurações salvas com sucesso!" });
    } catch (error) {
      console.error("Erro ao salvar configurações:", error);
      this.setState({ alertDisplay: "alert-danger", alertText: "Erro ao salvar configurações!" });
    }
  }

  async getFavoritos() {
    try {
      const result = await this.service.getFavoritosNewService();
      let fav = result.map((m, i) => {
        return { ...m, text: m.endereco };
      });
      fav.push({ id: 0, text: "Não quero pré-selecionar um endereço!" });
      console.log(fav);
      this.setState({ favoritos: fav });
    } catch (error) {
      console.error("Erro ao obter favoritos:", error);
    }
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ fotoPerfil: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
