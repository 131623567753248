import CoreModel from "../../../service/CoreModel";
import Service from "./Service";
import moment from "moment";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      fullData: [],
      data: [],
      pages: null,
      loading: true,
      currentPage: 1,
      pageSize: 10,
      successMessage: "",
      errorMessage: "",
      selecionado: {},
      showModal: false,
      center: {
        latitude: 0,
        longitude: 0,
      },
      orig: {
        latitude: 0,
        longitude: 0,
      },
      dest: {
        latitude: 0,
        longitude: 0,
      },
      dataFinal: moment(),
      dataInicial: moment().add('days', -180).startOf("month"),
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchData2 = this.fetchData2.bind(this);
    this.fetchData3 = this.fetchData3.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
    this.escolhecorrida = this.escolhecorrida.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageChange2 = this.onPageChange2.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);

  }



  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance) }, 300)
    });
  }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ dados, instance });
    this.setState({ loading: true });
    //let empresa = this.webservice.getCookie('empresa-id');
    // fetch your data
    let order = dados.sorted;
    if (order.length <= 0) {
      order = [{ id: "t12_num_corrida", desc: true }]
    }
    let pages = dados.page + 1;
    let result;
    result = await this.service.getTipo2('corridas?faturamentovoucher_id={"eq": null}&voucher_eletronico_validado={"eq": true}&t12_st_corrida={"eq":"E"}&t12_dthr_corrida={"gte": " ' + moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") + ' " }', '', order, pages, dados.pageSize, dados.filtered);
    let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      auth: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false,
    })
  }

  fetchData3 = async () => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getTipo(
        'corridas',
        "",
        [{ id: "-t12_dthr_corridas" }],
        this.state.currentPage,
        this.state.pageSize,
        [{ id: "ztatus1", value: "5"}]
      );
      let fullData = result.result;
      let count = result.count;
      this.setState({
        showModal: false,
        fullData,
        data: fullData,
        pages: Math.ceil(count / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  fetchData2 = async () => {
    this.setState({ loading: true });
    try {
      const fullData = await this.service.listar(
        this.state.dataInicial,
        this.state.dataFinal
      );
      this.setState({
        showModal: false,
        fullData,
        data: fullData.slice(0, this.state.pageSize),
        pages: Math.ceil(fullData.length / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };


  closeDetails = () => {
    this.setState({ showModal: false, selecionado: {} });
    this.fetchData();
  }
  escolhecorrida = (selecionado) => {
    this.setState({
      selecionado: selecionado,
      showModal: true,
      center: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      orig: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      dest: {
        latitude: selecionado.latitudedestino,
        longitude: selecionado.longitudedestino,
      },
    });
  };
  onPageChange = (pageIndex) => {
    this.setState({
      currentPage: pageIndex,
    });
    this.fetchData();
  };
  onPageChange2 = (pageIndex) => {
    const { fullData, pageSize } = this.state;
    const startRow = pageIndex * pageSize;
    const endRow = startRow + pageSize;
    this.setState({
      currentPage: pageIndex,
      data: fullData.slice(startRow, endRow),
    });
  };
}
