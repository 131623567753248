import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import "../styles/CustomFormControl.css";
import FileBase64 from 'react-file-base64';

const CustomFormControlFoto = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      {!!formik.values[name] && (
        <div key={"fcd"+name} style={{display: 'block'}}>
        <img key={"fci"+name}
          src={formik.values[name]}
          alt="Foto"
          style={{ height: 100 }}
        /></div>
      )}
      <FileBase64 key={"fc"+name}
        multiple={ false }
        onDone={ (x) => {
          formik.setFieldValue(name, !!x.base64 ? x.base64 : "")
        } } />
        {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
        {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
      </FormGroup>
  );
};

export default CustomFormControlFoto;
