import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { old } from "../../go-versao";

const estiloNavItem = { color: "white", fontWeight: "bold" };

class SubHeader extends Component {
  render() {
    const {
      textoEsquerda,
      textoCentral = "Painel",
      textoDireita,
      useColors,
    } = this.props;
    const textoFinalDireita = textoDireita || textoEsquerda;
    var cores = {
      cor1: "black",
      cor2: "white",
      cor3: "black",
      cor4: "white",
      cor5: "black",
      cor6: "white",
    };
    if (!useColors || !useColors.cor1) {
      cores = {
        cor1: "black",
        cor2: "white",
        cor3: "black",
        cor4: "white",
        cor5: "black",
        cor6: "white",
      };
    } else {
      cores = useColors;
    }
    if (!old) {
      return (
        <div className="bgColor2">
          <div className="container">
            <div
              className="row"
              style={{
                padding: "10px 0",
                margin: "auto",
                marginBottom: 10,
                display: "flex",
              }}
            >
              <div
                className={"col-md-10 fontColor5"}
                style={{
                  fontSize: 20,
                  fontWeight: "bolder",
                  textAlign: "left",
                  margin: "auto",
                }}
              >
                {textoEsquerda}
              </div>
              <div
                className={"col-md-1 fontColor5"}
                style={{
                  fontSize: 10,
                  fontWeight: "bolder",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {textoCentral}
              </div>
              <div
                className={"col-md-1 fontColor5"}
                style={{
                  fontSize: 10,
                  fontWeight: "bolder",
                  textAlign: "right",
                  margin: "auto",
                }}
              >
                {textoFinalDireita}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        
          <div className="container">
            <div className="rowAjust">
            <div className="bgColor2 round7">
              <div>
                <div
                  style={{
                    padding: "10px 0",
                    margin: "auto",
                    marginBottom: 10,
                    display: "flex",
                  }}
                >
                  <div
                    className={"fontColor5"}
                    style={{
                      fontSize: 20,
                      fontWeight: "bolder",
                      textAlign: "left",
                      margin: "auto",
                    }}
                  >
                    {textoEsquerda}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SubHeader;
