import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactTable from "react-table";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import AutoComplete from "../autocomplete/AutoComplete.js";
import InputMask from "react-input-mask";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Dialog from "react-bootstrap-dialog-v2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import Hotkeys from "react-hot-keys";
import TipoCorridaApi from "./tipoCorridaApi.js";
import SubHeaderView from "../header/SubHeaderView";
import ViewModel from "./ViewModel";
import GoMap from "../map/GoMap.js";
import Icon from "react-icons-wrapper";
import IconButton from "../extensions/IconButton.js";
import Box from "@material-ui/core/Box";
import "moment/locale/pt-br.js";
import { cnpj, cpf } from "cpf-cnpj-validator";

class Solicitar extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;

    this.tempo = this.tempo.bind(this);
    this.sendTaxi = this.sendTaxi.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.modalFavoritoHide = this.modalFavoritoHide.bind(this);
    this.modalEditHide = this.modalEditHide.bind(this);
    this.deleteFavorito = this.deleteFavorito.bind(this);
    this.salvarFavorito = this.salvarFavorito.bind(this);
    this.newPag = this.newPag.bind(this);
    this.manageShortcut = this.manageShortcut.bind(this);
    this.setFavorito = this.setFavorito.bind(this);
    this.setTipoPagamentoNew = this.setTipoPagamentoNew.bind(this);
    this.setTipoPagamento = this.setTipoPagamento.bind(this);
    this.setInpudDestino = this.setInpudDestino.bind(this);
    this.showHideSolicitante = this.showHideSolicitante.bind(this);
    this.showHideOrigem = this.showHideOrigem.bind(this);
    this.showHideMap = this.showHideMap.bind(this);
    this.showHidePagamento = this.showHidePagamento.bind(this);
    this.showHideDestino = this.showHideDestino.bind(this);
    this.calculaValor = this.calculaValor.bind(this);
    this.changeEndereco = this.changeEndereco.bind(this);
    this.changeEnderecoExtra = this.changeEnderecoExtra.bind(this);
    this.changeEnderecoOrigem = this.changeEnderecoOrigem.bind(this);
    this.changeEnderecoOrigemExtra = this.changeEnderecoOrigemExtra.bind(this);
    this.changeEnderecoDestino = this.changeEnderecoDestino.bind(this);
    this.changeEnderecoDestinoExtra =
      this.changeEnderecoDestinoExtra.bind(this);
    this.changeOptions = this.changeOptions.bind(this);
    this.onChangeNomeFunc = this.onChangeNomeFunc.bind(this);
    this.onBlurNomeFunc = this.onBlurNomeFunc.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.atualizaMapa = this.atualizaMapa.bind(this);
    this.showHideOpcoes = this.showHideOpcoes.bind(this);

    this.showModalObservacao = this.showModalObservacao.bind(this);
    this.hideModalObservacao = this.hideModalObservacao.bind(this);
    this.hideModalObservacaoDestino =
      this.hideModalObservacaoDestino.bind(this);
    this.showModalObservacaoDestino =
      this.showModalObservacaoDestino.bind(this);

    this.refHeigth = React.createRef();
    this.refWidth = React.createRef();
    this.refOffset = React.createRef();
  }
  hideModalObservacaoDestino() {
    this.viewModel.hideModalObservacaoDestino();
  }
  showModalObservacaoDestino(id) {
    this.viewModel.showModalObservacaoDestino(id);
  }
  hideModalObservacao() {
    this.viewModel.hideModalObservacao();
  }
  showModalObservacao() {
    this.viewModel.showModalObservacao();
  }
  showHideSolicitante() {
    this.viewModel.showHideSolicitante();
  }
  showHideOrigem() {
    this.viewModel.showHideOrigem();
  }
  showHideMap() {
    this.viewModel.showHideMap();
  }

  showHidePagamento() {
    this.viewModel.showHidePagamento();
  }

  showHideOpcoes() {
    this.viewModel.showHideOpcoes();
  }
  showHideDestino() {
    this.viewModel.showHideDestino();
  }

  setTipoPagamentoNew(tipo, pgtos) {
    this.viewModel.setTipoPagamentoNew(tipo, pgtos);
  }

  setTipoPagamento(tipo, pgto) {
    this.viewModel.setTipoPagamentoNew(tipo, pgto);
  }

  async newPag() {
    this.viewModel.newPag();
    await this.componentWillMount();
    this.componentDidMount();
  }
  salvarFavorito() {
    this.viewModel.salvarFavorito();
  }

  deleteFavorito(id) {
    this.viewModel.deleteFavorito(id);
  }

  onBlurNomeFunc(event, { highlightedSuggestion }) {
    this.viewModel.onBlurNomeFunc(highlightedSuggestion);
  }

  onChangeNomeFunc(event, { newValue }) {
    this.viewModel.onChangeNomeFunc(newValue);
  }

  async setFavorito(fav, i) {
    this.viewModel.setFavorito(fav, i);
  }

  setInpudDestino(input, key) {
    this.viewModel.setInpudDestino(input, key);
  }

  handleValueChange(value) {
    this.viewModel.handleValueChange(value);
  }

  tempo() {
    let hora = Number(moment().hour());
    let minuto = Number(moment().minute()) + 15;

    return (
      <TimePicker
        defaultValue={moment().hour(hora).minute(minuto)}
        onChange={this.handleValueChange}
        showSecond={false}
        minuteStep={15}
        value={this.viewModel.state.horaAgend}
        allowEmpty={false}
      />
    );
  }

  async componentWillMount() {
    this.viewModel.willMount();
  }

  componentDidMount() {
    this.viewModel.pros = this.props;
    this.viewModel.didMount();
    this.atualizaMapa();

  }
  executaAtualizaMapa() {
    const offsetHeight = this.refOffset.current?.offsetHeight + 75;
    const mapHeight = this.refHeigth.current?.offsetHeight - offsetHeight;
    const mapWidth = this.refWidth.current?.offsetWidth - 30;

    this.viewModel.setState({
      mapHeight: mapHeight > 0 ? mapHeight : this.state.mapHeight,
      mapWidth: mapWidth > 0 ? mapWidth : this.state.mapWidth,
    });
  }
  atualizaMapa() {
    setTimeout(this.executaAtualizaMapa.bind(this), 1000);
  }

  dateHandleChange(date) {
    this.viewModel.dateHandleChange(date);
  }

  async calculaValor() {
    this.viewModel.calculaValor();
  }

  async sendTaxi() {
    this.viewModel.sendTaxi();
  }

  changeOptions(newAddress) {
    this.viewModel.changeOptions(newAddress);
  }
  changeEnderecoOrigem(sugestion, focus = false, j) {
    this.viewModel.changeEnderecoOrigem(sugestion, focus, j);
  }
  changeEnderecoDestinoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    this.viewModel.changeEnderecoDestinoExtra(
      newAddress,
      newCity,
      number,
      focus,
      j,
      label,
      sugestion
    );
  }
  changeEnderecoOrigemExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    this.viewModel.changeEnderecoOrigemExtra(
      newAddress,
      newCity,
      number,
      focus,
      j,
      label,
      sugestion
    );
  }
  changeEnderecoDestino(sugestion, focus = false, j) {
    this.viewModel.changeEnderecoDestino(sugestion, focus, j);
  }
  changeEnderecoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    this.viewModel.changeEnderecoExtra(
      newAddress,
      newCity,
      number,
      focus,
      j,
      label,
      sugestion
    );
  }

  changeEndereco(sugestion, focus = false, j) {
    this.viewModel.changeEndereco(sugestion, focus, j);
  }

  modalEditHide() {
    this.viewModel.setState({ showEditFavoritos: false });
  }

  modalFavoritoHide() {
    this.viewModel.modalFavoritoHide();
  }

  manageShortcut(e) {
    let code = e.keyCode ? e.keyCode : e.which;
  }
  onKeyUp(keyNm, e, handle) {}
  onKeyDown(keyNm, e, handle) {
    if (keyNm === "esc") {
      this.newPag();
    }
  }


  render() {
    moment.locale("pt-br");
    let central = this.props.childProps.central;
    console.log("this.props.childProps.central", central);
    let cor1 = "black";
    let cor2 = "white";
    let cor3 = "black";
    let cor4 = "white";
    let cor5 = "black";
    let cor6 = "white";
    if (!!this.props.childProps && !!this.props.childProps.useColors) {
      cor1 = this.props.childProps.useColors.cor1
        ? this.props.childProps.useColors.cor1
        : "black";
      cor2 = this.props.childProps.useColors.cor2
        ? this.props.childProps.useColors.cor2
        : "white";
      cor3 = this.props.childProps.useColors?.cor3
        ? this.props.childProps.useColors?.cor3
        : "black";
      cor4 = this.props.childProps.useColors?.cor4
        ? this.props.childProps.useColors?.cor4
        : "black";
      cor5 = this.props.childProps.useColors?.cor5
        ? this.props.childProps.useColors?.cor5
        : "black";
      cor6 = this.props.childProps.useColors?.cor6
        ? this.props.childProps.useColors?.cor6
        : "black";
    }
    return (
      <div className="page-content-wrapper">
        <Hotkeys
          keyName="esc"
          onKeyDown={this.onKeyDown.bind(this)}
          onKeyUp={this.onKeyUp.bind(this)}
        ></Hotkeys>
        <SubHeaderView
          textoEsquerda="Nova Solicitação"
          useColors={this.props.childProps.useColors}
        />

        <div className="page-content">
          <div className="container">
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div
                    ref={this.refHeigth}
                    className={
                      this.viewModel.state.showColapseMap
                        ? "col-md-11"
                        : "col-md-8"
                    }
                  >
                    <div className="portlet light round12">
                      <div
                        className={"alert " + this.viewModel.state.alertDisplay}
                      >
                        <button
                          className="close"
                          onClick={() =>
                            this.viewModel.setState({
                              alertDisplay: "display-hide",
                            })
                          }
                        ></button>
                        <span>{this.viewModel.state.alertText}</span>
                      </div>
                      {this.viewModel.state.showColapseSolicitante && (
                        <div
                          className={"row round7"}
                          style={{
                            marginTop: 5,
                            backgroundColor: cor1 + "33",
                          }}
                          onClick={() => this.showHideSolicitante()}
                        >
                          <div className="col-md-11" style={{ fontSize: 20 }}>
                            <b>Solicitante</b>
                          </div>
                          <div className="col-md-1">
                            <Icon
                              name={"AiOutlineDown"}
                              size={22}
                              color={"black"}
                            />
                          </div>
                        </div>
                      )}
                      {!this.viewModel.state.showColapseSolicitante && (
                        <>
                          <div
                            className="row"
                            style={{
                              marginTop: 5,
                              borderTopColor: cor1,
                              borderTopWidth: 1,
                              borderTopStyle: "solid",
                            }}
                            onClick={() => this.showHideSolicitante()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Solicitante</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineUp"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              "form-group form-md-line-input " +
                              this.viewModel.state.validation
                            }
                          >
                            <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              Solicitante
                              <span className="required" aria-required="true">
                                {" "}
                                *
                              </span>
                            </label>
                            <div className="col-md-3">
                              <div className="autoComp func-autocomp">
                                <Autosuggest
                                  ref={"autocomplete-usuario"}
                                  highlightFirstSuggestion={true}
                                  focusInputOnSuggestionClick={false}
                                  suggestions={
                                    this.viewModel.state.sugest_funcs
                                  }
                                  onSuggestionSelected={(
                                    e,
                                    { suggestion, method }
                                  ) => {
                                    let func_data_mod = suggestion;
                                    if (!func_data_mod.valorlimit) {
                                      func_data_mod.valorlimit = 0;
                                    }
                                    if (func_data_mod.valorlimit === 0) {
                                      func_data_mod.valorlimit = 100000;
                                    }
                                    let depz = [];
                                    let depzs = 0;
                                    for (var dp in this.viewModel.state
                                      .departamentoss) {
                                      let pz =
                                        this.viewModel.state.departamentoss[dp];
                                      if (
                                        (func_data_mod.departamentos &&
                                          func_data_mod.departamentos.indexOf(
                                            pz.id
                                          ) > -1) ||
                                        this.viewModel.state.role < 7
                                      ) {
                                        depz.push(pz);
                                      }
                                    }

                                    if (depz.length > 0) {
                                      depzs = depz[0].id;
                                    } else {
                                      depz.push({
                                        calendar: null,
                                        empresa: 0,
                                        id: 0,
                                        identificacao: "NENHUM",
                                        text: "NENHUM",
                                        valorlimit: null,
                                      });
                                      depzs = 0;
                                    }
                                    if (
                                      func_data_mod.departamentos.length > 0
                                    ) {
                                      depzs = func_data_mod.departamentos[0];
                                    }

                                    this.viewModel.setState({
                                      suggestion: func_data_mod,
                                      selection: depzs,
                                      departamentosz: depz,
                                    });
                                  }}
                                  onSuggestionsFetchRequested={({ value }) => {
                                    this.viewModel.afterUpdateUser(value);
                                  }}
                                  onSuggestionsClearRequested={() => {
                                    this.viewModel.setState({
                                      sugest_funcs: [],
                                    });
                                  }}
                                  getSuggestionValue={(item) =>
                                    item.voucher_usuarios_nome
                                  }
                                  renderSuggestion={(item) => (
                                    <div>
                                      <span>
                                        <b>CPF: </b>
                                      </span>
                                      {item.matricula}

                                      <br />
                                      <span>
                                        <span>
                                          <b>Nome: </b>
                                        </span>
                                        {item.voucher_usuarios_nome}
                                      </span>
                                    </div>
                                  )}
                                  inputProps={{
                                    value: this.viewModel.state.sugest_nomeFunc,
                                    onChange: this.onChangeNomeFunc,
                                    onBlur: this.onBlurNomeFunc,
                                    className: "form-control",
                                    disabled:
                                      this.viewModel.state.role === 7
                                        ? true
                                        : false,
                                  }}
                                />
                              </div>
                            </div>

                            <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              Centro de Custo
                              <span
                                className="required"
                                aria-required="true"
                              ></span>
                            </label>
                            <div className="col-md-3">
                              <Select2
                                style={{ width: 250 }}
                                id="departamento-id"
                                ref="departamento_type"
                                value={this.viewModel.state.selection}
                                data={this.viewModel.state.departamentosz}
                                onChange={(e) => {
                                  this.viewModel.setState({
                                    selection: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div
                            className={
                              "form-group form-md-line-input " +
                              this.viewModel.state.validation
                            }
                          >
                            <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              No.Telefone
                              <span className="required" aria-required="true">
                                {" "}
                                *
                              </span>
                            </label>
                            <div className="col-md-4">
                              <div className="input-icon left">
                                <i className="fa fa-phone"></i>
                                <InputMask
                                  value={this.viewModel.state.telefone}
                                  className="form-control"
                                  mask={this.viewModel.state.telefoneMask}
                                  maskChar="_"
                                  placeholder="No. Telefone"
                                  style={{ paddingLeft: 30 }}
                                  onChange={(e) => {
                                    let v = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    this.viewModel.setState({
                                      telefone: v,
                                    });
                                    if (/^.....9/.test(v)) {
                                      this.viewModel.setState({
                                        telefoneMask: "(99) 99999-9999",
                                      });
                                    } else {
                                      this.viewModel.setState({
                                        telefoneMask: "(99) 9999-9999",
                                      });
                                    }
                                  }}
                                />
                                <div className="form-control-focus"></div>
                              </div>
                            </div>
                            <label
                              className="col-md-1 control-label"
                              htmlFor="form_control_1"
                            >
                              {true ? "Passageiro" : "Contato"}
                            </label>
                            <div className="col-md-5">
                              <div className="input-icon left">
                                <i className="fa fa-user"></i>
                                <input
                                  className="form-control"
                                  placeholder={
                                    true
                                      ? "Nome do Passageiro"
                                      : "Nome do Contato no Remetente"
                                  }
                                  value={this.viewModel.state.passageiro}
                                  onChange={(e) =>
                                    this.viewModel.setState({
                                      passageiro: e.target.value,
                                    })
                                  }
                                  type="text"
                                  style={{ paddingLeft: 30 }}
                                />
                                <div className="form-control-focus"></div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {this.viewModel.state.showColapseOrigem && (
                        <div
                          className={"row round7"}
                          style={{
                            marginTop: 5,
                            backgroundColor: cor1 + "33",
                          }}
                          onClick={() => this.showHideOrigem()}
                        >
                          <div className="col-md-11" style={{ fontSize: 20 }}>
                            <b>Endereço de Origem</b>
                          </div>
                          <div className="col-md-1">
                            <Icon
                              name={"AiOutlineDown"}
                              size={22}
                              color={"black"}
                            />
                          </div>
                        </div>
                      )}

                      {!this.viewModel.state.showColapseOrigem && (
                        <>
                          <div
                            className={"row"}
                            style={{
                              marginTop: 5,
                              borderTopColor: cor1,
                              borderTopWidth: 1,
                              borderTopStyle: "solid",
                            }}
                            onClick={() => this.showHideOrigem()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Endereço de Origem</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineUp"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-2"
                              style={{ lineHeight: 3, paddingLeft: 30 }}
                            >
                              Origem
                            </div>
                            <div className="col-md-7">
                              <AutoComplete
                        central={central}
                                chave={"enderecoorigem999"}
                                autoFocus={true}
                                address={this.viewModel.state.options}
                                setInpudDestino={this.setInpudDestino}
                                changeOptions={this.changeOptions.bind(this)}
                                endereco={this.viewModel.state.fullAddress}
                                position={this.viewModel.state.ac_o_pos}
                                salvafavorito={this.setFavorito}
                                changeEndereco={this.changeEndereco.bind(this)}
                                changeEnderecoExtra={this.changeEnderecoExtra.bind(
                                  this
                                )}
                                title="Endereço de Origem"
                                i={999}
                                showObservacoes={true}
                                onclickObservacoes={(k) =>
                                  this.showModalObservacao(k)
                                }
                                center={this.viewModel.state.origemLatLng}
                                altcenter={{
                                  lat: this.viewModel.state.empresa_latitude,
                                  lng: this.viewModel.state.empresa_longitude,
                                }}
                                cores={this.props.childProps.useColors}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {this.viewModel.state.showColapseDestino && (
                        <div
                          className={"row round7"}
                          style={{
                            marginTop: 5,
                            backgroundColor: cor1 + "33",
                          }}
                          onClick={() => this.showHideDestino()}
                        >
                          <div className="col-md-11" style={{ fontSize: 20 }}>
                            <b>Endereços de Destino</b>
                          </div>
                          <div className="col-md-1">
                            <Icon
                              name={"AiOutlineDown"}
                              size={22}
                              color={"black"}
                            />
                          </div>
                        </div>
                      )}
                      {!this.viewModel.state.showColapseDestino && (
                        <>
                          <div
                            className={"row"}
                            style={{
                              marginTop: 5,
                              borderTopColor: cor1,
                              borderTopWidth: 1,
                              borderTopStyle: "solid",
                            }}
                            onClick={() => this.showHideDestino()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Endereços de Destino</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineUp"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                          {this.viewModel.state.destinos.map((m, j) => {
                            let linha =
                              j % 2 === 0
                                ? "row linhaDestinoON round7"
                                : "row linhaDestinoOff round7";
                            return (
                              <>
                                <div className={linha}>
                                  <div
                                    className="col-md-2"
                                    style={{ lineHeight: 3, paddingLeft: 30 }}
                                  >
                                    Destino {j + 1}
                                  </div>
                                  <div className="col-md-7">
                                    <AutoComplete
                        central={central}
                                      chave={"enrerecodestino" + j}
                                      salvafavorito={this.setFavorito}
                                      setInpudDestino={this.setInpudDestino}
                                      address={this.viewModel.state.options}
                                      changeOptions={this.changeOptions.bind(
                                        this
                                      )}
                                      endereco={m.fullAddress}
                                      changeEndereco={this.changeEndereco.bind(
                                        this
                                      )}
                                      position={m.ac_pos}
                                      changeEnderecoExtra={this.changeEnderecoExtra.bind(
                                        this
                                      )}
                                      title="Endereço*"
                                      i={j}
                                      showObservacoes={true}
                                      onclickObservacoes={(k) =>
                                        this.showModalObservacaoDestino(k)
                                      }
                                      showRemover={
                                        this.viewModel.state.destinos.length > 1
                                      }
                                      onclickRemover={async () => {
                                        let addvet =
                                          this.viewModel.state.destinos;
                                        addvet.pop();
                                        await this.viewModel.setState({
                                          destinos: addvet,
                                        });
                                        this.calculaValor();
                                      }}
                                      center={this.viewModel.state.origemLatLng}
                                      altcenter={{
                                        lat: this.viewModel.state
                                          .empresa_latitude,
                                        lng: this.viewModel.state
                                          .empresa_longitude,
                                      }}
                                      cores={this.props.childProps.useColors}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          <div className="row">
                            <Box
                              display="flex"
                              flexWrap="nowrap"
                              alignItems="center"
                            >
                              {this.viewModel.state.destinos.length <
                                this.viewModel.state.maximodestinos <
                                15 && (
                                <IconButton
                                  icone={"FaPlusCircle"}
                                  texto={"Adicionar Destino"}
                                  tamanho={22}
                                  cores={this.props.childProps.useColors}
                                  click={async () => {
                                    let addvet = this.viewModel.state.destinos;
                                    let contagem =
                                      this.viewModel.state.destinos.length;
                                    addvet.push({
                                      autocomplete: {},
                                      destino: "",
                                      numeroDestino: "",
                                      cidadeDestino: "",
                                      obs: "",
                                      item: "",
                                      valor: 0,
                                      pagamento: "",
                                      itens: [{ id: 0, valor: "" }],
                                      contato: "",
                                      parada:
                                        this.viewModel.state.tiposolicitacao ===
                                        2
                                          ? true
                                          : false,
                                      retorno: false,
                                      fullAddress: "",
                                      ac_pos: null,
                                    });
                                    await this.viewModel.setState({
                                      destinos: addvet,
                                    });
                                    this.viewModel.inputs[
                                      "enrerecodestino" + contagem
                                    ].focus();
                                  }}
                                />
                              )}

                              {this.viewModel.state.destinos.length > 1 && (
                                <IconButton
                                  icone={"FaMinusCircle"}
                                  texto={"Remover Destino"}
                                  tamanho={22}
                                  direcao={"direita"}
                                  cores={this.props.childProps.useColors}
                                  click={async () => {
                                    let addvet = this.viewModel.state.destinos;
                                    addvet.pop();
                                    await this.viewModel.setState({
                                      destinos: addvet,
                                    });
                                    this.calculaValor();
                                  }}
                                />
                              )}
                            </Box>
                          </div>
                        </>
                      )}

                      {this.viewModel.state.showColapsePagamento && (
                        <React.Fragment>
                          <div
                            className={"row round7"}
                            style={{
                              marginTop: 5,
                              backgroundColor: cor1 + "33",
                            }}
                            onClick={() => this.showHidePagamento()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Pagamento</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineDown"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {!this.viewModel.state.showColapsePagamento && (
                        <>
                          <div
                            className={"row"}
                            style={{
                              marginTop: 5,
                              borderTopColor: cor1,
                              borderTopWidth: 1,
                              borderTopStyle: "solid",
                            }}
                            onClick={() => this.showHidePagamento()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Pagamento</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineUp"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                          {this.viewModel.state.api_valores.length < 1 && (
                            <div className="row">
                              <div className="col-md-10">
                                <div
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    borderColor: "red",
                                    borderWidth: 1,
                                  }}
                                >
                                  {this.viewModel
                                    .checkCidadeAtuacao()
                                    .map((x) => {
                                      return (
                                        <div
                                          className={"pagamentoNOKx round7"}
                                          style={{
                                            whiteSpace: "normal",
                                            flexDirection: "column",
                                            display: "inline-flex",
                                            padding: 7,
                                            margin: 5,
                                            alignContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {x}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          )}
                          {this.viewModel.state.api_valores.length > 0 && (
                            <div className="row">
                              <div className="col-md-10">
                                <div
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    borderColor: "red",
                                    borderWidth: 1,
                                  }}
                                >
                                  {this.viewModel.state.api_valores.map((x) => {
                                    return (
                                      <TipoCorridaApi
                                        prices={x}
                                        escolhetipo={this.setTipoPagamentoNew}
                                        pagamento={
                                          this.viewModel.state.pagtoSelectedApi
                                        }
                                        tiposelecionado={
                                          this.viewModel.state.tiposelecionado
                                        }
                                        cores={this.props.childProps.useColors}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}

                          {!!this.viewModel.state.pagamentos_api &&
                            this.viewModel.state.pagamentos_api.length > 0 && (
                              <div className="row">
                                <div className="col-md-2">
                                  <label
                                    className="control-label"
                                    htmlFor="form_control_1"
                                    style={{ width: 120 }}
                                  >
                                    Forma de Pagamento *
                                  </label>
                                </div>
                                {this.viewModel.state.pagamentos_api.map(
                                  (x) => {
                                    let classe =
                                      x.id ===
                                      this.viewModel.state.pagtoSelectedApi
                                        ? "col-md-2 btn bgColor2 fontColor5 round7 bdColor5"
                                        : "col-md-2 btn bgColor5 fontColor2 round7 bdColor2";
                                    return (
                                      <div
                                        className={classe}
                                        style={{
                                          margin: 5,
                                          whiteSpace: "normal",
                                        }}
                                        onClick={() =>
                                          this.viewModel.savePagamentoApi(x.id)
                                        }
                                      >
                                        {x.nome_pagamento}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}

{this.viewModel.state.showColapseOutrasOpcoes && (
                        <React.Fragment>
                          <div
                            className={"row round7"}
                            style={{
                              marginTop: 5,
                              backgroundColor: cor1 + "33",
                            }}
                            onClick={() => this.showHideOpcoes()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Outras Opções</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineDown"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}

{!this.viewModel.state.showColapseOutrasOpcoes && (
                        <>
                          <div
                            className={"row"}
                            style={{
                              marginTop: 5,
                              borderTopColor: cor1,
                              borderTopWidth: 1,
                              borderTopStyle: "solid",
                            }}
                            onClick={() => this.showHideOpcoes()}
                          >
                            <div className="col-md-11" style={{ fontSize: 20 }}>
                              <b>Outras Opções</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineUp"}
                                size={22}
                                color={"black"}
                              />
                            </div>
                          </div>

                          <div className="row vmargin5">
                            <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              Horário da Corrida
                            </label>
                            <div className="col-md-4 md-radio-inline">
                              <div className="md-radio has-success">
                                <input
                                  id="rbAgendaParaJa"
                                  checked={this.viewModel.state.praJaCheck}
                                  className="md-radiobtn"
                                  onChange={(e) =>
                                    this.viewModel.setState({
                                      praJaCheck: e.target.checked,
                                      agendCheck: !e.target.checked,
                                      showAgenda: e.target.checked
                                        ? "none"
                                        : "checked",
                                    })
                                  }
                                  type="radio"
                                />
                                <label
                                  htmlFor="rbAgendaParaJa"
                                  style={{ color: cor2 }}
                                >
                                  <span
                                    className="inc"
                                    style={{ borderColor: cor2 }}
                                  ></span>
                                  <span
                                    className="check"
                                    style={{ backgroundColor: cor2 }}
                                  ></span>
                                  <span
                                    className="box"
                                    style={{ borderColor: cor2 }}
                                  ></span>
                                  Para já!
                                </label>
                              </div>
                              <div className="md-radio has-success">
                                <input
                                  id="rbAgendaAgendar"
                                  style={{ border: cor1 }}
                                  checked={this.viewModel.state.agendCheck}
                                  className="md-radiobtn"
                                  onChange={(e) =>
                                    this.viewModel.setState({
                                      agendCheck: e.target.checked,
                                      praJaCheck: !e.target.checked,
                                      showAgenda: e.target.checked
                                        ? "inherit"
                                        : "none",
                                    })
                                  }
                                  type="radio"
                                />
                                <label
                                  htmlFor="rbAgendaAgendar"
                                  style={{ color: cor2 }}
                                >
                                  <span className="inc"></span>
                                  <span
                                    className="check"
                                    style={{ backgroundColor: cor2 }}
                                  ></span>
                                  <span
                                    className="box"
                                    style={{ borderColor: cor2 }}
                                  ></span>
                                  Quero Agendar
                                </label>
                              </div>
                            </div>

                            <div
                              className="form-col-md-6"
                              style={{
                                display: this.viewModel.state.showAgenda,
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <label
                                  className="fontColor2"
                                  style={{ paddingTop: 4 }}
                                  htmlFor="form_control_1"
                                >
                                  Agenda
                                </label>
                                <DatePicker
                                  className="rc-time-picker-input"
                                  style={{ border: 0 }}
                                  minDate={this.viewModel.minimalDate()}
                                  showTimeSelect
                                  dateFormat="DD/MM/YYYY HH:mm"
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  selected={this.viewModel.state.dataAgend}
                                  onChange={this.dateHandleChange}
                                  required
                                />
                              </Box>
                            </div>
                          </div>

                          <div className="row vmargin5">
                            <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              &nbsp;
                            </label>
                            <div className="col-md-10">
                              <div
                                onClick={() =>
                                  this.viewModel.setState({
                                    favCheck: !this.viewModel.state.favCheck,
                                  })
                                }
                              >
                                <Box display="flex" alignItems="center">
                                  {!!this.viewModel.state.favCheck ? (
                                    <i
                                      style={{
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        padding: -5,
                                      }}
                                      className="fa fa-check-square-o fontColor2"
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        padding: -5,
                                      }}
                                      className="fa fa-square-o fontColor2"
                                    ></i>
                                  )}
                                  <div style={{ color: cor2, padding: 5 }}>
                                    Adicionar aos Favoritos?
                                  </div>
                                </Box>
                              </div>
                            </div>
                              {this.viewModel.state.suggestion.voucher_usuarios_email === 'cliente@goapp.com.br' && (
                                <button
                                onClick={() => this.calculaValor()}
                                className="btn"
                                style={{
                                  color: cor5,
                                  backgroundColor: cor2,
                                  borderColor: cor2,
                                }}
                              >
                                <i className="fa fa-paper-plane-o"></i>
                                &nbsp; Recalcular &nbsp;-&nbsp;{this.viewModel.state.api_dados_distancia} m
                              </button>
                              )}
                          </div>
                          {central.num_maximo_carros > 1 && (
                          <div className="row vmargin5">
                          <label
                              className="col-md-2 control-label"
                              htmlFor="form_control_1"
                            >
                              Quantidade de Carros
                            </label>

                            <div className='col-md-8'>
                                        <div>
                                          <div
                                            className='input-group'
                                            style={{ width: 150 }}
                                          >
                                            <input
                                              value={this.viewModel.state.qtdCorridas}
                                              className='form-control'
                                              maxLength='2'
                                              readOnly='true'
                                              type='text'
                                            />
                                            <div className='input-group-btn'>
                                              <button
                                                type='button'
                                                className='btn default'
                                                onClick={() => {
                                                  let number =
                                                  this.viewModel.state.qtdCorridas
                                                  console.log('number', [number, central.num_maximo_carros])
                                                  if (
                                                    number <
                                                    Number(
                                                      central.num_maximo_carros
                                                    )
                                                  )
                                                    number++
                                                  else
                                                    number = Number(
                                                      central.num_maximo_carros
                                                    )
                                                    this.viewModel.setState({
                                                    qtdCorridas: number
                                                  })
                                                }}
                                              >
                                                <i className='fa fa-angle-up'></i>
                                              </button>
                                              <button
                                                type='button'
                                                className='btn default'
                                                onClick={() => {
                                                  let number =
                                                  this.viewModel.state.qtdCorridas
                                                  if (number > 1) number--
                                                  else number = 1
                                                  this.viewModel.setState({
                                                    qtdCorridas: number
                                                  })
                                                }}
                                              >
                                                <i className='fa fa-angle-down'></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                            </div>)}
                          </>)}

                          {!!this.viewModel.state.pagtoSelectedApi && (
                            <div className="row">
                              <div className="col-md-offset-2 col-md-10">
                                &nbsp;
                                {!this.viewModel.state.favCheck &&
                                  this.viewModel.state.podepedir && (
                                    <button
                                      onClick={() => this.sendTaxi()}
                                      className="btn"
                                      style={{
                                        color: cor5,
                                        backgroundColor: cor2,
                                        borderColor: cor2,
                                      }}
                                    >
                                      <i className="fa fa-paper-plane-o"></i>
                                      &nbsp; Solicitar
                                    </button>
                                  )}
                                {this.viewModel.state.favCheck &&
                                  this.viewModel.state.podepedir && (
                                    <button
                                      onClick={() => this.sendTaxi()}
                                      className="btn"
                                      style={{
                                        color: cor5,
                                        backgroundColor: cor2,
                                        borderColor: cor2,
                                      }}
                                    >
                                      <i className="fa fa-paper-plane-o"></i>
                                      &nbsp; Solicitar e Adicionar Endereço aos
                                      Favoritos
                                    </button>
                                  )}
                                {!this.viewModel.state.podepedir && (
                                  <button
                                    className="btn"
                                    style={{
                                      color: cor5,
                                      backgroundColor: cor2,
                                      borderColor: cor2,
                                    }}
                                  >
                                    <i className="fa fa-paper-plane-o"></i>
                                    &nbsp; Solicitando, Aguarde!
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                    <div
                      className={"col-md-1 round7"}
                      style={{
                        borderTopColor: cor1,
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        backgroundColor: cor2 + "33",
                        paddingBottom: 10,
                        display: !this.viewModel.state.showColapseMap ? "none" : "",
                      }}
                    >
                      <div>
                        <React.Fragment>
                          <div
                            className={"form-group"}
                            onClick={() => this.viewModel.showHideMap(() => {this.atualizaMapa()})}
                          >
                            <div
                              className="col-md-11"
                              style={{
                                fontSize: 20,
                                writingMode: "vertical-rl",
                                color: "#ffffff",
                                paddingTop: 10,
                              }}
                            >
                              <b>Mapa</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineLeft"}
                                size={22}
                                color={"white"}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                    <div
                      ref={this.refWidth}
                      className={"col-md-4 round7"}
                      style={{
                        borderTopColor: cor2,
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        backgroundColor: cor1 + "33",
                        paddingBottom: 10,
                        display: this.viewModel.state.showColapseMap ? "none" : "",
                      }}
                    >
                      <div ref={this.refOffset}>
                        <React.Fragment>
                          <div
                            className={"form-group form-md-line-input"}
                            onClick={() => this.viewModel.showHideMap(() => {this.atualizaMapa()})}
                          >
                            <div
                              className="col-md-11"
                              style={{ fontSize: 20, color: "#ffffff" }}
                            >
                              <b>Mapa</b>
                            </div>
                            <div className="col-md-1">
                              <Icon
                                name={"AiOutlineRight"}
                                size={22}
                                color={"white"}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                      <GoMap
                        width={this.state.mapWidth ?? 200}
                        height={this.state.mapHeight ?? 400}
                        markO={this.viewModel.state.markerLocal}
                        mark1={this.viewModel.state.markerDest}
                        mark4={this.viewModel.state.motoristas}
                        c_points={this.viewModel.state.c_points}
                        c_points1={this.viewModel.state.d_points}
                        cores={this.props.childProps.useColors}
                      ></GoMap>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          keyboard
          show={this.viewModel.state.modal_observacao}
          onHide={this.hideModalObservacao}
          container={this}
          bsSize="large"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; {central.obs_field_label_plural}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="portlet-title"
              style={{ marginBottom: 10, color: "#d8030b" }}
            >
              <div className="caption">
                <span className="caption-subject bold uppercase">
                  <i className="fa fa-star-o"></i>&nbsp; {central.obs_field_label_plural}
                </span>
                <div className="form-group form-md-line-input">
                  <label
                    className="col-md-2 control-label"
                    htmlFor="form_control_1"
                  >
                    Complemento
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      value={this.viewModel.state.complemento}
                      onChange={(e) =>
                        this.viewModel.setState({
                          complemento: e.target.value,
                        })
                      }
                      placeholder={"Complemento do Endereço"}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label
                    className="col-md-2 control-label"
                    htmlFor="form_control_1"
                  >
                    {central.obs_field_label_plural}
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      value={this.viewModel.state.referencia}
                      onChange={(e) =>
                        this.viewModel.setState({
                          referencia: e.target.value,
                        })
                      }
                      placeholder={
                        true
                          ? central.obs_field_label_plural + " do Endereço de Origem"
                          : central.obs_field_label_plural + " do Endereço de Embarque"
                      }
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          keyboard
          show={this.viewModel.state.modal_observacao_destino}
          onHide={this.hideModalObservacaoDestino}
          container={this}
          bsSize="large"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; {central.obs_field_label_plural} Destino
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="portlet-title"
              style={{ marginBottom: 10, color: "#d8030b" }}
            >
              <div className="caption">
                <span className="caption-subject bold uppercase">
                  <i className="fa fa-star-o"></i>&nbsp; {central.obs_field_label_plural}
                </span>
                <div className="form-group form-md-line-input">
                  <label
                    className="col-md-2 control-label"
                    htmlFor="form_control_1"
                  >
                    Complemento
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      value={
                        !!this.viewModel.state.destinos &&
                        !!this.viewModel.state.destinos[
                          this.viewModel.state.modal_observacao_destino_id
                        ]
                          ? this.viewModel.state.destinos[
                              this.viewModel.state.modal_observacao_destino_id
                            ].complemento
                          : ""
                      }
                      onChange={(e) => {
                        let mydest = this.viewModel.state.destinos;

                        mydest[
                          this.viewModel.state.modal_observacao_destino_id
                        ].complemento = e.target.value;
                        this.viewModel.setState({ destinos: mydest });
                      }}
                      placeholder={"Complemento do Endereço"}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label
                    className="col-md-2 control-label"
                    htmlFor="form_control_1"
                  >
                    {central.obs_field_label_plural}
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      value={
                        !!this.viewModel.state.destinos &&
                        !!this.viewModel.state.destinos[
                          this.viewModel.state.modal_observacao_destino_id
                        ]
                          ? this.viewModel.state.destinos[
                              this.viewModel.state.modal_observacao_destino_id
                            ].obs
                          : ""
                      }
                      onChange={(e) => {
                        let mydest = this.viewModel.state.destinos;
                        mydest[
                          this.viewModel.state.modal_observacao_destino_id
                        ].obs = e.target.value;
                        this.viewModel.setState({ destinos: mydest });
                      }}
                      placeholder={central.obs_field_label_plural}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          keyboard
          show={this.viewModel.state.showPix}
          onHide={() => this.viewModel.hideModalPix()}
          container={this}
          bsSize="large"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pagar por PIX</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group form-md-line-input">
              <label
                className="col-md-2 control-label"
                htmlFor="form_control_1"
              >
                CPF
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  value={this.viewModel.state.pixCpf}
                  onChange={(e) => {
                    let text = e.target.value;
                    text = text.replace(/[^+\d]/g, "");
                    text = cpf.format(text);
                    this.viewModel.setState({ pixCpf: text });
                  }}
                  placeholder={"CPF"}
                  type="text"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn blue"
                onClick={() => this.viewModel.hideModalPix()}
              >
                Cancelar
              </button>
              &nbsp;
              <button
                className="btn default"
                onClick={() => this.viewModel.sendTaxiPix()}
              >
                Solicitar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Dialog
          ref={(el) => {
            this.dialog = el;
          }}
        />
        <Modal
          keyboard
          show={this.viewModel.state.showEditFavoritos}
          onHide={this.modalEditHide}
          container={this}
          bsSize="large"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; Cadastro de Endereços
              Favoritos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-horizontal">
              <div className="form-body">
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">
                    Apelido para Endereço
                  </label>
                  <div className="col-md-10">
                    <div className="input-icon left">
                      <i className="fa fa-star-half-o"></i>
                      <input
                        className="form-control"
                        placeholder="Apelido para Endereço"
                        value={this.viewModel.state.selected.identificacao}
                        onChange={(e) =>
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              identificacao: e.target.value,
                            },
                          })
                        }
                        type="text"
                        style={{ paddingLeft: 30 }}
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">Telefone</label>
                  <div className="col-md-10">
                    <div className="input-icon left">
                      <i className="fa fa-phone"></i>
                      <InputMask
                        value={this.viewModel.state.selected.telefone}
                        className="form-control"
                        mask={this.viewModel.state.telefoneMask}
                        maskChar="_"
                        placeholder="No. Telefone"
                        onChange={(e) => {
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              telefone: e.target.value,
                            },
                          });
                          if (/^.....9/.test(e.target.value)) {
                            this.viewModel.setState({
                              telefoneMask: "(99) 99999-9999",
                            });
                          } else {
                            this.viewModel.setState({
                              telefoneMask: "(99) 9999-9999",
                            });
                          }
                        }}
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">
                    {true ? "Passageiro" : "Contato"}
                  </label>
                  <div className="col-md-10">
                    <div className="input-icon left">
                      <i className="fa fa-user"></i>
                      <input
                        className="form-control"
                        placeholder={
                          true
                            ? "Nome do Passageiro"
                            : "Nome do Contato no Remetente"
                        }
                        value={this.viewModel.state.selected.passageiro}
                        onChange={(e) =>
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              passageiro: e.target.value,
                            },
                          })
                        }
                        type="text"
                        style={{ paddingLeft: 30 }}
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">Origem</label>
                  <div className="col-md-8">
                    <div className="input-group has-success">
                      <AutoComplete
                        central={central}
                        address={this.viewModel.state.options}
                        salvafavorito={this.setFavorito}
                        changeOptions={this.changeOptions.bind(this)}
                        endereco={this.viewModel.state.selected.origem_endereco}
                        changeEndereco={this.changeEnderecoOrigem.bind(this)}
                        changeEnderecoExtra={this.changeEnderecoOrigemExtra.bind(
                          this
                        )}
                        title="Endereço*"
                        center={this.viewModel.state.center}
                        altcenter={{
                          lat: this.viewModel.state.empresa_latitude,
                          lng: this.viewModel.state.empresa_longitude,
                        }}
                      />
                    </div>
                  </div>
                  <label className="col-md-1 control-label">Número</label>
                  <div className="col-md-1">
                    <div className="input-group has-success">
                      <input
                        className="form-control"
                        ref={"numeroOrigem"}
                        onFocus={(e) => e.target.select()}
                        value={this.viewModel.state.selected.origem_numero}
                        onChange={(e) =>
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              origem_numero: e.target.value,
                            },
                          })
                        }
                        onBlur={() => {
                          this.getLatLng();
                        }}
                        placeholder="No."
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">
                  {central.obs_field_label_plural}
                  </label>
                  <div className="col-md-10">
                    <div className="input-group has-success">
                      <span className="input-group-addon">
                        <i className="fa fa-quote-left"></i>
                      </span>
                      <input
                        className="form-control"
                        value={this.viewModel.state.selected.referencia}
                        onChange={(e) =>
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              referencia: e.target.value,
                            },
                          })
                        }
                        placeholder="Referência do Endereço de Origem"
                        type="text"
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-2 control-label">Destino</label>
                  <div className="col-md-10">
                    <div
                      className="input-group has-success"
                      style={{ width: "100%" }}
                    >
                      <span
                        className="input-group-addon"
                        style={{
                          position: "absolute",
                          padding: "12px 5px 7px 12px",
                        }}
                      >
                        <i className="fa fa-map-marker"></i>
                      </span>
                      <AutoComplete
                        central={central}
                      
                        address={this.viewModel.state.options}
                        changeOptions={this.changeOptions.bind(this)}
                        endereco={
                          this.viewModel.state.selected.destino_endereco
                        }
                        changeEndereco={this.changeEnderecoDestino.bind(this)}
                        changeEnderecoExtra={this.changeEnderecoDestinoExtra.bind(
                          this
                        )}
                        title="Endereço*"
                        center={this.viewModel.state.center}
                        altcenter={{
                          lat: this.viewModel.state.empresa_latitude,
                          lng: this.viewModel.state.empresa_longitude,
                        }}
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                  <label
                    className="col-md-1 control-label"
                    htmlFor="form_control_1"
                  >
                    Número
                  </label>
                  <div className="col-md-1">
                    <div className="input-group has-success">
                      <input
                        className="form-control"
                        ref={"numeroDestino"}
                        onFocus={(e) => e.target.select()}
                        value={
                          this.viewModel.state.selected.destino_numero
                            ? this.viewModel.state.selected.destino_numero
                            : ""
                        }
                        onChange={(e) =>
                          this.viewModel.setState({
                            selected: {
                              ...this.viewModel.state.selected,
                              destino_numero: e.target.value,
                            },
                          })
                        }
                        onBlur={() => {
                          this.getLatLng();
                        }}
                        placeholder="No."
                        type="text"
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn blue"
                onClick={() => this.salvarFavorito()}
              >
                Salvar
              </button>
              &nbsp;
              <button
                className="btn default"
                onClick={() => this.modalEditHide()}
              >
                Fechar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Solicitar;
