import React, { Component } from 'react';
import Icon from 'react-icons-wrapper';
import Fontes from '../../fonts/Fontes.js';

class TipoCorridaApi extends Component {

    constructor(props) {
      super(props);
      this.escolhe = this.escolhe.bind(this);
      this.icone = this.icone.bind(this);
      this.state = {
          loading: false
      }
    }
    icone(ic) {
        console.log('icone',ic)
        switch (ic) {
            case 'carro':
                return <Icon name={'FaCar'} size={60} />;
                break;
            case 'bike':
                return <Icon name={'FaBicycle'} size={60} />;
                break;
            case 'moto':
                return <Icon name={'FaMotorcycle'} size={60} />;
                break;
            case 'taxi':
                return <Icon name={'FaTaxi'} size={60} />;
                break;
            case 'entrega':
                return <Icon name={'FaCar'} size={60} />;
                break;
            default:
                return null;
                break;
        }
    }
    escolhe(sigla, pagamentos) {
        this.props.escolhetipo(sigla.toLowerCase(), pagamentos);
    }
    render() {
        let price = this.props.prices;
        let pagamento = price.pagamentos[0]

        if (!!this.props.pagamento) {
            let escolhe = price.pagamentos.filter(x => x.id == this.props.pagamento)
            if (escolhe && escolhe.length > 0) {
                pagamento = escolhe[0]
            }
        }
        let classe = this.props.tiposelecionado.toLowerCase() === price.sigla_modalidade.toLowerCase() + price.sigla_corrida.toLowerCase() ? 'pagamentoOKx round7' : 'pagamentoNOKx round7';
        
        return <><div className={classe} style={{ flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center'}}  onClick={() => this.escolhe(price.sigla_modalidade + price.sigla_corrida, price.pagamentos)}>
                            <div style={{ alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                            <label style={{ width:'100%', textAlign:'center'}}>
                            {!!this.props.showIcon && (<Fontes.IconFont icone={price.icone} size={60} color={this.props.cores.cor2}  />)}
                            {!!this.props.showIcon && (<div>{price.nome.length > 1 ? price.nome : ""}{"  "}{price.sigla_modalidade == "ENT" ? <Icon name={'GiCardboardBox'} size={18} /> : <Icon name={'GiMeeple'} size={18} />}</div>)}
                            {!this.props.showIcon && (<div>{price.nome.length > 1 ? price.nome : ""}</div>)}
                            </label>
                            </div>
                            <div>{price.nome_tabelamento.length > 1 ? price.nome_tabelamento : "  "}</div>
                            
                            
                                <div style={{ flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                                <label style={{ width:'100%', textAlign:'center', fontSize: 14}}>
                                R$ {Number(pagamento.valor_final).toFixed(2).toString().replace('.',',')}
                                </label>
                                </div>
                </div></>;
    }
}
export default TipoCorridaApi;