import React, { Component } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment";
import ViewModel from "./ViewModel";

import Icon from "react-icons-wrapper";
import IconButton from "../extensions/IconButton.js";
import Box from "@material-ui/core/Box";
import "moment/locale/pt-br.js";
import GoMap from "../map/GoMap.js";
import {
  Panel,
  Button,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Image,
  Modal,
} from "react-bootstrap";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";

class CorridaDados extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.sendMensagem = this.sendMensagem.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.closeCorrida = this.closeCorrida.bind(this);
    this.viewModel.closeCorrida = this.closeCorrida.bind(this);
  }
  componentDidMount() {
    this.viewModel.gettipoCancel();
    this.viewModel.loadCorridaInfo(this.props.corridaInfo);
    this.intervallUpdateCorrida = setInterval(() => {
      this.viewModel.loadCorridaInfo(this.props.corridaInfo);
    }, 5000);
    this.intervalupdateMoto = setInterval(this.viewModel.updateMoto, 5000);
    this.intervalgetMensagens = setInterval(this.viewModel.getMensagens, 5000);
  }
  componentWillUnmount() {
    clearInterval(this.intervallUpdateCorrida);
    clearInterval(this.intervalupdateMoto);
    clearInterval(this.intervalgetMensagens);
  }

  closeCorrida() {
    if (this.props.close) {
      this.props.close();
    } 
  }

  async cancelar(motivo, id) {
    await this.viewModel.cancelar(motivo, id);
    if (this.props.close) {
      this.props.close();
    }
  }

  async sendMensagem() {
    this.viewModel.sendMensagem();
  }

  showChat() {
    let corridaInfo = this.viewModel.state.corridaInfo;
    let msgs = [];

    for (
      let index = 0;
      index < this.viewModel.state.mensagens_list.length;
      index++
    ) {
      const element = this.viewModel.state.mensagens_list[index];
      msgs.push({
        type: "text",
        message: element.text,
        sentTime: this.viewModel.retornaHoraApenas(element.createdAt),
        sender: element.user._id === 2 ? "me" : "driver",
        direction: element.user._id === 2 ? 1 : 0,
      });
    }

    return (
      <div className="col-md-4 padding10 bgColor4">
        <div style={{ height: 500, width: 300 }}>
          <div styles={{ height: 450 }}>
          <MainContainer>
    <ChatContainer>
      <MessageList>
        {msgs.map((msg, index) => (
        <Message model={msg} />))}
      </MessageList>
    </ChatContainer>
  </MainContainer>
          </div>
          {!corridaInfo.t12_dthr_dembarque &&
            (corridaInfo.tipocancelamento_id === undefined ||
              corridaInfo.tipocancelamento_id === 0 ||
              corridaInfo.tipocancelamento_id === null) && (
              <>
                <FormControl
                  type="text"
                  componentClass="input"
                  value={this.viewModel.state.chatMsg}
                  placeholder="Escreva o texto"
                  onChange={(e) => this.viewModel.setState({ chatMsg: e.target.value })}
                />
                <Button onClick={() => this.sendMensagem()}>Enviar</Button>
              </>
            )}
        </div>
      </div>
    );
  }

  render() {
    moment.locale("pt-br");
    let corridaInfo = this.viewModel.state.corridaInfo;

    return (
      <div className="row round7 bgColor4 bdsColor2">
        {!!corridaInfo && (
          <div className="col-md-6 padding10">
            {corridaInfo.t12_st_corrida === "N" && (
              <div>
                <strong>Nova Solicitação</strong>
              </div>
            )}
            {corridaInfo.t12_st_corrida === "A" && (
              <div>
                <strong>SOLICITAÇÃO AGENDADA</strong>
              </div>
            )}
            {corridaInfo.t12_st_corrida === "C" && (
              <div>
                <strong>SOLICITAÇÃO CANCELADA</strong>
              </div>
            )}
            {corridaInfo.t12_st_corrida === "E" &&
              !!corridaInfo.t12_dthr_dembarques && (
                <div>
                  <strong>SOLICITAÇÃO FINALIZADA</strong>
                </div>
              )}
            {corridaInfo.t12_st_corrida === "E" &&
              !corridaInfo.t12_dthr_dembarques && (
                <div>
                  <strong>SOLICITAÇÃO EM ANDAMENTO</strong>
                </div>
              )}
            {corridaInfo.t12_num_corrida && (
              <div>
                <strong>ID: </strong> {corridaInfo.t12_num_corrida}
              </div>
            )}
            {corridaInfo.t12_nome && (
              <div>
                <strong> Passageiro: </strong>
                {corridaInfo.t12_nome}{" "}
              </div>
            )}
            {corridaInfo.voucher_departamento_identificacao && (
              <div>
                <strong>Centro de Custo: </strong>
                {corridaInfo.voucher_departamento_identificacao}{" "}
              </div>
            )}
            {corridaInfo.t12_fone && (
              <div>
                {" "}
                <strong>Telefone: </strong> {corridaInfo.t12_fone}{" "}
              </div>
            )}
            {corridaInfo.enderecoscomponentes && (
              <>
                {corridaInfo.enderecoscomponentes.origem && (
                  <>
                    {corridaInfo.enderecoscomponentes.origem.fullAddress && (
                      <div>
                        <strong> Endereço: </strong>{" "}
                        {corridaInfo.enderecoscomponentes.origem.fullAddress} -{" "}
                        {corridaInfo.enderecoscomponentes.origem.bairro}
                      </div>
                    )}
                    {corridaInfo.enderecoscomponentes.origem.cidade && (
                      <div>
                        <strong> Cidade: </strong>{" "}
                        {corridaInfo.enderecoscomponentes.origem.cidade} /{" "}
                        {corridaInfo.enderecoscomponentes.origem.estado}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {corridaInfo.t12_complemento && (
              <div>
                <strong> Complemento: </strong> {corridaInfo.t12_complemento}
              </div>
            )}
            {corridaInfo.t12_obs && (
              <div>
                <strong> Referência / Observação: </strong>{" "}
                {corridaInfo.t12_obs}
              </div>
            )}
            {corridaInfo.t12_dthr_tele && (
              <div>
                <strong> Hora da corrida: </strong>{" "}
                {this.viewModel.retornaHora(corridaInfo.t12_dthr_tele)}
              </div>
            )}
            {corridaInfo.t12_dthr_agendamento && (
              <div>
                <strong> Hora da corrida agendada: </strong>{" "}
                {this.viewModel.retornaHora(corridaInfo.t12_dthr_agendamento)}
              </div>
            )}
            {corridaInfo.t12_dthr_embarques && (
              <div>
                <strong> Hora de embarque: </strong>{" "}
                {this.viewModel.retornaHora(corridaInfo.t12_dthr_embarques)}
              </div>
            )}
            {corridaInfo.t12_dthr_dembarques && (
              <div>
                <strong> Hora de desembarque: </strong>{" "}
                {this.viewModel.retornaHora(corridaInfo.t12_dthr_dembarques)}
              </div>
            )}
            {corridaInfo.valorcalculado && (
              <div>
                <strong> Valor: </strong>
                <NumberFormat
                  value={corridaInfo.valorcalculado}
                  displayType={"text"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                />{" "}
              </div>
            )}

            {corridaInfo.t08_motoristas_fotobase64 && (
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  overflow: "hidden",
                  borderRadius: 25,
                }}
              >
                <Image
                  alt=""
                  style={{ width: 100, position: "relative", top: "-30%" }}
                  src={corridaInfo.t08_motoristas_fotobase64}
                />
              </div>
            )}
            {!corridaInfo.t08_motoristas_fotobase64 && (
              <Image
                alt=""
                style={{ margin: 10 }}
                src={require("../../images/user.png")}
              />
            )}
            {corridaInfo.t07_carro_t07_placa_detran &&
              corridaInfo.t08_motoristas_t08_nomemotorista && (
                <div>
                  <strong> Motorista: </strong>
                  {corridaInfo.t08_motoristas_t08_nomemotorista}
                </div>
              )}
            {corridaInfo.t07_carro_t07_placa_detran && (
              <div>
                <strong> Placa do carro: </strong>{" "}
                {corridaInfo.t07_carro_t07_placa_detran}
              </div>
            )}
            {corridaInfo.modelo && (
              <div>
                <strong> Modelo: </strong> {corridaInfo.modelo}
              </div>
            )}
            {corridaInfo.dados_corrida && (
              <>
                {corridaInfo.dados_corrida.destinos && (
                  <div>
                    <div>
                      <strong>Destinos: </strong>
                    </div>
                    {corridaInfo.dados_corrida.destinos.map((c, b) => {
                      console.log("dados destinos 2", c);
                      return (
                        <div
                          style={{
                            border: 1,
                            borderColor: "black",
                            borderStyle: "solid",
                            padding: 2,
                            margin: 2,
                          }}
                        >
                          {c.retorno && (
                            <div>
                              <strong>Retorno ao Remetente</strong>
                            </div>
                          )}

                          {c.contato && <div>Cliente: {c.contato}</div>}
                          {c.fullAddress && (
                            <div>Endereço: {c.fullAddress}</div>
                          )}
                          {c.complemento && <div>Complemento: {c.obs}</div>}
                          <div>{pegaValor(b, corridaInfo.tabelacomissao)}</div>

                          {c.item && <div>Item: {c.item}</div>}
                          {c.obs && <div>Observação: {c.obs}</div>}
                          {c.valor !== undefined && c.valor !== 0 && (
                            <div>Valor a Cobrar: {c.valor}</div>
                          )}
                          {c.pagamento && (
                            <div>Tipo do Pagamento: {c.pagamento}</div>
                          )}
                          {c.dataHoraAviso && (
                            <div>
                              Finalizado:{" "}
                              {this.viewModel.retornaHoraApenas(
                                c.dataHoraAviso
                              )}
                            </div>
                          )}
                          {c.entrega && (
                            <>
                              {c.entrega_cancelada && (
                                <>
                                  <div>ENTREGA CANCELADA</div>
                                  {c.motivocancelamento && (
                                    <div>Motivo: {c.motivocancelamento}</div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {c.entrega && (
                            <>
                              {c.recibo && (
                                <div>Tipo do Recibo: {c.recibo}</div>
                              )}
                              {c.recibo_documento && (
                                <div>Documento: {c.recibo_documento}</div>
                              )}
                              {c.obsrecibo && (
                                <div>Observação do Recibo: {c.obsrecibo}</div>
                              )}
                            </>
                          )}
                          {c.itens &&
                            c.itens.length > 0 &&
                            c.itens[0].valor !== undefined &&
                            c.itens[0].valor.length > 0 && (
                              <div>
                                Itens:
                                <ul>
                                  {c.itens.map((a, b) => {
                                    if (a.valor && a.valor.length > 0) {
                                      return <li>{a.valor}</li>;
                                    } else {
                                      return null;
                                    }
                                  })}
                                </ul>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                )}{" "}
              </>
            )}
            {!corridaInfo.dados_corrida && (
              <>
                {corridaInfo.destinos && (
                  <div>
                    <div>
                      <strong>Destinos:</strong>
                    </div>
                    {corridaInfo.destinos.map((c, b) => {
                      console.log("dados destinos", c);
                      return (
                        <div
                          style={{
                            border: 1,
                            borderColor: "black",
                            borderStyle: "solid",
                            padding: 2,
                            margin: 2,
                          }}
                        >
                          {c.retorno && (
                            <div>
                              <strong>Retorno ao Remetente</strong>
                            </div>
                          )}
                          {c.contato && <div>Cliente: {c.contato}</div>}
                          {c.fullAddress && (
                            <div>Endereço: {c.fullAddress}</div>
                          )}
                          {c.complemento && <div>Complemento: {c.obs}</div>}
                          {c.entrega && <div>ENTREGA</div>}
                          <div>{pegaValor(b, corridaInfo.tabelacomissao)}</div>
                          {c.item && <div>Item: {c.item}</div>}
                          {c.obs && <div>Observação: {c.obs}</div>}
                          {c.valor !== undefined && c.valor !== 0 && (
                            <div>Valor a Cobrar: {c.valor}</div>
                          )}
                          {c.pagamento && (
                            <div>Tipo do Pagamento: {c.pagamento}</div>
                          )}
                          {c.dataHoraAviso && (
                            <div>
                              Finalizado:{" "}
                              {this.viewModel.retornaHoraApenas(
                                c.dataHoraAviso
                              )}
                            </div>
                          )}
                          {c.itens &&
                            c.itens.length > 0 &&
                            c.itens[0].valor !== undefined &&
                            c.itens[0].valor.length > 0 && (
                              <div>
                                Itens:
                                <ul>
                                  {c.itens.map((a, b) => {
                                    if (a.valor && a.valor.length > 0) {
                                      return <li>{a.valor}</li>;
                                    } else {
                                      return null;
                                    }
                                  })}
                                </ul>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                )}{" "}
              </>
            )}
            {corridaInfo.tipocancelamento_id !== undefined &&
              corridaInfo.tipocancelamento_id !== 0 &&
              corridaInfo.tipocancelamento_id !== null && (
                <div>
                  <strong> Motivo cancelamento: </strong>{" "}
                  {this.viewModel.state.tipo_cancel.map((tipo) => {
                    if (tipo.id === corridaInfo.tipocancelamento_id) {
                      return tipo.descricao;
                    } else {
                      return null;
                    }
                  })}{" "}
                </div>
              )}
            {corridaInfo.tipocancelamento_obs && (
              <div>
                <strong> Observação: </strong>{" "}
                {corridaInfo.tipocancelamento_obs}
              </div>
            )}
            {corridaInfo.t12_st_corrida === "A" && (
<div>
<div
                className="btn round7 bgColor2 fontColor5 hmargin5"
                              
                              onClick={() => { this.viewModel.handleShow() }}>
                              Alterar Agendamento
                            </div>

<div
                              className="form-col-md-6"
                              style={{
                                display: this.viewModel.state.showAgenda ? "block" : "none",
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                
                                <DatePicker
                                  className="rc-time-picker-input"
                                  style={{ border: 0 }}
                                  minDate={this.viewModel.minimalDate()}
                                  showTimeSelect
                                  dateFormat="DD/MM/YYYY HH:mm"
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  selected={this.viewModel.state.dataAgend}
                                  onChange={this.viewModel.dateHandleChange}
                                  required
                                />
                              </Box>


                              <div
                className="btn round7 bgColor2 fontColor5 hmargin5"
                              
                              onClick={() => { this.viewModel.agendaCorrida() }}>
                              Efetivar Alteração
                            </div>
                            </div></div>)}

            {!corridaInfo.t12_dthr_dembarque &&
              corridaInfo.tipocancelamento_id === null && (
                <>
                  <Panel expanded={this.viewModel.state.open2}>
                    <Panel.Collapse>
                      <Panel.Body>
                        <form>
                          <FormGroup controlId="formControlsSelectMultiple">
                            <ControlLabel>Motivo cancelamento</ControlLabel>
                            <FormControl
                              componentClass="select"
                              onSelect={this.viewModel.handleChange}
                              onChange={this.viewModel.handleChange}
                              inputRef={(el) => (this.inputEl = el)}
                            >
                              <option value=""></option>
                              {this.viewModel.state.tipo_cancel.map((c) => {
                                return (
                                  <option ref={"selecionado"} value={c.id}>
                                    {c.descricao}
                                  </option>
                                );
                              })}
                            </FormControl>
                          </FormGroup>
                          <FormGroup controlId="formBasicText">
                            <ControlLabel>Observações: </ControlLabel>
                            <FormControl
                              type="text"
                              componentClass="textarea"
                              value={this.viewModel.state.value2}
                              placeholder="Escreva o texto"
                              onChange={(e) =>
                                this.setState({ value2: e.target.value })
                              }
                            />
                          </FormGroup>
                        </form>
                        <div
                className="btn round7 bgColor2 fontColor5 hmargin5"
                          onClick={() =>
                            this.cancelar(
                              this.viewModel.state.value2,
                              this.viewModel.state.selection
                            )
                          }
                        >
                          Enviar
                        </div>
                      </Panel.Body>
                    </Panel.Collapse>
                  </Panel>

                  <div
                className="btn round7 bgColor2 fontColor5 hmargin5"
                onClick={() =>
                  this.viewModel.setState({ open2: !this.viewModel.state.open2 })
                    }
                  >
                    Cancelar corrida
                  </div>
                </>
              )}

            {(corridaInfo.t12_st_corrida === "E" && !corridaInfo.t12_dthr_dembarques) && (
              <div
                className="btn round7 bgColor2 fontColor5 hmargin5"
                onClick={() => this.viewModel.setState({ showModal: true })}
              >
                Chat
              </div>
            )}
          </div>
        )}
        {!this.viewModel.state.showModal && (
          <div className="col-md-6 padding10">
            <GoMap
              width={300}
              height={500}
              markO={this.viewModel.state.markerbase}
              mark2={this.viewModel.state.marquerAzul}
              mark3={this.viewModel.state.marquerVerde}
              mark4={this.viewModel.state.driversAround}
              c_points={this.viewModel.state.rotaVermelha}
              c_points1={this.viewModel.state.rotaAzul}
              c_points2={this.viewModel.state.rotaVerde}
            ></GoMap>
          </div>
        )}
        {this.viewModel.state.showModal && this.showChat()}
      </div>
    );
  }
}

function pegaValor(id, tabelacomissao) {
  if (tabelacomissao !== undefined) {
    if (tabelacomissao.tabelas !== undefined) {
      if (tabelacomissao.tabelas[id] !== undefined) {
        let a = tabelacomissao.tabelas[id];
        return (
          "Valor: R$ " +
          Number(a.valorTotal).toFixed(2).toString().replace(".", ",") +
          " - Tipo da solicitação: " +
          a.nome
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export default CorridaDados;
