import React, { Component } from "react";
import ViewModel  from "./ViewModel.js";



class List extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.getTokenCallBack(this.props.loginUser)
  }

  historyPush(password) {
    if (!!this.props.history) {
      this.props.history.push({
        pathname: "/",
        state: { senha: password },
      });
    }
  }
  loginCallback(result) {
    if (!!this.props.loginUser) {
      this.props.loginUser(result);
    }
  }
  loginUser() {
    this.viewModel.loginUser(this.loginCallback, this.historyPush);
  }
  render() {
    const { data, pages, loading, pageSize, currentPage } =
      this.viewModel.state;
    return (<div>loading...</div>);
  }
}

export default List;
