import CoreService from "../../service/CoreService";

export default class Service extends CoreService {
  async saveDep(funcID, depID) {
    let id = this.getCookie("empresa-id");
    let result = await this.fetchOn(
      "voucher_empresa/" +
        id +
        "/funcionario/" +
        funcID +
        "/add_departamento/" +
        depID,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return result.result;
  }

  async pegaDep() {
    let id = this.getCookie("empresa-id");
    let central_id = this.getCookie("central-id");
    console.log("id", id);

    let resultado = await this.getDepartamentoslist(id);
    let funclist = await this.getFunclist(id);
    let central_data = await this.getCentral(central_id);

    console.log("departamentos", resultado);

    resultado = resultado.map((m) => ({ ...m, text: m.identificacao }));
    funclist = funclist.map((m) => ({ ...m, id: m.fid, text: m.nome }));

    return {
      departamento: resultado,
      funclist: funclist,
      central_data: central_data,
    };
  }

  async searchUser(email) {
    let result = await this.fetchOn("voucher_usuarios/by_email/" + email);
    console.log("-----------------> SEARCHUSER", result);
    if (result.result) {
      return result.result;
    }
    return null;
  }

  async editUser(
    email,
    nome,
    telefone,
    id,
    email_corrida,
    email_corrida_resp,
    email_corrida_acomp,
    email_corrida_acomp_resp,
    password
  ) {
    let dados = {
      email: email,
      username: email,
      telefone: telefone,
      nome: nome,
      email_corrida: email_corrida,
      email_corrida_resp: email_corrida_resp,
      email_corrida_acomp: email_corrida_acomp,
      email_corrida_acomp_resp: email_corrida_acomp_resp,
    };
    if (password) {
      dados.password = password;
    }
    let result = await this.fetchOn("voucher_usuarios/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dados),
    });

    return result.result;
  }

  async createUser(email, nome, telefone, cpf, senha) {
    let senhapadrao = "go";
    if (senha) {
      senhapadrao = senha;
    }
    let result = await this.fetchOn("voucher_usuarios", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        username: email,
        telefone: telefone,
        cpf: cpf,
        password: senhapadrao,
        nome: nome,
      }),
    });
    return result.result;
  }

  async listar() {
    try {
      let id = this.getCookie("empresa-id");
      const response = await this.fetchOn(`voucher_empresa/${id}/funcionario`);
      return response.result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }

  async criar(
    cpf,
    calendario,
    departamento,
    matricula,
    perfil,
    email,
    cota,
    nome,
    telefone,
    status,
    dep,
    responsavel,
    email_nova_corrida_gestor,
    email_nova_corrida_gestor_valor,
    senhapadrao
  ) {
    // console.log("EMAIL", email)

    let user = await this.searchUser(email);
    if (!senhapadrao) {
      senhapadrao = "go";
    }
    let empresa = this.getCookie("empresa-id");
    let result;

    if (user && user.id) {
      result = await this.fetchOn(
        "voucher_empresa/" + empresa + "/funcionario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nome: nome,
            status: status,
            calendar: calendario,
            telefone: telefone,
            departamento: departamento,
            matricula: matricula,
            role: perfil,
            usuario: user.id,
            valorlimit: cota,
            responsavel: responsavel,
            email_nova_corrida_gestor: email_nova_corrida_gestor,
            email_nova_corrida_gestor_valor: email_nova_corrida_gestor_valor,
          }),
        }
      );
    } else {
      let created = await this.createUser(
        email,
        nome,
        telefone,
        cpf,
        senhapadrao
      );
      if (created) {
        result = await this.fetchOn(
          "voucher_empresa/" + empresa + "/funcionario",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nome: nome,
              status: status,
              calendar: calendario,
              departamento: departamento,
              telefone: telefone,
              matricula: matricula,
              role: perfil,
              usuario: created.result[0].id,
              valorlimit: cota,
              responsavel: Number(responsavel),
            }),
          }
        );

        if (dep.length !== 0) {
          dep.map((d) => {
            let dd = parseInt(d, 10);
            if (user && user.id) {
              this.saveDep(result.result[0].id, dd);
            } else {
              this.saveDep(result.result[0].id, dd);
            }
            return null;
          });
        }
      } else {
        return created;
      }

    }

    return result.result;
  }

  async cadastroAutorizado(
    cpf,
    calendario,
    departamento,
    matricula,
    perfil,
    email,
    cota,
    nome,
    telefone,
    status,
    dep,
    responsavel,
    email_nova_corrida_gestor,
    email_nova_corrida_gestor_valor,
    senhapadrao
  ) {
    let user = await this.searchUser(email);
    if (!senhapadrao) {
      senhapadrao = "go";
    }
    let empresa = this.getCookie("empresa-id");
    let result;

    if (user && user.id) {
      result = await this.fetchOn(
        "voucher_empresa/" + empresa + "/funcionario",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nome: nome,
            status: status,
            calendar: calendario,
            telefone: telefone,
            departamento: departamento,
            matricula: matricula,
            role: perfil, //permissao
            usuario: user.id,
            valorlimit: cota, //valor limite
            responsavel: responsavel,
            email_nova_corrida_gestor: email_nova_corrida_gestor,
            email_nova_corrida_gestor_valor: email_nova_corrida_gestor_valor,
          }),
        }
      );
    } else {
      let created = await this.createUser(
        email,
        nome,
        telefone,
        cpf,
        senhapadrao
      );
      //console.log("resultadoo da criacaoo", created)
      if (created.ok !== false) {
        result = await this.fetchOn(
          "voucher_empresa/" + empresa + "/funcionario",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nome: nome,
              status: status,
              calendar: calendario,
              departamento: departamento,
              telefone: telefone,
              matricula: matricula,
              role: perfil,
              usuario: created[0].id,
              valorlimit: cota,
              responsavel: Number(responsavel),
            }),
          }
        );
        // está passando um vetor percorrer 1 por 1 para mandar, criar tarefa carlson
        result = await result.json();
        //console.log("testando aqui", result)
        if (dep.length !== 0) {
          dep.map((d) => {
            let dd = parseInt(d, 10);
            if (user && user.id) {
              this.saveDep(result[0].id, dd);
            } else {
              this.saveDep(result[0].id, dd);
            }
            return null;
          });
        }
      } else {
        return created;
      }

      // result2 = await this.saveDep(created.id,dep);
    }

    return result;
  }

  async atualizar(
    calendario,
    matricula,
    perfil,
    email,
    cota,
    nome,
    telefone,
    status,
    id,
    email_antigo,
    responsavel,
    email_nova_corrida_gestor,
    email_nova_corrida_gestor_valor,
    password
  ) {
    let user = await this.searchUser(email_antigo);
    let empresa = this.getCookie("empresa-id");
    let result;
    let dados = {
      status: status,
      calendar: calendario,
      telefone: telefone,
      matricula: matricula,
      role: perfil,
      usuario: user.id,
      valorlimit: cota,
      responsavel: responsavel,
      email_nova_corrida_gestor: email_nova_corrida_gestor,
      email_nova_corrida_gestor_valor: email_nova_corrida_gestor_valor,
    };
    if (password) {
      dados.password = password;
    }
    if (user && user.id) {
      result = await this.fetchOn(
        "voucher_empresa/" + empresa + "/funcionario/" + id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dados),
        }
      );
      this.editUser(
        email,
        nome,
        telefone,
        user.id,
        undefined,
        undefined,
        undefined,
        undefined,
        password
      );
    }

    return result.result;
  }

  async deletar(id) {
    let empresa = this.getCookie("empresa-id");
    let result = await this.fetchOn(
      "voucher_empresa/" + empresa + "/funcionario/" + id,
      { method: "DELETE" }
    );

    return result.result;
  }

  async enviaexcel(myexcel) {
    let result = await this.fetchOn("voucher_usuarios/batch/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: myexcel,
      }),
    });

    console.log("-------------------->resultado excel", result);
    return result.result;
  }
}
