import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import NumberFormat from "react-number-format";
import moment from "moment";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 1}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 1}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class ListDepartamento extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { data, pages, loading, pageSize, currentPage } =
      this.props.viewModel.state;

    return (
      <div>
        <ReactTable
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.props.viewModel.fetchData}
          onPageChange={this.props.viewModel.onPageChange}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          } 
          columns={[
            { Header: "Nome", accessor: "nome",},
            { Header: "Endereço", accessor: "endereco",},
            { Header: "Complemento", accessor: "complemento",},
            { Header: "Observação", accessor: "coordenada",},
            { Header: "Tipo", filterable: false, accessor: "tagtipe",
            Cell:({ row, original }) => {
              let a = ['Normal','Casa','Trabalho']
              return (<div>{a[original.tagtipe]}</div>)}},
          ]

          }
          defaultPageSize={pageSize}
          page={currentPage}
          className="-striped -highlight round7"
          style={{
            border: "none",
          }}
          getTheadProps={() => {
            return {
              className: "round7",
              style: {
                backgroundColor: "white",
                color: "black",
              },
            };
          }}
          getTheadFilterProps={() => {
            return { className: "round7" };
          }}
          getTheadFilterTrProps={() => {
            return { className: "round7" };
          }}
          getTbodyProps={() => {
            return { className: "round7" };
          }}
          getTdProps={() => {
            return { className: "alignCenter" };
          }}
          getTheadThProps={() => {
            return {
              style: {
                fontFamily: '"Roboto", sans-serif',
                fontWeight: "bold",
              },
            };
          }}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: (e) => {
                this.props.viewModel.escolhe(rowInfo.original);
              },
              className: "round7",
            };
          }}
          PaginationComponent={CustomPaginationComponent}
        />
      </div>
    );
  }
}

export default ListDepartamento;
