import React, { Component } from "react";
import {
  Button,
  Modal,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from "react-bootstrap";
import CorridaDados from "../../dadosCorrida/CorridaDados";

class ViewCorrida extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { successMessage, errorMessage, showModal, selecionado } =
      this.props.viewModel.state;

    return (
      <Modal
        show={showModal}
        bsSize="large"
        onHide={this.props.viewModel.closeDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>Corrida</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid style={{ padding: "15px" }}>
            <CorridaDados
              corridaInfo={selecionado.t12_num_corrida}
              childProps={this.props.childProps}
              close={this.props.viewModel.closeDetails}
            />
          </Grid>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ViewCorrida;
