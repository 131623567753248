import CoreService from "../../../service/CoreService";
import moment from "moment";

export default class Service extends CoreService {
  async listar(dataInicial, dataFinal) {
    try {
      const response = await this.fetchOn(
        'corridas?t12_dthr_corridas={"gte": " ' +
          moment(dataInicial).format("YYYY-MM-DD 00:00") +
          ' " ,"lte": "' +
          moment(dataFinal).format("YYYY-MM-DD 23:59") +
          ' " }&t12_st_corrida={"ne": "C"} '
      );
      return response.result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }
}
