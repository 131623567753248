import React, { useEffect, useState } from 'react';
import AlertService from './AlertService';
import { Modal } from "react-bootstrap";

const AlertComponent = () => {
  const [currentAlert, setCurrentAlert] = useState(null);

  useEffect(() => {
    const alertListener = (alert) => {
      setCurrentAlert(alert);
    };

    const alertService = AlertService.getInstance();
    alertService.setAlertListener(alertListener);

    return () => {
      // Limpar ou remover ouvintes quando o componente for desmontado
      alertService.setAlertListener(null);
    };
  }, []);

  const handleClose = () => {
    const alertService = AlertService.getInstance();
    setCurrentAlert(null)
    alertService.clearAlert();
  };

  return (
    <>
      {!!currentAlert && (
        <Modal
        bsSize="small"
        show={!!currentAlert}
        onHide={handleClose}
        container={this}
      >
        <div className={`alert alert-${currentAlert.type}`}>
          <p>{currentAlert.message}</p>
          <button onClick={handleClose}>Fechar</button>
        </div>
        </Modal>
      )}
    </>
  );
};

export default AlertComponent;
