const {versao, url} = require('../go-versao.js');
 

const googleApiUrl = "https://maps.googleapis.com/maps/api";
const addressKey = "AIzaSyCJvlPMRSy6vltr9575qooDS8bNKRtXxbs";
const serverUrl = url
const server1 = 'portal/'
const serverUrl2 = url + server1

export default class WS {

  

  getCentralSubDominio() {
    let url = window.location.href
      .toLowerCase()
      .replace("http://", "")
      .replace("https://", "")
      .split("/")[0]
      .replace(/[0-9]/g, "")
      .replace(":", "")
      .split(".");
    let saida = null;
    let ex = ["com", "br", "www", "localhost", "s-portal", "portal", "portalgoapp", "sportalgoapp"];
    //console.log("getDadosCentralCores", url);
    for (let index = url.length - 1; index >= 0; index--) {
      const element = url[index];

      if (!ex.includes(element)) {
      //  console.log("getDadosCentralCores", element);
        saida = element;
      }
    }
    //console.log("getDadosCentralCores saida", saida);

    return !!saida ? saida : "gosolucoes";
  }

  async getCentralInfosCores() {
    try {
      let central = this.getCentralSubDominio()
      let result = await this.fetch3('precadastroempresa/subdominios?central=' + central);
      
      result = await result.json()

      if (!!result) {
        return result
      } else {
        return {}
      }
      
    } catch (error) {
      //console.log('getCentralInfosCores', error)
      return {}
    }
  }

  estounaprod() {
    if (serverUrl.indexOf('localhost') > 0) {
      return false;
    } else if (serverUrl.indexOf('staging') > 0) {
      return false;
    } else {
      return true;
    }
  }
  async cancelTaxi(id, motivo, id_cancel) {
    //console.log("MOTIVO", motivo)
    let result = await this.fetch2('corrida/cancelar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idCorrida: id,
        obscancelamento: motivo,
        idcancelamento: id_cancel,
        atendente: 'Portal',
        tipocancelamento: 'C',

      })
    })

    return result;
  }

  qrlOp(id2) {

    let result = this.fetch2('corrida/qrl', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id2,
        operador: '1234'
      })
    })

    return result;
  }

  reenviarTaxi(id2) {
    let result = this.fetch2('corrida/enviarOutro', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id2,
      })
    })

    return result;
  }
  cancelaFatura(id2) {
    let result = this.fetch2('cancelafatura', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id2,
      })
    })

    return result;
  }
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  async fetch(extension) {
    let token = this.getCookie('portal-token');

    let object = {
      headers: {
        'x-access-token': token,
      }
    }

    let result = await fetch(serverUrl2 + extension, object);
    return result;
  }

  async  fetch2(extension, object = { headers: {} }) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id') || 1;
    let empresaID = this.getCookie('empresa-id');
    //console.log('centralID', centralID)

    object.headers = { ...object.headers, 'x-access-token': token, 'x-central-id': centralID, 'x-empresa-id': empresaID };

    let result = await fetch(serverUrl2 + extension, object);

    //console.log(extension, result)

    return result;
  }

  async  fetch3(extension, object = { headers: {} }) {
    let token = this.getCookie('portal-token');
    let centralID = 1;
    let empresaID = this.getCookie('empresa-id');
    //console.log('centralID', centralID)

    object.headers = { ...object.headers, 'x-access-token': token, 'x-central-id': centralID, 'x-empresa-id': empresaID };

    let result = await fetch(serverUrl+extension, object);

    //console.log(result)

    return result;
  }

  async sendEmailResetPassword(email) {
    let result = await this.fetch2('voucher_usuarios/request_password_reset/' + email, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return result;
  }

  async resetPassword(senha, code) {
    let result = await this.fetch2('voucher_usuarios/password_reset/' + code, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: senha,
      })
    })

    return result;
  }

  async updateAgenda(data, id, obs) {
    let result = await this.fetch2('corrida/agendamento/', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        datahoraagendamento: data,
        id: id,
        obs: obs
      })
    })

    return result;
  }
  async getFavoritosNew() {
    //console.log('getFavoritosNew')
    let result = await this.fetch2('favoritos');
    result = result.json();
    return result;
  }
  async getCentrais() {
    let result = await this.fetch2('central/?per_page=200&order=nomefantasia&ativo=true');
    result = result.json();

    return result;
  }
  async getCentral(id) {
    let result = await this.fetch2('central/' + id);
    result = result.json();

    return result;
  }
  async getEmpresas() {
    let result = await this.fetch2('voucher_empresa/?per_page=200&order=razaosocial&ativo=true');
    result = result.json();

    return result;
  }

  async getEmpresa(id) {
    let result = await this.fetch2('voucher_empresa/' + id);
    result = result.json();

    return result;
  }

  async getDepartamentos(id) {
    let result = await this.fetch2('voucher_empresa/' + id + "/voucher_departamento");
    result = result.json();

    return result;
  }
  async getDepartamentoslist(id) {
    let result = await this.fetch2('voucher_empresa/' + id + "/departamentolist");
    result = result.json();

    return result;
  }
  async getFunclist(id) {
    let result = await this.fetch2('voucher_empresa/' + id + "/funcionariolist");
    result = result.json();

    return result;
  }



  async criarDepartamento(departamento) {
    let id = this.getCookie('empresa-id');

    if (departamento.calendar.length > 0) {
      departamento = { ...departamento, calendar: departamento.calendar, empresa: id };
    } else {
      departamento = { ...departamento, calendar: null, empresa: id };
    }

    let result = await this.fetch2('voucher_empresa/' + id + '/voucher_departamento', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...departamento
      })
    })

    result = await result.json();

    return result;
  }


  async enviarDadosIugu(dados, producao) {
    let result = await this.fetch2('criartoken', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dados: dados,
        producao: producao,
      })
    })

    result = result.json();

    return result;
  }

  async atualizarDepartamento(departamento) {
    let id = this.getCookie('empresa-id');

    if (departamento.calendar.length > 0) {
      departamento = { ...departamento, calendar: departamento.calendar, empresa: id };
    } else {
      departamento = { ...departamento, calendar: null, empresa: id };
    }

    let result = await this.fetch2('voucher_empresa/' + id + "/voucher_departamento/" + departamento.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...departamento,
      })
    })

    return result;
  }

  async deletarDepartamento(depID) {
    let id = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + id + "/voucher_departamento/" + depID, { method: 'DELETE' })

    return result;
  }

  //mexer
  async verificaTabela(olat, olng, dlat, dlng) {
    let result = await this.fetch2('tabelamento/consulta?origemlatitude=' + olat + '&origemlongitude=' + olng+ '&destinolatitude=' +  dlat + '&destinolongitude=' + dlng)
    let resultjson = await result.json()
    return resultjson;
  }

  async adicionarFuncionarioDepartamento(funcID, depID) {
    let id = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + id + '/voucher_departamento/' + depID + '/add_funcionario/' + funcID, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    return result;
  }

  async deletarFuncionarioDepartamento(funcID, depID) {
    let id = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + id + '/voucher_departamento/' + depID + '/delete_funcionario/' + funcID, { method: 'DELETE' })

    return result;
  }

  async getFunc(id, idemp) {
    let result = await this.fetch2('voucher_empresa/' + idemp + "/funcionario");
    result = result.json();

    return result;
  }

  async getFuncsByName(name) {
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario?voucher_usuarios.nome=' + name);
    result = result.json();

    return result;
  }
  async getMsg(corrida) {
    let result = await this.fetch2('postmsg/' + corrida);
    var msges = await result.json(result);
    return msges;
  }
  async postMsg(corrida, msg) {
    //console.log('msg',[corrida, msg])
    let result = await this.fetch2('postmsg/' + corrida, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': this.getCookie('portal-token')
      },
      body: JSON.stringify({msg: msg})
    })
    var msges = await result.json(result);
    return msges;
  }
  async corridaByID(id) {
    let result = await this.fetch2('corrida/' + id);
    var corrida = await result.json();

    return corrida;
  }

  async getMotoristas(busca) {
    let result = await this.fetch2('motoristas/search' + busca);
    var motorista = await result.json(result);

    return motorista;
  }

  async solicitarApi(funcionario, solicitante, departamento, waypoints, pgto, telefone, nome, email, carros, passageios, observacoes, dataCompleta) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');

    let data = {
      central: centralID,
      empresa: empresa,
      funcionario: funcionario,
      solicitante: solicitante,
      departamento: departamento,
      waypoints: waypoints,
      email: email,
      telefone: telefone,
      nome: nome,
      data_hora_agendamento: dataCompleta,
      observacoes: observacoes,
      numero_carros: carros,
      passageiros: passageios,
      atendente: "portal",
      cdpagamento: pgto
    }

    let result = await this.fetch2('corrida/solicitar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
        'x-central-id': centralID,
      },
      body: JSON.stringify(data)
    })
    let response = await result.json();
    return response;
  
  }

  async calculaValorApi(funcionario, departamento, waypoints) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');

    let data = {
      central: centralID,
      empresa: empresa,
      funcionario: funcionario,
      departamento: departamento,
      waypoints: waypoints
    }


    let result = await this.fetch2('corrida/valores', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
        'x-central-id': centralID,
      },
      body: JSON.stringify(data)
    })
    let response = await result.json();
    return response;
  }

  async calculaOldApi(origin,destination,destinos,promo,retorno, tiposervico) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');
    let meusDest = [];
    let waypoints = [];
    //console.log('destinos',destinos)
    //console.log('destinos',origin)
    for (var m in destinos) {
      meusDest.push(destinos[m])
    }
    if (!destination.lng) {
      return {}
    }

    let data = {}




          data.waypoints = waypoints
          data.promocode = promo;
          data.retorno = retorno;
          data.empresa = Number(empresa);
          data.central = Number(centralID);
          data.destinos = meusDest;
          data.origem = origin;
          data.tiposervico = tiposervico ? tiposervico : 0;
          //let response = data;

    let result = await this.fetch2('corrida/calcular', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify(data)
    })
    let response = await result.json();
    return response;
  }

  async calcula(origin, destination, destinos, promo, retorno, tiposervico) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');
    let waypoints = [];
    
    waypoints.push({latitude: origin.lat, longitude: origin.lng})

   for ( let item in destinos){
    waypoints.push({
      latitude: destinos[item].lat,
      longitude: destinos[item].lng,
      parada: destinos[item].parada,
      retorno: destinos[item].retorno
    })
   }
    
    
    let data = {
      central: Number(centralID),
      empresa: Number(empresa),
      promocode: promo,
      waypoints: waypoints,
    }
    
    
    let result = await this.fetch2('corrida/calculavalor', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify(data)
    })
    let response = await result.json();

    //console.log('***response', response)

    return response

    // let token = this.getCookie('portal-token');
    // let centralID = this.getCookie('central-id');
    // let empresa = this.getCookie('empresa-id');
    // let meusDest = [];
    // let waypoints = [];
    // console.log('destinos',destinos)
    // console.log('destinos',origin)
    // for (var m in destinos) {
    //   meusDest.push(destinos[m])
    // }
    // if (!destination.lng) {
    //   return {}
    // }

    // let data = {}

    //       data.waypoints = waypoints //!
    //       data.promocode = promo; //!
    //       data.retorno = retorno;
    //       data.empresa = Number(empresa); //!
    //       data.central = Number(centralID); //!
    //       data.destinos = meusDest;
    //       data.origem = origin;
    //       data.tiposervico = tiposervico ? tiposervico : 0;
          
    // let result = await this.fetch2('corrida/calcular', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'x-access-token': token,
    //     'x-empresa-id': empresa,
    //   },
    //   body: JSON.stringify(data)
    // })
    // let response = await result.json();
    // console.log('***RESPOSTA DO BACK AQUI---->', response)
    // return response;
  }

  async orderTaxi(orderData) {
    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');
    let solicitacao = { 
            'x-central-id': centralID,
            destinos: orderData.destinos,
            enderecoscomponentes: orderData.enderecoscomponentes,
            moip: orderData.moip,
            emailcliente: orderData.emailcliente,
            voucher_funcionario: orderData.voucher_funcionario,
            telefone: orderData.telefone,
            nome: orderData.nome,
            voucher_funcionario_solicitante: orderData.voucher_funcionario_solicitante,
            endereco: orderData.endereco,
            numero: orderData.numero,
            latitude: orderData.latitude,
            longitude: orderData.longitude,
            enderecoDestino: orderData.enderecoDestino + ', ' + orderData.numeroDestino + ', ' + orderData.cidadeDestino,
            latitudeDestino: orderData.latitudeDestino,
            longitudeDestino: orderData.longitudeDestino,
            tipo_pagto: orderData.tipo_pagto,
            tipo_carro: orderData.tipo_carro,
            tipo_cliente: orderData.tipo_cliente,
            tipo_corrida: orderData.tipo_corrida,
            telefone_contato: orderData.telefone,
            data_hora_agendamento: orderData.data_hora_agendamento,
            observacoes: orderData.observacoes,
            coordenadas: orderData.coordenadas,
            bairrodestino: orderData.bairroDestino,
            bairro: orderData.bairro,
            numero_carros: orderData.numero_carros,
            codigos: orderData.codigos,
            atendente: orderData.atendente,
            tabelavalores: orderData.tabelavalores,
            id: orderData.id,
            cidade: orderData.cidade, 
            estado: orderData.estado, 
            estadodestino: orderData.estadodestino, 
            cidadedestino: orderData.cidadeDestino,
            cadastrar_cliente: orderData.cadastrarCliente,
            valorcalc: orderData.valorTabelada,
            complemento: orderData.complemento,
            caridade: orderData.caridade ? orderData.caridade : 0,
            antecipar: orderData.antecipar,
            tiposervico: orderData.tiposervico ? orderData.tiposervico : 0,
            departamento_voucher: orderData.departamento};
    if (orderData.creditosporcorrida) {
      solicitacao.creditosporcorrida = orderData.creditosporcorrida;
    }
    if (orderData.comissoes) {
      solicitacao.comissoes = orderData.comissoes;
    }
    //console.log("orderTaxi",solicitacao)
    let result = await this.fetch2('corrida/pedir_taxi', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify(solicitacao)
    })
    //let result = {ok:true}
    return result;
  }

  async getClient(phone) {
    let response = await this.fetch2('cliente/' + phone);
    response = await response.json();

    return response;
  }

  async callUrbsData1(at, urbs) {
    let img
    if(Number(at) > 5000){
      img = null
    }else{
      let response = await this.fetch2('urbs/condutores/' + at);
      //console.log("RESPOSTINHA", response)


      let response2 = await JSON.stringify(response)
       img = null;

      if (response2) {
        let motUrbs = response2[urbs];
        //console.log("MOTOURBS", motUrbs)
        if(motUrbs){
          img = 'http://www.urbs.curitiba.pr.gov.br/PORTAL/lab/taxiLegal/outputimage.php?ccc=' + motUrbs.CCC + '&h=' + motUrbs.H;

        }else{
          img = null;
        }
      }
    }


    return img;


  }
  async getAgent() {
    let result = await this.fetch3('precadastro/useragent');
    
    result = await result.json()
      return result
  }
  async getOptions(filter) {
    let response = await this.fetch2('codigos?filter=' + filter);
    response = await response.json();

    let result = response.map((option) => {
      return {
        id: option.codigo,
        text: option.descricao,
      }
    });

    return result;
  }

  async getAgendamentos(dataHoraFim, func, role) {
    let response = await this.fetch2('corrida/agendamento?dataHoraFim=' + dataHoraFim + '&func_id=' + func + '&func_role=' + role);
    response = await response.json();

    return response;
  }

  async runSearch(dataInicio, dataFim, type, value) {
    if (type === 'motorista') {
      if (value.length === 3) {
        type = 'rt'
      }
      else if (value.length === 4) {
        type = 'at'
      }
    }

    let response = await this.fetch2('corrida/historico?dataHoraInicio=' + dataInicio + '&dataHoraFim=' + dataFim + '&' + type + '=' + value);
    response = await response.json();

    return response;
  }

  async getCorridasAntigas(telefone) {
    let response = await this.fetch2('corrida/search?telefone=' + telefone);
    response = await response.json();

    return response;
  }

  async atualizarCadastro(nome, endereco, numero, complemento, latitude, longitude, telefone) {
    let result = await this.fetch2('cliente', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cliente: nome,
        endereco: endereco,
        numero: numero,
        complemento: complemento,
        latitude: latitude,
        longitude: longitude,
        telefone: telefone,
      })
    })
    return result;
  }

  statusmotorista(id_corrida) {
    return this.callEndpointWithPayload('status-motorista', {
      id_corrida: id_corrida,
    });
  }
  driversAround(latitude, longitude) {
    return this.callEndpointWithPayload('motoristas-proximos', {
      latitude: latitude,
      longitude: longitude,
    });
  }

  callEndpointWithPayload(method, parameters) {
    let payload = {
      method: method,
      parameters: parameters,
    }

    let token = this.getCookie('portal-token');
    let centralID = this.getCookie('central-id');
    let empresa = this.getCookie('empresa-id');

    return fetch(serverUrl + "client", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-central-id': centralID,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json());
  }

  despacho(id, uuid) {
    let result = this.fetch2('corrida/id/despacho', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        corrida: id,
        motorista: uuid
      })
    })

    return result;
  }

  async deleteVoucher(id) {
    let result = await this.fetch2('voucher_avulso/' + id, { method: 'DELETE' })

    return result;
  }

  async sendVoucherEdit(id, func, tempo, status, code, senha, validade, valor_limite, calendar, item) {
    let fresult = await this.searchUser(func);
    let idfunc
    let resultado = await this.getFuncById(fresult.id);
    //console.log("opa fresult", resultado)
    resultado.map(m => {
      if (parseInt(m.usuario, 10) === fresult.id) {
        idfunc = m.id.toString()
      }
      return null;
    })

    calendar = JSON.stringify(calendar)

    let token = this.getCookie('portal-token');
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_avulso/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify({
        empresa: empresa,
        criacao: tempo,
        funcionario: idfunc,
        status: status,
        code: code,
        pass: senha,
        validade: validade,
        valorlimit: valor_limite,
        calendar: calendar,
        item: item,
      })
    })
    return result;
  }

  async editVoucherStatus(id, status) {
    let token = this.getCookie('portal-token');
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_avulso/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify({
        status: status,

      })
    })
    return result
  }
  async sendVoucher(cpf, nome, func, tempo, status, code, senha, validade, valor_limite, calendar, item) {
    let idfunc = null;
    if (func !== '') {
      let fresult = await this.searchUser(func);
      if (fresult) {
        let resultado = await this.getFuncById(fresult.id);
        //console.log("opa fresult", resultado)
        resultado.map(m => {
          if (parseInt(m.usuario, 10) === fresult.id) {
            idfunc = m.id.toString()
          }
          return null;
        })
      }
    }

    calendar = JSON.stringify(calendar)

    let token = this.getCookie('portal-token');
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_avulso', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify({
        cpf: cpf,
        nome: nome,
        empresa: empresa,
        funcionario: idfunc,
        criacao: tempo,
        status: status,
        code: code,
        pass: senha,
        validade: validade,
        valorlimit: valor_limite,
        calendar: calendar,
        item: item,
      })
    })
    result = result.json()
    return result;
  }
  async getVoucher() {
    let result
    result = await this.fetch2('voucher_avulso?per_page=9999999999');


    /* let token = this.getCookie('portal-token');
     let empresa = this.getCookie('empresa-id');
     let result = await this.fetch2('voucher_avulso', {
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'x-access-token': token,
         'x-empresa-id': empresa,
       },
       body: JSON.stringify({
       })
     })*/
    result = result.json();
    return result;
  }

  // 'directions': async (params, callback) => {
  //   let result = null;
  //   let saida = null;
  //   let coordenadas = params.originLng  + ',' + params.originLat;
  //   coordenadas += ';' + params.destLng  + ',' + params.destLat;
  async directions(originLat, originLng, destLat, destLng, destinos) {
    return this.callEndpointWithPayload('directions-route', {
      originLat: originLat,
      originLng: originLng,
      destLng: destLng,
      destLat: destLat,
      destinos: destinos,
    });
  }
  async directionsDestinos(originLat, originLng, destLat, destLng, destinos) {
    let stringLatLng = ''
    for(let i in destinos){
      if(Number(i) !== Number((destinos.length - 1))){
        stringLatLng = stringLatLng + destinos[i].lng + ',' + destinos[i].lat + ';'
      }else{
        stringLatLng = stringLatLng + destinos[i].lng + ',' + destinos[i].lat
      }
    }
  return this.callEndpointWithPayload('directions-route-directions', {
    originLat: originLat,
    originLng: originLng,
    destLng: destLng,
    destLat: destLat,
    destinos: stringLatLng,
  });
}

  //Get the routes from origin to destiny
  // async directions(originLat, originLng, destLat, destLng, destinos) {
  //   let stringLatLng = ''
  //   console.log("EU SOU O ", destinos)
  //   for(let i in destinos){
  //     console.log("destinos poha", destinos.length)
  //     console.log("destino i", i)
  //     if(Number(i) !== Number((destinos.length - 1))){
  //       stringLatLng = stringLatLng + destinos[i].lat + ',' + destinos[i].lng + '|'
  //     }else{
  //       stringLatLng = stringLatLng + destinos[i].lat + ',' + destinos[i].lng
  //     }
  //   }
  //   console.log("mystring", stringLatLng)
  //   let response = await this.fetch2('google/directionswaypoint?&originLat=' + originLat + '&originLng=' + originLng + '&destLat=' + destLat + '&destLng=' + destLng + '&waypoints=' + stringLatLng)
  //   response = await response.json();

  //   return response;
  // }

  // Get the address coordinate
  async place(placeid,texto, lat, lon) {
    let response = await this.fetch2('google/place?place=' + placeid + '&texto=' + texto + '&lat=' + lat + '&lon=' + lon)
    response = await response.json();
    return response;
  }
  async autocomplete(address, coords) {
    //console.log('coords', [address, coords]) 

      if (!coords) {
        coords = {
          lat: -25.450587,
          lng: -49.231315
        }
      } 
      let latitude = !!coords.lat ? !isNaN(coords.lat) ? coords.lat : -25.450587 : -25.450587;
      let longitude = !!coords.lng ? !isNaN(coords.lng) ? coords.lng : -49.231315 : -49.231315;
      //console.log('coords', [coords, latitude, longitude]) 

    let response = await this.fetch2('google/autocomplete?allTypes=1&radius=100000&strictbounds=1&latitude=' + latitude + '&longitude=' + longitude + '&address=' + address)
    response = await response.json();
    return response;
  }

  async getFavoritos() {
    let result = await this.fetch2('favorito')
    result = await result.json()

    return result
  }

  async getFavById(id) {
    let result = await this.fetch2('favorito/' + id)
    result = await result.json()

    return result
  }

  async criarFavorito(favorito) {

    let info = await JSON.parse((JSON.stringify(favorito)));

    if (!info.destino_latitude) {
      delete info['destino_cidade'];
      delete info['destino_endereco'];
      delete info['destino_latitude'];
      delete info['destino_longitude'];
      delete info['destino_numero'];
    }
    info.formadepagamento = 10;
    let result = await this.fetch2('favorito', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(info)
    })

    return result;
  }

  async gerarBoleto(boleto, central) {
    let token = this.getCookie('portal-token');
    let centralID = central
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('criarboleto', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token,
        'x-empresa-id': empresa,
      },
      body: JSON.stringify({
        'x-central-id': centralID,
        // voucher_funcionario_solicitante: orderData.solicitanteID,
        email: boleto.email,
        ensure_workday_due_date: boleto.ensure_workday_due_date,
        due_date: boleto.due_date,
        items: boleto.items,
        payer: {
          cpf_cnpj: boleto.payer.cpf_cnpj,
          name: boleto.payer.name,
          phone_prefix: boleto.payer.phone_prefix,
          phone:  boleto.payer.phone,
          email:  boleto.payer.email,
          address:{
            zip_code:  boleto.payer.address.zip_code,
            street: boleto.payer.address.street,
            number: boleto.payer.address.number,
            district: boleto.payer.address.district,
            city: boleto.payer.address.city,
            state: boleto.payer.address.state,
            country: 'Brasil'
          }
        }
      })
    })

    return result;
  }

  async alterarFavorito(favorito) {

    let result = await this.fetch2('favorito/' + favorito.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...favorito
      })
    })

    return result;
  }


  async deleteFavorito(id) {
    let result = await this.fetch2('favorito/' + id, { method: 'DELETE' })

    return result;
  }

  async getUserById(id) {
    let result = await this.fetch2('voucher_usuarios/' + id)
    if (result.ok) {
      result = await result.json()
      return result
    }
    return null;
  }

  async searchUser(email) {
    let result = await this.fetch2('voucher_usuarios/by_email/' + email)
    if (result.ok) {
      result = await result.json()
      return result
    }
    return null;
  }

  async getFuncById(id) {
    if (!id) { return {} }
    let empresa = await this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario?usuario=' + id + '&empresa=' + empresa);
    result = await result.json();
    return result;
  }

  async getFuncById2(id) {
    let empresa = await this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario?id=' + id + '&empresa=' + empresa);
    result = await result.json();
    return result;
  }

  async searchUserByCPF(cpf) {
    let result = await this.fetch2('voucher_usuarios/by_cpf/' + cpf);
    result = await result.json();
    return result;
  }

  async allFuncs() {
    let empresa = await this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario?per_page=9999')
    result = await result.json()

    return result
  }

  async saveFuncFavorito(func, fav) {
    if (fav === 0) { fav = null }
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario/' + func, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        favorito_id: fav,
      })
    })

    return result;
  }
  async saveFuncCaridade(func, caridade) {
    if (!caridade) { caridade = 0 }
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario/' + func, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        caridade: caridade,
      })
    })

    return result;
  }
  async createUser(email, nome, telefone, cpf, senha) {
    let senhapadrao = 'go';
    if (senha) {senhapadrao = senha}
    let result = await this.fetch2('api/usuario_v2/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        username: email,
        telefone: telefone,
        // celular: celular,
        cpf: cpf,
        password: senhapadrao,
        nome: nome,
      })
    })
    //console.log("MASUQUE",result)
    if(result.ok === false){
      return result
    }else{
      let result2 = await result.json()
      //console.log("WATAFAKE MAN", result2)
      return result2
    }

  }

  async editUser(email, nome, telefone, id, email_corrida, email_corrida_resp, email_corrida_acomp, email_corrida_acomp_resp, password) {
    let dados = {
      email: email,
      username: email,
      telefone: telefone,
      nome: nome,
      email_corrida: email_corrida,
      email_corrida_resp: email_corrida_resp,
      email_corrida_acomp: email_corrida_acomp,
      email_corrida_acomp_resp: email_corrida_acomp_resp
    }
    if (password) {
      dados.password = password;
    }
    let result = await this.fetch2('voucher_usuarios/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dados)
    })

    return result;
  }

  async editSenhaUser(senha, id) {
    let result = await this.fetch2('voucher_usuarios/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: senha,
        nova_senha: false,
      })
    })

    return result
  }

  async deleteUser(id) {
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario/' + id, { method: 'DELETE' })

    return result;
  }

  async changeStatus(email, status, id) {

    let user = await this.searchUser(email)
    let empresa = this.getCookie('empresa-id');
    //console.log("entrei na func change")
    let result;

    if (user) {
      result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario/' + id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: status,

        })
      })
    }
    return result;
  }

  async editarAutorizado(calendario, matricula, perfil, email, cota, nome, telefone, status, id, email_antigo, responsavel, email_nova_corrida_gestor, email_nova_corrida_gestor_valor, password) {
    let user = await this.searchUser(email_antigo);
    let empresa = this.getCookie('empresa-id');
    let result;
    //let resp = ''
    //let resp2 = ''
    // if( responsavel && responsavel.length > 0){
    //   resp2 = await this.searchUser(responsavel)
    //   resp = resp2.id
    // }
    let dados = {
      status: status,
      calendar: calendario,
      telefone: telefone,
      matricula: matricula,
      role: perfil, //permissao
      usuario: user.id,
      valorlimit: cota, //valor limite
      responsavel: responsavel,
      email_nova_corrida_gestor: email_nova_corrida_gestor,
      email_nova_corrida_gestor_valor: email_nova_corrida_gestor_valor
    }
    if (password) {
      dados.password = password;
    }
    //console.log('dados',dados)
    if (user && user.id) {
      result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario/' + id, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados)
      })

      console.log("----------------------------------->resultadoo da edicaoo", result)
      
      this.editUser(email, nome, telefone, user.id, undefined,undefined,undefined,undefined, password)
    }

    return result
  }

  async saveDep(funcID, depID) {
    let id = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + id + '/funcionario/' + funcID + '/add_departamento/' + depID, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    return result;
  }

  async removeDep(funcID, depID) {
    let id = this.getCookie('empresa-id');
    let result = await this.fetch2('voucher_empresa/' + id + '/funcionario/' + funcID + '/delete_departamento/' + depID, { method: 'DELETE' })

    return result;
  }

  async cadastroAutorizado(cpf, calendario, departamento, matricula, perfil, email, cota, nome, telefone, status, dep, responsavel, email_nova_corrida_gestor, email_nova_corrida_gestor_valor, senhapadrao) {
    // console.log('8888888888888888888888888888888> dep:', dep)
    let user = await this.searchUser(email)
    // console.log("buscar funcionário -----------------------/>:", user)
    if (!senhapadrao) {senhapadrao= 'go'}
    // let resp2 = {id: 0}
    // let resp = 0
    // console.log("usuariooo", user)
    // if(responsavel && responsavel.length > 0){
    //   resp2 = await this.searchUser(responsavel)
    //   resp = resp2.id
    // }
    let empresa = this.getCookie('empresa-id');
    let result;

    if (user && user.id) {
      result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome:nome,
          status: status,
          calendar: calendario,
          telefone: telefone,
          departamento: departamento,
          matricula: matricula,
          role: perfil, //permissao
          usuario: user.id,
          valorlimit: cota, //valor limite
          responsavel: responsavel,
          email_nova_corrida_gestor: email_nova_corrida_gestor,
          email_nova_corrida_gestor_valor: email_nova_corrida_gestor_valor
        })
      })

    } else {
      let created = await this.createUser(email, nome, telefone, cpf, senhapadrao)
      // console.log("=====================> resultadoo da criacaoo", created)
      if(created.ok !== false ){
        result = await this.fetch2('voucher_empresa/' + empresa + '/funcionario', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nome:nome,
            status: status,
            calendar: calendario,
            departamento: departamento,
            telefone: telefone,
            matricula: matricula,
            role: perfil,
            usuario: created.id,
            valorlimit: cota,
            responsavel: Number(responsavel),
          })
        })

        // está passando um vetor percorrer 1 por 1 para mandar, criar tarefa carlson
        //! Isso aqui não funciona!!!

        // if (dep) {
        //   dep.map(d => {
        //     let dd = parseInt(d, 10);
        //     if (user && user.id) {
        //       this.saveDep(result[0].id, dd);
        //     } else {
        //       this.saveDep(result[0].id, dd);
        //     }
        //     return null;
        //   });
        // }
      }else{
        return created
      }

      // result2 = await this.saveDep(created.id,dep);
    }

    return result
  }

  async loginPortal(email, password) {
    let result = await this.fetch2('login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })

    return result;
  }

  async enviarPreCadastro(central, cnpj, tipoparticipante, razaosocial, cep, rua, numero, complemento, bairro, cidade, uf,telefone, email, cpfresponsavel, nomeresponsavel, codigoindicacao) {
    let result = await this.fetch3('precadastroempresa', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        central: central.toString().trim(),
        cnpj: cnpj.toString().replace(/[^+\d]/g, '').trim(),
        Tipo_de_Participante: tipoparticipante.toString().trim(),
        razao_social: razaosocial.toString().trim(),
        cep: cep.toString().trim(),
        rua: rua.toString().trim(),
        numero: numero.toString().toString().trim(),
        complemento: complemento.toString().trim(),
        bairro: bairro.toString().trim(),
        cidade: cidade.toString().trim(),
        uf: uf.toString().trim(),
        telefone: telefone.toString().trim(),
        email: email.toString().toLowerCase().trim(),
        nomeresponsavel: nomeresponsavel.toString().trim(),
        cpfresponsavel: cpfresponsavel.toString().replace(/[^+\d]/g, '').trim(),
        codigoindicacao: codigoindicacao.toString().toUpperCase().trim(),
      })
    })

    return result;
  }

  async getPreCadastro(cnpj) {
    let result = await this.fetch3("precadastroempresa/consulta?cnpj=" + cnpj)
    result = result.json()
    return result;
  }


  async getModulos() {
    let result = await this.fetch2("modulos")

    return result;
  }

  async criarTipo(tipo, tipo_object) {
    let result = await this.fetch2(tipo + '/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...tipo_object
      })
    })

    return result;
  }

  async getRandom(link) {
    let result = await fetch(serverUrl + 't/' + link)
    //console.log("RESULTT", result)
    if (result.ok) {
      result = await result.json()
      return result
    } else {

      return null
    }

  }

  async salvaAvaliacao(link,id,star,obs,detalhes){


    let result = await fetch(serverUrl + 't/' + link + '/r', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idcorrida: id,
        avadetalhes: detalhes,
        star_avaliacao: star,
        star_coments: obs
      })
    })

    return result;
  }


  async postMotAvalRandom(link, nota, descricao) {
    let result = await fetch(serverUrl + 't/' + link + '/r', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        avaliacao: nota,
        comentarios: {
          descricao: descricao
        }
      })
    })

    return result;
  }

  async getMotRandom(link) {
    let result = await fetch(serverUrl + 't/' + link + "/m")
    //console.log("resposta", result)
    if (result.status !== 404) {
      result = await result.json()
    }
    return result
  }

  async enviaexcel(myexcel){
    let result = await this.fetch2('voucher_usuarios/batch/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        excel: myexcel
      })
    })
    return result
  }

  async getTipo(tipo, id_tipo, sorted, page, pageSize, filtered) {
    let response;
    let filtros = filtered.map((m, i) => m.id.replace('voucher_usuarios_', 'voucher_usuarios.') + '=' + m.value).join('&');
    if (id_tipo !== '') {
      response = await this.fetch2(tipo + '/' + id_tipo);
    } else if (sorted.length > 0) {
      let order = sorted.map((m, i) => (m.desc ? '-' : '') + (m.id.search("voucher_usuarios") ? (m.id.replace("voucher_roles_", "voucher_roles.")) : (m.id.replace("voucher_usuarios_", "voucher_usuarios.")))).join(',');
      //console.log("teste corrida", order)
      response = await this.fetch2(tipo + '?per_page=' + pageSize + '&page=' + page + '&order=' + order + '&' + filtros);
    } else {
      response = await this.fetch2(tipo + '?per_page=' + pageSize + '&page=' + page + '&' + filtros);
    }

    return response;
  }

  async getTipo2(tipo, id_tipo, sorted, page, pageSize, filtered) {
    let response;
    let filtros = filtered?.map((m, i) => m.id + '=' + m.value).join('&');
    if (id_tipo !== '') {
      response = await this.fetch2(tipo + '/' + id_tipo);
    } else if (sorted.length > 0) {
      let order = sorted.map((m, i) => (m.desc ? '-' : '') + (m.id.search("voucher_usuarios") ? (m.id.replace("voucher_roles_", "voucher_roles.")) : (m.id.replace("voucher_usuarios_", "voucher_usuarios.")))).join(',');
      //console.log("teste corrida", order)
      response = await this.fetch2(tipo + '&per_page=' + pageSize + '&page=' + page + '&order=' + order + '&' + filtros);
    } else {
      response = await this.fetch2(tipo + '&per_page=' + pageSize + '&page=' + page + '&' + filtros);
    }

    // console.log('------------------------------------%%%%%%%%%%%', response)

    return response;
  }

  async getTipo3(tipo) {
    let response = await this.fetch2(tipo);
    return response;
  }

  async deleteTipo(tipo, id_tipo) {
    let result = await this.fetch2(tipo + '/' + id_tipo, { method: 'DELETE' })

    return result;
  }

  async alterarTipo(tipo, id_tipo, tipo_object) {
    let result = await this.fetch2(tipo + '/' + id_tipo, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...tipo_object
      })
    })

    return result;
  }


  async criarReclamacao(id_corrida, reclamacao, id_tipo_reclamacao, motorista_cpf) {
    let result = await this.fetch2('corrida/' + id_corrida + '/reclamacao', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        descricao: reclamacao,
        tiporeclamacao_id: id_tipo_reclamacao,
        motorista_cpf: motorista_cpf
      })
    })

    return result;
  }

  async getCorridaReclamacoes(id_corrida) {
    let response = await this.fetch2('corrida/' + id_corrida + '/reclamacao');
    response = await response.json();

    return response;
  }

  async deleteCorridaReclamacao(id_corrida, id_reclamacao) {
    let result = await this.fetch2('corrida/' + id_corrida + '/reclamacao/' + id_reclamacao, { method: 'DELETE' })

    return result;
  }

  async getReclamacaoCorrida(id_corrida, id_reclamacao) {
    let response = await this.fetch2('corrida/' + id_corrida + '/reclamacao/' + id_reclamacao);
    response = await response.json();

    return response;
  }

  async alterarReclamacao(id_corrida, reclamacao, id_tipo_reclamacao, id_reclamacao, motorista_cpf) {
    let result = await this.fetch2('corrida/' + id_corrida + '/reclamacao/' + id_reclamacao, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        descricao: reclamacao,
        tiporeclamacao_id: id_tipo_reclamacao,
        motorista_cpf: motorista_cpf
      })
    })

    return result;
  }

  async getTodasCorridas() {
    let result = await this.fetch2('corridas');
    result = result.json();

    return result;
  }

  async reverseGeocode(coords) {
    return fetch(googleApiUrl + '/geocode/json?&latlng=' + coords.lat + ',' + coords.lng + '&language=pt-BR')
      .then((response) => { console.log(response); return response.json() })
      .then((response) => {
        let my_result = response.results.find((el) => el.types.includes("street_address"));
        if (my_result) {
          let address_components = my_result.address_components;
          let shortAddress = address_components.find((el) => el.types.includes("route")).long_name + ", " +
            address_components.find((el) => el.types.includes("street_number")).long_name;
          let fullAddress = shortAddress;
          if (address_components.find((el) => el.types.includes("locality"))) {
            fullAddress = fullAddress + ', ' + address_components.find((el) => el.types.includes("locality")).long_name;
          }
          let cidade = '';
          if (address_components.find((el) => el.types.includes("administrative_area_level_2"))) {
            cidade = address_components.find((el) => el.types.includes("administrative_area_level_2")).long_name;
          }
          return { shortAddress: shortAddress, fullAddress: fullAddress, cidade: cidade };
        }
      })
  }

  async geocodeByPlaceId(street, number, city) {
    let response;
    if (number && city) {
      response = await this.fetch2('google/geocode?&endereco=' + street + ', ' + number + ', ' + city)
      response = await response.json();
    } else {
      response = await this.fetch2('google/geocode?&endereco=' + street);
      response = await response.json();
    }

    return response;
  }

  buildUrl(service, format, params) {
    params = {
      ...params,
      key: addressKey,
      language: 'pt-BR',
    }
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    let result = googleApiUrl + '/' + service + '/' + format + '?' + query;

    return result;
  }

  async salvaRelatorio(sqltext, relatorio, tipo, linhas, colunas) {
    let result = await this.fetch2('relatorios', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sqltext: sqltext,
        relatorio: relatorio,
        tipo: tipo,
        linhas: linhas,
        colunas: colunas,
      })
    })

    return result;
  }

  async listaRelatorio() {
    let response = await this.fetch2('relatorios');
    response = await response.json();

    return response;
  }

  async updateRelatorio(sqltext, relatorio, tipo, codigo, linhas, colunas) {
    let result = await this.fetch2('relatorios', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sqltext: sqltext,
        relatorio: relatorio,
        tipo: tipo,
        codigo: codigo,
        linhas: linhas,
        colunas: colunas,
      })
    })

    return result;
  }
  async executeRelatorio(codigo, parametros) {
    let esc = encodeURIComponent;
    let query = Object.keys(parametros)
      .map(k => esc(k) + '=' + esc(parametros[k]))
      .join('&');
    let response = await this.fetch2('relatorios/execute?codigo=' + codigo + '&' + query);
    response = await response.json();

    return response;
  }

  async getParametro() {
    let response = await this.fetch2('parametros');
    response = await response.json();

    return response;
  }

  async saveParametro(tempopendencia, tempoqrl) {
    let result = await this.fetch2('parametros', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tempopendencia: tempopendencia,
        tempoqrl: tempoqrl,
      })
    })

    return result;
  }
}
