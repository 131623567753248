import React, { Component } from "react";
import moment from "moment";
import CorridaDados from "./CorridaDados";
import SubHeaderView from "../header/SubHeaderView";

class DetalhesCorrida extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    require("moment/locale/pt-br.js");
    moment.locale("pt-br");
    return (
      <div className="page-content-wrapper">
        <SubHeaderView
          textoEsquerda="Detalhes da Corrida"
          useColors={this.props.childProps.useColors}
        />
        <div className="page-content">
          <div className="container">
            <CorridaDados
              corridaInfo={this.props.match.params.id}
              childProps={this.props.childProps}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default DetalhesCorrida;
