import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-table/react-table.css";
import { Tabs, Tab } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap/lib/DropdownToggle";
import "react-select2-wrapper/css/select2.css";
import Dialog from "react-bootstrap-dialog-v2";
import Select2 from "react-select2-wrapper";
import WS from "../../../services/webservice.js";
import ReactTable from "react-table";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input";
import { validaCPF } from "../controllers/validadorCPF.js";
//import inherits from '../../node_modules/babel-runtime/helpers/inherits';

class CadastrarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authHistorico: [
        {
          data: "",
          usuario: "",
          status: "",
          obs: "",
        },
      ],
      acima3Meses: "",
      ate3Meses: "active",
      nome: "",
      email: "",
      calen: [],
      celular: "",
      responsavel: 0,
      perfil: "Usuário",
      departamento: [
        { id: 1, text: "oi" },
        { id: 2, text: "oeqwewqi" },
        { id: 3, text: "oertttri" },
      ],
      dep: [],
      perfilSelected: 1,
      saldo: "",
      matricula: "",
      senha: "",
      email_nova_corrida_gestor: false,
      email_nova_corrida_gestor_valor: 0,
      confirmSenha: "",
      showSenha: "inherit",
      showCalendar: "none",
      cpf: "",
      telefoneMask: "(99) 9999-9999",
      moneyMask: " ,00",
      showSelect: "none",
      showCotaGastos: "none",
      cotaGastos: "00,00",
      statusSelected: 0,
      horaFim: "",
      horaInicio: "",
      noCheck: false,
      yesCheck: true,
      segCheck: false,
      terCheck: false,
      quaCheck: false,
      quiCheck: false,
      sexCheck: false,
      sabCheck: false,
      domCheck: false,
      ferCheck: false,
      dataFinal: null,
      dataInicial: null,
    };

    this.createJson = this.createJson.bind(this);
    this.removeCalen = this.removeCalen.bind(this);
    this.saveAutorizado = this.saveAutorizado.bind(this);
    this.getAuthHistorico = this.getAuthHistorico.bind(this);
    this.dateFinalHandleChange = this.dateFinalHandleChange.bind(this);
    this.dateInicialHandleChange = this.dateInicialHandleChange.bind(this);
    this.getSelectedOptionsValue = this.getSelectedOptionsValue.bind(this);
    this.pegaDep = this.pegaDep.bind(this);
    this.emailhandleChange = this.emailhandleChange.bind(this);
    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value);
    }

    return values;
  }
  componentDidMount() {
    this.pegaDep();
  }
  async pegaDep() {
    let id = await this.webservice.getCookie("empresa-id");
    let central_id = this.webservice.getCookie("central-id");
    console.log("id", id);
    let resultado = await this.webservice.getDepartamentoslist(id);
    let funclist = await this.webservice.getFunclist(id);
    let central_data = await this.webservice.getCentral(central_id);
    console.log("departamentos", resultado);
    resultado = resultado.map((m) => {
      return {
        ...m,
        text: m.identificacao,
      };
    });
    funclist = funclist.map((m) => {
      return {
        ...m,
        id: m.fid,
        text: m.nome,
      };
    });
    await this.setState({
      departamento: resultado,
      funclist: funclist,
      central_data: central_data,
    });
    console.log("departamentos", this.state.departamento);
    console.log("departamentos", this.state.dep);
    console.log("central_data", this.state.central_data);
  }

  componentWillMount() {
    if (this.props.location?.state && this.props.location?.state.autorizado) {
      this.setState(
        { autorizado: this.props.location.state.autorizado },
        () => {
          if (this.state.autorizado.status === "ativo")
            this.setState({ statusSelected: 0 });
          else if (this.state.autorizado.status === "bloqueado")
            this.setState({ statusSelected: 2 });
          else if (this.state.autorizado.status === "inativo")
            this.setState({ statusSelected: 1 });
          if (this.state.autorizado.perfil === "Administrador")
            this.setState({ perfilSelected: 1 });
          else if (this.state.autorizado.perfil === "Gestor")
            this.setState({ perfilSelected: 2 });
          else if (this.state.autorizado.perfil === "Gestor Aprovador")
            this.setState({ perfilSelected: 3 });
          else if (this.state.autorizado.perfil === "Contas a Pagar")
            this.setState({ perfilSelected: 4 });
          else if (this.state.autorizado.perfil === "Usuário Solicitante")
            this.setState({ perfilSelected: 5 });
          else if (this.state.autorizado.perfil === "Sub-Gestor")
            this.setState({ perfilSelected: 6 });
          else if (this.state.autorizado.perfil === "Usuário")
            this.setState({ perfilSelected: 7 });
          this.setState({
            nome: this.state.autorizado.nome,
            email: this.state.autorizado.email,
            celular: this.state.autorizado.celular,
            perfil: this.state.autorizado.perfil,
            saldo: this.state.autorizado.saldo,
            matricula: this.state.autorizado.matricula,
          });
        }
      );
      this.props.history.replace({
        pathname: "/editar-autorizado",
        state: {},
      });
    }
    this.getAuthHistorico();
  }

  getAuthHistorico() {
    if (this.state.ate3Meses === "active") {
      this.setState({
        authHistorico: [
          {
            data: "11/06 15:27",
            usuario: "leandro.fvc",
            status: "ATIVO",
            obs: "Inserido",
          },
          {
            data: "27/06 09:57",
            usuario: "jbetim442@gmail.com",
            status: "ATIVO",
            obs: "Editado",
          },
        ],
      });
    } else if (this.state.acima3Meses === "active") {
      this.setState({
        authHistorico: [
          {
            data: "19/04 15:52",
            usuario: "EDUARDO.FVC",
            status: "ATIVO",
            obs: "Inserido",
          },
        ],
      });
    }
  }


  saveDep() {}
  saveAutorizado() {
    let saldo;
    let jcalen;
    let s = this.state;

    if (
      s.nome !== "" &&
      s.email !== "" &&
      s.celular !== "" &&
      s.cpf !== "" &&
      s.dep
    ) {
      if (s.showCotaGastos === "inherit") {
        saldo = s.cotaGastos;
        let re = /[.]+/g;
        saldo = saldo.replace(re, "");
        console.log("olha o replace", saldo);
        saldo = saldo.replace(",", ".");
        console.log("SEGUNDO REPLACE", saldo);
      } else {
        saldo = "0";
      }
      if (s.calen.length > 0) {
        jcalen = s.calen;
      } else {
        jcalen = null;
      }
      if (validaCPF(s.cpf)) {
        let senhapadrao = "go";
        if (this.state.central_data) {
          if (this.state.central_data.senhapadrao) {
            senhapadrao = this.state.central_data.senhapadrao;
          }
        }
        this.webservice
          .cadastroAutorizado(
            s.cpf,
            jcalen,
            "3",
            s.cpf,
            s.perfilSelected,
            s.email,
            parseFloat(saldo),
            s.nome,
            s.celular,
            s.statusSelected,
            s.dep,
            s.responsavel,
            s.email_nova_corrida_gestor,
            s.email_nova_corrida_gestor_valor,
            senhapadrao
          )
          .then((result) => {
            console.log("----------------------------> OLHA O RESULT DO RETORNO:", result);
            if (result.status !== 400) {
              Dialog.setOptions({
                defaultOkLabel: "Ok",
                primaryClassName: "btn-danger ",
              });
              this.dialog.show({
                body: "Cadastro realizado",
                actions: [
                  Dialog.OKAction(() => {
                    this.props.history.push("/usuarios");
                  }),
                ],
                bsSize: "small",
                onHide: (dialog) => {
                  this.props.history.push("/usuarios");
                  dialog.hide();
                },
              });
            } else {
              Dialog.setOptions({
                defaultOkLabel: "Ok",
                primaryClassName: "btn-danger ",
              });
              this.dialog.show({
                body: "CPF ou e-mail já cadastrado",
                actions: [Dialog.OKAction(() => {})],
                bsSize: "small",
                onHide: (dialog) => {
                  dialog.hide();
                },
              });
            }
          });
      } else {
        Dialog.setOptions({
          defaultOkLabel: "Ok",
          primaryClassName: "btn-danger ",
        });
        this.dialog.show({
          body: "CPF Inválido",
          actions: [Dialog.OKAction(() => {})],
          bsSize: "small",
          onHide: (dialog) => {
            dialog.hide();
          },
        });
      }
    } else {
      Dialog.setOptions({
        defaultOkLabel: "Ok",
        primaryClassName: "btn-danger",
      });
      this.dialog.show({
        body: "Preencha todos os campos",
        actions: [Dialog.OKAction(() => {})],
        bsSize: "small",
        onHide: (dialog) => {
          dialog.hide();
        },
      });
    }
  }

  removeCalen(index) {
    let edit = this.state.calen;
    console.log("EDIT COM CALEN", edit);
    edit.splice(index, 1);
    this.setState({
      calen: edit,
    });
  }
  emailhandleChange(event, maskedvalue, floatvalue) {
    this.setState({ email_nova_corrida_gestor_valor: floatvalue });
  }
  createJson() {
    let calen2 = [];
    calen2 = this.state.calen;
    calen2.push({
      ini: this.state.horaInicio,
      fim: this.state.horaFim,
      seg: this.state.segCheck,
      ter: this.state.terCheck,
      qua: this.state.quaCheck,
      qui: this.state.quiCheck,
      sex: this.state.sexCheck,
      sab: this.state.sabCheck,
      dom: this.state.domCheck,
      fer: this.state.ferCheck,
    });
    console.log(calen2);
    this.setState({
      calen: calen2,
    });
  }

  //Save the date selected from DatePicker
  dateInicialHandleChange(date) {
    this.setState({
      dataInicial: date,
    });
  }

  //Save the date selected from DatePicker
  dateFinalHandleChange(date) {
    this.setState({
      dataFinal: date,
    });
  }

  render() {
    //Change the current language to portuguese BR
    require("moment/locale/pt-br.js");
    moment.locale("pt-br");

    return (
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="portlet light form-fit"
                      style={{ marginBottom: 0, paddingTop: 20 }}
                    >
                      <div
                        className="portlet-body form"
                        style={{ marginLeft: 20, marginTop: 20 }}
                      >


                        <Link
                          to="/usuarios"
                          className="btn round7 bgColor2 fontColor5 hmargin5"
                        >
                          <i className="fa fa-arrow-left"></i>
                          Voltar
                        </Link>
                        

                        <div>
                          <div className="form-horizontal">
                            <div className="form-group ">
                              <label className="control-label col-md-5">
                                STATUS:
                              </label>
                              <div className="col-md-3">
                                <div className="btn-group bootstrap-select bs-select form-control">
                                  <button
                                    className="btn btn-default"
                                    onClick={() =>
                                      this.setState({
                                        showSelect:
                                          this.state.showSelect === "none"
                                            ? "inherit"
                                            : "none",
                                      })
                                    }
                                  >
                                    {this.state.statusSelected === 0 && (
                                      <span className="filter-option pull-left">
                                        Autorizado &nbsp;
                                        <span className="label lable-sm label-success">
                                          <i className="fa fa-smile-o"></i>
                                          &nbsp; ATIVO
                                        </span>
                                      </span>
                                    )}
                                    {this.state.statusSelected === 2 && (
                                      <span className="filter-option pull-left">
                                        Autorizado &nbsp;
                                        <span className="label lable-sm label-warning">
                                          <i className="fa fa-meh-o"></i>&nbsp;
                                          BLOQUEADO
                                        </span>
                                      </span>
                                    )}
                                    {this.state.statusSelected === 1 && (
                                      <span className="filter-option pull-left">
                                        Autorizado &nbsp;
                                        <span className="label lable-sm label-danger">
                                          <i className="fa fa-frown-o"></i>
                                          &nbsp; INATIVO
                                        </span>
                                      </span>
                                    )}
                                    &nbsp;
                                    <span className="bs-caret">
                                      <span className="caret"></span>
                                    </span>
                                  </button>
                                  <div></div>
                                  <div
                                    className="dropdown-menu open"
                                    style={{
                                      display: this.state.showSelect,
                                      maxHeight: 149,
                                      overflow: "hidden",
                                      minHeight: 0,
                                    }}
                                  >
                                    <ul
                                      className="dropdown-menu inner"
                                      style={{
                                        maxHeight: 147,
                                        overflowY: "auto",
                                        minHeight: 0,
                                      }}
                                    >
                                      <li
                                        onClick={() =>
                                          this.setState({
                                            statusSelected: 0,
                                            showSelect: "none",
                                          })
                                        }
                                      >
                                        <button
                                          type="button"
                                          className="link-button"
                                          tabIndex="0"
                                        >
                                          Autorizado &nbsp;
                                          <span className="label lable-sm label-success">
                                            <i className="fa fa-smile-o"></i>
                                            &nbsp; ATIVO
                                          </span>
                                          <span className="fa fa-check check-mark"></span>
                                        </button>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.setState({
                                            statusSelected: 2,
                                            showSelect: "none",
                                          })
                                        }
                                      >
                                        <button
                                          type="button"
                                          className="link-button"
                                          tabIndex="0"
                                        >
                                          Autorizado &nbsp;
                                          <span className="label lable-sm label-warning">
                                            <i className="fa fa-meh-o"></i>
                                            &nbsp; BLOQUEADO
                                          </span>
                                          <span className="fa fa-check check-mark"></span>
                                        </button>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.setState({
                                            statusSelected: 1,
                                            showSelect: "none",
                                          })
                                        }
                                      >
                                        <button
                                          type="button"
                                          className="link-button"
                                          tabIndex="0"
                                        >
                                          Autorizado &nbsp;
                                          <span className="label lable-sm label-danger">
                                            <i className="fa fa-frown-o"></i>
                                            &nbsp; INATIVO
                                          </span>
                                          <span className="fa fa-check check-mark"></span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-body">
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  EMAIL:
                                </label>
                                <div className="col-md-4">
                                  <div>
                                    <input
                                      className="form-control"
                                      value={this.state.email}
                                      type="email"
                                      onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  Nº CELULAR:
                                </label>
                                <div className="col-md-4">
                                  <div>
                                    <InputMask
                                      ref="telefone"
                                      value={this.state.celular}
                                      autoFocus
                                      className="form-control"
                                      mask={this.state.telefoneMask}
                                      maskChar=""
                                      onChange={(e) => {
                                        this.setState({
                                          celular: e.target.value,
                                        });
                                        if (/^.....9/.test(e.target.value)) {
                                          this.setState({
                                            telefoneMask: "(99) 99999-9999",
                                          });
                                          this.setState({
                                            telefoneContato: e.target.value,
                                          });
                                        } else {
                                          this.setState({
                                            telefoneMask: "(99) 9999-9999",
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  CPF:{" "}
                                </label>
                                <div className="col-md-4">
                                  <div>
                                    <InputMask
                                      ref="cpf"
                                      value={this.state.cpf}
                                      autoFocus
                                      className="form-control"
                                        mask="999.999.999-99"
                                      maskChar=""
                                      onChange={(e) => {
                                        const input = e.target.value.replace(/\D/g, "").slice(0, 11);
                                        this.setState({ cpf: input });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                             
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  PERFIL: <span className="required">*</span>
                                </label>
                                <div className="col-md-3">
                                  <select
                                    className="table-group-action-input form-control"
                                    value={this.state.perfilSelected}
                                    onChange={(e) => {
                                      this.setState({
                                        perfilSelected:
                                          e.target.selectedOptions[0].value,
                                        perfil:
                                          e.target.selectedOptions[0].innerHTML,
                                      });
                                    }}
                                  >
                                    <option value="1">Administrador</option>
                                    <option value="2">Gestor</option>
                                    {/*<option value="3">Gestor Aprovador</option>*/}
                                    {/*<option value="4">Contas a Pagar</option>*/}
                                    <option value="5">
                                      Usuário Solicitante
                                    </option>
                                    {/*<option value="6">Sub-Gestor</option>*/}
                                    <option value="7">Usuário</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  NOME:
                                  <span className="required">*</span>
                                </label>
                                <div className="col-md-9">
                                  <input
                                    className="form-control"
                                    value={this.state.nome}
                                    type="text"
                                    onChange={(text) => {
                                      this.setState({
                                        nome: text.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="form-group">
																	<label className="col-md-2 control-label">
																		LOGIN:
                              <span className="required">*</span>
																	</label>
																	<div className="col-md-9">
																		<input className="form-control" value={this.state.matricula} type="text" onChange={(text) => { this.setState({ matricula: text.target.value }) }} />
																	</div>
																</div> */}
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  DEPARTAMENTO:
                                  <span className="required">*</span>
                                </label>
                                <div className="col-md-9">
                                  <Select2
                                    multiple
                                    style={{ marginLeft: 20, width: 200 }}
                                    value={this.state.dep}
                                    data={this.state.departamento}
                                    options={{
                                      placeholder: "",
                                    }}
                                    onSelect={(e) => {
                                      this.setState({
                                        dep: this.getSelectedOptionsValue(
                                          e.target.selectedOptions
                                        ),
                                      });
                                    }}
                                    onUnselect={(e) => {
                                      this.setState({
                                        dep: this.getSelectedOptionsValue(
                                          e.target.selectedOptions
                                        ),
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  RESPONSÁVEL:
                                </label>
                                <div className="col-md-9">
                                  <Select2
                                    ref={(e) => {
                                      this.tags = e;
                                    }}
                                    style={{ marginLeft: 20, width: 200 }}
                                    value={this.state.responsavel}
                                    data={this.state.funclist}
                                    options={{
                                      placeholder: "",
                                    }}
                                    onSelect={(e) => {
                                      let f = this.getSelectedOptionsValue(
                                        e.target.selectedOptions
                                      );
                                      this.setState({
                                        responsavel: Number(f[0]),
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  ENVIAR EMAIL PARA GESTOR:
                                </label>
                                <div className="col-md-2">
                                  <input
                                    id="email_nova_corrida_gestor"
                                    className="md-check"
                                    checked={
                                      this.state.email_nova_corrida_gestor
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        email_nova_corrida_gestor:
                                          e.target.checked,
                                      })
                                    }
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-md-2 control-label">
                                  AVISAR O GESTOR VALOR MAIOR O IGUAL A:
                                </label>
                                <div className="col-md-2">
                                  <CurrencyInput
                                    className="form-control"
                                    value={
                                      this.state.email_nova_corrida_gestor_valor
                                    }
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    onChangeEvent={this.emailhandleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="portlet light">
                                <div className="portlet-title">
                                  <div className="caption">
                                    <span className="caption-subject  bold uppercase">
                                      <i className="icon-settings "></i>&nbsp;
                                      Regras para utilização
                                    </span>
                                    &nbsp;
                                    <span className="caption-helper">
                                      Abaixo você poderá definir regras para
                                      permitir ou proibir o uso do serviço.
                                    </span>
                                  </div>
                                </div>
                                <div className="portlet-body form">
                                  <div className="form-horizontal">
                                    <div className="form-body">
                                      <div className="md-checkbox has-success">
                                        <input
                                          id="stCotaGastoCK"
                                          className="md-check"
                                          onChange={(e) =>
                                            this.setState({
                                              showCotaGastos: e.target.checked
                                                ? "inherit"
                                                : "none",
                                            })
                                          }
                                          type="checkbox"
                                        />
                                        <label htmlFor="stCotaGastoCK">
                                          <span className="inc"></span>
                                          <span className="check"></span>
                                          <span className="box"></span>
                                          Deseja estabelecer cota mensal de
                                          gastos com o serviço?
                                        </label>
                                      </div>
                                      <div
                                        className="form-group"
                                        style={{
                                          marginTop: 15,
                                          display: this.state.showCotaGastos,
                                        }}
                                      >
                                        <div className="col-md-12">
                                          <div className="form-control height-auto">
                                            <div
                                              className="scroller"
                                              style={{ overflowY: "auto" }}
                                            >
                                              <ul className="list-unstyled">
                                                <li>
                                                  <div className="form-group form-md-line-input has-success">
                                                    <label
                                                      className="col-md-3 control-label"
                                                      htmlFor="vlCotaGasto"
                                                    >
                                                      Valor
                                                    </label>
                                                    <div className="col-md-2">
                                                      <div className="input-icon">
                                                        <CurrencyInput
                                                          className="form-control"
                                                          value={
                                                            this.state
                                                              .cotaGastos
                                                          }
                                                          decimalSeparator=","
                                                          thousandSeparator="."
                                                          onChangeEvent={(
                                                            e
                                                          ) => {
                                                            this.setState({
                                                              cotaGastos:
                                                                e.target.value,
                                                            });
                                                          }}
                                                        />
                                                        <div className="form-control-focus"></div>
                                                        <i className="fa fa-dollar"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="md-checkbox has-success">
                                        <input
                                          id="tpRestricaoDataDS"
                                          onChange={(e) =>
                                            this.setState({
                                              showCalendar: e.target.checked
                                                ? "inherit"
                                                : "none",
                                            })
                                          }
                                          className="md-check"
                                          type="checkbox"
                                        />
                                        <label htmlFor="tpRestricaoDataDS">
                                          <span className="inc"></span>
                                          <span className="check"></span>
                                          <span className="box"></span>
                                          Permitir utilização do serviço apenas
                                          em determinadas horas e dias da
                                          semana?(Os Horários inicial e final
                                          não são obrigatórios.)
                                        </label>
                                      </div>
                                      <div
                                        className="form-group"
                                        style={{
                                          marginTop: 15,
                                          display: this.state.showCalendar,
                                        }}
                                      >
                                        <div className="col-md-12">
                                          <div className="form-control height-auto">
                                            <div
                                              className="scroller"
                                              style={{
                                                height: 275,
                                                overflowY: "auto",
                                              }}
                                              data-always-visible="1"
                                            >
                                              <ul
                                                className="list-unstyled"
                                                id="ul_"
                                              >
                                                <li>
                                                  <div className="form-group">
                                                    <div className="col-md-2">
                                                      <label>Hora Início</label>
                                                      <div className="input-group">
                                                        <TimePicker
                                                          format={24}
                                                          onChange={(time) => {
                                                            let mon = moment
                                                              .utc(time * 1000)
                                                              .format("HH:mm");
                                                            this.setState({
                                                              horaInicio: mon,
                                                            });
                                                          }}
                                                          value={
                                                            this.state
                                                              .horaInicio
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                      <label>Hora Fim</label>
                                                      <div className="input-group">
                                                        <TimePicker
                                                          format={24}
                                                          onChange={(time) => {
                                                            let mon = moment
                                                              .utc(time * 1000)
                                                              .format("HH:mm");
                                                            this.setState({
                                                              horaFim: mon,
                                                            });
                                                          }}
                                                          value={
                                                            this.state.horaFim
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="md-checkbox-inline">
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS2"
                                                          name="stDS2"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              segCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS2">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Seg.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS3"
                                                          name="stDS3"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              terCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS3">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Ter.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS4"
                                                          name="stDS4"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              quaCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS4">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Qua
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS5"
                                                          name="stDS5"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              quiCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS5">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Qui.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS6"
                                                          name="stDS6"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              sexCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS6">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Sex.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS7"
                                                          name="stDS7"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              sabCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS7">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Sab.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS1"
                                                          name="stDS1"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              domCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS1">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Dom.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <input
                                                          id="stDS8"
                                                          name="stDS8"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              ferCheck:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                          className="md-check"
                                                          value="1"
                                                          type="checkbox"
                                                        />
                                                        <label htmlFor="stDS8">
                                                          <span></span>
                                                          <span className="check"></span>
                                                          <span className="box"></span>
                                                          Fer.
                                                        </label>
                                                      </div>
                                                      <div className="md-checkbox">
                                                        <button
                                                          type="button"
                                                          className="btn btn-circle btn-icon-only blue"
                                                          onClick={() =>
                                                            this.createJson()
                                                          }
                                                        >
                                                          <i className="fa fa-plus"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li>
                                                  <ReactTable
                                                    manual
                                                    //data={this.state.corridas}
                                                    pages={this.state.pages}
                                                    pageSizeOptions={[
                                                      5, 10, 20, 50, 100, 200,
                                                    ]}
                                                    loading={this.state.loading}
                                                    onFetchData={
                                                      this.fetchDataTimeOut
                                                    }
                                                    data={this.state.calen}
                                                    previousText="Anterior"
                                                    nextText="Próxima"
                                                    loadingText="Carregando..."
                                                    noDataText="Nenhum dado encontrado"
                                                    pageText="Página"
                                                    ofText="de"
                                                    rowsText="linhas"
                                                    columns={[
                                                      {
                                                        Header: "Inicio",
                                                        accessor: "ini",
                                                        width: 90,
                                                      },
                                                      {
                                                        Header: "Final",
                                                        accessor: "fim",
                                                        width: 90,
                                                      },
                                                      {
                                                        Header: "Seg",
                                                        accessor: "seg",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Ter",
                                                        accessor: "ter",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Qua",
                                                        accessor: "qua",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Qui",
                                                        accessor: "qui",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Sex",
                                                        accessor: "sex",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Sab",
                                                        accessor: "sab",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Dom",
                                                        accessor: "dom",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Fer",
                                                        accessor: "fer",
                                                        width: 80,
                                                        Cell: (row) => {
                                                          if (
                                                            row.value === true
                                                          ) {
                                                            return "S";
                                                          } else {
                                                            return "N";
                                                          }
                                                        },
                                                      },
                                                      {
                                                        Header: "Delete",
                                                        Cell: (row) => {
                                                          console.log(
                                                            "sou o retorno",
                                                            row
                                                          );
                                                          return (
                                                            <div
                                                              onClick={() => {
                                                                this.removeCalen(
                                                                  row.index
                                                                );
                                                              }}
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              <i className="fa fa-trash">
                                                                {" "}
                                                                Deletar
                                                              </i>
                                                            </div>
                                                          );
                                                        },
                                                      },
                                                    ]}
                                                    defaultPageSize={
                                                      this.state.calen.length <=
                                                      0
                                                        ? 4
                                                        : this.state.calen
                                                            .length
                                                    }
                                                    showPagination={false}
                                                    className="-striped -highlight"
                                                  />
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <span className="help-block">
                                            Adicione 1 ou mais Periodos
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actions"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
              marginRight: 20,
            }}
          >
            <div
              className="actions btn-set"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                className="btn round7 bgColor2 fontColor5 hmargin5"
                onClick={() => this.saveAutorizado()}
              >
                <i className="fa fa-check"></i>&nbsp; Salvar
              </button>
              &nbsp;
            </div>
            <Dialog
              ref={(el) => {
                this.dialog = el;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default CadastrarUsuarios;
