import CoreModel from "../../service/CoreModel";
import Service from "./Service";
import moment from "moment";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      fullData: [],
      data: [],
      pages: null,
      loading: true,
      currentPage: 1,
      pageSize: 10,
      successMessage: "",
      errorMessage: "",
      selecionado: {},
      showModal: false,
      center: {
        latitude: 0,
        longitude: 0,
      },
      orig: {
        latitude: 0,
        longitude: 0,
      },
      dest: {
        latitude: 0,
        longitude: 0,
      },
      dataFinal: moment().add("days", 180).startOf("month"),
      dataInicial: moment().add("days", -180).startOf("month"),
    };
  }

  closeDetails = () => {
    this.setState({ showModal: false, selecionado: {} });
    this.fetchData();
  }

  fetchData2 = async (user) => {
    this.setState({ loading: true });

    console.log("user 1", user);
    if (!user) {
      user = this.state.user;
    } 
    console.log("user 2'", user);
    try {
      const fullData = await this.service.listar(
        this.state.dataFinal,
        user.func_id,
        user.func_role
      );
      this.setState({
        showModal: false,
        fullData,
        data: fullData.slice(0, this.state.pageSize),
        pages: Math.ceil(fullData.length / this.state.pageSize),
        loading: false,
        user: user,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  escolhecorrida = (selecionado) => {
    this.setState({
      selecionado: selecionado,
      showModal: true,
      user: this.state.user,
      center: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      orig: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      dest: {
        latitude: selecionado.latitudedestino,
        longitude: selecionado.longitudedestino,
      },
    });
  };

  onPageChange2 = (pageIndex) => {
    const { fullData, pageSize } = this.state;
    const startRow = pageIndex * pageSize;
    const endRow = startRow + pageSize;
    this.setState({
      currentPage: pageIndex,
      data: fullData.slice(startRow, endRow),
    });
  };

  onPageChange = (pageIndex) => {
    this.setState({
      currentPage: pageIndex,
    });
    this.fetchData();
  };

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getTipo(
        'corridas',
        "",
        [{ id: "-t12_dthr_corridas" }],
        this.state.currentPage,
        this.state.pageSize,
        [{ id: "ztatus1", value: "4"}]
      );
      let fullData = result.result;
      let count = result.count;
      this.setState({
        showModal: false,
        fullData,
        data: fullData,
        pages: Math.ceil(count / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };
}
