import CoreService from "../../service/CoreService";

export default class Service extends CoreService {
  async listar() {
    const id = this.getCookie("empresa-id");

    try {
      const response = await this.fetchOn(`voucher_empresa_v2/${id}/departamentolist`);
      const result = response.result;
      // console.log("---------------------------------------------->", result);
      return result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }

  async criar(dataReq) {
    const id = this.getCookie("empresa-id"); //ok
    const dataParaEnviar = {
      identificacao: dataReq.identificacao, //ok
      descricao: dataReq.descricao, //ok
      valorlimit: parseFloat(dataReq.valorlimit), //ok
      empresa: +id, //ok
    };

    try {
      const response = await this.fetchOn(`voucher_empresa_v2/${id}/voucher_departamento`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataParaEnviar),
      });
      if (response.result) {
        return response.result;
      } else {
        try {
          const errorResult = response.result;
          throw new Error(errorResult.message || "Erro desconhecido");
        } catch (jsonError) {
          throw new Error(response.statusText);
        }
      }
    } catch (error) {
      console.error("Erro ao criar:", error.message);
      throw error;
    }
  }

  async deletar(depID) {
    let id = this.getCookie("empresa-id");
    let response = await this.fetchOn("voucher_empresa_v2/" + id + "/voucher_departamento/" + depID, { method: "DELETE" });

    return response.result;
  }

  async atualizar(dadosAtualizados) {
    const id = this.getCookie("empresa-id");
    try {
      const response = await this.fetchOn(`voucher_empresa_v2/${id}/voucher_departamento/${dadosAtualizados.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dadosAtualizados),
      });

      if (response.result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Erro ao atualizar departamento:", error.message);
      throw error;
    }
  }
}
