import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div className="btn round7 bgColor2 fontColor5 hmargin5" onClick={() => onPageChange(0)} disabled={page === 0}>
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class ListDepartamento extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { data, pages, loading, pageSize, currentPage } = this.props.viewModel.state;

    return (
      <div>
        <div
          className="btn round7 bgColor2 fontColor5 hmargin5"
          onClick={this.props.viewModel.toggleCreateDepartamento}
        >
          + Novo
        </div>

        <ReactTable
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.props.viewModel.fetchData}
          onPageChange={this.props.viewModel.onPageChange}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: "DEPARTAMENTO",
              accessor: "identificacao",
            },
            {
              Header: "DESCRIÇÃO",
              accessor: "descricao",
            },
            {
              Header: "VALOR LIMITE",
              accessor: "valorlimit",
            },
            {
              Header: "AÇÕES",
              accessor: "acoes",
              Cell: ({ original }) => (
                <div style={{ display: "flex", justifyContent: "flex-between" }}>
                  <div
                    className="btn round7 bgColor2 fontColor5 hmargin5"
                    onClick={() => this.props.viewModel.handleEditClick(original)}
                  >
                    Editar
                  </div>
                  <div
                    className="btn round7 bgColor2 fontColor5 hmargin5"
                    onClick={() => this.props.viewModel.handleDelete(original.id)}
                  >
                    Excluir
                  </div>
                </div>
              ),
            },
          ]}
          defaultPageSize={pageSize}
          page={currentPage}
          className="-striped -highlight round7"
          style={{
            border: "none",
          }}
          getTheadProps={() => {
            return {
              className: "round7",
              style: {
                backgroundColor: "white",
                color: "black",
              },
            };
          }}
          getTheadFilterProps={() => {
            return { className: "round7" };
          }}
          getTheadFilterTrProps={() => {
            return { className: "round7" };
          }}
          getTbodyProps={() => {
            return { className: "round7" };
          }}
          getTdProps={() => {
            return { className: "alignCenter" };
          }}
          getTheadThProps={() => {
            return {
              style: {
                fontFamily: '"Roboto", sans-serif',
                fontWeight: "bold",
              },
            };
          }}
          getTrProps={(state, rowInfo) => {
            if (!!rowInfo) {
              return {
                className: "round7",
              };
            } else {
              return {};
            }
          }}
          PaginationComponent={CustomPaginationComponent}
        />
      </div>
    );
  }
}

export default ListDepartamento;
