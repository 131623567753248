import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import "../styles/CustomFormControl.css";
import { cnpj, cpf } from "cpf-cnpj-validator";

const CustomFormControlCpf = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      <FormControl key={"fc"+name}
        className="custom-form-control"
        type={type}
        name={name}
        onChange={(e) => {
          let text = e.target.value;
          text = text.replace(/[^+\d]/g, "");
          text = text.slice(0, 11);
          text = cpf.format(text);
            formik.setFieldValue(name, text)
        }}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={placeholder}
        bSsize={bSsize}
      />
      {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
      {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
    </FormGroup>
  );
};

export default CustomFormControlCpf;
