import { useFormik } from "formik";
import * as yup from "yup";
import { realizarCadastro } from "../api";

const validationSchema = yup.object({
      cpf: yup.string().required("Campo obrigatório"),
      nome_completo: yup.string().required("Campo obrigatório"),
      data_de_nascimento: yup.date().required("Campo obrigatório"),
      nome_da_mae: yup.string().required("Campo obrigatório"),
      cep: yup.string().required("Campo obrigatório"),
      rua: yup.string().required("Campo obrigatório"),
      numero: yup.string().required("Campo obrigatório"),
      complemento: yup.string(),
      bairro: yup.string().required("Campo obrigatório"),
      cidade: yup.string().required("Campo obrigatório"),
      uf: yup.string().required("Campo obrigatório"),
      telefone: yup.string().required("Campo obrigatório"),
      email: yup.string().email().required("Campo obrigatório"),
      rg: yup.string().required("Campo obrigatório"),
      cnh: yup.string().required("Campo obrigatório"),
      cidadeatuacao: yup.string().required("Campo obrigatório"),
      cnh_data_de_vencimento: yup.date().required("Campo obrigatório"),
      curitiba: yup.boolean(),
      urbs_at: yup.string().when('cidadeatuacao', {
        is: (v) => v === "CURITIBA-PARANÁ",
        then: (schema) => schema.required("Campo obrigatório"),
        otherwise: (schema) => schema.notRequired(),
    }),
      urbs_matricula: yup.string().when('cidadeatuacao', {
        is: (v) => v === "CURITIBA-PARANÁ",
        then: (schema) => schema.required("Campo obrigatório"),
        otherwise: (schema) => schema.notRequired(),
    }),
      urbs_data_de_vencimento: yup.string().when('cidadeatuacao', {
        is: (v) => v === "CURITIBA-PARANÁ",
        then: (schema) => schema.required("Campo obrigatório"),
        otherwise: (schema) => schema.notRequired(),
    }),
      banco_nome: yup.string().required("Campo obrigatório"),
      banco_n_da_agencia: yup.string().required("Campo obrigatório"),
      banco_n_da_conta: yup.string().required("Campo obrigatório"),
      banco_tipo_da_conta: yup.string().required("Campo obrigatório"),
      carro_categoria: yup.string().required("Campo obrigatório"),
      carro_renavam: yup.string().required("Campo obrigatório"),
      carro_placa_do_detran: yup.string().required("Campo obrigatório"),
      senha: yup.string().required("Senha é obrigatória"),
      fotobase64: yup.string().required("Campo obrigatório"),
      fotobase64_1: yup.string().required("Campo obrigatório"),
      fotobase64_2: yup.string().when('cidadeatuacao', {
        is: (v) => v === "CURITIBA-PARANÁ",
        then: (schema) => schema.required("Campo obrigatório"),
        otherwise: (schema) => schema.notRequired(),
    }),
      fotobase64_3: yup.string().required("Campo obrigatório"),
      foto_carro_1: yup.string().required("Campo obrigatório"),
      foto_carro_2: yup.string().required("Campo obrigatório"),
      foto_carro_3: yup.string().required("Campo obrigatório"),
      foto_carro_4: yup.string().required("Campo obrigatório"),
      foto_carro_5: yup.string().required("Campo obrigatório"),
      foto_carro_6: yup.string().required("Campo obrigatório"),
      foto_carro_7: yup.string().required("Campo obrigatório")
});

function usePreCadastroController(central) {
  const onSubmit = async (values) => {
    console.log("submit", values)
    try {
      const response = await realizarCadastro(values, central);
      return response;
    } catch (error) {
      console.log("Erro ao realizar cadastro.", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      cpf: "",
      nome_completo: "",
      data_de_nascimento: "",
      nome_da_mae: "",
      cep: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      telefone: "",
      email: "",
      rg: "",
      cnh: "",
      cnh_data_de_vencimento: "",
      alvara: "",
      urbs_at: "",
      urbs_matricula: "",
      urbs_data_de_vencimento: "",
      banco_nome: "",
      banco_n_da_agencia: "",
      banco_n_da_conta: "",
      banco_tipo_da_conta: "",
      carro_categoria: "",
      carro_renavam: "",
      carro_placa_do_detran: "",
      senha: "",
      fotobase64: "",
      fotobase64_1: "",
      fotobase64_2: "",
      fotobase64_3: "",
      foto_carro_1: "",
      foto_carro_2: "",
      foto_carro_3: "",
      foto_carro_4: "",
      foto_carro_5: "",
      foto_carro_6: "",
      foto_carro_7: "",
      cidadeatuacao: ""
    },
    validationSchema,
    onSubmit,
  });

  return { formik };
}

export default usePreCadastroController;
