import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";

import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import "../styles/CustomFormControl.css";

const CustomFormControlSelect = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  itens,
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;
  let useItens = (!!itens ? itens : []).map((item) => { return {key: "kos" + item.value, id: item.value, value: item.value, text: item.text, label: item.text}})

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      <Select2 key={"fc"+name}
        name={name}
        value={formik.values[name]}
        data={useItens}
        onSelect={(e) => {
          formik.setFieldValue(name, e.target.value);
        }}
        options={{
          placeholder: placeholder,
          width: "100%",
        }}
      />
      {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
      {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
    </FormGroup>
  );
};

export default CustomFormControlSelect;
