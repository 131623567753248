import React, { Component } from 'react';
import Icon from 'react-icons-wrapper';
import Box from '@material-ui/core/Box';





export default class IconButton extends Component {

    constructor(props) {
      super(props);
      this.click = this.click.bind(this);
      this.state = {
          loading: false
      }
    }
    click() {
        if (this.props.click) {
            this.props.click();
        }
    }
    render() {
        let cor1 = "black";
        let cor2 = "white";
        let cor3 = "black";
        let cor4 = "white";
        let cor5 = "black";
        let cor6 = "white";
        if (!!this.props.cores) {
          cor1 = this.props.cores.cor1
            ? this.props.cores.cor1
            : "black";
          cor2 = this.props.cores.cor2
            ? this.props.cores.cor2
            : "white";
          cor3 = this.props.cores?.cor3
            ? this.props.cores?.cor3
            : "black";
          cor4 = this.props.cores?.cor4
            ? this.props.cores?.cor4
            : "white";
          cor5 = this.props.cores?.cor5
            ? this.props.cores?.cor5
            : "black";
          cor6 = this.props.cores?.cor6
            ? this.props.cores?.cor6
            : "white";
        }
        let icone = this.props.icone ? this.props.icone : 'FaBeer';
        let tamanho = this.props.tamanho ? this.props.tamanho : 30;
        let texto = this.props.texto ? this.props.texto : 'Cerveja';
        let direcao = this.props.direcao ? this.props.direcao : 'esquerda';
        let cssBase = this.props.cssbase ? this.props.cssbase : {};

        cssBase.backgroundColor = cor2;
        cssBase.margin = 3;
        return (
            <Box
                className='round7'
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                css={cssBase}
                onClick={this.click}>
                {direcao === 'esquerda' && <Box css={{ margin: 5}}><Icon name={icone} size={tamanho} color={cor5} /></Box>}
                <Box css={{ margin: 5, color: cor5, fontWeight:'Bold' }}>{texto}</Box>
                {direcao === 'direita' && <Box css={{ margin: 5 }}><Icon name={icone} size={tamanho} color={cor5} /></Box>}
            </Box>

        );
    }
}