import WS from "../../services/webservice";
import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import Loader from "react-loader";
import { Modal, Image } from "react-bootstrap";
import ReactTable from "react-table";
import IconButton from "../extensions/IconButton.js";
import ViewModel from "./ViewModel";
import Box from "@material-ui/core/Box";

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;

    this.autocomplete = this.autocomplete.bind(this);
    this.salvaSugestion = this.salvaSugestion.bind(this);
    this.getFavoritos = this.getFavoritos.bind(this);
    this.showFav = this.showFav.bind(this);
    this.hideFav = this.hideFav.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.storeInputReference = this.storeInputReference.bind(this);
    this.onChange = this.onChange.bind(this);
    this.webservice = new WS();
  }

  componentDidMount() {
    this.getFavoritos();
  }

  onChange(event, { newValue }) {
    this.props.changeEnderecoExtra(newValue, null, null, false, this.props.i);
  }

  getFavoritos() {
    this.viewModel.getFavoritos();
  }

  onSuggestionsFetchRequested({ value }) {
    this.viewModel.onSuggestionsFetchRequested(
      value,
      this.props.center,
      this.props.altcenter,
      this.props.changeOptions
    );
  }

  autocomplete(address) {
    this.viewModel.autocomplete(
      address,
      this.props.center,
      this.props.altcenter,
      this.props.changeOptions
    );
  }

  onSuggestionsClearRequested() {
    this.props.changeOptions([]);
  }

  salvaSugestion(suggestion) {
    this.viewModel.salvaSugestion(
      suggestion,
      this.props.center,
      this.props.altcenter,
      this.props.changeEndereco,
      this.props.i
    );
  }

  storeInputReference(autosuggest) {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
      if (this.props.setInpudDestino) {
        this.props.setInpudDestino(autosuggest.input, this.props.chave);
      }
    }
  }

  showFav() {
    this.viewModel.showFav();
  }

  hideFav() {
    this.viewModel.hideFav();
  }

  render() {
    let cor1 = "black";
    let cor2 = "white";
    let cor3 = "black";
    let cor4 = "white";
    let cor5 = "black";
    let cor6 = "white";
    if (!!this.props.cores) {
      cor1 = this.props.cores.cor1 ? this.props.cores.cor1 : "black";
      cor2 = this.props.cores.cor2 ? this.props.cores.cor2 : "white";
      cor3 = this.props.cores?.cor3 ? this.props.cores?.cor3 : "black";
      cor4 = this.props.cores?.cor4 ? this.props.cores?.cor4 : "white";
      cor5 = this.props.cores?.cor5 ? this.props.cores?.cor5 : "black";
      cor6 = this.props.cores?.cor6 ? this.props.cores?.cor6 : "white";
    }

    let central = this.props.central;
    let observacao = "Observação";
    let observacoes = "Observações";
    if (!!this.props.central) {
      if (!!this.props.central.obs_field_label_plural) {
        observacoes = this.props.central.obs_field_label_plural;
      }
      if (!!this.props.central.obs_field_label) {
        observacao = this.props.central.obs_field_label;
      }
    }
    return (
      <div>
        <div className="col-md-12">
          <div className="col-md-10">
            <div className="zindex1" style={{ margin: 5 }}>
              <div className="input-icon">
                <i className="fa fa-map-marker"></i>
                <Loader loaded={this.viewModel.state.loaded}></Loader>
                <Autosuggest
                  ref={this.storeInputReference}
                  highlightFirstSuggestion={true}
                  focusInputOnSuggestionClick={false}
                  suggestions={this.props.address}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={(e, { suggestion, method }) => {
                    this.viewModel.setState({ loaded: false });
                    this.salvaSugestion(suggestion);
                  }}
                  getSuggestionValue={(item) => item.endereco}
                  renderSuggestion={(item) => (
                    <div className="zindex999" style={{ padding: "6px 3px" }}>
                      <i className="fa fa-map-marker"></i>&nbsp;
                      {item.fullAddress}
                    </div>
                  )}
                  inputProps={{
                    className: "form-control placeholder-no-fix round7 zindex1",
                    style: { paddingLeft: 30 },
                    value: this.props.endereco,
                    onChange: this.onChange,
                    placeholder: !!this.props.placeholder
                      ? this.props.placeholder
                      : "Endereço",
                    autoFocus: this.props.autoFocus ? true : false,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <Box display="flex" flexWrap="nowrap" alignItems="center">
              <IconButton
                icone={"MdStars"}
                texto={"Salvos"}
                tamanho={22}
                cores={this.props.cores}
                click={() => this.showFav(this.props.i)}
              />
              {!!this.props.showObservacoes && (
                <IconButton
                  icone={"MdNote"}
                  texto={observacao}
                  tamanho={22}
                  cores={this.props.cores}
                  click={() => this.props.onclickObservacoes(this.props.i)}
                />
              )}
            </Box>
          </div>
        </div>
        <Modal
          keyboard
          show={this.viewModel.state.showfavmodal}
          onHide={this.hideFav}
          container={this}
          bsSize="large"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; Endereços Favoritos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactTable
              data={this.viewModel.state.favoritos}
              previousText="Anterior"
              nextText="Próxima"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              className="-striped -highlight round7"
              style={{
                border: "none",
              }}
              getTheadProps={() => {
                return {
                  className: "round7",
                  style: {
                    backgroundColor: "white",
                    color: "black",
                  },
                };
              }}
              getTheadFilterProps={() => {
                return { className: "round7" };
              }}
              getTheadFilterTrProps={() => {
                return { className: "round7" };
              }}
              getTbodyProps={() => {
                return { className: "round7" };
              }}
              getTheadThProps={() => {
                return {
                  style: {
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: "bold",
                  },
                };
              }}
              getTrProps={(state, rowInfo) => {
                return {
                  className: "round7",
                };
              }}
              getTdProps={(state, props, column) => {
                return {
                  className: "alignCenter",
                  style: {
                    cursor: "pointer",
                  },
                  onClick: async (e) => {
                    this.props.changeEndereco(
                      props.original.autocomplete,
                      true,
                      this.props.i
                    );
                    if (this.props.salvafavorito) {
                      this.props.salvafavorito(props.original, this.props.i);
                    }
                    this.hideFav();
                  },
                };
              }}
              columns={[
                {
                  Header: (
                    <div className="header-table-left">
                      <i className="fa fa-user"></i>&nbsp;Apelido
                    </div>
                  ),
                  accessor: "nome",
                  width: 100,
                },
                {
                  Header: (
                    <div className="header-table-left">
                      <i className="fa fa-briefcase"></i>&nbsp;endereco
                    </div>
                  ),
                  accessor: "endereco",
                },
              ]}
              defaultPageSize={10}
              showPagination={true}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default AutoComplete;
