import React from "react";
import { Alert, Col, Form, Grid, Image, Row } from "react-bootstrap";
import usePreCadastroController from "../controllers/preCadastroController.js";
import { PreCadastroService } from '../api/services/PreCadastroService.js';

import CustomFormControl from "../extensions/CustomFormControl.js";
import CustomFormControlCpf from "../extensions/CustomFormControlCpf.js";
import CustomFormControlCep from "../extensions/CustomFormControlCep.js";
import CustomFormControlTelefone from "../extensions/CustomFormControlTelefone.js";
import CustomFormControlSelect from "../extensions/CustomFormControlSelect.js";
import CustomFormControlAgencia from "../extensions/CustomFormControlAgencia.js";
import CustomFormControlFoto from "../extensions/CustomFormControlFoto.js";
import "../styles/preCadastroLayout.css";

const bancos = [
  { value: "001", b: "Banco do Brasil", text: "001 - Banco do Brasil" },
  { value: "033", b: "Santander", text: "033 - Santander" },
  { value: "104", b: "Caixa Econômica", text: "104 - Caixa Econômica" },
  { value: "237", b: "Bradesco", text: "237 - Bradesco" },
  { value: "341", b: "Itaú", text: "341 - Itaú" },
  { value: "041", b: "Banrisul", text: "041 - Banrisul" },
  { value: "748", b: "Sicredi", text: "748 - Sicredi" },
  { value: "756", b: "Sicoob", text: "756 - Sicoob" },
  { value: "077", b: "Inter", text: "077 - Inter" },
  { value: "070", b: "BRB", text: "070 - BRB" },
  { value: "085", b: "Via Credi", text: "085 - Via Credi" },
  { value: "655", b: "Neon/Votorantim", text: "655 - Neon/Votorantim" },
  { value: "260", b: "Nubank", text: "260 - Nubank" },
  { value: "290", b: "Pagseguro", text: "290 - Pagseguro" },
  { value: "212", b: "Banco Original", text: "212 - Banco Original" },
  { value: "422", b: "Safra", text: "422 - Safra" },
  { value: "746", b: "Modal", text: "746 - Modal" },
  { value: "021", b: "Banestes", text: "021 - Banestes" },
  { value: "136", b: "Unicred", text: "136 - Unicred" },
  { value: "274", b: "Money Plus", text: "274 - Money Plus" },
  {
    value: "389",
    b: "Mercantil do Brasil",
    text: "389 - Mercantil do Brasil",
  },
  { value: "376", b: "JP Morgan", text: "376 - JP Morgan" },
  {
    value: "364",
    b: "Gerencianet Pagamentos do Brasil",
    text: "364 - Gerencianet Pagamentos do Brasil",
  },
];

const onSubmitExtra = async (formik, central, setLoading, setStates) => {
  console.log("submit", [formik.errors, formik.values, formik.isValid])
  if (!formik.isValid) return;
  setLoading(true);
  try {
    let pSer = new PreCadastroService();

    let response = await pSer.realizarCadastro(formik.values, central);
    setStates({
      alertDisplay: "alert-info",
      alertText: "Seu cadastro foi realizado com sucesso! Aguarde a aprovação do administrador.",
    })
    return response;
  } catch (error) {
    setLoading(false);
    console.log("Erro ao realizar cadastro.", error);
  }
};

function PreCadastroLayout({ icone, central, cidades, loading, setLoading, setStates, alertDisplay, alertText }) {
  const { formik } = usePreCadastroController(central.id);
  const { handleSubmit } = formik;

  return (
    <>
      <Grid>
        <Row>
          {loading && (
            <div className="loading-container">
              <div className="loading">Carregando</div>
            </div>
          )}
          {!loading &&  (
          <div>
            <Col
              md={6}
              mdOffset={3}
              style={{ borderRadius: "10px", marginTop: "20px" }}
            >
              <div className="neumorphic-form-container">
                <div className="img-container-icon">
                  {!!icone ? <Image src={icone}></Image> : <></>}
                </div>
                <Form noValidate onSubmit={handleSubmit}>
                  <CustomFormControlCpf
                    label="CPF"
                    name="cpf"
                    type="text"
                    formik={formik}
                    placeholder="Digite seu CPF"
                  />
                  <CustomFormControl
                    label="Nome Completo"
                    name="nome_completo"
                    type="text"
                    formik={formik}
                    placeholder="Digite seu nome completo"
                  />
                  <CustomFormControl
                    label="E-mail"
                    name="email"
                    type="email"
                    formik={formik}
                    placeholder="Digite seu e-mail"
                  />
                  <CustomFormControl
                    label="Data de Nascimento"
                    name="data_de_nascimento"
                    type="date"
                    formik={formik}
                    placeholder="Selecione sua data de nascimento"
                  />
                  <CustomFormControl
                    label="Nome da Mãe"
                    name="nome_da_mae"
                    type="text"
                    formik={formik}
                    placeholder="Digite o nome da sua mãe"
                  />
                  <CustomFormControlCep
                    label="CEP"
                    name="cep"
                    type="text"
                    formik={formik}
                    placeholder="Digite seu CEP"
                  />
                  <CustomFormControl
                    label="Rua"
                    name="rua"
                    type="text"
                    formik={formik}
                    placeholder="Digite o nome da rua"
                  />
                  <CustomFormControl
                    label="Número"
                    name="numero"
                    type="text"
                    formik={formik}
                    placeholder="Digite o número da residência"
                  />
                  <CustomFormControl
                    label="Complemento"
                    name="complemento"
                    type="text"
                    formik={formik}
                    placeholder="Complemento (opcional)"
                  />
                  <CustomFormControl
                    label="Bairro"
                    name="bairro"
                    type="text"
                    formik={formik}
                    placeholder="Digite o nome do bairro"
                  />
                  <CustomFormControl
                    label="Cidade"
                    name="cidade"
                    type="text"
                    formik={formik}
                    placeholder="Digite o nome da cidade"
                  />
                  <CustomFormControl
                    label="UF"
                    name="uf"
                    type="text"
                    formik={formik}
                    placeholder="Digite a UF"
                  />
                  <CustomFormControlTelefone
                    label="Telefone"
                    name="telefone"
                    type="tel"
                    formik={formik}
                    placeholder="Digite seu telefone"
                  />
                  <CustomFormControl
                    label="RG"
                    name="rg"
                    type="text"
                    formik={formik}
                    placeholder="Digite seu RG"
                  />
                  <CustomFormControl
                    label="CNH"
                    name="cnh"
                    type="text"
                    formik={formik}
                    placeholder="Digite sua CNH"
                  />
                  <CustomFormControl
                    label="Data de Vencimento da CNH"
                    name="cnh_data_de_vencimento"
                    type="date"
                    formik={formik}
                  />

                  <CustomFormControlSelect
                    label="Cidade de atuação"
                    name="cidadeatuacao"
                    type="text"
                    itens={cidades.map((x) => {
                      return { value: x, text: x };
                    })}
                    formik={formik}
                    placeholder="Digite o nome da Cidade de atuação"
                  />
                  {formik.values.cidadeatuacao === "CURITIBA-PARANÁ" && (
                  <CustomFormControl
                    label="AT URBS"
                    name="urbs_at"
                    type="text"
                    formik={formik}
                    placeholder="Digite o AT URBS"
                  />)}
                  {formik.values.cidadeatuacao === "CURITIBA-PARANÁ" && (
                  <CustomFormControl
                    label="Matrícula URBS"
                    name="urbs_matricula"
                    type="text"
                    formik={formik}
                    placeholder="Digite a matrícula URBS"
                  />)}
                  {formik.values.cidadeatuacao === "CURITIBA-PARANÁ" && (
                  <CustomFormControl
                    label="Data de Vencimento URBS"
                    name="urbs_data_de_vencimento"
                    type="date"
                    formik={formik}
                  />)}
                  <CustomFormControlSelect
                    label="Nome do Banco"
                    name="banco_nome"
                    type="text"
                    formik={formik}
                    itens={bancos}
                    placeholder="Digite o nome do banco"
                  />
                  <CustomFormControlSelect
                    label="Tipo da Conta"
                    name="banco_tipo_da_conta"
                    type="text"
                    itens={[
                      { value: "Corrente", text: "Corrente" },
                      { value: "Poupança", text: "Poupança" },
                    ]}
                    formik={formik}
                    placeholder="Tipo da conta (Corrente/Poupança)"
                  />
                  <CustomFormControlAgencia
                    label="Número da Agência"
                    name="banco_n_da_agencia"
                    tipo="ag"
                    type="text"
                    formik={formik}
                    placeholder="Digite o número da agência"
                  />
                  <CustomFormControlAgencia
                    label="Número da Conta"
                    name="banco_n_da_conta"
                    tipo="cc"
                    type="text"
                    formik={formik}
                    placeholder="Digite o número da conta"
                  />
                  <CustomFormControlSelect
                    label="Categoria do Carro"
                    name="carro_categoria"
                    type="text"
                    itens={[
                      { value: "Moto", text: "Moto" },
                      { value: "Carro", text: "Carro" },
                    ]}
                    formik={formik}
                    placeholder="Digite a categoria do carro"
                  />
                  <CustomFormControl
                    label="RENAVAM"
                    name="carro_renavam"
                    type="text"
                    formik={formik}
                    placeholder="Digite o RENAVAM do carro"
                  />
                  <CustomFormControl
                    label="Placa do DETRAN"
                    name="carro_placa_do_detran"
                    type="text"
                    formik={formik}
                    placeholder="Digite a placa do DETRAN"
                  />
                  <CustomFormControl
                    label="Senha"
                    name="senha"
                    type="password"
                    formik={formik}
                    placeholder="Crie uma senha"
                  />
                  <CustomFormControlFoto
                    label="Foto do Motorista"
                    name="fotobase64"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto do CNH"
                    name="fotobase64_1"
                    type="file"
                    formik={formik}
                  />
                  {formik.values.cidadeatuacao === "CURITIBA-PARANÁ" && (
                  <CustomFormControlFoto
                    label="Foto da Matrícula URBS"
                    name="fotobase64_2"
                    type="file"
                    formik={formik}
                  />)}
                  <CustomFormControlFoto
                    label="Foto do Comprovante"
                    name="fotobase64_3"
                    type="file"
                    formik={formik}
                  />

                  <CustomFormControlFoto
                    label="Foto do CRLV"
                    name="foto_carro_1"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto da Frente do Carro"
                    name="foto_carro_2"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto da Traseira do Carro"
                    name="foto_carro_3"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto da Lateral Direita do Carro"
                    name="foto_carro_4"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto da Lateral Esqueda do Carro"
                    name="foto_carro_5"
                    type="file"
                    formik={formik}
                  />
                  <CustomFormControlFoto
                    label="Foto do Painel do Carro"
                    name="foto_carro_6"
                    type="file"
                    formik={formik}
                  />

                  <CustomFormControlFoto
                    label="Foto dos Bancos do Carro"
                    name="foto_carro_7"
                    type="file"
                    formik={formik}
                  />
                  <div
                    type="button"
                    className={formik.isValid ? "btn bgColor2 round7 fontColor1" : "btn bgColor1 round7 fontColor6 disabled"}
                    onClick={() => {onSubmitExtra(formik, central.id, setLoading, setStates)}}
                    style={{ width: "100%" }}
                  >
                    Enviar
                  </div>
                  <div>
                      <div
                        className={"portlet light round12 alert " + alertDisplay}
                      >
                        <button
                          className="close"
                          onClick={() =>
                            setStates({
                              alertDisplay: "display-hide",
                            })
                          }
                        ></button>
                        <span>{alertText}</span>
                      </div></div>
                </Form>
              </div>
            </Col>
          </div>)}
        </Row>
      </Grid>
    </>
  );
}

export default PreCadastroLayout;
