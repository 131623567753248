import React, { Component } from "react";
import ResetSenhaView from "./views/ResetSenha";
import ViewModel from "./controllers/ViewModel.js";

export default class ResetSenha extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="container">
            <ResetSenhaView {...this.props} viewModel={this.viewModel} />
          </div>
        </div>
      </div>
    );
  }
}
