import React, { Component } from "react";
import AcompanhamentoView from "./views/Acompanhamento";

export default class Acompanhamento extends Component {
  constructor(props) {
    super(props);
    // this.viewModel = new ViewModel();
    // this.viewModel.mirrorState = (s) => this.setState(s);
    // this.viewModel.props = props;
  }
  componentDidMount() {
    // this.viewModel.fetchData();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader
          textoEsquerda="Acompanhamento"
          textoCentral="acompanhamento"
        />
        <div className="page-content">
          <div className="container">
            <AcompanhamentoView  {...this.props}/>
          </div>
        </div>
      </div>
    );
  }
}
