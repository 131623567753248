import React, { Component } from "react";
import { old }  from '../../go-versao';

import { Modal } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";

class ModalsView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var showEmpresa = this.props.viewModel.state.showEmpresa;
    var showCentral = this.props.viewModel.state.showCentral;
    var appshowNovaSenha = this.props.viewModel.state.appshowNovaSenha;
    var alertDisplay = this.props.viewModel.state.alertDisplay;
    var alertText = this.props.viewModel.state.alertText;
    var senha = this.props.viewModel.state.senha;
    var senhaConfirm = this.props.viewModel.state.senhaConfirm;
    var selected = this.props.viewModel.state.selected;
    var empresa = this.props.viewModel.state.empresa;
    var dataEmpresas = this.props.viewModel.state.dataEmpresas;

    return (
      <>
        <Modal
          keyboard
          show={showCentral}
          onHide={this.props.viewModel.modalHide}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; CONTADO DESENVOLVEDOR
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="portlet-body">
              <div>
                <div className="well">
                  <address>
                    <strong>GO Express</strong>
                    <br />
                    Sistemas de Gestão e Apps para empresas de Entregas e
                    Transportes
                    <br />
                    Rua Álvaro Andrade, 435
                    <br />
                    Bairro Portão, Curitiba, 80610240
                    <br />
                    <abbr title="Phone">Tel:</abbr> (41) 3288-7149
                    <br />
                    <strong>Email </strong>
                    <a target="_blank" href="mailto:comercial@goapps.com.br">
                      comercial@goapps.com.br
                    </a>
                    <br />
                    <strong>Email </strong>
                    <a target="_blank" href="mailto:suporte@goapps.com.br">
                      suporte@goapps.com.br
                    </a>
                    <br />
                    <strong>Site </strong>
                    <a target="_blank" href="http://www.goapps.com.br">
                      www.goapps.com.br
                    </a>
                    <br />
                    <strong>Facebook </strong>
                    <a
                      target="_blank"
                      href="http://www.facebook.com/solucoesgoexpress"
                    >
                      @solucoesgoexpress
                    </a>
                    <br />
                    <strong>Instagram </strong>
                    <a
                      target="_blank"
                      href="http://www.instagram.com/solucoesgoexpress"
                    >
                      @solucoesgoexpress
                    </a>
                    <br />
                  </address>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.props.viewModel.modalHide}
              className="link-button btn default"
            >
              Fechar
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          keyboard
          show={appshowNovaSenha}
          onHide={this.props.viewModel.modalHidenovasenha}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Mudar Senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-horizontal">
              <div className={"alert " + alertDisplay}>
                <button
                  className="link-button close"
                  onClick={this.props.viewModel.hideAlert}
                ></button>
                <span>{alertText}</span>
              </div>
              <div className="form-body">
                <div className="form-group form-md-line-input">
                  <label className="col-md-3 control-label">Nova Senha</label>
                  <div className="col-md-9">
                    <div className="input-icon left">
                      <input
                        className="form-control"
                        value={senha}
                        type="password"
                        onChange={(e) => this.props.viewModel.onchangeSenha(e.target.value)}
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-md-line-input">
                  <label className="col-md-3 control-label">
                    Confirmar Senha
                  </label>
                  <div className="col-md-9">
                    <div className="input-icon left">
                      <input
                        className="form-control"
                        value={senhaConfirm}
                        type="password"
                        onChange={(e) =>
                          this.props.viewModel.onchangeSenhaConfirm(e.target.value)
                        }
                      />
                      <div className="form-control-focus"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
            <div
            className="btn round7 bgColor5 fontColor2 hmargin5"
                onClick={() => {
                  this.props.viewModel.buttonConfirmSenha();
                }}
              >
                Salvar
              </div>
              &nbsp;
              <div
            className="btn round7 bgColor5 fontColor2 hmargin5"
                onClick={() => this.props.viewModel.modalHidenovasenha()}
              >
                Fechar
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          keyboard
          show={showEmpresa}
          onHide={this.props.viewModel.modalHideEmpresa}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-star-o"></i>&nbsp; Informações do Convênio
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="portlet-body">
              <div>
                <div className="well">
                  <address>
                    <strong>
                      Prestador de Serviço:{" "}
                      {selected.id && selected.nomefantasia}
                    </strong>
                    <br />
                    {!!selected.endereco && (
                      <>
                        {selected.id &&
                          selected.endereco.rua +
                            ", " +
                            selected.endereco.numero}
                        <br />
                        {selected.id && selected.endereco.cidade},{" "}
                        {selected.id && selected.endereco.cep}
                        <br />
                        <abbr title="Phone">Tel:</abbr>{" "}
                        {selected.id && selected.endereco.telefonecontato}
                        <br />
                        <abbr title="Phone">Tel:</abbr>{" "}
                        {selected.id && selected.endereco.celularcontato}
                        <br />
                        <strong>Email: </strong>
                        <a
                          href={
                            selected.id && "mailto:" + selected.endereco.email
                          }
                        >
                          {selected.id && selected.endereco.email}
                        </a>
                        <br />
                        <strong>Site: </strong>
                        <a
                          target="_blank"
                          href={selected.id && selected.endereco.site}
                        >
                          {selected.id && selected.endereco.site}
                        </a>
                        <br />
                      </>
                    )}
                  </address>
                </div>
                <div className="well">
                  <address>
                    <strong>
                      Conveniado: {empresa && empresa.razaosocial}
                    </strong>
                    <br />
                    {empresa && empresa.cnpj}
                    <br />
                    {empresa && empresa.endereco}
                    <br />
                    <abbr title="Phone">Tel:</abbr>{" "}
                    {selected.id && empresa.telefone}
                  </address>
                </div>
              </div>
              {dataEmpresas && dataEmpresas.length > 1 && (
                <div className="well">
                  <address>
                    <strong>Outros Convênios do Associado</strong>
                    <br />
                  </address>
                  <ReactTable
                    data={dataEmpresas}
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando..."
                    noDataText="Nenhum dado encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                    pageSize={
                      dataEmpresas.length !== 0 ? dataEmpresas.length : 4
                    }
                    columns={[
                      {
                        Header: (
                          <div className="header-table-left">
                            <i className="fa fa-taxi"></i>
                            &nbsp;Empresa
                          </div>
                        ),
                        accessor: "razaosocial",
                      },
                      {
                        id: "checkbox",
                        accessor: "",
                        Cell: ({ original }) => {
                          return (                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={
                                empresa.razaosocial === original.razaosocial
                                  ? true
                                  : false
                              }
                              onChange={() => this.props.viewModel.toggleRowEmpresa(original)}
                            />
                          );
                        },
                        Header: (
                          <div className="header-table-left">
                            <i className="fa fa-pencil-square-o"></i>
                            &nbsp;Padrão
                          </div>
                        ),
                      },
                    ]}
                    defaultPageSize={dataEmpresas.length}
                    showPagination={false}
                    className="-striped -highlight"
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.props.viewModel.modalHideEmpresa}
              className="link-button btn default"
            >
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ModalsView