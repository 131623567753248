import React, { Component } from "react";
import { Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
class MinhaContaView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { viewModel } = this.props;

    <div
      style={{
        padding: "10px 0",
        margin: "auto",
        marginBottom: 10,
        display: "flex",
      }}
    >
      <div
        className={"fontColor5"}
        style={{
          fontSize: 20,
          fontWeight: "bolder",
          textAlign: "left",
          margin: "auto",
        }}
      >
      </div>
    </div>;

    return (
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="page-content-inner">
              <div className="mt-content-body ">
                <div className=" rowAjust">
                  <div className="(col)-md-12">
                    <div className="portlet">
                      <div className="portlet-title" style={{ borderBottom: "none" }}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="fontColor2">
                          <Row className="clearfix">
                            <Col md={12} className="tabbable-line">
                              <Nav
                                bsStyle="tabs"
                                className="navbar-left fontColor2"
                                style={{
                                  fontSize: "1.5em",
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                <NavItem eventKey="first">Informacões Pessoais</NavItem>
                                {/* <NavItem eventKey="second">Alterar Avatar</NavItem> */}
                                <NavItem eventKey="third">Senha Portal</NavItem>
                                {/* <NavItem eventKey="fourth">Senha Autorização</NavItem> */}
                                <NavItem eventKey="fifth">Configurações</NavItem>
                              </Nav>
                            </Col>

                            <Col sm={12} md={12} style={{ paddingTop: 10 }} className="fontColor2">
                              <Tab.Content animation>
                                <Tab.Pane eventKey="first">
                                  <div className={"alert " + viewModel.state.alertDisplay2}>
                                    <button
                                      className="close"
                                      onClick={() => viewModel.setState({ alertDisplay2: "display-hide" })}
                                    ></button>
                                    <span>{viewModel.state.alertText2}</span>
                                  </div>
                                  <div className="ng-pristine ng-valid">
                                    <div className="form-group">
                                      <label className="control-label">Nome Completo </label>
                                      <input
                                        className="form-control round7"
                                        type="text"
                                        onChange={(e) => viewModel.setState({ nome: e.target.value })}
                                        value={viewModel.state.nome}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label">No.Telefone </label>
                                      <input
                                        className="form-control round7"
                                        type="text"
                                        onChange={(e) => viewModel.setState({ telefone: e.target.value })}
                                        value={viewModel.state.telefone}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label">
                                        Enviar-me email de acompanhamento de cada corrida solicitada?
                                      </label>
                                      <input
                                        className="form-control"
                                        type="checkbox"
                                        onChange={(e) => viewModel.setState({ email_corrida_acomp: e.target.checked })}
                                        checked={viewModel.state.email_corrida_acomp}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label">
                                        Enviar-me email de acompanhamento de cada corrida solicitada pelos usuarios que
                                        sou responsavel?
                                      </label>
                                      <input
                                        className="form-control round7"
                                        type="checkbox"
                                        onChange={(e) =>
                                          viewModel.setState({ email_corrida_acomp_resp: e.target.checked })
                                        }
                                        checked={viewModel.state.email_corrida_acomp_resp}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label">
                                        Enviar-me email com o recibo de cada corrida realizada?
                                      </label>
                                      <input
                                        className="form-control round7"
                                        type="checkbox"
                                        onChange={(e) => viewModel.setState({ email_corrida: e.target.checked })}
                                        checked={viewModel.state.email_corrida}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label">
                                        Enviar-me email com o recibo de cada corrida realizada pelos usuarios que sou
                                        responsavel?
                                      </label>
                                      <input
                                        className="form-control round7"
                                        type="checkbox"
                                        onChange={(e) => viewModel.setState({ email_corrida_resp: e.target.checked })}
                                        checked={viewModel.state.email_corrida_resp}
                                      />
                                    </div>
                                    <div className="margiv-top-10">
                                      <button
                                        className="btn round7 bgColor2 fontColor5 hmargin5"
                                        onClick={() => viewModel.saveInfos()}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="second">
                                  <div className="form-group">
                                    <div className="fileinput fileinput-new">
                                      <div id="content">
                                        <div className="frame" id="divImg">
                                          <div
                                            className="guillotine-window"
                                            style={{ width: "100%", height: "auto", paddingTop: "75%" }}
                                          >
                                            <div
                                              className="guillotine-canvas"
                                              style={{ width: "100%", height: "125%", top: "-0.76%", left: "0%" }}
                                            >
                                              <img
                                                alt=""
                                                id="imgAvatar"
                                                src={viewModel.state.fotoPerfil}
                                                style={{ perspective: 1000, backfaceVisibility: "hidden" }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div id="controls">
                                          <button
                                            id="rotate_left"
                                            className="btn default"
                                            type="button"
                                            title="Rotate left"
                                          >
                                            {" "}
                                            &lt;{" "}
                                          </button>
                                          <button id="zoom_out" className="btn default" type="button" title="Zoom out">
                                            {" "}
                                            -{" "}
                                          </button>
                                          <button id="fit" type="button" className="btn default" title="Fit image">
                                            {" "}
                                            [ ]{" "}
                                          </button>
                                          <button id="zoom_in" className="btn default" type="button" title="Zoom in">
                                            {" "}
                                            +{" "}
                                          </button>
                                          <button
                                            id="rotate_right"
                                            className="btn default"
                                            type="button"
                                            title="Rotate right"
                                          >
                                            {" "}
                                            &gt;{" "}
                                          </button>
                                        </div>
                                        <div>
                                          <span className="btn default btn-file">
                                            <div className="ng-pristine ng-valid">
                                              <span className="fileinput-new">Selecione uma Imagem</span>
                                              <input onChange={viewModel.onImageChange.bind(this)} type="file" />
                                            </div>
                                          </span>
                                          <br />
                                          Ou
                                          <div className="margin-top-10">
                                            <button className="btn silver-haze">Tire uma Foto(Selfie)</button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="margin-top-10">
                                        <button
                                          className="btn round7 bgColor2 fontColor5 hmargin5"
                                          onClick={() => viewModel.saveImage().bind(this)}
                                        >
                                          Salvar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="third">
                                  <div className="ng-pristine ng-valid">
                                    <div className="form-body">
                                      <div className="form-group">
                                        <label className="control-label">Nova senha</label>
                                        <div className="input-group">
                                          <input
                                            className="form-control round7"
                                            value={viewModel.state.senha}
                                            type="password"
                                            onChange={(e) => viewModel.setState({ senha: e.target.value })}
                                          />
                                          <span className="input-group-addon">
                                            <i className="fa fa-user"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="control-label">Confirme a senha</label>
                                        <div className="input-group">
                                          <input
                                            className="form-control round7"
                                            value={viewModel.state.senhaConfirm}
                                            type="password"
                                            onChange={(e) => viewModel.setState({ senhaConfirm: e.target.value })}
                                          />
                                          <span className="input-group-addon">
                                            <i className="fa fa-user"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="margin-top-10">
                                        <button
                                          className="btn round7 bgColor2 fontColor5 hmargin5"
                                          onClick={() => viewModel.savePortalPass()}
                                        >
                                          Salvar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="fourth">
                                  <div className="form-horizontal">
                                    <div className="note note-success">
                                      <h4 className="block">Olá, </h4>
                                      <p>
                                        Você possui uma autorização para solicitar o serviço pela Empresa{" "}
                                        <strong>ligteste</strong>, é necessário que a senha de autorização de pagamento
                                        da corrida seja redefinida, esta senha poderá ser solicitada no início ou
                                        encerramento do serviço.
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="dsSenhaAtual" className="col-md-4 control-label">
                                        Senha Atual
                                        <span className="required"> *</span>
                                      </label>
                                      <div className="col-md-5">
                                        <div className="input-icon">
                                          <i className="fa fa-user"></i>
                                          <input className="form-control round7" type="password" />
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                      <label htmlFor="dsNovaSenha1" className="col-md-4 control-label">
                                        Nova Senha
                                        <span className="required"> *</span>
                                      </label>
                                      <div className="col-md-5">
                                        <div className="input-icon">
                                          <i className="fa fa-user"></i>
                                          <input className="form-control round7" type="password" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="dsNovaSenha1" className="col-md-4 control-label">
                                        Repita Nova Senha
                                        <span className="required"> *</span>
                                      </label>
                                      <div className="col-md-5">
                                        <div className="input-icon">
                                          <i className="fa fa-user"></i>
                                          <input className="form-control round7" type="password" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group" onClick={() => viewModel.saveAuthPass().bind(this)}>
                                      <div className="col-md-9">
                                        <button className="btn green-haze">Validar</button>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="fifth">
                                  <div className={"alert " + viewModel.state.alertDisplay}>
                                    <button
                                      className="close"
                                      onClick={() => viewModel.setState({ alertDisplay: "display-hide" })}
                                    ></button>
                                    <span>{viewModel.state.alertText}</span>
                                  </div>
                                  <div className="ng-pristine ng-valid">
                                    <div className="form-body">
                                      <table className="table table-light table-hover">
                                        <tbody>
                                          <tr>
                                            <td>
                                              Ao Solicitar uma corrida pré-selecionar o "Local Favorito" de Endereço:
                                            </td>
                                            <td>
                                              <Select2
                                                style={{ width: "24vw" }}
                                                className="form-control"
                                                ref="fav_type"
                                                value={viewModel.state.favoritoSelected}
                                                data={viewModel.state.favoritos}
                                                onSelect={(e) => {
                                                  viewModel.setState(
                                                    {
                                                      favoritoSelected: parseInt(e.target.selectedOptions[0].value, 10),
                                                    },
                                                    () => {
                                                      viewModel.saveConfig();
                                                    }
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MinhaContaView;
