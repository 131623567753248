import React, { Component } from "react";
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Modal, Row } from "react-bootstrap";

class CreateDepartamento extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { successMessage, errorMessage, showModal, editData, departamentoSelecionado } = this.props.viewModel.state;

    const saveButtonStyle = {
      backgroundColor: "#E56324",
      color: "white",
      borderColor: "#E56324",
      marginRight: "10px",
    };

    return (
      <Modal
        show={showModal}
        onHide={() =>
          this.props.viewModel.setState({
            showModal: false,
            editData: { identificacao: "", descricao: "", valorlimit: "" },
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Departamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid style={{ padding: "15px" }}>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Identificação:</ControlLabel>
                    <FormControl
                      type="text"
                      name="identificacao"
                      placeholder="Identificação"
                      value={editData.identificacao}
                      onChange={this.props.viewModel.handleEditInputChange}
                      style={{ marginBottom: "10px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Descrição:</ControlLabel>
                    <FormControl
                      as="textarea"
                      name="descricao"
                      rows={3}
                      value={editData.descricao}
                      onChange={this.props.viewModel.handleEditInputChange}
                      style={{ marginBottom: "10px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Saldo Gasto:</ControlLabel>
                    <FormControl
                      type="number"
                      name="valorlimit"
                      placeholder="Saldo Limite"
                      value={editData.valorlimit}
                      onChange={this.props.viewModel.handleEditInputChange}
                      style={{ marginBottom: "10px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="btn round7 bgColor5 fontColor2 hmargin5"
            onClick={() =>
              this.props.viewModel.setState({
                showModal: false,
                editData: { identificacao: "", descricao: "", valorlimit: "" },
              })
            }
          >
            Cancelar
          </div>
          <div
            className="btn round7 bgColor5 fontColor2 hmargin5"
            onClick={() => this.props.viewModel.handleUpdate(editData, departamentoSelecionado.id)}
          >
            Salvar Alterações
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateDepartamento;
