const { url } = require("../../../../go-versao.js");
const serverUrl = url;

export default class Webservice {
  getCookie(name) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

  async customFetch(extension, options = { headers: {} }) {
    const response = await fetch(`${serverUrl}${extension}`, {
      ...options,
      headers,
    });


    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  }


  async getCentralInfosCores(domain) {
    try {
      let useDomain = !!domain ? domain : "gosolucoes";
      let result = await this.fetchOff(
        "precadastroempresa/subdominios?central=" + useDomain
      );
      if (!!result.result) {
        return result.result;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }

  async fetchOff(extension, object = { headers: {} }, centralID = 1) {
    let token = "";
    let empresaID = 1;
    object.headers = {
      ...object.headers,
      "x-access-token": token,
      "x-central-id": centralID,
      "x-empresa-id": empresaID,
    };

    let result = await fetch(serverUrl + extension, object);
    let count = result.headers.get("X-Total-Count");
    let response = await result.json();

    return { result: response, count: count };
  }
}
