import React, { Component } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Panel, Button, Col, FormGroup, ControlLabel, FormControl, Image } from 'react-bootstrap'
import WS from '../services/webservice';
import ReactTable from "react-table";
import CorridaDados from './CorridaDados.js';
import { MessageList } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css';
import ReactCrop from 'react-image-crop';
import soundfile from '../assets/ding.mp3';

//const { compose, withProps, lifecycle } = require("recompose");

var vet2 = ["a"];
// var FotoMoto = require('../images/taxi.png')
// function retorno(destinos, origlat, origlng, destlat, destlng) {
//   if (destinos && destinos.length > 0) {
//     for (let i = 0; i < destinos.length; i++) {
//       if (!destinos[i].dataHoraAviso) {
//         if (i === 0) {
//           let myretorno = {
//             origemLat: origlat,
//             origemLng: origlng,
//             destLat: destinos[0].latitude,
//             destLng: destinos[0].longitude,
//           }
//           return myretorno
//         } else {
//             let myretorno = {
//               origemLat: destinos[i - 1].latitude,
//               origemLng: destinos[i - 1].longitude,
//               destLat: destinos[i].latitude,
//               destLng: destinos[i].longitude,
//             }
//             return myretorno

//         }
//       }
//     }
//     let myretorno = {
//       origemLat: destinos[destinos.length - 1].latitude,
//       origemLng: destinos[destinos.length - 1].longitude,
//       destLat: destlat,
//       destLng: destlng,
//     }
//     return myretorno
//   }else{
//     let myretorno = {
//       origemLat: origlat,
//       origemLng: origlng,
//       destLat: destlat,
//       destLng: destlng,
//     }
//     return myretorno
//   }

// }
const audio = new Audio(soundfile);
class CorridasAndamento extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      center: {
        latitude: -25.47,
        longitude: -49.29,
      },
      dest: {
        latitude: -25.47,
        longitude: -49.29,
      },
      orig: {
        latitude: -25.47,
        longitude: -49.29,
      },
      zoom: 11,
      corridas: [],
      vetor: [],
      motorista: [],
      auth: [],
      value2: '',
      pages: null,
      loading: null,
      tipo_cancel: [],
      open: false,
      open2: false,
      selection: "",
      selected: [],
      selecionado: {},
      mensagens_list: [],
      chatMsg: '',
      loaded: true,
      loaded2: true,
      marquerVerde: [],
      marquerAzul: [],
      rotaVerde:[],
      rotaAzul: [],
      rotaVermelha: [],
      mensagensCompletas: [],
      notificacaomsg: true,
      msgplay: [],
    };

    this.webservice = new WS();

    this.updateData = this.updateData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderMarkers = this.renderMarkers.bind(this);
    this.escolhecorrida = this.escolhecorrida.bind(this);
    this.fetchData = this.fetchData.bind(this)
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.retornaHora = this.retornaHora.bind(this);
    this.retornaHoraApenas = this.retornaHoraApenas.bind(this)
    this.cancelar = this.cancelar.bind(this);
    this.gettipo = this.gettipo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getmoto = this.getmoto.bind(this);
    this.showmap = this.showmap.bind(this);
    this.sendMensagem = this.sendMensagem.bind(this);
    this.getMensagens = this.getMensagens.bind(this);
    this.getTodasMensagens = this.getTodasMensagens.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.notificacaomsg = this.notificacaomsg.bind(this);
    this.fechaTela = this.fechaTela.bind(this);
    this.clearselecionado = this.clearselecionado.bind(this)
  }

  playAudio() {
    clearTimeout(this.playAudiotimeout);
    this.playAudiotimeout = setTimeout(() => { 
        if (this.state.notificacaomsg) {
          audio.play();
        }
       }, 1000);
  }
  notificacaomsg() {
    this.setState({notificacaomsg: !this.state.notificacaomsg});
  }
  async updateData() { //pergutnar sobre devolver ordenado ou nao a parte de corridas
    try {
      let resultMoto
      let resultMotoJson
      let user = this.props.childProps.user;
      let kuser = ''
      if (user.func_role > 2) {
        kuser = '&voucher_func=' & user.func_id
      }
      
      let result = await this.webservice.fetch2('corridas?t12_dthr_dembarques={"eq": null}&t12_st_corrida={"nall": ["C","A"]}' + kuser);
      var corrid = await result.json();

      if (corrid.length !== this.state.vetor.length) {
        vet2 = []
        this.setState({
          loaded: true,
        })
        corrid.map(async funfa => {
          if (funfa.id) {
            await this.handleClick(funfa.id).then(resultado => {
              vet2.push(resultado)
            })
          }
        })
      }
      if (this.state.selecionado.t12_num_corrida) {
        let result2 = await this.webservice.fetch2('corridas?t12_num_corrida=' + this.state.selecionado.t12_num_corrida + kuser);
        var corrid2 = await result2.json();
        let dados = corrid2.find(x => x.t12_num_corrida === this.state.selecionado.t12_num_corrida);
        ///console.log('achei selecionada', [dados, corrid])
        await this.setState({
          selecionado: dados
        })
      }
      if (false) {
        resultMoto = await this.webservice.fetch2('corridas/' + this.state.selecionado.t12_num_corrida + '/posicao_motorista')
        resultMotoJson = await resultMoto.json();
        console.log('marcadores2 0', [this.state.selecionado, resultMotoJson]);
        this.webservice.directions(this.state.selecionado.t12_lat, this.state.selecionado.t12_lon, this.state.selecionado.latitudedestino, this.state.selecionado.longitudedestino).then(resposta => {
          //console.log('drivers rota 2', resposta);
          if (resposta) {
            if (resposta.result) {
              if (resposta.result.routes[0]) {
                if (resposta.result.routes[0].geometry) {
                  if (resposta.result.routes[0].geometry.coordinates) {
                    let geometrics = resposta.result.routes[0].geometry.coordinates;
                    this.setState({
                      rotaVermelha: geometrics
                    })
                  }
                }
              }
            }
          }
        })
        if (!this.state.selecionado.t12_dthr_dembarque) {
          if (!this.state.selecionado.t12_dthr_embarque) {
            console.log('marcadores2 0', 'entrou verde');
            this.setState({
              marquerVerde: [{ lat: resultMotoJson.latitude, lng: resultMotoJson.longitude, img_src: null, label: 'Motorista' }],
              marquerAzul: []
            })
            this.webservice.directions(resultMotoJson.latitude, resultMotoJson.longitude, this.state.selecionado.t12_lat, this.state.selecionado.t12_lon).then(resposta => {
              //console.log('drivers rota 1', resposta);
              if (resposta) {
                if (resposta.result) {
                  if (resposta.result.routes[0]) {
                    if (resposta.result.routes[0].geometry) {
                      if (resposta.result.routes[0].geometry.coordinates) {
                        let geometrics = resposta.result.routes[0].geometry.coordinates;
                        this.setState({
                          rotaVerde:geometrics,
                          rotaAzul: []
                        })
                      }
                    }
                  }
                }
              }
            })
          } else {
            this.setState({
              marquerAzul: [{ lat: resultMotoJson.latitude, lng: resultMotoJson.longitude, img_src: null, label: 'Motorista' }],
              marquerVerde: []
            })
            this.webservice.directions(resultMotoJson.latitude, resultMotoJson.longitude, this.state.selecionado.latitudedestino, this.state.selecionado.longitudedestino).then(resposta => {
              //console.log('drivers rota 2', resposta);
              if (resposta) {
                if (resposta.result) {
                  if (resposta.result.routes[0]) {
                    if (resposta.result.routes[0].geometry) {
                      if (resposta.result.routes[0].geometry.coordinates) {
                        let geometrics = resposta.result.routes[0].geometry.coordinates;
                        this.setState({
                          rotaVerde: [],
                          rotaAzul: geometrics
                        })
                      }
                    }
                  }
                }
              }
            })
          }
        }
        // this.webservice.statusmotorista(this.state.selecionado.t12_num_corrida).then(resposta => {
        //   console.log('getRandom',resposta)
        // })
      }
      this.setState({
        vetor: vet2,
        corridas: corrid,
        loaded: false,
        motorista: resultMotoJson,
      }, () => this.fetchDataTimeOut(this.state.dados, this.state.instance, false)
      )

    } catch (err) {
      this.setState({ loaded: true, error: 'Ocorreu um erro ao carregar os dados do servidor... Tentando novamente' })
    }
  }

  componentWillUnmount() {
    
    clearInterval(this.timerId);
    clearInterval(this.timerId2);
  }

  async fetchData(dados, instance, stats) {
    // show the loading overlay
    try {
      this.setState({ dados, instance })
      this.setState({ loading: stats }) //true
      //let empresa = this.webservice.getCookie('empresa-id');
      // fetch your data
      if (dados) {


        let pages = dados.page + 1
        console.log("pageszin", pages)
        let result = await this.webservice.getTipo2('corridas?t12_dthr_dembarques={"eq": null}&t12_st_corrida={"nall": ["C","A","M","T","H"]}', '', dados.sorted, pages, dados.pageSize, dados.filtered);
        let count = result.headers.get('X-Total-Count');
        result = await result.json();
        console.log('LIST', result)

        this.setState({
          auth: result,
          pages: Math.ceil(count / dados.pageSize),
          loading: false
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    } catch (err) {
      console.log("erro", err)
    }
  }

  showmap() {
    return (
      <></>
    )
  }
  fetchDataTimeOut(dados, instance, stats) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => { this.fetchData(dados, instance, stats) }, 300)
    });
  }


  async gettipo() {
    let result = await this.webservice.fetch2('tipo_cancelamento')
    let jresult = await result.json()
    this.setState({
      tipo_cancel: jresult,
    })
  }
  componentDidMount() {
    this.timerId = setInterval(this.updateData, 10000);
    this.timerId2 = setInterval(this.fetchData(this.state.dados, this.state.instance, true), 9000)
    this.gettipo();
    this.getTodasMensagens();

  }
  async handleClick(key) { //buscando todos dados da corrida para colocar na tabela
    let chave = await this.webservice.fetch2('corridas/' + key)
    chave = await chave.json();
    console.log("CHAVE")

    /*this.setState({
      selected: chave,
      showRtDetails: true,
      zoom: 16,

    });*/
    return (chave)
  }

  renderMarkers(map, maps) {
    console.log("dentro do maps", this.state.selected.latitude)
    let marker = new maps.Marker({
      position: { lat: this.state.selected.latitude, lng: this.state.selected.longitude },
      map: map,
      icon: require('../assets/global/img/passageiro.png'),
      title: 'Cliente!'
    });
    console.log(marker);
    if (this.state.selected.motorista) {
      let marker2 = new maps.Marker({
        position: { lat: this.state.selected.motorista.latitude, lng: this.state.selected.motorista.longitude },
        map: map,
        title: 'Motorista!'
      });
      console.log(marker2);
    }
  }

  async getmoto(at, urbs) {
    // let getUrbs = await this.webservice.callUrbsData1(at, urbs);
    // this.setState({ imageperfil: getUrbs });
  }

  escolhecorrida(selecionado) {
    let todasmsg = this.state.mensagensCompletas;
    let id = selecionado.t12_num_corrida;
    let minhaMsg = todasmsg.find(x => x.id === id);
    if (minhaMsg === undefined) {minhaMsg = {id: id, msg:[]}}
    let nMSG = minhaMsg.msg.length;
    let msgplay = this.state.msgplay;
    let minhapley = msgplay.findIndex(x => x.id === id);
    if (minhapley >= 0) {
      msgplay.splice(minhapley);
    }
    msgplay.push({id: id, nmsg: nMSG});
    this.setState({
      msgplay: msgplay,
      selecionado: selecionado,
      center: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      orig: {
        latitude: selecionado.t12_lat,
        longitude: selecionado.t12_lon,
      },
      dest: {
        latitude: selecionado.latitudedestino,
        longitude: selecionado.longitudedestino
      },
      destinos: selecionado.dados_corrida ? (selecionado.dados_corrida.destinos ? selecionado.dados_corrida.destinos : null) : null

    }, () => { if (this.state.selecionado.t07_carro_t07_conceccao) { this.getmoto(this.state.selecionado.t07_carro_t07_conceccao, this.state.selecionado.t08_motoristas_t08_urbs); this.showmap() } })
    console.log("state", this.state.selected)

    let self = this;
    // this.webservice.statusmotorista(selecionado.id).then(resposta => {
    //   console.log('getRandom',resposta)
    // })
    setTimeout(() => {self.getMensagens();},3000);
    //setTimeout(() => {self.getTodasMensagens();},3000);
  }

  cancelar(motivo, id) {
    console.log("motivo2", motivo)
    if (id !== "") {
      this.webservice.cancelTaxi(this.state.selecionado.t12_num_corrida, motivo, id)
      this.setState({ open: !this.state.open })
      this.fetchDataTimeOut(this.state.dados, this.state.instance, true)
    } else {
      alert("Escreve o motivo do cancelamento")
    }
  }
  fechaTela() {
    this.clearselecionado()
    this.setState({ open: !this.state.open })
    this.fetchDataTimeOut(this.state.dados, this.state.instance, true)
  }
  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }
  retornaHoraApenas(dado) {
    return (moment(dado).format("HH:mm:ss"))
  }
  retornaHora(dado) {
    return (moment(dado).format("DD/MM/YYYY HH:mm"))
  }
async clearselecionado() {
  this.setState({selecionado:{}})
}

  async updateSelecionado() {
    if (this.state.selecionado.t12_num_corrida) {
      let cor = await this.webservice.getMsg(this.state.selecionado.t12_num_corrida);
      this.setState({selecionado:cor})

      let self = this;
      setTimeout(() => {self.updateSelecionado();},3000);
    }
  }
  async getMensagens() {
    if (this.state.selecionado.t12_num_corrida) {
      let cor = await this.webservice.getMsg(this.state.selecionado.t12_num_corrida);
      this.setState({mensagens_list:cor})

      let self = this;
      setTimeout(() => {self.getMensagens();},3000);
    }
  }
  async getTodasMensagens() {
    let msgs = [];
    console.log('getTodasMensagens', 'entrei')
    if (this.state.auth) {
      for (let index = 0; index < this.state.auth.length; index++) {
        const e = this.state.auth[index];
        let cor = await this.webservice.getMsg(e.t12_num_corrida);
        console.log('getTodasMensagens', cor)
        msgs.push({id:e.t12_num_corrida, msg:cor})
      }
      this.setState({mensagensCompletas:msgs})
    }
    let self = this;
    setTimeout(() => {self.getTodasMensagens();},4000);
  }
  async updateMsg(text) {
    this.setState({chatMsg: text})
  }
  async sendMensagem(id, tam) {
    let msgplay = this.state.msgplay;
    let minhapley = msgplay.findIndex(x => x.id === id);
    if (minhapley >= 0) {
      msgplay.splice(minhapley);
    }
    msgplay.push({id: id, nmsg: tam});
    this.setState({msgplay:msgplay})
  }
  render() {
    let marker = []
    console.log('marcadores2 1',this.state.marquerVerde)
    if (this.state.orig) {marker.push({ lat: this.state.orig.latitude, lng: this.state.orig.longitude, img_src: null, label: 'origem' })}
    if (this.state.dest) {marker.push({ lat: this.state.dest.latitude, lng: this.state.dest.longitude, img_src: null, label: 'destino' })}
    //[{ lat: sugestion.latitude, lng: sugestion.longitude, img_src: null, label: sugestion.fullAddress }]

    let msgs = [];

    // let msgteste = {
    //       position: 'right',
    //       type: 'text',
    //       text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
    //       date: new Date(),
    //   }
      for (let index = 0; index < this.state.mensagens_list.length; index++) {
        const element = this.state.mensagens_list[index];
        msgs.push({
          type:"text",
          text: element.text,
          dateString: this.retornaHoraApenas(element.createdAt),
          position: element.user._id === 2 ? 'right' : 'left'
        })
      }
      //[{"text":"msg1","user":{"_id":2},"createdAt":"2019-12-09T14:17:33.489Z","id":1,"_id":"msg18826270id01"}]

      console.log('msgs',msgs)
    return (
      <div className="page-content-wrapper">

        <div className="page-content">
          <div className="container">
            <ul className="page-breadcrumb breadcrumb">
              <li>
                <i className="icon-home"></i>&nbsp;
                <Link to="/corrida-andamento">Lista de Corridas em Andamento</Link>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
              <i className="icon-control-play"></i>&nbsp;
              <button className="btn btn-warning-go btn-sm" onClick={this.notificacaomsg}>
              
                Norificação sonora {this.state.notificacaomsg ? '(Ligada)' : '(Desligada)'}</button>

              </li>

            </ul>
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div className="portlet light">
                      <div className="portlet-title" id="divInfoMoto" style={{ display: 'none' }}>
                        <div className="caption">
                          <i className="fa fa-taxi"></i><span id="divDescMoto"></span>
                        </div>
                      </div>
                      <div>
                        Selecione uma corrida para companhar
                      </div>
                      <div className="portlet-body">

                        <ReactTable
                          manual
                          data={this.state.auth}
                          pages={this.state.pages}
                          pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                          loading={this.state.loading}
                          onFetchData={this.fetchDataTimeOut}
                          previousText='Anterior'
                          nextText='Próxima'
                          getLoadingProps={(dado) => console.log(dado)}
                          loadingText='Carregando...'
                          noDataText='Nenhum dado encontrado'
                          pageText='Página'
                          ofText='de'
                          rowsText='linhas'
                          getTdProps={(state, props, columns) => {
                            return {
                              onClick: (e) => { this.escolhecorrida(props.row._original); this.setState({ open: true }); }
                            }
                          }}
                          columns={[
                            {
                              Header: "Número",
                              accessor: "t12_num_corrida",
                            },
                            {
                              Header: "Centro de custo",
                              accessor: "voucher_departamento_identificacao",
                            },
                            {
                              Header: "Tempo total",
                              accessor: "datahora",
                              Cell: props => <div>
                                {props.original.t12_dthr_agendamento && <i className="icon-clock"></i>}&nbsp;
                                {!props.original.t12_dthr_agendamento && moment.utc((moment.duration(moment().diff(moment(props.original.t12_dthr_tele)))).asMilliseconds()).format("m")}
                                {props.original.t12_dthr_agendamento && moment.utc((moment.duration(moment().diff(moment(props.original.t12_dthr_agendamento).subtract(props.original.t12_min_adiantamento, "minutes")))).asMilliseconds()).format("m")} minutos</div>
                            },
                            {
                              Header: "Passageiro",
                              accessor: "t12_nome",
                            },
                            {
                              Header: "Trajeto Origem",
                              accessor: "t12_endereco",
                            },

                            {
                              Header: "Trajeto Destino",
                              accessor: "taxi333$enderecodestino",
                            },
                            {
                              Header: "Mensagens",
                            Cell: props => {
                              let todasmsg = this.state.mensagensCompletas;
                              let id = props.row._original.t12_num_corrida;
                              let minhaMsg = todasmsg.find(x => x.id === id);
                              if (minhaMsg === undefined) {minhaMsg = {id: id, msg:[]}}
                              let nMSG = minhaMsg.msg.length;
                            let msgplay = this.state.msgplay;
                            let minhapley = msgplay.find(x => x.id === id);
                            if (minhapley === undefined) {minhapley = {id: id, nmsg: 0}}
                              if (nMSG > minhapley.nmsg) {
                                 this.playAudio();
                              }
                              //console.log('getTodasMensagens minhaMsg',minhaMsg)
                              return(<div>{minhaMsg.msg.length}</div>)},
                            },
                            {
                              Header: "Status",
                              accessor: "t12_st_corrida",
                              Cell: props => <div>{props.row._original.t12_dthr_dembarques ? "Corrida Concluida" : props.row._original.t12_dthr_embarques ? "Em andamento" : props.row._original.datahoraemfrente ? "Em frente" : props.row._original.t07_carro_t07_placa_detran ? "A caminho" : "Aguardando"}
                              </div>,
                            },
                          ]}
                          defaultPageSize={5}
                          className="-striped -highlight"
                        />
                      </div>
                      <div className="portlet-body">
                        <div>
                          <Panel id="collapsible-panel-example-1" expanded={this.state.open}>
                            <Panel.Collapse>
                              <Panel.Body>
                                <CorridaDados corridainfo={this.state.selecionado} sendMensagem={this.sendMensagem} fechaTela={this.fechaTela}/>
         
                                

                              </Panel.Body>
                            </Panel.Collapse>
                          </Panel>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorridasAndamento;
