import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-table/react-table.css";
import SubHeader from "../header/SubHeaderView.js";

class Solicidada extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corridaInfo: {pix: {isPix: false}},
      pdf: false,
      tipocentral: 1,
    };
    this.moveback = this.moveback.bind(this);
  }

  moveback() {
    this.props.history.push({ pathname: "/" });
  }

  componentWillUnmount() {
    clearTimeout(this.bocktimeout);
  }

  componentDidMount() {
    if (this.props.location.state.pix.isPix) {
      this.setState({ corridaInfo: this.props.location.state });
    } else {
       this.bocktimeout = setTimeout(() => {
         this.props.history.push({ pathname: "/" });
       }, 4000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.bocktimeout);
  }

  render() {
    let qrcores = {}
    let code = ""
    let img = null
    if (!!this.state.corridaInfo.pix.isPix) {
      qrcores = this.state.corridaInfo.pix.qrCode[0]
      code = qrcores.text
      img = qrcores.links[0].href
    }
    console.log(this.state.corridaInfo.pix)
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Corrida Registrada" textoCentral="Corridas" />
        {!this.state.corridaInfo.pix.isPix && (
        <div className="page-content">
          <div className="container">
            <h4 className="block">Sua corrida foi Registrada.</h4>
            <p>
              Agora você pode consultar sua corrida em corridas em andamento ou
              em corridas agendadas.
            </p>
            <Link to="/corrida-andamento">Acompanhe a corrida aqui</Link>
          </div>
        </div>)}
        {!!this.state.corridaInfo.pix && !!this.state.corridaInfo.pix.isPix && (
          <div className="page-content">
            <div className="container">
              <h4 className="block">Sua corrida foi Registrada.</h4>
              <p>
                Para Realizar o Pagamento use o QRCode abaixo ou o código
              </p>
              <img src={img} 
                width={250}
                height={250} />
              <p>
                <div onClick={() => navigator.clipboard.writeText(code)} className="round7 padding10 bgColor1 fontColor4">{code}</div>
              <Link to="/corrida-andamento">Acompanhe a corrida aqui</Link></p>
            </div>
          </div>)}
      </div>
    );
  }
}
export default Solicidada;
