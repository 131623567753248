import React, { Component } from 'react';
import { Col, Row, Modal, Image, Button } from 'react-bootstrap';
import  WS  from '../services/webservice';
import './avaliacaostar.css';
import Moment from 'moment';
import Rating from 'react-star-rating-meter';
import Switch from "react-switch";

const questoesStar = [
    {id:'foto', tipo: 'bool', txt: 'A foto condiz com o motorista?', cal:'Foto Ok?'},
    {id:'educacao', tipo: 'int', txt: 'Como você classifica a educação do motorista?', cal:'Educado?'},
    {id:'limpeza', tipo: 'int', txt: 'Como você classifica a limpreza do veículo?', cal:'Carro Limpo?'},
  ];

export default class AvaliacaoStar extends Component {
    constructor(props){
        super(props);
        this.state = {
            star:5,
            obs:'',
            questoes: {}
        }
        this.webservice = new WS();
        this.renderQuestoes = this.renderQuestoes.bind(this);
        this.savequestao = this.savequestao.bind(this);
        this.saveStar = this.saveStar.bind(this);
        this.avaliacorrida = this.avaliacorrida.bind(this);
    }
    avaliacorrida() {
        let questoes = [];
        if (this.props.corrida.questoes) {
            questoes = this.props.corrida.questoes;
        } else {
            questoes = questoesStar;
        }
        this.salvaAvaliacao(this.props.corrida.randomcode,this.props.corrida.id, this.state.star, this.state.obs, this.state.questoes, questoes)
    }
    salvaAvaliacao(link,id,star,obs,detalhes,questoes) {
        for (let index = 0; index < questoes.length; index++) {
          let e = questoes[index];
          let v = detalhes[e.id];
          if (!v) {
            if (e.tipo === 'int') {v = 5;}
            if (e.tipo === 'bool') {v = 1;}
            if (e.tipo === 'str') {v = '';}
          }
          e.valor = v;
          questoes[index] = e;
        }
        this.webservice.salvaAvaliacao(link,id,star,obs,questoes).then(
          (result) => {
            if (result) {
              this.props.save();
            }
          }
        );
      }
    savequestao(value,questao) {
        console.log(questao.id,value)
        let questoes = this.state.questoes;
        questoes[questao.id] = value;
        this.setState({
            questoes: questoes
        })
    }
    saveStar(value) {
        this.setState({
            star: value
        })
    }
    renderQuestoes(questao) {
        switch(questao.tipo) {
            case 'int':
                    return <Row key={questao.id}><Col  xs={12} md={8}>{questao.txt}</Col><Col xs={12} md={3}><Rating
                    disabled={false}
                    maxStars={5}
                    initialRating={5}
                    label={"noMeter"}
                    showMeter={false}
                    starSelectColor={'#f9e613'}
                    rating={this.state.questoes[questao.id] ? this.state.questoes[questao.id] : 5}
                    getRating={(rating) => this.savequestao(rating,questao)}
                /></Col></Row>
                break;
            case 'bool':
                let x = this.state.questoes[questao.id];
                let h = true;
                if (typeof(x) != "undefined") {
                    h = x>0 ? true : false;
                }
                return <Row key={questao.id}><Col xs={12} md={8}>{questao.txt}</Col><Col xs={12} md={3}>
                        <Switch 
                            onChange={(e)=>this.savequestao(e ? 1 : 0,questao)} 
                            checked={h} 
                            />
                </Col></Row>
            default:
                return null
        }
    }
    render() {
        let corrida = this.props.corrida;
        let aberto = true;
        let questoes = [];
        console.log('this.state.questoes',this.state.questoes)
        if (!corrida) {
            aberto = false;
            corrida = {valorcalculado:0};
            //questoes = corrida.questoes;
        } else {
            if (corrida.questoes) {
                questoes = corrida.questoes;
            } else {
                questoes = questoesStar;
            }
        }
        return(
            <Modal 
                keyboard 
                show={aberto}
                container={this}
                ref='AvaliacaoStar' 
                classname="modal"
                >
                <div style={{margin:10}}>
                    <Row ><Col  xs={12} md={12} style={{fontWeight:'bold'}}>Avaliação da Viagem</Col></Row>
                    <Row ><Col  xs={12} md={3} classname="tipo">Data da Viagem:</Col><Col  xs={12} md={9}>{Moment(corrida.t12_dthr_corrida).format('DD/MM/YYYY HH:mm')}</Col></Row>                  
                    <Row ><Col  xs={12} md={3}>Origem:</Col><Col  xs={12} md={9}> {corrida.endereco_origem + ' ' + corrida.numero}</Col></Row>
                    {corrida.endereco_destino ? <Row ><Col  xs={12} md={3}>Destino:</Col><Col  xs={12} md={9}>{corrida.endereco_destino}</Col></Row>: null}
                    <Row ><Col  xs={12} md={3}>Valor </Col><Col  xs={12} md={9}>R$ {corrida.valorcalculado.toFixed(2)}</Col></Row>
                    {corrida.observacoes ?  <Row ><Col  xs={12} md={3}>Observações: </Col><Col  xs={12} md={9}>{corrida.observacoes}</Col></Row>: null}
                    {corrida.nome_motorista ?
                        <Row ><Col  xs={12} md={3}>
                            {corrida.fotobase64 ? 
                                <Image style={{margin: 10, width: 70, height: 70, borderRadius:15}} src={corrida.fotobase64}/>
                                :null
                            }
                        </Col><Col  xs={12} md={9}>
                            <Row ><Col  xs={12} md={3}>Motorista:</Col><Col  xs={12} md={9}>{corrida.nome_motorista}</Col></Row>
                            <Row ><Col  xs={12} md={3}>Carro:</Col><Col  xs={12} md={9}>{corrida.carro}</Col></Row>
                            <Row ><Col  xs={12} md={3}>Placa:</Col><Col  xs={12} md={9}>{corrida.placa}</Col></Row>
                            </Col></Row>
                    : null}<br />
                    <b>Avaliacao</b><br />
                    <Row ><Col  xs={12} md={8}>Nível de Satisfação</Col><Col xs={12} md={3}>
                    <Rating
                        disabled={false}
                        maxStars={5}
                        initialRating={5}
                        label={"noMeter"}
                        showMeter={false}
                        starSelectColor={'#f9e613'}
                        rating={this.state.star}
                        getRating={(rating) => this.saveStar(rating)}
                    /></Col></Row>
                    {questoes.map((point, index) => {return this.renderQuestoes(point)})}
                    <Button classname="button" onClick = {this.avaliacorrida}>
                        <p classname="buttonp" >Avaliar</p>
                    </Button>
                </div>
            </Modal>
        )
    }
}

