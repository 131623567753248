import CoreModel from "../../service/CoreModel";
import Service from "./Service";
var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      showCreateDepartamento: false,
      fullData: [],
      data: [],
      pages: null,
      loading: true,
      currentPage: 1,
      pageSize: 10,
    };

    this.fetchData = this.fetchData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.manageTipo = this.manageTipo.bind(this);
  }

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getTipo(
        'tipo_pagamento',
        "",
        [],
        this.state.currentPage,
        this.state.pageSize,
        []
      );
      let fullData = result.result;
      let count = result.count;
      this.setState({
        showModal: false,
        fullData,
        data: fullData,
        pages: Math.ceil(count / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  onPageChange = (pageIndex) => {
    this.setState({
      currentPage: pageIndex,
    });
    this.fetchData();
  };


  manageTipo = async (row) => {
    try {
      await this.service.postTipo(row.pgto, row.ativo ? "desativar" : "ativar").then(() => {
        console.log("Tipo alterado com sucesso");
        this.fetchData();
      })
      return result;
    } catch (error) {
      console.error("Erro ao criar:", error);
      throw error;
    }
  };
}
