import CoreService from "../../service/CoreService";

export default class Service extends CoreService {
    async getVoucher() {
        let result
        result = await this.fetchOn('voucher_avulso?per_page=9999999999');
        return result.result;
      }

      async getEmpresas() {
        let result = await this.fetchOn('voucher_empresa/?per_page=200&order=razaosocial&ativo=true');   
        return result.result;
      }
    
      async getEmpresa(id) {
        let result = await this.fetchOn('voucher_empresa/' + id);   
        return result.result;
      }
}
