import React, { Component } from "react";
import Create from "./view/Create.js";
import List from "./view/List.js";
import ViewModel from "./controllers/ViewModel.js";
import SubHeader from "../header/SubHeaderView.js";
import CreateV2 from "./view/CreateV2.js";
import CadastrarUsuarios from "./view/CadastrarUsuarios.js";
import ListarUsuarios from "./view/ListarUsuarios.js";
import EditarUsuario from "./view/EditarUsuario.js";

export default class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
  }

  componentDidMount() {
    this.viewModel.fetchData();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Usuários" textoCentral="Usuários" />
        <div className="page-content">
          <div className="container">
            <ListarUsuarios history={this.props.history} />
            {/* <EditarUsuario history={this.props.history} /> */}
          </div>
        </div>
      </div>
    );
  }
}
