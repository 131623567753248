import CoreModel from "../service/CoreModel";
import Service from "./Service";
var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      loaded: true,
      favoritos: [],
      showfavmodal: false,
    };
    this.showFav = this.showFav.bind(this);
    this.hideFav = this.hideFav.bind(this);
    this.getFavoritos = this.getFavoritos.bind(this);
  }

  showFav() {
    this.setState({ showfavmodal: true });
  }
  hideFav() {
    this.setState({ showfavmodal: false });
  }
  getFavoritos() {
    this.service.getFavoritosNew().then((result) => {
      this.setState({ favoritos: result });
    });
  }

  salvaSugestion(suggestion, centro, altcenter, callback, id) {
    if (centro.lat === "") {
      centro = altcenter;
    }
    this.service
      .place(suggestion.place_id, suggestion.linha, centro.lat, centro.lng)
      .then((result) => {
        callback(result, true, id);
        this.setState({
          loaded: true,
          position: [result.latitude, result.longitude],
        });
      });
  }

  onSuggestionsFetchRequested(address, centro, altcenter, callback) {
    clearTimeout(this.state.searchTimeout);
    this.setState({
      searchTimeout: setTimeout(() => {
        this.autocomplete(address, centro, altcenter, callback);
      }, 2000),
    });
  }

  autocomplete(address, centro, altcenter, callback) {
    if (centro.lat === "") {
      centro = altcenter;
    }
    this.service
      .autocomplete(address, centro)
      .then((addresses) => {
        callback(addresses);
      })
      .catch((e) => {
        this.setState({
          error: true,
        });
      });
  }
}
