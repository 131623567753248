import CoreModel from "../../service/CoreModel";
import Service from "./Service";
import CurrencyInput from 'react-currency-input';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import { Row, Image, Checkbox, Modal, Breadcrumb, Button, Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap'
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import AutoCompleteHelper from './AutoCompleteHelper.js';

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      fullData: [],
      data: [],
      autocomplete: {},
      pages: null,
      loading: true,
      currentPage: 1,
      pageSize: 10,
      successMessage: "",
      errorMessage: "",
      selecionado: {},
      showModal: false,
      sublistas: {}
    };

    this.fetchData = this.fetchData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.escolhe = this.escolhe.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.orgSubLists = this.orgSubLists.bind(this);
    this.saveSelecionado = this.saveSelecionado.bind(this);
  }

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getTipo(
        "favoritos",
        "",
        [],
        this.state.currentPage,
        this.state.pageSize,
        []
      );
      let fullData = result.result;
      let count = result.count;
      this.setState({
        showModal: false,
        fullData,
        data: fullData,
        pages: Math.ceil(count / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  escolhe = (selecionado) => {
    this.setState({
      selecionado: selecionado,
      id: selecionado.id,
      showModal: true,
      edit: true
    });
  };

  onPageChange = (pageIndex) => {
    this.setState({
      currentPage: pageIndex,
    });
    this.fetchData();
  };

  saveSelecionado = (campos) => {
    let saverecords = [];
    campos.map((m, i) => {
      if (!m.campos) {
        return (saverecords = { ...saverecords, [m.ref]: this.state.selecionado[m.ref] })
      } else {
        return (saverecords = { ...saverecords, [m.ref]: JSON.stringify(this.state.selecionado[m.ref]) })
      }
    })

    let allFields = true;
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].obrigatorio) {
        if (saverecords[campos[i].ref] === "" || saverecords[campos[i].ref] === null || saverecords[campos[i].ref] === undefined) {
          //this.dialog.showAlert('Por favor preencha ' + campos[i].label + '!');
          allFields = false;
          break;
        }
      }
    }
    if (!allFields) { return; }
    if (this.state.new) {
        this.service.criarTipo("favoritos", saverecords).then(result => {
          //Dialog.resetOptions()
          if (!!result.result) {
            this.showAlert('criado com sucesso!')
            this.setState({ showModal: false, new: false })
            this.fetchData();
          } else {
            //this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
          }
        })
    } else if (this.state.edit) {

        this.service.alterarTipo("favoritos", this.state.id, saverecords, campos).then(result => {
          //Dialog.resetOptions()
          if (!!result.result) {
            this.showAlert('editado com sucesso!')
            this.setState({ showModal: false, edit: false })
            this.fetchData();
          } else {
            //this.dialog.showAlert('Por favor preencha os campos obrigatórios!')
          }
        })

    }
  }

  async orgSubLists(subtipos) {
    if (subtipos) {
      for (let index = 0; index < subtipos.length; index++) {
        const element = subtipos[index];
        if (!this.state.sublistas[element.tipo]) {
          if (element.p==='get') {
            this.getSubTipo(element.tipo,element.id,element.txt);
          } else if (element.p==='array') {
            this.arraySubTipo(element.tipo,element.array)
          } else if (element.p==='object') {
            this.objectSubTipo(element.tipo,element.array)
          }
        }
      }
    }
  }
  objectSubTipo(tipo,array) {
    let sublista = this.state.sublistas;
    let resposta = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      resposta.push(element);
    }
    sublista[tipo]= resposta;
    this.setState({
      sublistas: sublista
    })
  }
  arraySubTipo(tipo,array) {
    let sublista = this.state.sublistas;
    let resposta = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      resposta.push({id:element, text:element})
    }
    sublista[tipo]= resposta;
    this.setState({
      sublistas: sublista
    })
  }
  getSubTipo(tipo,id,texto) {
    this.service.getSubTipo(tipo).then(
      async (response) => {
        let resposta = [];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          resposta.push({id:element[id], text:element[texto]})
        }
        let sublista = this.state.sublistas;
        sublista[tipo]= resposta;
        this.setState({
          sublistas: sublista
        })
      }
    );
  }

  renderFields = (campos, pai) => {
    if (campos) {
      let referencia = this.state.selecionado;
      if (pai) {
        referencia = this.state.selecionado[pai];
      }
      if (!referencia) {
        return;
      }
      return (
      <div>
          {campos.map((m, i) => {
            if (this.state.edit) {
              if (m.ref !== "senha") {
                if (referencia[m.ref] === undefined) {
                  return null;
                }
              }
            }
            if (m.type === "select") {
              //this.getSubTipo(m.listtype);
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <Select2
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : []}
                    onSelect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai)
                    }}
                    options={{
                      placeholder: m.placeHolder,
                      width: '100%',
                    }}
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === "selectint") {
              //this.getSubTipo(m.listtype);
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <Select2
                    ref={m.ref}
                    value={referencia[m.ref]}
                    data={this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : []}
                    onSelect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValueInt(e.target.selectedOptions), pai)
                    }}
                    onUnselect={e => {
                      this.saveStateValues(m.ref, this.getSelectedOptionsValueInt(e.target.selectedOptions), pai)
                    }}
                    options={{
                      placeholder: m.placeHolder,
                      width: '100%',
                    }}
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === "autocomplete") {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <AutoCompleteHelper
                    autocomplete = {this.autocomplete}
                    address={this.state.autocomplete[m.ref] ? this.state.autocomplete[m.ref] : []}
                    endereco={referencia[m.ref]}
                    changeList={function (value) { 
                      let autocomplete = this.state.autocomplete;
                      autocomplete[m.ref]= value;
                      this.setState({autocomplete: autocomplete})}.bind(this)
                    }
                    changeTxt={
                      function (value) {
                        this.saveStateValues(m.ref, value, pai);
                      }.bind(this)}
                    Selected={
                      function (retorno) {
                        //console.log('Selected',retorno)
                        this.place(retorno.place_id, retorno.text).then((place) => {
                          //console.log('Selected place',place)
                          this.saveStateValues(m.autocomplete, place, pai);
                        })
                        
                      }.bind(this)}
                    title={m.label}
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === 'currencydisabled') {
              referencia[m.ref] = referencia[m.ref] === "" ? 0 : referencia[m.ref];
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <NumberFormat ref={m.ref} value={Number(referencia[m.ref])} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} />
                  </Col>
                </FormGroup>
              )
            } else if (m.type === 'currency') {
              referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <CurrencyInput
                    ref={m.ref}
                    className="form-control"
                    value={(referencia[m.ref] && referencia[m.ref].taxaRetornoFixo) || referencia[m.ref]}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                      this.saveStateValues(m.ref, floatvalue, pai)
                    }}
                  />
                  </Col>
                </FormGroup>
              )
            } else if (m.type === 'percentage') {
              referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <CurrencyInput
                    ref={m.ref}
                    className="form-control"
                    value={referencia[m.ref]}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix="%"
                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                      this.saveStateValues(m.ref, floatvalue, pai)
                    }}
                  />
                  </Col>
                </FormGroup>
              )
            } else if (m.type === 'date') {
              if (!referencia[m.ref]) {
                this.saveStateValues(m.ref, moment(), pai);
              }
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <DatePicker
                    selected={referencia[m.ref]}
                    className='form-control'
                    dateFormat="DD/MM/YYYY"
                    onChange={e => { this.saveStateValues(m.ref, e, pai) }}
                    name={m.ref}
                  />
                  </Col>
                </FormGroup>
              )
            } else if (m.type === 'datedisabled') {
              if (!referencia[m.ref]) {
                this.saveStateValues(m.ref, moment(), pai);
              }
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <div>{moment(referencia[m.ref]).format("DD/MM/YYYY HH:mm")}</div>
                  </Col>
                </FormGroup>
              )
            } else if (m.type === 'maskedtext') {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <InputMask
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    className="form-control"
                    mask={m.mask}
                    maskChar="_"
                    placeholder={m.placeHolder}
                    onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === 'telefone') {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <InputMask
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    className="form-control"
                    mask={/^.....9/.test(referencia[m.ref]) ? m.maskCel : m.maskFixo}
                    maskChar="_"
                    placeholder={m.placeHolder}
                    onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === 'json') {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  {this.renderFields(m, m.ref)}
                </FormGroup>
              )
            } else if (m.type === 'checkbox') {
                  return (
                    <FormGroup  key={m.ref}>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={referencia[m.ref] ? true : false}
                        onChange={e => {
                          this.saveStateValues(m.ref, e.target.checked, pai)
                        }}>
                        <b>{m.label}</b>
                      </Checkbox>
                    </FormGroup>
                  )
            } else if (m.type === 'checkboxint') {
                  return (
                    <FormGroup  key={m.ref}>
                      <Checkbox
                        style={{ margin: '0' }}
                        checked={referencia[m.ref] ? true : false}
                        onChange={e => {
                          let valor = e.target.checked ? 1 : 0;
                          this.saveStateValues(m.ref, valor, pai)
                        }}>
                        <b>{m.label}</b>
                      </Checkbox>
                    </FormGroup>
                  )
            } else if (m.type === "disabled") {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <FormControl
                    value={this.state.central}
                    disabled="rue"
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === "disable") {
              return (
                <FormGroup  key={m.ref}>
                  <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                  <Col sm={10}>
                  <FormControl
                    value={referencia[m.ref] || ''}
                    ref={m.ref}
                    disabled="true"
                  />
                  </Col>
                </FormGroup>
              );
            } else if (m.type === "hidden") {
              return null;
            } else if (m.type === "modulos") {
              if (this.state.modulos) {
                return (
                  <div key={m.ref}>
                    <ReactTable
                      data={this.state.modulosTable}
                      resolveData={data => data.map(row => row)}
                      columns={[
                        {
                          Header: "Modulos",
                          accessor: "name"
                        },
                        {
                          Header: "Ações",
                          id: 'actions',
                          accessor: d => d.actions ? d.actions.map((m, i) => m).join(', ') : ''
                        },
                        {
                          Header: 'Remover',
                          width: 68,
                          filterable: false,
                          sortable: false,
                          style: {
                            cursor: 'pointer',
                          },
                          Cell: props =>
                            <div>
                              <Image src={require('../../../images/remove.png')} style={{ margin: 'auto', display: 'block' }}
                                onClick={function () {
                                  Dialog.setOptions({
                                    defaultOkLabel: 'Sim',
                                    defaultCancelLabel: 'Não',
                                    primaryClassName: 'btn-danger '
                                  })
                                  this.dialog.show({
                                    body: 'Você tem certeza que deseja remover??',
                                    actions: [
                                      Dialog.CancelAction(() => { }),
                                      Dialog.OKAction(() => {
                                        for (let i = 0; i < this.state.modulosTable.length; i++) {
                                          if (this.state.modulosTable[i].id === props.original.id) {
                                            this.state.modulosTable.splice(i, 1);
                                            this.setState({ modulosTable: this.state.modulosTable }, () => console.log(this.state.modulosTable))
                                            break;
                                          }
                                        }
                                      })
                                    ],
                                    bsSize: 'small',
                                    onHide: (dialog) => {
                                      dialog.hide()
                                    }
                                  })
                                }.bind(this)}></Image>

                            </div>
                        }

                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                    />
                    <Button onClick={() => {
                      this.setState({ showModulos: true, moduloSelected: [], actionsCheck: [], moduloSelectedData: [] })
                      let m = this.state.modulos.filter(array => !this.state.modulosTable.find(m => m.id === array.id));
                      this.setState({ modulos: m })
                    }}>Adicionar Modulos</Button>
                  </div>
                );
              }
              else return null;
            } else {
                return (
                  <FormGroup  key={m.ref}>
                    <Col sm={2}><ControlLabel>{m.label}</ControlLabel></Col>
                    <Col sm={10}>
                    <FormControl
                      value={referencia[m.ref] || ''}
                      ref={m.ref}
                      type={m.type}
                      maxLength={m.maxLength ? m.maxLength : ""}
                      placeholder={m.placeHolder}
                      onChange={e => { this.saveStateValues(m.ref, e.target.value, pai) }}
                    />
                    </Col>
                  </FormGroup>
                );
              
            }


          })}
        </div>);
    }
  }

  saveStateValues(ref, valor, pai) {
    let r = this.state.selecionado;
    if (pai) {
      r[pai][ref] = valor;
    } else {
      r[ref] = valor;
    }
    this.setState({ selecionado: r });
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return values;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values;
  }
  getSelectedOptionsValueInt(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return 0;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values[0];
  }
}
