import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppOld from './App';
import App from './structure/index/App';
import registerServiceWorker from './registerServiceWorker';
import {old} from './go-versao';
import AlertService from './structure/service/AlertService';

const alertService = AlertService.getInstance();

const RootOld = () => (
  <React.StrictMode>
    <AppOld />
  </React.StrictMode>
)

const Root = () => (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
if (!!old) {
  ReactDOM.render(<Root />, document.getElementById('root'));
} else {
  ReactDOM.render(<Root />, document.getElementById('root'));
}
registerServiceWorker();
