import React, { Component } from "react";
import Graficos from "./view/Graficos.js";
import ViewModel from "./controllers/ViewModel.js";
import SubHeader from "../header/SubHeaderView.js";

export default class Painel extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.carregaInicial();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Painel" textoCentral="Painel" />
        <div className="page-content">
          <div className="container">
            <Graficos {...this.props} viewModel={this.viewModel} />
          </div>
        </div>
      </div>
    );
  }
}
