import CoreService from "../service/CoreService";
var jwtDecode = require("jwt-decode");

export default class Service extends CoreService {


  async editSenhaUser(senha, id) {
    let result = await this.fetchOn('voucher_usuarios_v2/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: senha,
        nova_senha: false,
      })
    })

    return result.result
  }


  
}
