import CoreModel from "../service/CoreModel";
import Service from "./Service";
import moment from "moment";
import "moment/locale/pt-br.js";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      showModal: false,
      mensagens_list:[],
      rotaVermelha: [],
      tipo_cancel:[],
      driversAround: [],
      pdf: false,
      corridaInfo: {},
      showAgenda: false
  };
    this.loadCorridaInfo = this.loadCorridaInfo.bind(this);
    this.updateMoto = this.updateMoto.bind(this);
    this.getMensagens = this.getMensagens.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.sendMensagem = this.sendMensagem.bind(this);
    this.gettipoCancel = this.gettipoCancel.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.timeHandleChange = this.timeHandleChange.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.agendaCorrida = this.agendaCorrida.bind(this);
  }

  cancelar(motivo, id) {
    console.log("motivo2", motivo);
    if (id !== "") {
      this.service.cancelTaxi(
        this.state.corridaInfo.t12_num_corrida,
        motivo,
        id
      );
      // this.setState({ open: !this.state.open })
      if (this.props.fechaTela) {
        this.props.fechaTela();
      }
    } else {
      alert("Escreve o motivo do cancelamento");
    }
  }
  async getMensagens() {
    if (this.state.corridaInfo) {
      if (this.state.corridaInfo.t12_num_corrida) {
        let cor = await this.service.getMsg(
          this.state.corridaInfo.t12_num_corrida
        );
        this.setState({ mensagens_list: cor });
        if (this.props.sendMensagem) {
          this.props.sendMensagem(
            this.state.corridaInfo.t12_num_corrida,
            cor.length
          );
        }
      } else {
        this.setState({ mensagens_list: [] });
      }
    } else {
      this.setState({ mensagens_list: [] });
    }
  }

  closeCorrida = () => {}

  async updateMsg(text) {
    this.setState({ chatMsg: text });
  }

  async sendMensagem() {
    let corrida = this.state.corridaInfo.t12_num_corrida;
    let msg = this.state.chatMsg;
    let cor = await this.service.postMsg(corrida, msg);
    this.setState({ mensagens_list: cor, chatMsg: "" });
  }
  handleShow() {
    let dagend = moment(this.state.corridaInfo.t12_dthr_agendamento);

    this.setState({ showAgenda: !this.state.showAgenda, dataAgend: dagend, horaAgend: dagend })
  }
  timeHandleChange(value) {
    this.setState({ horaAgend: value });
  }
  minimalDate() {
    const start = moment().add(9, "minutes")
const remainder = 15 - (start.minute() % 15);
 
return moment(start).add(remainder, "minutes");


  }
  dateHandleChange(date) {
    this.setState({
      dataAgend: date,
    });
  }
  agendaCorrida() {
    var dataCompleta = null;
  let id = this.state.corridaInfo.t12_num_corrida;
      let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
      let hour = moment(this.state.dataAgend).format("HH:mm");

      dataCompleta = moment(date + 'T' + hour + ':00').utc().format("YYYY-MM-DD HH:mm");
    this.service.updateAgenda(dataCompleta, id, '').then(result => {
      this.showAlert('Alteração realizada com sucesso!!')
      if (this.closeCorrida) {
        this.closeCorrida();
      }
    })
  }
  async corridaByID(id) {
    let result = await this.service.fetchOn('corridas?t12_num_corrida=' + id);
    var corrida = result.result;

    if (corrida.length > 0) {
      return corrida[0];
    } else {
      return {};
    }
    
  }

  loadCorridaInfo(id) {
    this.corridaByID(id).then(corrida => {
      this.setState({ corridaInfo: corrida });
    });
  }

  async gettipoCancel() {
    let result = await this.service.fetchOn("tipo_cancelamento");
    let jresult = result.result;
    this.setState({
      tipo_cancel: jresult,
    });
  }

  async updateMoto() {
    if (this.state.corridaInfo) {
      let corrida = this.state.corridaInfo;
      let markerbase = [];
      let markerbaseok = [];
      markerbase.push({
        lat: corrida.t12_lat,
        lng: corrida.t12_lon,
        img_src: null,
        label: "origem",
      });
      if (corrida.destinos) {
        for (let index = 0; index < corrida.destinos.length; index++) {
          const element = corrida.destinos[index];
          markerbase.push({
            lat: element.lat,
            lng: element.lng,
            img_src: null,
            label: "destino",
          });
        }
      }
      if (corrida.dados_corrida) {
        if (corrida.dados_corrida.destinos) {
          for (
            let index = 0;
            index < corrida.dados_corrida.destinos.length;
            index++
          ) {
            const element = corrida.dados_corrida.destinos[index];
            if (!element.avisoDestino) {
              markerbaseok.push({
                lat: element.lat,
                lng: element.lng,
                img_src: null,
                label: "destino",
              });
            }
          }
        }
      }
      this.setState({ markerbase: markerbase });

      this.service
        .directionsDestinos(
          corrida.t12_lat,
          corrida.t12_lon,
          corrida.latitudedestino,
          corrida.longitudedestino,
          corrida.destinos
        )
        .then((resposta) => {
          if (resposta.result) {
            this.setState({
              rotaVermelha: resposta.result,
              pdf: true,
            });
          }
        });
      if (!corrida.t07_carro_t07_placa_detran) {
        this.service.driversAround(corrida.t12_lat, corrida.t12_lon);
      }
      if (corrida.t07_carro_t07_placa_detran && !corrida.t12_dthr_dembarque) {
        let resultMoto = await this.service.fetchOn(
          "corridas/" + corrida.t12_num_corrida + "/posicao_motorista"
        );
        let resultMotoJson = resultMoto.result;
        if (!corrida.t12_dthr_dembarque) {
          if (!corrida.t12_dthr_embarque) {
            this.setState({
              marquerVerde: [
                {
                  lat: resultMotoJson.latitude,
                  lng: resultMotoJson.longitude,
                  img_src: null,
                  label: "Motorista",
                },
              ],
              marquerAzul: [],
            });
            this.service
              .directions(
                resultMotoJson.latitude,
                resultMotoJson.longitude,
                corrida.t12_lat,
                corrida.t12_lon
              )
              .then((resposta) => {
                if (resposta.result) {
                  this.setState({
                    rotaVerde: resposta.result,
                    rotaAzul: [],
                  });
                }
              });
          } else {
            this.setState({
              marquerAzul: [
                {
                  lat: resultMotoJson.latitude,
                  lng: resultMotoJson.longitude,
                  img_src: null,
                  label: "Motorista",
                },
              ],
              marquerVerde: [],
            });
            this.service
              .directionsDestinos(
                resultMotoJson.latitude,
                resultMotoJson.longitude,
                corrida.latitudedestino,
                corrida.longitudedestino,
                markerbaseok
              )
              .then((resposta) => {
                if (resposta.result) {
                  this.setState({
                    rotaVerde: [],
                    rotaAzul: resposta.result,
                  });
                }
              });
          }
        } else {
          this.setState({
            rotaVerde: [],
            rotaAzul: [],
            marquerVerde: [],
            marquerAzul: [],
          });
        }
      } else {
        this.setState({
          rotaVerde: [],
          rotaAzul: [],
          marquerVerde: [],
          marquerAzul: [],
        });
      }
    }
  }
}
