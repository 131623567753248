import React, { Component, Suspense } from "react";
import { Router, Route, Switch, Link } from "react-router-dom";
import Routes from "./Routes";
import HeaderView from "../header/HeaderView";
import ModalsView from "../header/ModalsView";
import ViewModel from "./ViewModel";
import History from "../service/History.js";
import AlertComponent from "../service/AlertComponent.js";
import {old} from '../../go-versao';

//Fontes
import "../../fonts/Fontes.css";
import "../../fonts/FontAwesome.ttf";
import "../../fonts/AntDesign.ttf";
import "../../fonts/Entypo.ttf";
import "../../fonts/EvilIcons.ttf";
import "../../fonts/Feather.ttf";
import "../../fonts/Fontisto.ttf";
import "../../fonts/Foundation.ttf";
import "../../fonts/Ionicons.ttf";
import "../../fonts/MaterialCommunityIcons.ttf";
import "../../fonts/MaterialIcons.ttf";
import "../../fonts/Octicons.ttf";
import "../../fonts/SimpleLineIcons.ttf";
import "../../fonts/Zocial.ttf";
import "../../fonts/Montserrat-SemiBold.ttf";
import "../../fonts/Poppins-Light.ttf";
import "../../fonts/Poppins-Regular.ttf";
//Fontes

import "./view/App.css";
import "../../assets/global/plugins/font-awesome/css/font-awesome.css";
import "../../assets/global/plugins/simple-line-icons/simple-line-icons.css";
import "../../assets/global/plugins/bootstrap/css/bootstrap.css";
import "../../assets/global/plugins/bootstrap-switch/css/bootstrap-switch.css";
import "../../assets/global/plugins/select2/css/select2.css";
import "../../assets/global/css/components.css";
import "../../assets/global/css/plugins.css";
import "../../assets/global/plugins/bootstrap-select/css/bootstrap-select.css";
import "../../assets/global/css/todo.css";
import "../../assets/global/css/star-rating.css";
import "../../assets/global/css/conta.css";
import "../../assets/global/css/guillotine.css";
import "../../assets/pages/css/invoice.css";
import "../../assets/pages/css/profile.css";
import "../../assets/layouts/layout3/css/layout.css";
import "../../assets/layouts/layout3/css/themes/default.css";
import "../../assets/layouts/layout3/css/themes/blue-hoki.css";
import "../../assets/layouts/layout3/css/custom.css";

import _ from "lodash";

class App extends Component {
  constructor(props) {
    super(props);

    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;

    this.modalHide = this.modalHide.bind(this);
    this.modalHidenovasenha = this.modalHidenovasenha.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.modalHideEmpresa = this.modalHideEmpresa.bind(this);
    this.toggleRowEmpresa = this.toggleRowEmpresa.bind(this);
    this.getDadosCentralCores = this.getDadosCentralCores.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.setShowEmpresa = this.setShowEmpresa.bind(this);
    this.setShowCentral = this.setShowCentral.bind(this);
    this.onchangeSenha = this.onchangeSenha.bind(this);
    this.onchangeSenhaConfirm = this.onchangeSenhaConfirm.bind(this);
    this.buttonConfirmSenha = this.buttonConfirmSenha.bind(this);
    this.getCentralTitle = this.getCentralTitle.bind(this);
    this.buttonConfirmSenha = this.buttonConfirmSenha.bind(this);
    this.hideAlert = this.hideAlert.bind(this); 
  }

  componentDidMount() {
    this.getDadosCentralCores();
  }

  async buttonConfirmSenha() {
    this.viewModel.buttonConfirmSenha();
  }

  async getDadosCentralCores() {
    this.viewModel.getDadosCentralCores();
  }

  async loginUser(user) {
    await this.viewModel.loginUser(user);
  }

  handleLogout() {
    this.viewModel.handleLogout();
  }

  modalHide() {
    this.viewModel.modalHide();
  }

  getCentralTitle() {
    return this.viewModel.getCentralTitle()
  }

  modalHideEmpresa() {
    this.viewModel.modalHideEmpresa();
  }

hideAlert() {
    this.viewModel.hideAlert();
  }

  setShowCentral() {
    this.viewModel.setShowCentral();
  }

  toggleUser() {
    this.viewModel.toggleUser();
  }

  setShowEmpresa() {
    this.viewModel.setShowEmpresa();
  }

  toggleRow(central) {
    this.viewModel.toggleRow(central);
  }

  async toggleRowEmpresa(empresa) {
    this.viewModel.toggleRowEmpresa(empresa);
  }

  onchangeSenha(e) {
    this.viewModel.onchangeSenha(e.target.value);
  }

  onchangeSenhaConfirm(e) {
    this.viewModel.onchangeSenhaConfirm(e.target.value);
  }

  modalHidenovasenha() {
    this.viewModel.modalHidenovasenha();
  }

  render() {
    this.viewModel.setColorsControler(document.documentElement);

    return (
      <Router history={History}>
        <div className="page-wrapper">
          <HeaderView
                viewModel={this.viewModel}
            state={this.viewModel.getState()}
            setShowCentral={this.setShowCentral}
            loginUser={this.loginUser}
            toggleUser={this.toggleUser}
            setShowEmpresa={this.setShowEmpresa}
            handleLogout={this.handleLogout}
          />
          <div className="page-wrapper-row full-height">
            <div className="page-wrapper-middle">
              <div className="page-container">
                <ModalsView
                viewModel={this.viewModel}
                centralcores={this.viewModel.state.centralcores}
                />
                <Suspense fallback={<div>loading...</div>}>
                <Routes
                  childProps={this.viewModel.childProps()}
                  isAuthenticated={this.viewModel.getState().isAuthenticated}
                  centralcores={this.viewModel.getState().centralcores}
                  loginUser={this.loginUser}
                />
                </Suspense>
                          <div className="copyright">{this.getCentralTitle()}</div>

              </div>
            </div>
          </div>
          <AlertComponent />

        </div>
      </Router>
    );
  }
}

export default App;
