import React, { Component } from "react";
import PreCadastroLayout from "./layouts/preCadastroLayout";
import { PreCadastroService } from "./api/services/PreCadastroService";

export default class PreCadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cidades: [],
      central: {},
      alertDisplay: "display-hide",
      alertDisplayloading: false,
    };
    this.loadData = this.loadData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setStates = this.setStates.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let service = new PreCadastroService();
    let central = await service.getCentral();
    let cidades = await service.getCidades(central.id);

    this.setState({
      central: central,
      cidades: cidades,
    });
  };

  setStates = (states) => {
    this.setState({
      ...states,
    });
  }

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  }

  render() {
    return (
      <PreCadastroLayout
        {...this.props}
        central={this.state.central}
        cidades={this.state.cidades}
        loading={this.state.loading}
        alertDisplay={this.state.alertDisplay}
        alertText={this.state.alertText}
        setStates={this.setStates}
        setLoading={this.setLoading}
      />
    );
  }
}
