import CoreModel from "../../service/CoreModel";
import Service from "./Service";
import moment from "moment";
import Workbook from "react-excel-workbook-emptystring";
var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      senha: "",
      senhaConfirm: "",
      showNovaSenha: false,
      alertDisplay: "display-hide",
      alertText: "Usuário ou Senha inválidos",
      chartsData: [],
      dateSelected: "",
      dataInicial: moment().startOf("month"),
      dataFinal: moment(),
      showDates: false,
      showCalendars: false,
      load: false,
      selection: "",
      selection2: "",
      dados: [],
      grafname: "",
      Ydata: "",
      Xdata: "",
      Namedata: "",
      FinalXF: "",
      FinalXG: "",
      FinalY: "",
      newvet1: [],
      newvet2: [],
      newvet3: [],
      newvet4: [],
      extension: "",
      showvet1: [],
      showvet2: [],
      showvet3: [],
      showvet4: [],
      open: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      codigos_pgto: [],
      empresa: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.onSave = this.onSave.bind(this);
    this.createAgregation = this.createAgregation.bind(this);
    this.getDados = this.getDados.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.modalHide1 = this.modalHide1.bind(this);
    this.modalHide2 = this.modalHide2.bind(this);
    this.modalHide3 = this.modalHide3.bind(this);
    this.modalHide4 = this.modalHide4.bind(this);
    this.dateInicialHandleChange = this.dateInicialHandleChange.bind(this);
    this.dateFinalHandleChange = this.dateFinalHandleChange.bind(this);
    this.showDatesSelector = this.showDatesSelector.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.carregaInicial = this.carregaInicial.bind(this);
    this.escolhecorrida = this.escolhecorrida.bind(this);
    this.escolhecorrida2 = this.escolhecorrida2.bind(this);
    this.escolhecorrida3 = this.escolhecorrida3.bind(this);
    this.escolhecorrida4 = this.escolhecorrida4.bind(this);
  }
  onSave() {
    this.setState(
      {
        Xdata: this.state.selection2,
        Ydata: this.state.selection,
        Namedata: this.state.grafname,
        open: !this.state.open,
      },
      () =>
        this.setState({
          selection: "",
          selection2: "",
          grafname: "",
        })
    );
    this.inputEl.value = "";
    this.inputEl2.value = "";
  }

  createAgregation() {
    let funcao;
    let field;
    if (this.state.Ydata === "NUMC") {
      funcao = '"COUNT"';
      field = '"*"';
    } else {
      if (this.state.Ydata === "MC") {
        funcao = '"AVG"';
        field = '"valorcalculado"';
      } else {
        if (this.state.Ydata === "VT") {
          funcao = '"SUM"';
          field = '"valorcalculado"';
        } else {
          if (this.state.Ydata === "CB") {
            funcao = '"MIN"';
            field = '"valorcalculado"';
          } else {
            if (this.state.Ydata === "CC") {
              funcao = '"MAX"';
              field = '"valorcalculado"';
            } else {
              if (this.state.Ydata === "CCAN") {
                funcao = '"COUNT"';
                field = '"*"';
                this.setState({
                  extension: "",
                });
              } else {
                funcao = "";
                field = "";
              }
            }
          }
        }
      }
    }
    return '{"function": " ' + funcao + '", "field": "' + field + '"}';
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.senha) {
      if (
        this.props.location.state.senha === "teste" ||
        this.props.location.state.senha === "go" ||
        this.props.location.state.senha === "peroxidos"
      ) {
        this.setState({ showNovaSenha: true });
      }
      this.props.history.replace({
        pathname: "/",
        state: {},
      });
    }
  }

  criaExcel(vetor) {
    return (
      <Workbook
        element={
          <button
            type="button"
            className="link-button"
            href
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ margin: 5 }}
          >
            <i className="fa fa-file-excel-o"></i>Excel Completo
          </button>
        }
      >
        <Workbook.Sheet data={vetor} name="Relatorio">
          <Workbook.Column label="Voucher" value="numero_voucher" width={20} />
          <Workbook.Column
            label="Nº da corrida"
            value="t12_num_corrida"
            width={20}
          />
          <Workbook.Column label="Origem" value="origem" width={20} />
          <Workbook.Column
            label="Data agendamento"
            value="t12_dthr_agendamento"
            width={20}
          />
          <Workbook.Column
            label="Data inicio"
            value="t12_dthr_embarques"
            width={20}
          />
          <Workbook.Column
            label="Data fim"
            value="t12_dthr_dembarques"
            width={20}
          />
          <Workbook.Column
            label="Nome solicitante"
            value="voucher_usuarios_nome"
            width={20}
          />
          <Workbook.Column
            label="Nome passageiro"
            value="t12_nome"
            width={20}
          />
          <Workbook.Column
            label="Email"
            value="voucher_usuarios_email"
            width={20}
          />
          <Workbook.Column
            label="Matricula"
            value="voucher_funcionario_matricula"
            width={20}
          />
          <Workbook.Column
            label="Destino"
            value="taxi333$enderecodestino"
            width={20}
          />
          <Workbook.Column
            label="Forma de pagamento"
            value="t12_cd_tp_pgto_a"
            width={20}
          />
          <Workbook.Column label="Km" value="t12_dthr_corrida" width={20} />
          <Workbook.Column label="Valor" value="valorcalculado" width={20} />
          <Workbook.Column
            label="Nome motorista"
            value="t08_motoristas_t08_nomemotorista"
            width={20}
          />
          <Workbook.Column label="Carro" value="modelo" width={20} />
          <Workbook.Column
            label="Placa"
            value="t07_carro_t07_placa_detran"
            width={20}
          />
          <Workbook.Column
            label="Ano"
            value="t07_carro_t07_ano_fabricacao"
            width={20}
          />
          <Workbook.Column
            label="Categoria"
            value="t12_dthr_corrida"
            width={20}
          />
        </Workbook.Sheet>
      </Workbook>
    );
  }

  async getDados() {
    let result = await this.service.fetchOn(
      'corridas?fields=$DATE.HH|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&aggregation=[{"function": "COUNT", "field": "*"}, {"function": "AVG", "field": "valorcalculado"},{"function":"SUM", "field":"valorcalculado"}]&grouping=$DATE.HH|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=200'
    );
    let result2 = await this.service.fetchOn(
      'corridas?fields=t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a&grouping=t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&aggregation=[{"function":"COUNT","field":"*"}, {"function": "AVG", "field":"valorcalculado"}, {"function":"SUM", "field":"valorcalculado"}]&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=201'
    );
    let testin = await this.service.fetchOn(
      'corridas?t12_dthr_corridas={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=202'
    );
    let result3 = await this.service.fetchOn(
      'corridas?fields=$DATE.DD|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&aggregation=[{"function":"COUNT","field":"*"}, {"function": "AVG", "field":"valorcalculado"}, {"function":"SUM", "field":"valorcalculado"}]&grouping=$DATE.DD|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=203'
    );
    let result4 = await this.service.fetchOn(
      'corridas?aggregation=[{"function":"COUNT","field":"*"}, {"function": "AVG", "field":"valorcalculado"}, {"function":"SUM", "field":"valorcalculado"}]&t12_dthr_corridas={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=202'
    );
    let pgto = await this.service.fetchOn("codigos?filter=pagto");
    let result5 = await this.service.fetchOn(
      'corridas?fields=$DATE.DD|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&aggregation=[{"function": "COUNT", "field": "*"}, {"function": "AVG", "field": "valorcalculado"},{"function":"SUM", "field":"valorcalculado"}]&grouping=$DATE.DD|t12_dthr_corrida,t12_nome,numero_voucher,t12_num_corrida,origem,t12_dthr_agendamento,datahoraconfirmada,t12_dthr_corridas,t12_dthr_dembarques,t12_dthr_embarques,t12_endereco,taxi333$enderecodestino,valorcalculado,voucher_usuarios.nome,voucher_usuarios.email,voucher_funcionario.matricula,t07_carro_t07_conceccao,t08_motoristas_t08_nomemotorista,modelo,t07_carro_t07_placa_detran,t07_carro_t07_ano_fabricacao,voucher_func,t12_cd_tp_pgto_a,t12_numero&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }&t12_st_corrida=E&voucher_eletronico_validado=true&per_page=204'
    );

    let jtestin = testin.result;
    let jresult = result.result;
    let jresult2 = result2.result;
    let jresult3 = result3.result;
    let jresult4 = result4.result;
    let jresult5 = result5.result;

    let jpgto = pgto.result;
    let newvet1 = [];
    let newvet2 = [];
    let newvet3 = [];
    let newvet4 = [];
    let achou;
    for (let i in jresult2) {
      achou = newvet1
        .map((e) => {
          return e.voucher_func;
        })
        .indexOf(jresult2[i].voucher_func);
      if (achou !== -1) {
        newvet1[achou].corridaz_valorcalculado_sum =
          jresult2[i].corridaz_valorcalculado_sum +
          newvet1[achou].corridaz_valorcalculado_sum;
        newvet1[achou].count = jresult2[i].count + newvet1[achou].count;
        newvet1[achou].corridaz_valorcalculado_avg =
          newvet1[achou].corridaz_valorcalculado_sum / newvet1[achou].count;
      } else {
        newvet1.push(jresult2[i]);
      }
    }
    for (let i in jresult3) {
      achou = newvet2
        .map((e) => {
          return e.t12_dthr_corrida;
        })
        .indexOf(jresult3[i].t12_dthr_corrida);
      if (achou !== -1) {
        newvet2[achou].corridaz_valorcalculado_sum =
          jresult3[i].corridaz_valorcalculado_sum +
          newvet2[achou].corridaz_valorcalculado_sum;
        newvet2[achou].count = jresult3[i].count + newvet2[achou].count;
        newvet2[achou].corridaz_valorcalculado_avg =
          newvet2[achou].corridaz_valorcalculado_sum / newvet2[achou].count;
      } else {
        newvet2.push(jresult3[i]);
      }
    }

    for (let i in jresult) {
      achou = newvet3
        .map((e) => {
          return e.t12_dthr_corrida;
        })
        .indexOf(jresult[i].t12_dthr_corrida);
      if (achou !== -1) {
        newvet3[achou].corridaz_valorcalculado_sum =
          jresult[i].corridaz_valorcalculado_sum +
          newvet3[achou].corridaz_valorcalculado_sum;
        newvet3[achou].count = jresult[i].count + newvet3[achou].count;
        newvet3[achou].corridaz_valorcalculado_avg =
          newvet3[achou].corridaz_valorcalculado_sum / newvet3[achou].count;
      } else {
        newvet3.push(jresult[i]);
      }
    }

    for (let i in jresult5) {
      achou = newvet4
        .map((e) => {
          return e.t12_dthr_corrida;
        })
        .indexOf(jresult5[i].t12_dthr_corrida);
      if (achou !== -1) {
        newvet4[achou].corridaz_valorcalculado_sum =
          jresult5[i].corridaz_valorcalculado_sum +
          newvet4[achou].corridaz_valorcalculado_sum;
        newvet4[achou].count = jresult5[i].count + newvet4[achou].count;
        newvet4[achou].corridaz_valorcalculado_avg =
          newvet4[achou].corridaz_valorcalculado_sum / newvet4[achou].count;
      } else {
        newvet4.push(jresult5[i]);
      }
    }
    this.setState({
      graf1: jresult2,
      graf2: jresult3,
      graf3: jresult,
      graf4: jresult5,
      stats: jresult4,
      load: true,
      newvet1: newvet1,
      newvet2: newvet2,
      newvet3: newvet3,
      newvet4: newvet4,
      codigos_pgto: jpgto,
    });
  }
  modalHide() {
    this.setState({ showNovaSenha: false });
  }

  modalHide1() {
    this.setState({ open1: false, showvet1: [] });
  }

  modalHide2() {
    this.setState({ open2: false, showvet2: [] });
  }

  modalHide3() {
    this.setState({ open3: false, showvet3: [] });
  }
  modalHide4() {
    this.setState({ open4: false, showvet4: [] });
  }

  dateInicialHandleChange(date) {
    this.setState({
      dataInicial: date,
    });
  }

  //Save the date selected from DatePicker
  dateFinalHandleChange(date) {
    this.setState({
      dataFinal: date,
    });
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }

  handleChange2() {
    this.setState({ selection2: this.inputEl2.value });
  }
  handleChange3(e) {
    this.setState({ grafname: e.target.value });
  }

  async showDatesSelector(item, calendar) {
    if (!!calendar) {
      await this.setState({
        showDates: true,
        dateSelected: item,
        showCalendars: true,
      });
    } else {
      await this.setState({ showDates: false, dateSelected: item });
      await this.setState({ load: false });
      await this.setDateRange();
      await this.getDados();
    }
    console.log("painel 1", [
      item,
      this.state.showDates,
      this.state.dateSelected,
      this.state.showCalendars,
    ]);
  }

  async setDateRange() {
    console.log("painel 2", this.state.dateSelected);

    this.setState({ showCalendars: false });
    let inicial;
    let final;

    if (this.state.dateSelected === 0) {
      inicial = moment().subtract(1, "days");
      final = moment().subtract(1, "days");
    } else if (this.state.dateSelected === 1) {
      inicial = moment().subtract(6, "days");
      final = moment();
    } else if (this.state.dateSelected === 2) {
      inicial = moment().subtract(29, "days");
      final = moment();
    } else if (this.state.dateSelected === 3) {
      inicial = moment().startOf("month");
      final = moment();
    } else if (this.state.dateSelected === 4) {
      inicial = moment().subtract(1, "months").startOf("month");
      final = moment().subtract(1, "months").endOf("month");
    }

    await this.setState({
      dataInicial: inicial,
      dataFinal: final,
      showDates: false,
    });
  }

  carregaInicial() {
    this.service
      .getEmpresa(this.service.getCookie("empresa-id"))
      .then((result) => {
        this.setState({ empresa: result });
      });
    this.getDados();
  }

  escolhecorrida(corrida) {
    let showvet = [];
    let graf1 = this.state.graf1;
    for (let i in graf1) {
      if (graf1[i].voucher_func === corrida.voucher_func) {
        for (let j in this.state.codigos_pgto) {
          if (
            this.state.codigos_pgto[j].codigo === graf1[i].t12_cd_tp_pgto_a[0]
          ) {
            graf1[i].t12_cd_tp_pgto_a = this.state.codigos_pgto[j].descricao;
          }
        }
        showvet.push(graf1[i]);
      }
    }
    this.setState({
      showvet1: showvet,
    });
  }
  escolhecorrida2(corrida) {
    let showvet = [];
    for (let i in this.state.graf2) {
      if (this.state.graf2[i].t12_dthr_corrida === corrida.t12_dthr_corrida) {
        showvet.push(this.state.graf2[i]);
      }
    }
    this.setState({
      showvet2: showvet,
    });
  }
  escolhecorrida3(corrida) {
    let showvet = [];
    for (let i in this.state.graf3) {
      if (this.state.graf3[i].t12_dthr_corrida === corrida.t12_dthr_corrida) {
        showvet.push(this.state.graf3[i]);
      }
    }
    this.setState({
      showvet3: showvet,
    });
  }
  escolhecorrida4(corrida) {
    let showvet = [];
    for (let i in this.state.graf3) {
      if (this.state.graf3[i].t12_dthr_corrida === corrida.t12_dthr_corrida) {
        showvet.push(this.state.graf4[i]);
      }
    }
    this.setState({
      showvet4: showvet,
    });
  }
}
