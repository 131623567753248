import React, { Component } from "react"
import SubHeader from "../../header/SubHeaderView.js"
import ViewModel from "./controllers/ViewModel.js"
import VouchersFaturadosV2View from "./view/VouchersFaturadosV2.js"

export default class VouchersFaturadosV2 extends Component {
  constructor(props) {
    super(props);
    // this.viewModel = new ViewModel();
    // this.viewModel.mirrorState = (s) => this.setState(s);
    // this.viewModel.props = props;
  }

  componentDidMount() {
    // this.viewModel.fetchData(this.props.childProps.user);
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Relatórios" textoCentral="Faturados" />
        <div className="page-content">
          <div className="container">
            <VouchersFaturadosV2View {...this.props}  />
          </div>
        </div>
      </div>
    );
  }
}
