import {PreCadastroService} from './services/PreCadastroService.js';


const realizarCadastro = async (dados, central) => {
  // console.log('#3 ---->')
  console.log('recebido no api/index.js:', dados)
  let pSer = new PreCadastroService();
  return await pSer.realizarCadastro(dados, central);
};

export {realizarCadastro};
