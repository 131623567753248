import React, { Component } from "react";
import { Modal, Image } from "react-bootstrap";
import TextBox from "../../components/TextBox";
import ViewModel from "./ViewModel";
import { cnpj, cpf } from "cpf-cnpj-validator";

export default class Cadastro extends Component {
  constructor(props) {
    super(props);

    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentWillUnmount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="page-md login loginBack">
        <div className="logo">
          {!!this.props.icone ? (
            <Image src={this.props.icone} style={{ width: 255 }}></Image>
          ) : (
            <></>
          )}
        </div>

        <div className="content bgColor4 round7">
          <div className="register-form" style={{ display: "block" }}>
            <div
              className="fontColor3"
              style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}
            >
              Quer se convêniar?
            </div>
            <div
              className="fontColor1"
              style={{
                fontSize: 14,
                fontWeight: "regular",
                textAlign: "center",
              }}
            >
              Entre com seus dados de contato e um representante entrará em
              contato.
            </div>
            <div className={"alert " + this.viewModel.state.alertDisplay}>
              <button
                className="close"
                onClick={() =>
                  this.viewModel.setState({ alertDisplay: "display-hide" })
                }
              ></button>
              <span>{this.viewModel.state.alertText}</span>
            </div>

            <div className="form-group">
              <label className="control-label fontColor1">CNPJ</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="CNPJ"
                  placeholder="CNPJ"
                  maxLength={18}
                  value={this.viewModel.state.cnpj}
                  onChange={(e) => {
                    let text = e.target.value;
                    text = text.replace(/[^+\d]/g, "");
                    text = text.slice(0, 14);
                    text = cnpj.format(text);
                    this.viewModel.setState({ cnpj: text });
                  }}
                  onBlur={(e) => this.viewModel.checkCnpf()}
                  error={this.viewModel.state.error.cnpj}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">
                Razão Social/Nome
              </label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Razão Social"
                  placeholder="Razão Social"
                  value={this.viewModel.state.razao}
                  onChange={(e) =>
                    this.viewModel.setState({ razao: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("razao");
                  }}
                  error={this.viewModel.state.error.razao}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">
                CPF do Responsável
              </label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="CPF do Responsável"
                  placeholder="CPF do Responsável"
                  maxLength={14}
                  value={this.viewModel.state.cpfresponsavel}
                  onChange={(e) => {
                    let text = e.target.value;
                    text = text.replace(/[^+\d]/g, "");
                    text = text.slice(0, 11);
                    text = cpf.format(text);
                    this.viewModel.setState({ cpfresponsavel: text });
                  }}
                  onBlur={(e) => {
                    this.viewModel.checkCpf();
                  }}
                  error={this.viewModel.state.error.cpfresponsavel}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">
                Nome do Contato / Responsável
              </label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Nome do Contato / Responsável"
                  placeholder="Nome do Contato / Responsável"
                  value={this.viewModel.state.nomeresponsavel}
                  onChange={(e) =>
                    this.viewModel.setState({ nomeresponsavel: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("nomeresponsavel");
                  }}
                  error={this.viewModel.state.error.nomeresponsavel}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Telefone</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Telefone"
                  placeholder="Telefone"
                  maxLength={15}
                  value={this.viewModel.state.telefone}
                  onChange={(e) => {
                    let text = e.target.value.replace(/[^+\d]/g, "");
                    let x = "";

                    text = text.slice(0, 11);
                    if (text.length > 10) {
                      x = text
                        .replace(/\D+/g, "")
                        .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
                    } else {
                      x = text
                        .replace(/\D+/g, "")
                        .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
                    }

                    this.viewModel.setState({ telefone: x });
                  }}
                  onBlur={(e) => {
                    this.viewModel.justCheckText("telefone");
                  }}
                  error={this.viewModel.state.error.telefone}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Email</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Email"
                  placeholder="Email"
                  value={this.viewModel.state.e_mail}
                  onChange={(e) =>
                    this.viewModel.setState({ e_mail: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("e_mail");
                  }}
                  error={this.viewModel.state.error.e_mail}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">CEP</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="CEP"
                  placeholder="CEP"
                  value={this.viewModel.state.cep}
                  onChange={(e) => {
                    let text = e.target.value;
                    text = text.replace(/[^+\d]/g, "");
                    let saida = "";
                    if (text.length > 5) {
                      saida = text.substring(0, 5) + "-";
                      saida += text.substring(5, 8);
                    } else {
                      saida = text;
                    }
                    this.viewModel.setState({ cep: saida });
                  }}
                  onBlur={(e) => {
                    this.viewModel.validaCep(true);
                  }}
                  error={this.viewModel.state.error.cep}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Endereço</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Endereço"
                  placeholder="Endereço"
                  value={this.viewModel.state.endereco}
                  onChange={(e) =>
                    this.viewModel.setState({ endereco: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("endereco");
                  }}
                  error={this.viewModel.state.error.endereco}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Número</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Número"
                  placeholder="Número"
                  value={this.viewModel.state.numero}
                  onChange={(e) =>
                    this.viewModel.setState({ numero: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("numero");
                  }}
                  error={this.viewModel.state.error.numero}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Complemento</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Complemento"
                  placeholder="Complemento"
                  value={this.viewModel.state.complemento}
                  onChange={(e) =>
                    this.viewModel.setState({ complemento: e.target.value })
                  }
                  onBlur={(e) => {}}
                  error={this.viewModel.state.error.complemento}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Bairro</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Bairro"
                  placeholder="Bairro"
                  value={this.viewModel.state.bairro}
                  onChange={(e) =>
                    this.viewModel.setState({ bairro: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("bairro");
                  }}
                  error={this.viewModel.state.error.bairro}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">Cidade</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="Cidade"
                  placeholder="Cidade"
                  value={this.viewModel.state.cidade}
                  onChange={(e) =>
                    this.viewModel.setState({ cidade: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("cidade");
                  }}
                  error={this.viewModel.state.error.cidade}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label fontColor1">UF</label>
              <div className="input-icon">
                <TextBox
                  className="form-control placeholder-no-fix round7 bgColor4"
                  name="UF"
                  placeholder="UF"
                  value={this.viewModel.state.uf}
                  onChange={(e) =>
                    this.viewModel.setState({ uf: e.target.value })
                  }
                  onBlur={(e) => {
                    this.viewModel.justCheckText("uf");
                  }}
                  error={this.viewModel.state.error.uf}
                />
              </div>
            </div>

            <div className="form-actions">
              <button
                className="btn bgColor1 fontColor4 round7"
                onClick={() => this.viewModel.navigateTo("/")}
              >
                <i className="m-icon-swapleft"></i> Voltar
              </button>
              <button
                className="btn pull-right bgColor2 fontColor5 round7"
                onClick={this.viewModel.precadastro}
              >
                Enviar
                <i className="m-icon-swapright m-icon-white"></i>
              </button>
            </div>
          </div>
        </div>
        <Modal
          keyboard
          show={this.viewModel.state.showModal}
          onHide={this.viewModel.modalHide}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Body>Pre-cadastro realizado com sucesso</Modal.Body>
        </Modal>
      </div>
    );
  }
}
