import styled from 'styled-components'
import React, { Component } from 'react'
import ReactDom from 'react-dom'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import ReactTable from 'react-table'
import Select2 from 'react-select2-wrapper'
import 'react-select2-wrapper/css/select2.css'
import AutoComplete from './AutoComplete.js'
import InputMask from 'react-input-mask'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import Dialog from 'react-bootstrap-dialog-v2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
//import VoucherEletronico from './VoucherEletronico.js';
import DadosIUGU from './DadosIUGU.js'
import moment from 'moment'
import WS from '../services/webservice'
import CurrencyInput from 'react-currency-input'
import Autosuggest from 'react-autosuggest'
import Hotkeys from 'react-hot-keys'
import TipoCorrida from './tipoCorrida.js'
import TipoCorridaApi from './tipoCorridaApi.js'

import GoMap from './GoMap.js'
import Icon from 'react-icons-wrapper'
import IconButton from './IconButton.js'
import Fontes from '../fonts/Fontes.js';


import { flexbox } from '@material-ui/system'
import Box from '@material-ui/core/Box'
import { ReactComponent as Entrega1 } from '../images/entrega1.svg'
import { ReactComponent as Entrega2 } from '../images/entrega2.svg'
import { ReactComponent as Entrega3 } from '../images/entrega3.svg'
import { GrWaypoint } from 'react-icons/gr'
var jwtDecode = require('jwt-decode')

// -- STYLED COMPONENTS --

const BotaoLimpar = styled.div`
  background-color: #e43a45;
  color: #ffffff;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 25px !important;
  overflow: hidden;
  &:hover {
    transition: all 0.3s;
    background-color: #cf1c28;
  }
`


// -- FIM STYLED COMPONENTS --

class Solicitar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      api_waypoints: [],
      api_valores: [],
      api_rotas: [],
      showColapseSolicitante: true,
      showColapseOrigem: false,
      showColapseMap: true,
      showColapseModalidade: false,
      showColapsePagamento: false,
      showColapseDestino: false,
      pricesselecionado: {},
      maximodestinos: 3,
      tiposelecionado: '',
      tiposolicitacao: 1,
      showDetalhe: false,
      showModal: false,
      favorito_id: null,
      corrida: {},
      moip: null,
      pricesEscolhido: null,
      alertDisplay: 'display-hide',
      alertText: 'Por favor preencha o número no endereço!',
      band: '',
      km: '',
      valorMin: '',
      taxaRet: '',
      perfil: '',
      role: 10,
      pagamentos_api: [],
      markerLocal: [
        {
          lat: '',
          lng: '',
          img_src: '',
          label: ''
        }
      ],
      markerDest: [
        {
          lat: '',
          lng: '',
          img_src: ''
        }
      ],
      motoMarker: [
        {
          lat: '',
          lng: '',
          img_src: ''
        }
      ],
      mostrardetalhesuser: false,
      valorCorridaFloat: null,
      valorExecutivoFloat: null,
      dataCompleta: null,
      valorExecutivo: '',
      valorNormal: '',
      distance: '',
      distanceValue: '',
      options: [],
      telefoneMask: '(99) 9999-9999',
      origemLatLng: { lat: '', lng: '' },
      destinoLatLng: { lat: '', lng: '' },
      apelido: '',
      telefone: '',
      passageiro: '',
      origem: '',
      origemautocomplete: {},
      fullAddress: '',
      d_fullAddress: '',
      ac_o_pos: null,
      numero: '',
      referencia: '',
      complemento: '',
      destino: '',
      destinos: [
        {
          autocomplete: {},
          destino: '',
          numeroDestino: '',
          cidadeDestino: '',
          obs: '',
          item: '',
          valor: 0,
          pagamento: '',
          contato: '',
          parada: false,
          retorno: false,
          fullAddress: '',
          ac_pos: null
        }
      ],
      numeroDestino: '',
      cidadeDestino: '',
      address: '',
      pagtoOptions: [],
      pagtoSelected: '112',
      taxiCM: 'active',
      taxiEX: '',
      showCartao: false,
      tipo_cor: false,
      corSelected: [],
      favoritos: [],
      carroSelected: [],
      animalCheck: false,
      cadeiranteCheck: false,
      portaMalasCheck: false,
      wifiCheck: false,
      showAgenda: 'none',
      praJaCheck: true,
      agendCheck: false,
      horaAgend: moment(),
      dataAgend: moment(),
      favCheck: false,
      podepedir: true,
      validation: '',
      antecipar: '20',
      selecionado: false,
      haveDestino: false,
      qtdCorridas: 1,
      telefoneempresa: '',
      limitecarros: 10,
      solicitarpreferencias: true,
      solicitarpreferenciasmoto: true,
      tipocentral: 1,
      central_data: {},
      empresa_data: {},
      user_data: {},
      func_data: {},
      sugest_funcs: [],
      motoristas: [],
      sugest_nomeFunc: '',
      departamentoss: [],
      departamentosz: [],
      selection: 0,
      selected: {
        telefone: '',
        passageiro: '',
        identificacao: '',
        origem_endereco: '',
        origem_numero: '',
        origem_cidade: '',
        referencia: '',
        complemento: '',
        destino_endereco: '',
        destino_numero: '',
        destino_cidade: '',
        formadepagamento: '',
        origem_latitude: null,
        origem_longitude: null,
        destino_latitude: null,
        destino_longitude: null
      },
      suggestion: {
        departamento: '',
        usuario: '',
        empresa: '',
        matricula: '',
        valorlimit: '',
        role: '',
        voucher_roles_descricao: '',
        voucher_usuarios_email: '',
        voucher_usuarios_username: '',
        voucher_usuarios_nome: '',
        voucher_usuarios_telefone: '',
        status: '',
        selection: ''
      }
    }

    this.webservice = new WS()
    this.inputs = []
    this.tempo = this.tempo.bind(this)
    this.sendTaxi = this.sendTaxi.bind(this)
    this.sendTaxiReal = this.sendTaxiReal.bind(this)
    this.getPrices = this.getPrices.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.setTipoTaxi = this.setTipoTaxi.bind(this)
    this.disableHour = this.disableHour.bind(this)
    this.dateHandleChange = this.dateHandleChange.bind(this)
    this.modalFavoritoHide = this.modalFavoritoHide.bind(this)
    this.modalEditHide = this.modalEditHide.bind(this)
    this.deleteFavorito = this.deleteFavorito.bind(this)
    this.salvarFavorito = this.salvarFavorito.bind(this)
    this.getLatLng = this.getLatLng.bind(this)
    this.newPag = this.newPag.bind(this)
    this.handleChangeDados = this.handleChangeDados.bind(this)
    this.manageShortcut = this.manageShortcut.bind(this)
    this.hideDetalhe = this.hideDetalhe.bind(this)
    this.showDetalhe = this.showDetalhe.bind(this)
    this.setFavorito = this.setFavorito.bind(this)
    this.setTipoPagamentoNew = this.setTipoPagamentoNew.bind(this)
    this.setaTipoSolicitacao = this.setaTipoSolicitacao.bind(this)
    this.setTipoPagamento = this.setTipoPagamento.bind(this)
    this.setInpudDestino = this.setInpudDestino.bind(this)
    this.driversAround = this.driversAround.bind(this)
    this.showHideSolicitante = this.showHideSolicitante.bind(this)
    this.showHideOrigem = this.showHideOrigem.bind(this)
    this.showHideMap = this.showHideMap.bind(this)
    this.showHideModalidade = this.showHideModalidade.bind(this)
    this.showHidePagamento = this.showHidePagamento.bind(this)
    this.showHideDestino = this.showHideDestino.bind(this)
    this.calculaValor = this.calculaValor.bind(this)
    this.newSendTaxiReal = this.newSendTaxiReal.bind(this)
    this.disabledHours = this.disabledHours.bind(this)
    this.disabledMinutes = this.disabledMinutes.bind(this)
    //useHotkeys('ctrl+k',this.newPag);
  }
  showHideSolicitante() {
    this.setState({
      showColapseSolicitante: !this.state.showColapseSolicitante
    })
  }
  showHideOrigem() {
    this.setState({ showColapseOrigem: !this.state.showColapseOrigem })
  }
  showHideMap() {
    this.setState({ showColapseMap: !this.state.showColapseMap })
  }
  showHideModalidade() {
    this.setState({ showColapseModalidade: !this.state.showColapseModalidade })
  }
  showHidePagamento() {
    this.setState({ showColapsePagamento: !this.state.showColapsePagamento })
  }
  showHideDestino() {
    this.setState({ showColapseDestino: !this.state.showColapseDestino })
  }

  setTipoPagamentoNew(tipo, pgtos) {
    this.setState({
      tiposelecionado: tipo,
      pagamentos_api: pgtos,
      pagtoSelectedApi: pgtos[0].id
    })
  }

  setTipoPagamento(tipo, pgto) {
    let prices = {}
    if (this.state.prices) {
      if (this.state.prices[tipo]) {
        prices = this.state.prices[tipo]
      }
    }
    console.log('this.state.pagtoSelected mudando', [tipo, pgto])
    if (!pgto) {
      let pgtofiltrados = this.state.pagtoOptions.filter(
        (x) => Number(x.id) === Number(112)
      )
      if (pgtofiltrados.length > 0) {
        pgto = '112'
      } else {
        pgto = '10'
      }
    }
    console.log('pagtoOptions', this.state.pagtoOptions)
    this.setState({
      tiposelecionado: tipo,
      pricesselecionado: prices,
      pagtoSelected: pgto
    })
  }

  async newPag() {
    await this.setState({
      api_waypoints: [],
      api_valores: [],
      api_rotas: [],
      showColapseSolicitante: true,
      showColapseOrigem: false,
      showColapseMap: true,
      showColapseModalidade: false,
      showColapsePagamento: false,
      showColapseDestino: false,
      pricesselecionado: {},
      empresa_latitude: -25,
      empresa_longitude: -49,
      tiposelecionado: '',
      tiposolicitacao: 1,
      newPag: true,
      showModal: false,
      corrida: {},
      favorito_id: null,
      moip: null,
      prices: {},
      c_points: null,
      pricesEscolhido: null,
      mostrardetalhesuser: false,
      alertDisplay: 'display-hide',
      alertText: 'Por favor preencha o número no endereço!',
      band: '',
      km: '',
      valorMin: '',
      taxaRet: '',
      perfil: '',
      role: 10,
      markerLocal: [
        {
          lat: '',
          lng: '',
          img_src: '',
          label: ''
        }
      ],
      markerDest: [
        {
          lat: '',
          lng: '',
          img_src: ''
        }
      ],
      motoMarker: [
        {
          lat: '',
          lng: '',
          img_src: ''
        }
      ],
      valorCorridaFloat: null,
      dataCompleta: null,
      valorExecutivo: '',
      valorNormal: '',
      distance: '',
      distanceValue: '',
      options: [],
      telefoneMask: '(99) 9999-9999',
      origemLatLng: { lat: '', lng: '' },
      destinoLatLng: { lat: '', lng: '' },
      apelido: '',
      telefone: '',
      passageiro: '',
      origem: '',
      origemautocomplete: {},
      fullAddress: '',
      d_fullAddress: '',
      ac_o_pos: null,
      numero: '',
      referencia: '',
      complemento: '',
      destino: '',
      destinos: [
        {
          autocomplete: {},
          destino: '',
          numeroDestino: '',
          cidadeDestino: '',
          obs: '',
          item: '',
          valor: 0,
          pagamento: '',
          contato: '',
          parada: false,
          retorno: false,
          fullAddress: '',
          ac_pos: null
        }
      ],
      numeroDestino: '',
      cidadeDestino: '',
      address: '',
      pagtoOptions: [],
      pagtoSelected: '112',
      taxiCM: 'active',
      taxiEX: '',
      showCartao: false,
      tipo_cor: false,
      corSelected: [],
      favoritos: [],
      carroSelected: [],
      animalCheck: false,
      cadeiranteCheck: false,
      portaMalasCheck: false,
      wifiCheck: false,
      retornoOriginalCheck: false,
      showAgenda: 'none',
      praJaCheck: true,
      agendCheck: false,
      horaAgend: moment(),
      dataAgend: moment(),
      favCheck: false,
      podepedir: true,
      validation: '',
      antecipar: '20',
      selecionado: false,
      haveDestino: false,
      qtdCorridas: 1,
      telefoneempresa: '',
      limitecarros: 10,
      solicitarpreferencias: true,
      solicitarpreferenciasmoto: true,
      tipocentral: 1,
      central_data: {},
      empresa_data: {},
      user_data: {},
      func_data: {},
      sugest_funcs: [],
      sugest_nomeFunc: '',
      departamentoss: [],
      departamentosz: [],
      selected: {
        telefone: '',
        passageiro: '',
        identificacao: '',
        origem_endereco: '',
        origem_numero: '',
        origem_cidade: '',
        referencia: '',
        complemento: '',
        destino_endereco: '',
        destino_numero: '',
        destino_cidade: '',
        formadepagamento: '',
        origem_latitude: null,
        origem_longitude: null,
        destino_latitude: null,
        destino_longitude: null
      },
      suggestion: {
        departamento: '',
        usuario: '',
        empresa: '',
        matricula: '',
        valorlimit: '',
        role: '',
        voucher_roles_descricao: '',
        voucher_usuarios_email: '',
        voucher_usuarios_username: '',
        voucher_usuarios_nome: '',
        voucher_usuarios_telefone: '',
        status: '',
        selection: ''
      }
    })
    await this.componentWillMount()
    this.componentDidMount()
  }
  salvarFavorito() {
    let s = this.state.selected

    console.log(s)

    if (s.identificacao === '') {
      alert('Por favor preencha o apelido!')
      return
    }
    if (s.telefone === '') {
      alert('Por favor preencha o telefone!')
      return
    }
    if (s.passageiro === '') {
      alert('Por favor preencha o passageiro!')
      return
    }
    if (s.origem_endereco === '') {
      alert('Por favor preencha o endereço de origem!')
      return
    }
    if (s.origem_numero === '') {
      alert('Por favor preencha o número de origem!')
      return
    }
    // if (this.props.childProps.empresaData.destino_obrigatorio) {
    //   if (s.destino_endereco === '') { alert('Por favor preencha o endereço de destino!'); return; }
    //   if (s.destino_numero === '') { alert('Por favor preencha o número do destino!'); return; }
    //   if (s.destino_cidade === '') { alert('Por favor preencha o endereço de destino!'); return; }
    // }
    //if (s.formadepagamento.length <= 0) { alert('Por favor selecione a forma de pagamento!'); return; }

    if (this.state.novo) {
      this.setState(
        {
          selected: {
            ...s,
            telefone: s.telefone.toString(),
            usuario: this.props.childProps.user.id,
            origem_latitude: this.state.origemLatLng.lat,
            origem_longitude: this.state.origemLatLng.lng,
            destino_latitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lat
                ? this.state.destinoLatLng.lat
                : null,
            destino_longitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lng
                ? this.state.destinoLatLng.lng
                : null
          }
        },
        async () => {
          await this.webservice.criarFavorito(this.state.selected)
          
          this.setState({
            selected: {
              telefone: '',
              passageiro: '',
              identificacao: '',
              origem_endereco: '',
              origem_numero: '',
              origem_cidade: '',
              referencia: '',
              complemento: '',
              destino_endereco: '',
              destino_numero: '',
              destino_cidade: '',
              formadepagamento: 10,
              origem_latitude: null,
              origem_longitude: null,
              destino_latitude: null,
              destino_longitude: null
            }
          })
          this.getFavoritos()
          this.setState({ showEditFavoritos: false })
        }
      )
    } else if (this.state.editar) {
      this.setState(
        {
          selected: {
            ...s,
            telefone: s.telefone.toString(),
            origem_latitude: this.state.origemLatLng.lat,
            origem_longitude: this.state.origemLatLng.lng,
            destino_latitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lat
                ? this.state.destinoLatLng.lat
                : null,
            destino_longitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lng
                ? this.state.destinoLatLng.lng
                : null
          }
        },
        () => {
          this.webservice
            .alterarFavorito(this.state.selected)
            .then((result) => {
              
              this.setState({
                selected: {
                  telefone: '',
                  passageiro: '',
                  identificacao: '',
                  origem_endereco: '',
                  origem_numero: '',
                  origem_cidade: '',
                  referencia: '',
                  complemento: '',
                  destino_endereco: '',
                  destino_numero: '',
                  destino_cidade: '',
                  formadepagamento: 112,
                  origem_latitude: null,
                  origem_longitude: null,
                  destino_latitude: null,
                  destino_longitude: null
                }
              })
            })
          this.getFavoritos()
          this.setState({ showEditFavoritos: false }, () => {
            Dialog.resetOptions()
            this.dialog.showAlert('Favorito editado com sucesso!')
          })
        }
      )
    }
  }

  deleteFavorito(id) {
    this.webservice.deleteFavorito(id).then(() => {
      this.getFavoritos()
      Dialog.resetOptions()
      this.dialog.showAlert('Favorito deletado com sucesso!')
    })
  }

  onBlurNomeFunc = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.setState({
        sugest_nomeFunc: highlightedSuggestion.voucher_usuarios_nome
      })
    }
  }

  onChangeNomeFunc = (event, { newValue }) => {
    this.setState({ sugest_nomeFunc: newValue })
  }

  async setFavorito(fav, i) {
    console.log('setfavorito', fav)
    this.changeEndereco(fav.autocomplete, true, i)
    if (parseInt(i) === 999) {
      if (fav.coordenada) {
        this.setState({
          referencia: fav.coordenada
        })
      }
      if (fav.complemento) {
        this.setState({
          complemento: fav.complemento
        })
      }
    }
    this.input.focus()
  }
  setInpudDestino(input, key) {
    console.log('setInpudDestino', key)
    if (key === 'enrerecodestino0') {
      this.input = input
    }
    this.inputs[key] = input
  }
  async getFavoritos() {
    let result = await this.webservice.getFavoritosNew()
    await this.setState({ favoritos: result })
    return result
    //return null;
  }
  componentWillUnmount() {
    clearInterval(this.timerId)
  }
  async pegaDep() {
    let id = await this.webservice.getCookie('empresa-id')
    
    let resultado = await this.webservice.getDepartamentoslist(id)
    
    resultado = resultado.map((m) => {
      return {
        ...m,
        text: m.identificacao
      }
    })
    this.setState({ departamentoss: resultado }, () => console.log("QUE POHA TA ACONTECENDO AQUI", this.state.departamentoss))
  }
  hideModal() {
    this.setState({ showModal: false })
  }

  hideDetalhe() {
    this.setState({ showDetalhe: false })
  }
  showDetalhe(tipo) {
    let s = this.state.prices[tipo]
    if (s) {
      this.setState({ pricesEscolhido: s, showDetalhe: true })
    }
  }
  handleValueChange = (value) => {
    this.setState({ horaAgend: value })
  }

  disableMinute(minute) {
    let vet = []
    for (let i = 0; i < 60; i++) {
      if (i < minute) {
        vet.push(i)
      }
    }
    return vet
  }

  disableHour(hour) {
    let vet = []
    for (let i = 0; i < 24; i++) {
      if (i < hour) {
        vet.push(i)
      }
    }
    return vet
  }

  disabledHours() {
    let date = moment(this.state.dataAgend).format('YYYY-MM-DD')
    let today = moment().format('YYYY-MM-DD')
    let hora = Number(moment().hour())

    if (date === today) {
      return  [...Array(hora).keys()]
    } else {
      return []
    }
  }
  
  disabledMinutes(h) {

    let date = moment(this.state.dataAgend).format('YYYY-MM-DD')
    let today = moment().format('YYYY-MM-DD')
    let hora = Number(moment().hour())
    let minuto = Number(moment().minute())
    if (date === today) {
      if (hora > h) {
        return []
      } else {
        return  [...Array(minuto + 10).keys()]
      }
    } else {
      return []
    }
  }
  


  tempo() {
    let hora = Number(moment().hour())
    let minuto = Number(moment().minute()) + 15

    return (
      <TimePicker
        defaultValue={moment().hour(hora).minute(minuto)}
        onChange={this.handleValueChange}
        showSecond={false}
        minuteStep={15}
        value={this.state.horaAgend}
        allowEmpty={false}
      />
    )
  }
  //voucher_roles
  BlockDay() {
    var valida = false
    if (this.state.suggestion.calendar !== null) {
      this.state.suggestion.calendar.map((m) => {
        if (
          m.seg === true &&
          moment().isoWeekday() === 1 &&
          moment().isBetween(
            moment(m.ini, 'HH:mm'),
            moment(m.fim, 'HH:mm', 'second')
          )
        ) {
          valida = true
        } else {
          if (
            m.ter === true &&
            moment().isoWeekday() === 2 &&
            moment().isBetween(
              moment(m.ini, 'HH:mm'),
              moment(m.fim, 'HH:mm', 'second')
            )
          ) {
            valida = true
          } else {
            if (
              m.qua === true &&
              moment().isoWeekday() === 3 &&
              moment().isBetween(
                moment(m.ini, 'HH:mm'),
                moment(m.fim, 'HH:mm', 'second')
              )
            ) {
              valida = true
            } else {
              if (
                m.qui === true &&
                moment().isoWeekday() === 4 &&
                moment().isBetween(
                  moment(m.ini, 'HH:mm'),
                  moment(m.fim, 'HH:mm', 'second')
                )
              ) {
                valida = true
              } else {
                if (
                  m.sex === true &&
                  moment().isoWeekday() === 5 &&
                  moment().isBetween(
                    moment(m.ini, 'HH:mm'),
                    moment(m.fim, 'HH:mm', 'second')
                  )
                ) {
                  valida = true
                } else {
                  if (
                    m.sab === true &&
                    moment().isoWeekday() === 6 &&
                    moment().isBetween(
                      moment(m.ini, 'HH:mm'),
                      moment(m.fim, 'HH:mm', 'second')
                    )
                  ) {
                    valida = true
                  } else {
                    if (
                      m.dom === true &&
                      moment().isoWeekday() === 7 &&
                      moment().isBetween(
                        moment(m.ini, 'HH:mm'),
                        moment(m.fim, 'HH:mm', 'second')
                      )
                    ) {
                      valida = true
                    }
                  }
                }
              }
            }
          }
        }
        return valida
      })
    }

    
    if (valida === true) {
      return true
    } else {
      return false
    }
  }
  async setaTipoSolicitacao(tipo) {
    //let tipo_str = tipo === 1 ? 'Coleta - entrega  - Valor Mínimo R$ 12,00' : tipo === 2 ? 'Serviços Especiais - Com Hora parada Valor Mínimo R$ 25,00 retorno de 40%' : 'Coleta - Entrega - Com Retorno Valor Mínimo R$ 12,00 retorno de 30%';

    await this.setState({
      tiposolicitacao: tipo,
      tiposolicitacao_str: '',
      retornoOriginalCheck: tipo === 1 ? false : true,
      destinos: this.state.destinos.map((el) => {
        return { ...el, parada: tipo === 2 ? true : false }
      })
    })
    //this.calculaValor()
  }
  funcDep(dep) {
    let retorno
    if (dep !== '') {
      retorno = this.state.departamentoss.map((m) => {
        
        console.log(Number(m.id) === Number(dep))
        if (Number(m.id) === Number(dep)) {
          if (
            Number(m.valorlimit) >
            Number(m.saldo_gasto) + Number(this.state.valorCorridaFloat)
          ) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })
      let achou = retorno.indexOf(true)
      console.log('sou result', retorno)
      return achou === -1 ? false : true
    }
  }
  success(pos) {
    // this.setState({
    //   //center: { lat: pos.coords.latitude, lng: pos.coords.longitude },
    //   //markerLocal: { lat: pos.coords.latitude, lng: pos.coords.longitude },
    //   //origemLatLng: { lat: pos.coords.latitude, lng: pos.coords.longitude }
    // })
  }
  async componentWillMount() {
    this.setaTipoSolicitacao(1)
    let token = this.webservice.getCookie('portal-token')
    let data = jwtDecode(token)
    this.webservice.getFuncById(data.id).then((result) => {
      if (result.length > 0) {
        this.setState({
          perfil: result[0].voucher_roles_descricao,
          role: result[0].role,
          favorito_id: result[0].favorito_id
        })
        console.log('perfil', result)
      }
    })
    if (!this.props.location.state) {
      navigator.geolocation.getCurrentPosition(this.success.bind(this))
    }
    await this.pegaDep()
    let f = await this.getFavoritos()

    if (this.state.favorito_id && !this.state.newPag) {
      console.log('favorito', this.state.favorito_id)
      console.log('favorito', f)
      //let f = this.state.favoritos;
      let mf = f.filter((x) => x.id === this.state.favorito_id)
      console.log('favorito', mf)
      if (mf.length > 0) this.setFavorito(mf[0], 999)
    } else {
      setTimeout(() => {
        ReactDom.findDOMNode(this.inputs['enderecoorigem999']).focus()
      }, 1000)
    }

    let central_id = this.webservice.getCookie('central-id')
    let empresa_id = this.webservice.getCookie('empresa-id')
    let user_data = await this.webservice.getUserById(data.id)
    let central_data = await this.webservice.getCentral(central_id)
    let empresa_data = await this.webservice.getEmpresa(empresa_id)
    let func_data = await this.webservice.getFuncById(data.id)
    this.setState({
      central_data: central_data,
      empresa_data: empresa_data,
      user_data: user_data,
      func_data: func_data[0],
      empresa_latitude: empresa_data.latitude,
      empresa_longitude: empresa_data.longitude
    })

    if (central_data) {
      let valores = central_data.valores
      let cnl = false
      let prm = false
      if (valores) {
        let prm_check = valores[valores.findIndex((obj) => obj.sigla === 'prm')]
        let cnl_check = valores[valores.findIndex((obj) => obj.sigla === 'cnl')]
        cnl = cnl_check.ativo
        prm = prm_check.ativo
        console.log('checkcnl', [cnl, prm])
      }
      console.log('SELECIONADO', valores)

      if (central_data.tipo_central === 2) {
      }

      await this.setState({
        tipocentral: central_data.tipo_central,
        cnl_ativo: cnl,
        prm_ativo: prm,
        taxiCM: cnl ? 'active' : '',
        taxiEX: cnl ? '' : 'active'
      })
    }
    console.log('props empresa', empresa_data)
    if (empresa_data) {
      let empresa = empresa_data
      let telefoneempresa = empresa.telefone
      console.log('props empresa', empresa)
      let limitecarros = empresa.limitecarros
      let solicitarpreferencias = empresa.solicitarpreferencias
      await this.setState({
        telefoneempresa: telefoneempresa,
        limitecarros: limitecarros,
        solicitarpreferencias: solicitarpreferencias
      })
    }
    if (this.state.func_data) {
      let func_data_mod = this.state.func_data
      if (!func_data_mod.valorlimit) {
        func_data_mod.valorlimit = 0
      }
      if (func_data_mod.valorlimit === 0) {
        func_data_mod.valorlimit = 100000
      }

      let depz = []
      let depzs = 0
      for (var dp in this.state.departamentoss) {
        let pz = this.state.departamentoss[dp]
        if (
          (func_data_mod.departamentos &&
            func_data_mod.departamentos.indexOf(pz.id) > -1) ||
          this.state.role < 7
        ) {
          depz.push(pz)
        }
      }
      console.log('this.state.departamentoss', this.state.departamentoss)
      if (depz.length > 0) {
        depzs = depz[0].id
      } else {
        depz.push({
          calendar: null,
          empresa: 0,
          id: 0,
          identificacao: 'NENHUM',
          text: 'NENHUM',
          valorlimit: null
        })
        depzs = 0
      }
      if (func_data_mod.departamentos.length > 0) {
        depzs = func_data_mod.departamentos[0]
      }
      console.log('Change 1', depzs)
      console.log('Change 1', this.state.selection)
      // if (this.state.selection > 0) {
      //   if (func_data_mod.departamentos.indexOf(this.state.selection) > -1 || this.state.role < 7) {
      //     depzs = this.state.selection;
      //   }
      // }
      console.log('departamento', this.state.func_data)

      this.setState({
        telefone: this.state.func_data.voucher_usuarios_telefone
          ? this.state.func_data.voucher_usuarios_telefone
          : empresa_data.telefone,
        passageiro: this.state.func_data.voucher_usuarios_nome,
        suggestion: func_data_mod,
        sugest_nomeFunc: this.state.func_data.voucher_usuarios_nome,
        selection: depzs,
        departamentosz: depz,
        mostrardetalhesuser:
          this.state.func_data.voucher_usuarios_email ===
          'paluch.tiago@gmail.com'
            ? true
            : false
      })
    }
    // if (this.props.location.state && this.props.location.state.dados) {
    //   if (this.props.location.state.dados.selected) {
    //     let selecionado = this.props.location.state.dados.selected;
    //
    //
    //     let copyDestinos = this.state.destinos
    //     copyDestinos[0].numeroDestino = selecionado.destino_numero
    //     copyDestinos[0].cidadeDestino = selecionado.destino_cidade
    //     copyDestinos[0].destino = selecionado.destino_endereco
    //     copyDestinos[0].lat = selecionado.destino_latitude
    //     copyDestinos[0].lng = selecionado.destino_longitude
    //     this.setState({
    //       selecionado: true,
    //       destinoLatLng: selecionado.destino_latitude ? { lat: selecionado.destino_latitude, lng: selecionado.destino_longitude } : { lat: '', lng: '' },
    //       origemLatLng: { lat: selecionado.origem_latitude, lng: selecionado.origem_longitude },
    //       telefone: selecionado.telefone,
    //       passageiro: selecionado.passageiro,
    //       origem: selecionado.origem_endereco,
    //       numero: selecionado.origem_numero,
    //       referencia: selecionado.referencia,
    //       destino: selecionado.destino_endereco,
    //       numeroDestino: selecionado.destino_numero,
    //       cidadeDestino: selecionado.destino_cidade,
    //       pagtoSelected: [parseInt(selecionado.formadepagamento, 10)],
    //       destinos: copyDestinos,
    //     }, () => {
    //       if (selecionado.destino_endereco !== '') {
    //         this.setState({ haveDestino: true });
    //         console.log('have prices')
    //         this.calculaValor();
    //       } else {
    //         this.setState({ haveDestino: false });
    //         console.log('not have prices')
    //       }
    //     })
    //   } else {
    //     let split = this.props.location.state.dados.endereco.split(", ");
    //     let numero = split[1];
    //     let telefone = this.props.childProps.user.user.telefone;
    //     console.log('telefoneempresa',telefone)
    //     console.log('telefoneempresa',this.state.telefoneempresa)
    //     this.setState({
    //       telefone: this.props.childProps.user.user.telefone ? this.props.childProps.user.user.telefone : this.state.telefoneempresa,
    //       passageiro: this.props.childProps.user.user.nome,
    //       address: this.props.location.state.dados.endereco,
    //       origem: split[0],
    //       numero: numero.match("[0-9]+") ? numero : '',
    //       cidade: !numero.match("[0-9]+") ? numero : split[2],
    //       origemLatLng: this.props.location.state.dados.latlng,
    //     })
    //   }
    //   this.props.history.replace({
    //     pathname: '/pedir-taxi',
    //     state: {}
    //   });
    // }
  }

  setTipoTaxi() {
    if (this.state.taxiCM) this.setState({ tipoTaxi: 'comum' })
    if (this.state.taxiEX) this.setState({ tipoTaxi: 'executivo' })
  }

  setTipoCor() {
    if (this.state.tipo_cor) {
    }
  }
  componentDidMount() {
    let empresaID = this.webservice.getCookie('empresa-id')
    
    this.webservice.getEmpresa(empresaID).then((result) => {
      
      let dayOfTheWeek = moment().format('d')
      let hourOfTheDay = moment().hour()
      if (
        (dayOfTheWeek >= 1 &&
          dayOfTheWeek <= 5 &&
          hourOfTheDay >= 6 &&
          hourOfTheDay < 20) ||
        (dayOfTheWeek === 6 && hourOfTheDay >= 6 && hourOfTheDay < 13)
      ) {
        this.setState({
          comissao: result.comissao ? result.comissao.toFixed(2) : 0,
          km: result.km_b1,
          band: result.band_b1,
          valorMin: result.valor_minimo,
          taxaRet: result.taxa_retorno,
          desc_v: result.desconto
        })
      } else {
        this.setState({
          comissao: result.comissao ? result.comissao.toFixed(2) : 0,
          km: result.km_b2,
          band: result.band_b2,
          valorMin: result.valor_minimo,
          taxaRet: result.taxa_retorno,
          desc_v: result.desconto
        })
      }
      if (result.maximodestinos) {
        this.setState({ maximodestinos: result.maximodestinos })
      } else {
        this.setState({ maximodestinos: 3 })
      }
    })
    this.webservice.getOptions('pagto').then((result) => {
      console.log('pagtoSelected', result)
      this.setState({ pagtoOptions: result })
    })
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = []

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value)
    }

    return values
  }

  //Save the date selected from DatePicker
  dateHandleChange(date) {
    this.setState({
      dataAgend: date,
      horaAgend: moment()
    })
  }

  async calculaValor() {
    let departamento = Number(this.state.selection)
    let funcionario = this.state.suggestion.id
    let waypoints = []

    waypoints.push({
      latitude: this.state.origemLatLng.lat,
      longitude: this.state.origemLatLng.lng,
    })

    if (this.state.destinos.length > 0) {
      for (let tam = 0; tam < this.state.destinos.length; tam++) {
        let newObj = {
          latitude: this.state.destinos[tam].lat,
          longitude: this.state.destinos[tam].lng
        }
          waypoints.push(newObj)
      }
    }
    let dados = await this.webservice.calculaValorApi(funcionario, departamento, waypoints)
    this.setState({
      api_waypoints: waypoints
    })
    if (dados.valores) {
      this.setState({
        api_valores: dados.valores,
        api_rotas: dados.rota,
      })
    }
  }

  async sendTaxi() {
    await this.setState({
      podepedir: false
    })
    this.pedir = setTimeout(this.newSendTaxiReal, 2000)
  }

  async newSendTaxiReal() {
    let email = this.state.suggestion.voucher_usuarios_email
    let solicitante = this.state.func_data.id
    let funcionario =  this.state.suggestion.id
    let telefone = this.state.telefone
    let nome = this.state.passageiro

    let observacoes = this.state.referencia
    let numero_carros = this.state.qtdCorridas
    let departamento = Number(this.state.selection)
    let pgto = this.state.pagtoSelectedApi
    let waypoints = []

    waypoints.push({
      latitude: this.state.origemLatLng.lat,
      longitude: this.state.origemLatLng.lng,
      obs: !!this.state.complemento ? this.state.complemento : '',
      linha: this.state.fullAddress
    })

    if (this.state.destinos.length > 0) {
      for (let tam = 0; tam < this.state.destinos.length; tam++) {
        let newObj = {
          latitude: this.state.destinos[tam].lat,
          longitude: this.state.destinos[tam].lng,
          linha: this.state.destinos[tam].fullAddress,
          obs: (!!this.state.destinos[tam].complemento ? this.state.destinos[tam].complemento + ' ' : '') + (!!this.state.destinos[tam].obs ? this.state.destinos[tam].obs : '')
        }
        //if (this.state.tipocentral === 1) {
          waypoints.push(newObj)
        //}
      }
    }
    var dataCompleta = null
    if (this.state.agendCheck) {
      let date = moment(this.state.dataAgend).format('YYYY-MM-DD')
      let hour = this.state.horaAgend.format('HH:mm')

      dataCompleta = moment(date + 'T' + hour + ':00')
        .utc()
        .format('YYYY-MM-DD HH:mm')
    }

    let corrida = await this.webservice.solicitarApi(funcionario, solicitante, departamento, waypoints, pgto, telefone, nome, email, numero_carros, 1, observacoes, dataCompleta)

    console.log('corridanew api', corrida)

    if (this.state.favCheck) {
      console.log('fav_origem', this.state.origemautocomplete)
      console.log('fav_destino', this.state.destinos)
      if (!this.state.origemautocomplete.favorito) {
        let auto = this.state.origemautocomplete
        auto.favorito = true
        let origem = {
          nome: auto.shortAddress,
          endereco: auto.fullAddress,
          autocomplete: auto,
          tagtipe: 0,
          usuario: this.props.childProps.user.id,
          uuid: ''
        }
        let dados = await this.webservice.criarTipo('favoritos', origem)
        dados = dados.json()
        console.log('favoritos origem', dados)
      }
      for (let index = 0; index < this.state.destinos.length; index++) {
        const element = this.state.destinos[index]
        if (!element.autocomplete.favorito) {
          let auto = element.autocomplete
          auto.favorito = true
          let destino = {
            nome: auto.shortAddress,
            endereco: auto.fullAddress,
            autocomplete: auto,
            tagtipe: 0,
            usuario: this.props.childProps.user.id,
            uuid: ''
          }
          let dados = await this.webservice.criarTipo('favoritos', destino)
          dados = dados.json()
          console.log('favoritos destino', dados)
        }
      }
    }


    if (!!corrida && corrida.result) {
      this.props.history.push({
        pathname: '/corrida-registrada',
        state: { id: corrida.solicitacao }
      })
    } else {
      console.log('OCORREU UM ERRO', corrida)
      this.setState({
        alertDisplay: 'alert-danger',
        alertText: 'OCORREU UM ERRO',
        podepedir: true
      })
    }

  }

  async sendTaxiReal() {
    if (this.state.telefone !== '') {
      this.setState({ validation: 'has-success' })
    } else {
      this.setState({
        alertDisplay: 'alert-danger',
        alertText: 'Por favor preencha seu telefone!',
        podepedir: true
      })
      this.setState({ validation: 'has-error' })
      return
    }

    if (this.state.passageiro !== '') {
      this.setState({ validation: 'has-success' })
    } else {
      this.setState({
        alertDisplay: 'alert-danger',
        alertText: 'Por favor preencha o passageiro!',
        podepedir: true
      })
      this.setState({ validation: 'has-error' })
      return
    }

    if (this.state.origem === '') {
      this.props.history.push('/pedir-taxi')
      return
    }

    if (this.state.empresa_data.destino_obrigatorio) {
      if (this.state.destinos[0].destino === '') {
        this.setState({
          alertDisplay: 'alert-danger',
          alertText: 'Por favor preencha o destino!',
          podepedir: true
        })
        return
      }
    }

    //this.calculaValor()

    let meuprices = this.state.prices[this.state.tiposelecionado]
    let codigos = []

    codigos.push(this.state.tiposelecionado)

    if (this.state.tipo_cor) {
      let a = []
      a.push(101)
      await this.setState({ corSelected: a })
    } else {
      let a = []
      a.push(10)
      await this.setState({ corSelected: a })
    }

    if (this.state.pagtoSelected.length < 1) {
      this.setState({
        alertDisplay: 'alert-danger',
        alertText: 'Por favor selecione o metodo de pagamento!',
        podepedir: true
      })
      return
    }

    console.log('calcular', this.state.pagtoSelected)
    if (Number(this.state.pagtoSelected) === 112) {
      if (this.state.prices.vet) {
        meuprices = this.state.prices.vet
      }
    }
    var dataCompleta = null
    if (this.state.agendCheck) {
      let date = moment(this.state.dataAgend).format('YYYY-MM-DD')
      let hour = this.state.horaAgend.format('HH:mm')

      dataCompleta = moment(date + 'T' + hour + ':00')
        .utc()
        .format('YYYY-MM-DD HH:mm')

      this.setState({ dataCompleta: dataCompleta })
    }

    if (this.state.favCheck) {
      console.log('fav_origem', this.state.origemautocomplete)
      console.log('fav_destino', this.state.destinos)
      if (!this.state.origemautocomplete.favorito) {
        let auto = this.state.origemautocomplete
        auto.favorito = true
        let origem = {
          nome: auto.shortAddress,
          endereco: auto.fullAddress,
          autocomplete: auto,
          tagtipe: 0,
          usuario: this.props.childProps.user.id,
          uuid: ''
        }
        let dados = await this.webservice.criarTipo('favoritos', origem)
        dados = dados.json()
        console.log('favoritos origem', dados)
      }
      for (let index = 0; index < this.state.destinos.length; index++) {
        const element = this.state.destinos[index]
        if (!element.autocomplete.favorito) {
          let auto = element.autocomplete
          auto.favorito = true
          let destino = {
            nome: auto.shortAddress,
            endereco: auto.fullAddress,
            autocomplete: auto,
            tagtipe: 0,
            usuario: this.props.childProps.user.id,
            uuid: ''
          }
          let dados = await this.webservice.criarTipo('favoritos', destino)
          dados = dados.json()
          console.log('favoritos destino', dados)
        }
      }
    }
    console.log('this.state.pagtoSelected', this.state.pagtoSelected)
    let enderecoscomponentes = {
      origem: this.state.origemautocomplete,
      destino: this.state.destinos[this.state.destinos.length - 1].autocomplete
    }

    if (
      this.state.pagtoSelected.indexOf('112') > -1 ||
      this.state.pagtoSelected.indexOf(112) > -1
    ) {
      let novoVetorDestino = []
      let mydest = this.state.destinos
      console.log('MEU VETORZAO 1', mydest)

      if (this.state.destinos.length > 0) {
        for (let tam = 0; tam < this.state.destinos.length; tam++) {
          let newObj = {
            ...mydest[tam],
            key: 'destInt' + tam,
            order: tam,
            Address: {
              fullAddress: mydest[tam].fullAddress,
              shortAddress: mydest[tam].destino,
              autocomplete: mydest[tam].autocomplete
            },
            latitude: mydest[tam].lat,
            longitude: mydest[tam].lng
          }
          //if (this.state.tipocentral === 1) {
          novoVetorDestino.push(newObj)
          //}
        }
      }
      // if (this.state.tipocentral === 1) {
      //   if (novoVetorDestino.length < 2) {
      //     novoVetorDestino.pop();
      //   }
      // }
      let enderecoscomponentes = {
        origem: this.state.origemautocomplete,
        destino:
          this.state.destinos[this.state.destinos.length - 1].autocomplete
      }
      if (this.state.retornoOriginalCheck) {
        novoVetorDestino.push({
          Address: {
            fullAddress: this.state.origemautocomplete.fullAddress,
            shortAddress: this.state.origem,
            autocomplete: this.state.origemautocomplete
          },
          autocomplete: this.state.origemautocomplete,
          bairro: this.state.bairro,
          cidade: this.state.cidade,
          estado: this.state.estado,
          estadoDestino: this.state.estado,
          numeroDestino: this.state.numero,
          bairroDestino: this.state.bairro,
          cidadeDestino: this.state.cidade,
          destino: this.state.origem,
          fullAddress: this.state.origemautocomplete.fullAddress,
          latitude: this.state.origemLatLng.lat,
          lat: this.state.origemLatLng.lat,
          longitude: this.state.origemLatLng.lng,
          lng: this.state.origemLatLng.lng,
          contato: this.state.passageiro,
          obs: 'retorno ao remetente',
          parada: false,
          retorno: true,
          key: 'destInt' + novoVetorDestino.length,
          order: novoVetorDestino.length
        })
      }
      console.log('MEU VETORZAO', novoVetorDestino)
      if (this.state.suggestion.id) {
        /*console.log("soma 1", this.state.suggestion.valorlimit)
        console.log("soma 1.2", this.props.corrida.valorcalc)
        console.log("soma 2", Number(this.state.suggestion.saldo_gasto) + Number(this.props.corrida.valorcalc))
        console.log("RESULTADO", Number(this.state.suggestion.valorlimit) > Number(this.state.suggestion.saldo_gasto) + Number(this.props.corrida.valorcalc))
        console.log("VATNCAERASD", Number(this.state.suggestion.valorlimit) > (Number(this.state.suggestion.saldo_gasto) + Number(this.props.corrida.valorcalc)) || this.funcDep(this.state.selection))
        console.log("HUEHUEHUE", this.funcDep(this.state.selection))*/
        console.log('dia da semana', moment().isoWeekday())
        console.log('meu departamento', this.state.selection)

        //VALORCALC
        let valorCalculado = 0
        console.log('meuprices', [
          this.state.tiposelecionado,
          this.state.pagtoSelected,
          meuprices
        ])
        let priceusar = meuprices.comissoes.c01
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet
        }
        valorCalculado = priceusar.valorComtaxas
        //VALORCALC

        if (
          Number(this.state.suggestion.valorlimit) >
            Number(this.state.suggestion.saldo_gasto) +
              Number(this.state.valorCorridaFloat) ||
          this.funcDep(this.state.selection)
        ) {
          if (this.BlockDay() || this.state.suggestion.calendar === null) {
            this.webservice
              .orderTaxi({
                caridade: this.props.childProps.func.caridade,
                destinos: novoVetorDestino,
                enderecoscomponentes: enderecoscomponentes,
                emailcliente: this.state.suggestion.voucher_usuarios_email,
                voucher_funcionario_solicitante: this.state.func_data.id,
                voucher_funcionario: this.state.suggestion.id,
                telefone: this.state.telefone,
                nome: this.state.passageiro,
                endereco: this.state.origem,
                numero: this.state.numero,
                codigos: codigos.join(' ').toUpperCase().trim(),
                latitude: this.state.origemLatLng.lat,
                longitude: this.state.origemLatLng.lng,
                enderecoDestino:
                  this.state.destinos[this.state.destinos.length - 1].destino,
                numeroDestino:
                  this.state.destinos[this.state.destinos.length - 1]
                    .numeroDestino,
                cidadeDestino: this.state.cidadeDestino,
                bairroDestino: this.state.bairroDestino,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                estadodestino: this.state.estadodestino,
                latitudeDestino:
                  this.state.destinoLatLng.lat === ''
                    ? null
                    : this.state.destinoLatLng.lat,
                longitudeDestino:
                  this.state.destinoLatLng.lng === ''
                    ? null
                    : this.state.destinoLatLng.lng,
                tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
                tabelavalores: meuprices,
                valorTabelada: valorCalculado,
                data_hora_agendamento: dataCompleta,
                observacoes: this.state.referencia,
                complemento: this.state.complemento,
                antecipar: parseInt(this.state.antecipar),
                atendente: 'Portal',
                numero_carros: this.state.qtdCorridas,
                departamento: Number(this.state.selection)
              })
              .then(async (result) => {
                if (result.ok) {
                  result = await result.json()
                  console.log('corridanew', result)
                  this.props.history.push({
                    pathname: '/corrida-registrada',
                    state: { id: result.solicitacao }
                  })
                } else {
                  //result = await result.json();
                  console.log('Ocorreu um erro', result)
                  let erroextra = ''
                  if (result.err) {
                    erroextra = ' : ' + result.err
                  }
                  this.setState({
                    alertDisplay: 'alert-danger',
                    alertText: 'Ocorreu um erro' + erroextra,
                    podepedir: true
                  })
                }
              })
          } else {
            alert(
              'Não é possivel chamar a corrida fora do período predeterminado!'
            )
          }
        } else {
          alert('Saldo insuficiente para chamar a corrida')
        }
      }
    } else {
      if (
        (this.state.pagtoSelected.indexOf('113') > -1 ||
          this.state.pagtoSelected.indexOf(113) > -1) &&
        this.state.moip
      ) {
        let novoVetorDestino = []
        let mydest = this.state.destinos
        console.log('MEU VETORZAO 1', mydest)

        if (this.state.destinos.length > 0) {
          for (let tam = 0; tam < this.state.destinos.length; tam++) {
            let newObj = {
              ...mydest[tam],
              key: 'destInt' + tam,
              order: tam,
              Address: {
                fullAddress: mydest[tam].fullAddress,
                shortAddress: mydest[tam].destino,
                autocomplete: mydest[tam].autocomplete
              },
              latitude: mydest[tam].lat,
              longitude: mydest[tam].lng
            }
            //if (this.state.tipocentral === 1) {
            novoVetorDestino.push(newObj)
            //}
          }
        }
        // if (this.state.tipocentral === 1) {
        //   if (novoVetorDestino.length < 2) {
        //     novoVetorDestino.pop();
        //   }
        // }
        let enderecoscomponentes = {
          origem: this.state.origemautocomplete,
          destino:
            this.state.destinos[this.state.destinos.length - 1].autocomplete
        }
        if (this.state.retornoOriginalCheck) {
          novoVetorDestino.push({
            Address: {
              fullAddress: this.state.origemautocomplete.fullAddress,
              shortAddress: this.state.origem,
              autocomplete: this.state.origemautocomplete
            },
            autocomplete: this.state.origemautocomplete,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadoDestino: this.state.estado,
            numeroDestino: this.state.numero,
            bairroDestino: this.state.bairro,
            cidadeDestino: this.state.cidade,
            destino: this.state.origem,
            fullAddress: this.state.origemautocomplete.fullAddress,
            latitude: this.state.origemLatLng.lat,
            lat: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            lng: this.state.origemLatLng.lng,
            contato: this.state.passageiro,
            obs: 'retorno ao remetente',
            parada: false,
            retorno: true,
            key: 'destInt' + novoVetorDestino.length,
            order: novoVetorDestino.length
          })
        }

        //VALORCALC
        let valorCalculado = 0
        let priceusar = meuprices.comissoes.c01
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet
        }
        valorCalculado = priceusar.valorComtaxas
        //VALORCALC

        console.log('MEU VETORZAO', novoVetorDestino)
        this.webservice
          .orderTaxi({
            caridade: this.props.childProps.func.caridade,
            destinos: novoVetorDestino,
            enderecoscomponentes: enderecoscomponentes,
            moip: this.state.moip,
            telefone: this.state.telefone,
            nome: this.state.passageiro,
            emailcliente: this.state.suggestion.voucher_usuarios_email,
            voucher_funcionario: this.state.suggestion.id,
            voucher_funcionario_solicitante: this.state.func_data.id,
            endereco: this.state.origem,
            numero: this.state.numero,
            latitude: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            enderecoDestino:
              this.state.destinos[this.state.destinos.length - 1].destino,
            numeroDestino:
              this.state.destinos[this.state.destinos.length - 1].numeroDestino,
            cidadeDestino: this.state.cidadeDestino,
            bairroDestino: this.state.bairroDestino,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadodestino: this.state.estadodestino,
            latitudeDestino:
              this.state.destinoLatLng.lat === ''
                ? null
                : this.state.destinoLatLng.lat,
            longitudeDestino:
              this.state.destinoLatLng.lng === ''
                ? null
                : this.state.destinoLatLng.lng,
            tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
            codigos: codigos.join(' ').toUpperCase().trim(),
            tabelavalores: meuprices,
            valorTabelada: valorCalculado,
            data_hora_agendamento: dataCompleta,
            observacoes: this.state.referencia,
            complemento: this.state.complemento,
            antecipar: parseInt(this.state.antecipar),
            atendente: 'Portal',
            numero_carros: this.state.qtdCorridas
          })
          .then(async (result) => {
            if (result.ok) {
              result = await result.json()
              console.log('corridanew', result)
              this.props.history.push({
                pathname: '/corrida-registrada',
                state: { id: result.solicitacao }
              })
            } else {
              //result = await result.json();
              console.log('OCORREU UM ERRO', result)
              this.setState({
                alertDisplay: 'alert-danger',
                alertText: 'OCORREU UM ERRO',
                podepedir: true
              })
            }
          })
      } else {
        let novoVetorDestino = []
        let mydest = this.state.destinos
        console.log('MEU VETORZAO 1', mydest)

        if (this.state.destinos.length > 0) {
          for (let tam = 0; tam < this.state.destinos.length; tam++) {
            let newObj = {
              ...mydest[tam],
              key: 'destInt' + tam,
              order: tam,
              Address: {
                fullAddress: mydest[tam].fullAddress,
                shortAddress: mydest[tam].destino,
                autocomplete: mydest[tam].autocomplete
              },
              latitude: mydest[tam].lat,
              longitude: mydest[tam].lng
            }
            //if (this.state.tipocentral === 1) {
            novoVetorDestino.push(newObj)
            //}
          }
        }
        // if (this.state.tipocentral === 1) {
        //   if (novoVetorDestino.length < 2) {
        //     novoVetorDestino.pop();
        //   }
        // }
        let enderecoscomponentes = {
          origem: this.state.origemautocomplete,
          destino:
            this.state.destinos[this.state.destinos.length - 1].autocomplete
        }
        if (this.state.retornoOriginalCheck) {
          novoVetorDestino.push({
            Address: {
              fullAddress: this.state.origemautocomplete.fullAddress,
              shortAddress: this.state.origem,
              autocomplete: this.state.origemautocomplete
            },
            autocomplete: this.state.origemautocomplete,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadoDestino: this.state.estado,
            numeroDestino: this.state.numero,
            bairroDestino: this.state.bairro,
            cidadeDestino: this.state.cidade,
            destino: this.state.origem,
            fullAddress: this.state.origemautocomplete.fullAddress,
            latitude: this.state.origemLatLng.lat,
            lat: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            lng: this.state.origemLatLng.lng,
            contato: this.state.passageiro,
            obs: 'retorno ao remetente',
            parada: false,
            retorno: true,
            key: 'destInt' + novoVetorDestino.length,
            order: novoVetorDestino.length
          })
        }
        //console.log("MEU VETORZAO", novoVetorDestino)

        //VALORCALC
        let valorCalculado = 0
        console.log('meuprices', [
          this.state.tiposelecionado,
          this.state.pagtoSelected,
          meuprices
        ])
        let priceusar = meuprices.comissoes.c01
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet
        }
        valorCalculado = priceusar.valorComtaxas
        //VALORCALC

        this.webservice
          .orderTaxi({
            caridade: this.props.childProps.func.caridade,
            destinos: novoVetorDestino,
            enderecoscomponentes: enderecoscomponentes,
            telefone: this.state.telefone,
            nome: this.state.passageiro,
            emailcliente: this.state.suggestion.voucher_usuarios_email,
            voucher_funcionario: this.state.suggestion.id,
            voucher_funcionario_solicitante: this.state.func_data.id,
            endereco: this.state.origem,
            numero: this.state.numero,
            latitude: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            enderecoDestino:
              this.state.destinos[this.state.destinos.length - 1].destino,
            numeroDestino:
              this.state.destinos[this.state.destinos.length - 1].numeroDestino,
            cidadeDestino: this.state.cidadeDestino,
            bairroDestino: this.state.bairroDestino,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadodestino: this.state.estadodestino,
            latitudeDestino:
              this.state.destinoLatLng.lat === ''
                ? null
                : this.state.destinoLatLng.lat,
            longitudeDestino:
              this.state.destinoLatLng.lng === ''
                ? null
                : this.state.destinoLatLng.lng,
            tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
            codigos: codigos.join(' ').toUpperCase().trim(),
            tabelavalores: meuprices,
            valorTabelada: valorCalculado,
            data_hora_agendamento: dataCompleta,
            observacoes: this.state.referencia,
            complemento: this.state.complemento,
            antecipar: parseInt(this.state.antecipar),
            atendente: 'Portal',
            numero_carros: this.state.qtdCorridas
          })
          .then(async (result) => {
            if (result.ok) {
              result = await result.json()
              console.log('corridanew', result)
              this.props.history.push({
                pathname: '/corrida-registrada',
                state: { id: result.solicitacao }
              })
            } else {
              //result = await result.json();
              console.log('OCORREU UM ERRO', result)
              this.setState({
                alertDisplay: 'alert-danger',
                alertText: 'OCORREU UM ERRO',
                podepedir: true
              })
            }
          })
      }
    }
  }

  changeOptions(newAddress) {
    this.setState({ options: newAddress })
  }
  changeEnderecoOrigem(sugestion, focus = false, j) {
    //let origem_endereco = sugestion.fullAddress.split(',');

    // origemLatLng: { lat: '', lng: '' },
    // destinoLatLng: { lat: '', lng: '' },

    this.setState({
      markerLocal: [
        {
          lat: sugestion.latitude,
          lng: sugestion.longitude,
          img_src: null,
          label: sugestion.rua
        }
      ],
      selected: {
        ...this.state.selected,
        origem_endereco: sugestion.fullAddress,
        origem_numero: sugestion.numero,
        origem_bairro: sugestion.bairro,
        origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        origem_cidade: sugestion.cidade
      },
      origem_cidade: sugestion.cidade,
      origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
      origem_bairro: sugestion.bairro
    })
    //if (focus) { ReactDom.findDOMNode(this.refs.numeroOrigem).focus(); } enrerecodestino" + j
  }
  changeEnderecoDestinoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    let splited = newAddress.split(',')
    let parte1 = splited[0]
    //let parte2= number
    this.setState({
      selected: {
        ...this.state.selected,
        destino_endereco: parte1,
        destino_numero: number,
        destino_cidade: newCity
      },
      destino_cidade: newCity
    })
    if (focus) {
      ReactDom.findDOMNode(this.refs.numeroDestino).focus()
    }
  }
  changeEnderecoOrigemExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    console.log('whata a number', number)
    let splited = newAddress.split(',')
    let parte1 = splited[0]
    let parte2 = number
    console.log('whatafuck', parte1)
    console.log('whata', parte2)
    this.setState({
      selected: {
        ...this.state.selected,
        origem_endereco: parte1,
        origem_numero: number,
        origem_cidade: newCity
      },
      origem_cidade: newCity
    })
    if (focus) {
      ReactDom.findDOMNode(this.refs.numeroOrigem).focus()
    }
  }
  changeEnderecoDestino(sugestion, focus = false, j) {
    let origem_endereco = sugestion.fullAddress.split(',')

    // origemLatLng: { lat: '', lng: '' },
    // destinoLatLng: { lat: '', lng: '' },

    this.setState({
      selected: {
        ...this.state.selected,
        origem_endereco: origem_endereco,
        origem_numero: sugestion.numero,
        origem_bairro: sugestion.bairro,
        destinoLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        origem_cidade: sugestion.cidade
      },
      origem_cidade: sugestion.cidade,
      destinoLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
      origem_bairro: sugestion.bairro
    })
    if (focus) {
      ReactDom.findDOMNode(this.refs.numeroDestino).focus()
    }
  }
  changeEnderecoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    let mydest = this.state.destinos
    //let parte1
    //let parte2
    if (!newAddress) {
      newAddress = ''
    }
    console.log('changeEnderecoExtra', [
      newAddress,
      newCity,
      number,
      focus,
      j,
      label,
      sugestion
    ])
    if (Number(j) === 999) {
      //let splited = newAddress.split(',')
      //parte1 = splited[0]
      //parte2 = number ? number : splited[1]
      // endereco: this.state.origem,
      // numero: this.state.numero,
      // latitude: this.state.origemLatLng.lat,
      // longitude: this.state.origemLatLng.lng,
      this.setState({
        origem: newAddress,

        fullAddress: newAddress
      })
    } else {
      for (let a in mydest) {
        console.log('NUMERO AQUI', a)
        console.log('numero aqui 2', j)
        console.log('add', newAddress)
        //let splited = newAddress.split(',')
        //parte1 = splited[0]
        //parte2 = number ? number : splited[1]
        console.log('mydest', mydest[a])
        if (Number(a) === Number(j)) {
          console.log('TO ENTRANDO AQUI CARALHO')
          mydest[a].destino = newAddress
          mydest[a].fullAddress = newAddress
          this.setState({
            destinos: mydest
          })
          if (focus) {
            ReactDom.findDOMNode(this.refs['numeroDestino' + a]).focus()
          }
        }
      }
      this.setState({ destino: newAddress, d_newAddress: newAddress })
    }
  }
  driversAround(lat, lon) {
    this.webservice.driversAround(lat, lon).then((response) => {
      console.log('driversAround 1', response)
      let result = response.result.map((a, b) => {
        return {
          lat: a.latitude,
          lng: a.longitude,
          img_src: null,
          label: a.uuid,
          tipocolaborador: a.tipocolaborador
        }
      })
      console.log('driversAround 2', result)
      this.setState({
        motoristas: result
      })
    })
  }
  changeEndereco(sugestion, focus = false, j) {
    console.log('changeEndereco', sugestion)
    let mydest = this.state.destinos
    let endereco = sugestion.fullAddress.split(',')
    if (Number(j) === 999) {
      this.setState({
        origemautocomplete: sugestion,
        markerLocal: [
          {
            lat: sugestion.latitude,
            lng: sugestion.longitude,
            img_src: null,
            label: sugestion.fullAddress
          }
        ],
        origem: endereco[0],
        numero: sugestion.numero,
        bairro: sugestion.bairro,
        cidade: sugestion.cidade,
        ac_o_pos: [sugestion.latitude, sugestion.longitude],
        origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        fullAddress: sugestion.fullAddress
      })
      this.driversAround(sugestion.latitude, sugestion.longitude)
    } else {
      for (let a in mydest) {
        if (Number(a) === Number(j)) {
          mydest[a].autocomplete = sugestion
          mydest[a].destino = endereco[0]
          mydest[a].numeroDestino = sugestion.numero
          mydest[a].bairroDestino = sugestion.bairro
          mydest[a].cidadeDestino = sugestion.cidade
          mydest[a].fullAddress = sugestion.fullAddress
          mydest[a].ac_pos = [sugestion.latitude, sugestion.longitude]
          mydest[a].lng = sugestion.longitude
          mydest[a].lat = sugestion.latitude
          mydest[a].latitude = sugestion.latitude
          mydest[a].longitude = sugestion.longitude
          //this.refs['numeroDestino' + a].focus();
        }
      }

      this.setState({
        destino: mydest[mydest.length - 1].destino,
        destinos: mydest,
        numeroDestino: mydest[mydest.length - 1].numeroDestino,
        bairroDestino: mydest[mydest.length - 1].bairroDestino,
        cidadeDestino: mydest[mydest.length - 1].cidadeDestino,
        d_fullAddress: mydest[mydest.length - 1].fullAddress,
        destinoLatLng: {
          lat: mydest[mydest.length - 1].latitude,
          lng: mydest[mydest.length - 1].lng
        }
      })
    }
    //if (focus) { ReactDom.findDOMNode(this.refs.numeroDestino).focus(); }
    setTimeout(() => {
      this.calculaValor()
    }, 2000)
    setTimeout(() => {
      this.organizaMarquerDestinos()
    }, 2000)
  }
  async organizaMarquerDestinos() {
    let marques = []
    let destinos = this.state.destinos

    for (let x = 0; x < destinos.length; x++) {
      const e = destinos[x]
      marques.push({
        lat: e.latitude,
        lng: e.longitude,
        img_src: null,
        label: e.fullAddress
      })
      
    }

    await this.setState({ markerDest: marques })
  }
  async getLatLng() {}
  hideDadosIUGU() {
    this.setState({ showCartao: false })
  }
  modalEditHide() {
    this.setState({ showEditFavoritos: false })
  }
  setDadosIugu(dados, token, cpf, email, prod) {
    let tel = String(this.state.telefone)
    this.setState(
      {
        moip: {
          fone: {
            cpf: cpf,
            fim: tel.substring(0, 2),
            ini: tel.substring(2, this.state.telefone.length)
          },
          token: token,
          cartao: {
            nomecartao: dados.first_name + ' ' + dados.last_name,
            emailcliente: email
          },
          executado: 0,
          producao: prod
        }
      },
      () => {
        console.log(this.state.moip)
      }
    )
  }
  modalFavoritoHide() {
    this.setState({ showfavmodal: false })
  }
  //Sets the center and the zoom between local position and destination
  async getPrices() {
    this.calculaValor()
    if (this.state.origemLatLng.lat === '') {
      return
    }
    if (this.state.origemLatLng.lng === '') {
      return
    }
    if (this.state.destinoLatLng.lat === '') {
      return
    }
    if (this.state.destinoLatLng.lng === '') {
      return
    }

    const bounds = {
      ne: {
        lat: this.state.origemLatLng.lat,
        lng: this.state.origemLatLng.lng
      },
      sw: {
        lat: this.state.destinoLatLng.lat,
        lng: this.state.destinoLatLng.lng
      }
    }

    if (this.state.origemLatLng.lat > bounds.ne.lat) {
      bounds.ne.lat = this.state.origemLatLng.lat
    }
    if (this.state.origemLatLng.lng > bounds.ne.lng) {
      bounds.ne.lng = this.state.origemLatLng.lng
    }
    if (this.state.origemLatLng.lat < bounds.sw.lat) {
      bounds.sw.lat = this.state.origemLatLng.lat
    }
    if (this.state.origemLatLng.lng < bounds.sw.lng) {
      bounds.sw.lng = this.state.origemLatLng.lng
    }

    if (this.state.destinoLatLng.lat !== '') {
      if (this.state.destinoLatLng.lat > bounds.ne.lat) {
        bounds.ne.lat = this.state.destinoLatLng.lat
      }
      if (this.state.destinoLatLng.lng > bounds.ne.lng) {
        bounds.ne.lng = this.state.destinoLatLng.lng
      }
      if (this.state.destinoLatLng.lat < bounds.sw.lat) {
        bounds.sw.lat = this.state.destinoLatLng.lat
      }
      if (this.state.destinoLatLng.lng < bounds.sw.lng) {
        bounds.sw.lng = this.state.destinoLatLng.lng
      }
    }

    let origen = {
      origem: this.state.origem,
      numero: this.state.numero,
      cidade: this.state.cidade,
      fullAddress: this.state.fullAddress,
      obs: this.state.referencia,
      lat: this.state.origemLatLng.lat,
      lng: this.state.origemLatLng.lng
    }

    this.webservice
      .calcula(
        origen,
        this.state.destinoLatLng,
        this.state.destinos,
        null,
        this.state.retornoOriginalCheck
      )
      .then((result) => {
        let org = []
        let pricesTransporte = result.valores.transporte
        console.log('*prices', pricesTransporte)

        if (this.state.tipocentral === 2) {
          if (this.state.tiposolicitacao === 1) {
            pricesTransporte.pop.origemdatabela = 'padrão'
            pricesTransporte.mot.origemdatabela = 'padrão'
          }
          if (this.state.tiposolicitacao === 2) {
            pricesTransporte.cnl.origemdatabela = 'padrão'
            pricesTransporte.pop.origemdatabela = 'padrão'
          }
          if (this.state.tiposolicitacao === 3) {
            pricesTransporte.cnl.origemdatabela = 'padrão'
            pricesTransporte.mot.origemdatabela = 'padrão'
          }
        }

        let points = result.pontos

        if (pricesTransporte.vet.origemdatabela === 'padrão') {
          delete pricesTransporte.vet
        } else {
          org.push('vet')
        }
        if (pricesTransporte.cnl.origemdatabela === 'padrão') {
          delete pricesTransporte.cnl
        } else {
          org.push('cnl')
        }
        if (pricesTransporte.prm.origemdatabela === 'padrão') {
          delete pricesTransporte.prm
        } else {
          org.push('prm')
        }
        if (pricesTransporte.ldy.origemdatabela === 'padrão') {
          delete pricesTransporte.ldy
        } else {
          org.push('ldy')
        }

        if (pricesTransporte.mot.origemdatabela === 'padrão') {
          delete pricesTransporte.mot
        } else {
          org.push('mot')
        }
        if (pricesTransporte.cdo.origemdatabela === 'padrão') {
          delete pricesTransporte.cdo
        } else {
          org.push('cdo')
        }
        if (pricesTransporte.c15.origemdatabela === 'padrão') {
          delete pricesTransporte.c15
        } else {
          org.push('c15')
        }
        if (pricesTransporte.pop.origemdatabela === 'padrão') {
          delete pricesTransporte.pop
        } else {
          org.push('pop')
        }

        this.setState({
          prices: pricesTransporte,
          c_points: points,
          cidadeDestino: result.cidadeFim,
          cidade: result.cidadeInic,
          estado: result.estadoInic,
          estadodestino: result.estadoFim
        })

        if (org.length > 0) {
          this.setTipoPagamento(org[0])
        }
      })

    //Set the distance from local position to destination
    // let testandoaqui = await this.webservice.verificaTabela(this.state.origemLatLng.lat, this.state.origemLatLng.lng, this.state.destinoLatLng.lat, this.state.destinoLatLng.lng)
    // console.log("MEU TESTE API", testandoaqui)
    // if(testandoaqui.length > 0){
    //   let myprice = testandoaqui[0].valor.toFixed(2)
    //   console.log('meu preço', myprice)
    //   let finalprice = myprice.toString().replace(".", ",")
    //   console.log("meu fim", finalprice)
    //   this.setState({
    //     valorNormal: 'R$ ' + myprice,
    //     valorCorridaFloat: myprice,
    //   })
    // }else{
    //   this.webservice.directions(this.state.origemLatLng.lat, this.state.origemLatLng.lng, this.state.destinoLatLng.lat, this.state.destinoLatLng.lng, this.state.destinos).then(result => {
    //     this.setState({ distance: result.text })
    //     this.setState({ distanceValue: result.value }, () => {
    //       const bandeirada = this.state.band;
    //       const valorKM = this.state.km;
    //       let distanceFinal = 0
    //       console.log("QUERO SABER O QUE É", result)
    //       if (Object.keys(result).length !== 0) {
    //         let rotas = result.routes[0].legs
    //         for (let i in rotas) {
    //           distanceFinal = distanceFinal + rotas[i].distance.value
    //         }
    //         console.log('TAXA RETORNO ', this.state.taxaRet)
    //
    //         const dist = distanceFinal / 1000;
    //         let valorCorrida = bandeirada + (dist * valorKM);
    //         console.log("MEU VALOR DA CORRID", valorCorrida)
    //         if (valorCorrida < this.state.valorMin) { valorCorrida = this.state.valorMin }
    //
    //         if (this.state.cidade !== this.state.cidadeDestino) {
    //           if (this.state.taxaRet.taxaRetornoFixo && this.state.taxaRet.taxaRetornoFixo === 0) {
    //             valorCorrida += valorCorrida * (this.state.taxaRet.taxaRetornPerc / 100);
    //           } else {
    //             valorCorrida += this.state.taxaRet.taxaRetornoFixo;
    //           }
    //         }
    //
    //         valorCorrida = Number(valorCorrida) + Number(this.state.comissao)
    //         console.log("valor da corrida pos comissao", valorCorrida)
    //         let valorCorridaFloat = valorCorrida.toFixed(2);
    //         valorCorrida = valorCorridaFloat + "";
    //
    //         valorCorrida = valorCorrida.replace('.', ',');
    //
    //         this.setState({
    //           valorNormal: 'R$ ' + valorCorrida,
    //           valorCorridaFloat: valorCorridaFloat,
    //         })
    //       }
    //
    //     })
    //   })
    // }
  }
  async handleChangeDados(e) {
    await this.setState({ retornoOriginalCheck: e.target.checked })
    this.calculaValor()
  }
  handleChange() {
    console.log('opaopa', this.inputEl.value)
    this.setState({ selection: this.inputEl.value })
  }
  manageShortcut(e) {
    let code = e.keyCode ? e.keyCode : e.which
    console.log('manageShortcut', code)
    console.log('manageShortcut', e)
  }
  onKeyUp(keyNm, e, handle) {
    //console.log('keyNm',keyNm)
    //this.setState({output: keyNm})
  }
  onKeyDown(keyNm, e, handle) {
    //console.log('keyNm',keyNm)
    ///this.setState({output: keyNm})
    if (keyNm === 'esc') {
      this.newPag()
    }
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js')
    moment.locale('pt-br')

    let estitoicon = 'fa fa-taxi'
    let cor1 = 'black'
    let cor2 = 'white'
    let cor3 = 'black'
    if (this.props.childProps.cores) {
      cor1 = this.props.childProps.cores.background
        ? this.props.childProps.cores.background
        : 'black'
      cor2 = this.props.childProps.cores.foreground
        ? this.props.childProps.cores.foreground
        : 'white'
        cor3 = this.props.childProps.cores?.terceary ? this.props.childProps.cores?.terceary : 'black'
    }
    return (
      <div className='page-content-wrapper'>
        <Hotkeys
          keyName='esc'
          onKeyDown={this.onKeyDown.bind(this)}
          onKeyUp={this.onKeyUp.bind(this)}
        ></Hotkeys>
        <div className='page-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-9'>
                <ul className='page-breadcrumb breadcrumb'>
                  <li>
                    <i className={estitoicon}></i>&nbsp;
                    <Link to='/pedir-taxi'>Solicitação</Link>&nbsp;
                    <i className='fa fa-angle-right'></i>
                  </li>
                </ul>
              </div>
              {/*{false && (
                <div className='col-md-1'>
                  <button
                    type='button'
                    className='btn red-mint btn-circle'
                    onClick={() => {
                      this.setState({ showfavmodal: true })
                    }}
                  >
                    Favoritos
                  </button>
                </div>
                  )}*/}
              {true && (
                <div className='col-md-1'>
                  <BotaoLimpar
                    as='button'
                    onClick={() => {
                      this.newPag()
                    }}
                  >
                    Novo (esc)
                  </BotaoLimpar>

                  {/*<button
                    type='button'
                    className='btn red-mint btn-circle'
                    onClick={() => {
                      this.newPag()
                    }}
                  >
                    Novo (esc)
                  </button>*/}
                </div>
              )}
            </div>

            <div className='page-content-inner'>
              <div className='mt-content-body'>
                <div className='row'>
                  <div
                    className={
                      this.state.showColapseMap ? 'col-md-11' : 'col-md-8'
                    }
                  >
                    <div className='portlet light'>
                      <div className={'alert ' + this.state.alertDisplay}>
                        <button
                          className='close'
                          onClick={() =>
                            this.setState({ alertDisplay: 'display-hide' })
                          }
                        ></button>
                        <span>{this.state.alertText}</span>
                      </div>

                      <div className='portlet-body'>
                        <div className='form-horizontal'>
                          <div className='form-body'>
                            {this.state.showColapseSolicitante && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideSolicitante()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Solicitante</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineDown'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {!this.state.showColapseSolicitante && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideSolicitante()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Solicitante</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineUp'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    'form-group form-md-line-input ' +
                                    this.state.validation
                                  }
                                >
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Solicitante
                                    <span
                                      className='required'
                                      aria-required='true'
                                    >
                                      {' '}
                                      *
                                    </span>
                                  </label>
                                  <div className='col-md-3'>
                                    <div className='autoComp func-autocomp'>
                                      <Autosuggest
                                        ref={'autocomplete-usuario'}
                                        highlightFirstSuggestion={true}
                                        focusInputOnSuggestionClick={false}
                                        suggestions={this.state.sugest_funcs}
                                        onSuggestionSelected={(
                                          e,
                                          { suggestion, method }
                                        ) => {
                                          console.log('SUGESTAO', suggestion)
                                          let func_data_mod = suggestion
                                          if (!func_data_mod.valorlimit) {
                                            func_data_mod.valorlimit = 0
                                          }
                                          if (func_data_mod.valorlimit === 0) {
                                            func_data_mod.valorlimit = 100000
                                          }
                                          let depz = []
                                          let depzs = 0
                                          for (var dp in this.state
                                            .departamentoss) {
                                            let pz =
                                              this.state.departamentoss[dp]
                                            if (
                                              (func_data_mod.departamentos &&
                                                func_data_mod.departamentos.indexOf(
                                                  pz.id
                                                ) > -1) ||
                                              this.state.role < 7
                                            ) {
                                              depz.push(pz)
                                            }
                                          }

                                          if (depz.length > 0) {
                                            depzs = depz[0].id
                                          } else {
                                            depz.push({
                                              calendar: null,
                                              empresa: 0,
                                              id: 0,
                                              identificacao: 'NENHUM',
                                              text: 'NENHUM',
                                              valorlimit: null
                                            })
                                            depzs = 0
                                          }
                                          if (
                                            func_data_mod.departamentos.length >
                                            0
                                          ) {
                                            depzs =
                                              func_data_mod.departamentos[0]
                                          }
                                          console.log('Change 2 depzs', depzs)
                                          console.log(
                                            'Change 2 this.state.selection',
                                            this.state.selection
                                          )
                                          console.log(
                                            'Change 2 func_data_mod.departamentos',
                                            func_data_mod.departamentos
                                          )
                                          this.setState({
                                            suggestion: func_data_mod,
                                            selection: depzs,
                                            departamentosz: depz
                                          })
                                        }}
                                        onSuggestionsFetchRequested={({
                                          value
                                        }) => {
                                          this.webservice
                                            .getFuncsByName(value)
                                            .then((result) => {
                                              this.setState({
                                                sugest_funcs: result
                                              })
                                            })
                                        }}
                                        onSuggestionsClearRequested={() => {
                                          this.setState({ sugest_funcs: [] })
                                        }}
                                        getSuggestionValue={(item) =>
                                          item.voucher_usuarios_nome
                                        }
                                        renderSuggestion={(item) => (
                                          <div>
                                            <span>
                                              <b>CPF: </b>
                                            </span>
                                            {item.matricula}

                                            <br />
                                            <span>
                                              <span>
                                                <b>Nome: </b>
                                              </span>
                                              {item.voucher_usuarios_nome}
                                            </span>
                                          </div>
                                        )}
                                        inputProps={{
                                          value: this.state.sugest_nomeFunc,
                                          onChange: this.onChangeNomeFunc,
                                          onBlur: this.onBlurNomeFunc,
                                          className: 'form-control',
                                          disabled:
                                            this.state.role === 7 ? true : false
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Centro de Custo
                                    <span
                                      className='required'
                                      aria-required='true'
                                    ></span>
                                  </label>
                                  <div className='col-md-3'>
                                    <Select2
                                      style={{ width: 250 }}
                                      id='departamento-id'
                                      ref='departamento_type'
                                      value={this.state.selection}
                                      data={this.state.departamentosz}
                                      onChange={(e) => {
                                        console.log('change on', e.target.value)
                                        this.setState({
                                          selection: e.target.value
                                        })
                                      }}
                                    />
                                  </div>
                                </div>

                                <div
                                  className={
                                    'form-group form-md-line-input ' +
                                    this.state.validation
                                  }
                                >
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    No.Telefone
                                    <span
                                      className='required'
                                      aria-required='true'
                                    >
                                      {' '}
                                      *
                                    </span>
                                  </label>
                                  <div className='col-md-3'>
                                    <div className='input-icon'>
                                      <i className='fa fa-phone'></i>
                                      <InputMask
                                        value={this.state.telefone}
                                        className='form-control'
                                        mask={this.state.telefoneMask}
                                        maskChar='_'
                                        placeholder='No. Telefone'
                                        onChange={(e) => {
                                          this.setState({
                                            telefone: e.target.value
                                          })
                                          if (/^.....9/.test(e.target.value)) {
                                            this.setState({
                                              telefoneMask: '(99) 99999-9999'
                                            })
                                          } else {
                                            this.setState({
                                              telefoneMask: '(99) 9999-9999'
                                            })
                                          }
                                        }}
                                      />
                                      <div className='form-control-focus'></div>
                                    </div>
                                  </div>
                                  <label
                                    className='col-md-1 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    {true ? 'Passageiro' : 'Contato'}
                                  </label>
                                  <div className='col-md-6'>
                                    <div className='input-icon left'>
                                      <i className='fa fa-user'></i>
                                      <input
                                        className='form-control'
                                        placeholder={
                                          true
                                            ? 'Nome do Passageiro'
                                            : 'Nome do Contato no Remetente'
                                        }
                                        value={this.state.passageiro}
                                        onChange={(e) =>
                                          this.setState({
                                            passageiro: e.target.value
                                          })
                                        }
                                        type='text'
                                        style={{ paddingLeft: 30 }}
                                      />
                                      <div className='form-control-focus'></div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            {this.state.showColapseOrigem && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideOrigem()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Endereço de Origem</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineDown'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {!this.state.showColapseOrigem && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideOrigem()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Endereço de Origem</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineUp'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                                <div
                                  className='form-group form-md-line-input'
                                  style={{ marginRight: 0 }}
                                >
                                  <label
                                    className='col-md-2 control-label '
                                    htmlFor='form_control_1'
                                  >
                                    Origem
                                  </label>
                                  <div className='col-md-10'>
                                    <AutoComplete
                                      chave={'enderecoorigem999'}
                                      autoFocus={true}
                                      address={this.state.options}
                                      setInpudDestino={this.setInpudDestino}
                                      changeOptions={this.changeOptions.bind(
                                        this
                                      )}
                                      endereco={this.state.fullAddress}
                                      position={this.state.ac_o_pos}
                                      salvafavorito={this.setFavorito}
                                      changeEndereco={this.changeEndereco.bind(
                                        this
                                      )}
                                      changeEnderecoExtra={this.changeEnderecoExtra.bind(
                                        this
                                      )}
                                      title='Endereço de Origem'
                                      i={999}
                                      center={this.state.origemLatLng}
                                      altcenter={{
                                        lat: this.state.empresa_latitude,
                                        lng: this.state.empresa_longitude
                                      }}
                                      cores={this.props.childProps.cores}
                                    />
                                  </div>
                                </div>
                                <div className='form-group form-md-line-input'>
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Complemento
                                  </label>
                                  <div className='col-md-10'>
                                    <input
                                      className='form-control'
                                      value={this.state.complemento}
                                      onChange={(e) =>
                                        this.setState({
                                          complemento: e.target.value
                                        })
                                      }
                                      placeholder={'Complemento do Endereço'}
                                      type='text'
                                    />
                                  </div>
                                </div>
                                <div className='form-group form-md-line-input'>
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Observação
                                  </label>
                                  <div className='col-md-10'>
                                    <input
                                      className='form-control'
                                      value={this.state.referencia}
                                      onChange={(e) =>
                                        this.setState({
                                          referencia: e.target.value
                                        })
                                      }
                                      placeholder={
                                        true
                                          ? 'Observação ou Referência do Endereço de Origem'
                                          : 'Observação ou Referência do Endereço de Embarque'
                                      }
                                      type='text'
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            {this.state.showColapseDestino && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideDestino()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Endereços de Destino</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineDown'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {!this.state.showColapseDestino && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHideDestino()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Endereços de Destino</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineUp'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                                <div className='form-group form-md-line-input'>
                                  <div>
                                    {this.state.destinos.map((m, j) => {
                                      console.log('MEUS DESTINOS', m)
                                      let linha =
                                        j % 2 === 0
                                          ? 'col-md-12 linhaDestinoON'
                                          : 'col-md-12 linhaDestinoOff'
                                      return (
                                        <div className={linha}>
                                          <div>
                                            <label
                                              className='col-md-2 control-label'
                                              htmlFor='form_control_1'
                                            >
                                              <b>Endereço de Destino {j + 1}</b>
                                            </label>
                                            <div className='col-md-10'>
                                              <div
                                                key={'destino' + j}
                                                className='input-group has-success '
                                                style={{ width: '100%' }}
                                              >
                                                <AutoComplete
                                                  chave={'enrerecodestino' + j}
                                                  salvafavorito={
                                                    this.setFavorito
                                                  }
                                                  setInpudDestino={
                                                    this.setInpudDestino
                                                  }
                                                  address={this.state.options}
                                                  changeOptions={this.changeOptions.bind(
                                                    this
                                                  )}
                                                  endereco={m.fullAddress}
                                                  changeEndereco={this.changeEndereco.bind(
                                                    this
                                                  )}
                                                  position={m.ac_pos}
                                                  changeEnderecoExtra={this.changeEnderecoExtra.bind(
                                                    this
                                                  )}
                                                  title='Endereço*'
                                                  i={j}
                                                  center={
                                                    this.state.origemLatLng
                                                  }
                                                  altcenter={{
                                                    lat: this.state
                                                      .empresa_latitude,
                                                    lng: this.state
                                                      .empresa_longitude
                                                  }}
                                                  cores={
                                                    this.props.childProps.cores
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {true && (
                                            <div>
                                              <div
                                                key={'destinoobs' + j}
                                                className='input-group'
                                                style={{ width: '100%' }}
                                              >
                                                <div className='row'>
                                                  <label
                                                    className='col-md-2 control-label'
                                                    htmlFor='form_control_1'
                                                  >
                                                    Complemento
                                                  </label>
                                                  <div className='col-md-10'>
                                                    <input
                                                      key={'idestinoobs' + j}
                                                      className='form-control'
                                                      value={m.complemento}
                                                      onChange={(e) => {
                                                        let mydest =
                                                          this.state.destinos
                                                        for (let a in mydest) {
                                                          if (
                                                            Number(a) ===
                                                            Number(j)
                                                          ) {
                                                            mydest[
                                                              a
                                                            ].complemento =
                                                              e.target.value
                                                            this.setState({
                                                              destinos: mydest
                                                            })
                                                          }
                                                        }
                                                      }}
                                                      placeholder='Complemento'
                                                      type='text'
                                                    />
                                                  </div>
                                                </div>
                                                <div className='row'>
                                                  <label
                                                    className='col-md-2 control-label'
                                                    htmlFor='form_control_1'
                                                  >
                                                    Observação
                                                  </label>
                                                  <div className='col-md-10'>
                                                    <input
                                                      key={'idestinoobs' + j}
                                                      className='form-control'
                                                      value={m.obs}
                                                      onChange={(e) => {
                                                        let mydest =
                                                          this.state.destinos
                                                        for (let a in mydest) {
                                                          if (
                                                            Number(a) ===
                                                            Number(j)
                                                          ) {
                                                            mydest[a].obs =
                                                              e.target.value
                                                            this.setState({
                                                              destinos: mydest
                                                            })
                                                          }
                                                        }
                                                      }}
                                                      placeholder='Observação'
                                                      type='text'
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>

                                <Box
                                  display='flex'
                                  flexWrap='nowrap'
                                  alignItems='center'
                                >
                                  {this.state.destinos.length <
                                    this.state.maximodestinos <
                                    15 && (
                                    <IconButton
                                      icone={'FaPlusCircle'}
                                      texto={'Adicionar Destino'}
                                      tamanho={22}
                                      cores={this.props.childProps.cores}
                                      click={async () => {
                                        let addvet = this.state.destinos
                                        let contagem =
                                          this.state.destinos.length
                                        addvet.push({
                                          autocomplete: {},
                                          destino: '',
                                          numeroDestino: '',
                                          cidadeDestino: '',
                                          obs: '',
                                          item: '',
                                          valor: 0,
                                          pagamento: '',
                                          itens: [{ id: 0, valor: '' }],
                                          contato: '',
                                          parada:
                                            this.state.tiposolicitacao === 2
                                              ? true
                                              : false,
                                          retorno: false,
                                          fullAddress: '',
                                          ac_pos: null
                                        })
                                        await this.setState({
                                          destinos: addvet
                                        })
                                        this.inputs[
                                          'enrerecodestino' + contagem
                                        ].focus()
                                      }}
                                    />
                                  )}

                                  {this.state.destinos.length > 1 && (
                                    <IconButton
                                      icone={'FaMinusCircle'}
                                      texto={'Remover Destino'}
                                      tamanho={22}
                                      direcao={'direita'}
                                      cores={this.props.childProps.cores}
                                      click={() => {
                                        let addvet = this.state.destinos
                                        addvet.pop()
                                        this.setState(
                                          {
                                            destinos: addvet
                                          },
                                          () => this.calculaValor()
                                        )
                                      }}
                                    />
                                  )}
                                </Box>
                              </React.Fragment>
                            )}

                            {this.state.showColapsePagamento && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHidePagamento()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Pagamento</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineDown'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            {!this.state.showColapsePagamento && (
                              <React.Fragment>
                                <div
                                  className={'form-group form-md-line-input'}
                                  onClick={() => this.showHidePagamento()}
                                  style={{
                                    borderTopColor: 'black',
                                    borderTopWidth: 1,
                                    borderTopStyle: 'solid'
                                  }}
                                >
                                  <div
                                    className='col-md-11'
                                    style={{ fontSize: 20 }}
                                  >
                                    <b>Pagamento</b>
                                  </div>
                                  <div className='col-md-1'>
                                    <Icon
                                      name={'AiOutlineUp'}
                                      size={22}
                                      color={'black'}
                                    />
                                  </div>
                                </div>
                                {false &&
                                <div className='form-group form-md-line-input'>
                                  <div className='col-md-10'>
                                    <div
                                      style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        borderColor: 'red',
                                        borderWidth: 1
                                      }}
                                    >
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='cnl'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='vet'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='cdo'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='ldy'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='pop'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='mot'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='c15'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                      <TipoCorrida
                                        showsoma={false}
                                        icone={'carro'}
                                        prices={this.state.prices}
                                        sigla='prm'
                                        pgto='112'
                                        showDetalhe={this.showDetalhe}
                                        mostrardetalhesuser={
                                          this.state.mostrardetalhesuser
                                        }
                                        escolhetipo={this.setTipoPagamento}
                                        pagamento={this.state.pagtoSelected}
                                        tiposelecionado={
                                          this.state.tiposelecionado
                                        }
                                        destinos={this.state.destinos}
                                        cores={this.props.childProps.cores}
                                      />
                                    </div>
                                  </div>
                                </div>}
                                {console.log('api_valores',this.state.api_valores.length)}
                                {this.state.api_valores.length > 0 &&
                                  <div className='form-group form-md-line-input'>
                                    <div className='col-md-10'>
                                    <div
                                      style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                        borderColor: 'red',
                                        borderWidth: 1
                                      }}
                                    >
                                      {
                                        this.state.api_valores.map( (x) => {
                                          console.log('api_valores x',x)
                                          return <TipoCorridaApi
                                            prices={x}
                                            escolhetipo={this.setTipoPagamentoNew}
                                            pagamento={this.state.pagtoSelectedApi}
                                            tiposelecionado={this.state.tiposelecionado}
                                            cores={this.props.childProps.cores}
                                          />  
                                        })
                                      }

                                        

                                    </div>
                                    </div>
                                  </div>
                                }

                                <div className='row'>
                                  <div className='col-md-2'>
                                    <label
                                      className='control-label'
                                      htmlFor='form_control_1'
                                      style={{ width: 120 }}
                                    >
                                      Forma de Pagamento *
                                    </label>
                                  </div>
                                  <div className='col-md-10'>
                                    <div className='input-group has-success'>
                                      <Select2
                                        style={{ width: '196px' }}
                                        id='pagamento-id'
                                        ref='pagto_type'
                                        value={this.state.pagtoSelectedApi}
                                        data={this.state.pagamentos_api.map((x) => {
                                          return {id: x.id, text: x.nome_pagamento}
                                        })}
                                        onSelect={(e) => {
                                          this.setState({
                                            pagtoSelectedApi:
                                              e.target.selectedOptions[0].value
                                          })
                                        }}
                                        onUnselect={(e) => {
                                          this.setState({
                                            pagtoSelectedApi:
                                              e.target.selectedOptions[0].value
                                          })
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className='form-group form-md-line-radio'>
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Horário da Corrida
                                  </label>
                                  <div className='col-md-4 md-radio-inline'>
                                    <div className='md-radio has-success'>
                                      <input
                                        id='rbAgendaParaJa'
                                        checked={this.state.praJaCheck}
                                        className='md-radiobtn'
                                        onChange={(e) =>
                                          this.setState({
                                            praJaCheck: e.target.checked,
                                            agendCheck: !e.target.checked,
                                            showAgenda: e.target.checked
                                              ? 'none'
                                              : 'checked'
                                          })
                                        }
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='rbAgendaParaJa'
                                        style={{ color: cor1 }}
                                      >
                                        <span
                                          className='inc'
                                          style={{ borderColor: cor1 }}
                                        ></span>
                                        <span
                                          className='check'
                                          style={{ backgroundColor: cor1 }}
                                        ></span>
                                        <span
                                          className='box'
                                          style={{ borderColor: cor1 }}
                                        ></span>
                                        Para já!
                                      </label>
                                    </div>
                                    <div className='md-radio has-success'>
                                      <input
                                        id='rbAgendaAgendar'
                                        style={{ border: cor1 }}
                                        checked={this.state.agendCheck}
                                        className='md-radiobtn'
                                        onChange={(e) =>
                                          this.setState({
                                            agendCheck: e.target.checked,
                                            praJaCheck: !e.target.checked,
                                            showAgenda: e.target.checked
                                              ? 'inherit'
                                              : 'none'
                                          })
                                        }
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='rbAgendaAgendar'
                                        style={{ color: cor1 }}
                                      >
                                        <span className='inc'></span>
                                        <span
                                          className='check'
                                          style={{ backgroundColor: cor1 }}
                                        ></span>
                                        <span
                                          className='box'
                                          style={{ borderColor: cor1 }}
                                        ></span>
                                        Quero Agendar
                                      </label>
                                    </div>
                                  </div>
                                
                                <div
                                  className='form-col-md-2'
                                  style={{ display: this.state.showAgenda }}
                                >
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    Agenda
                                  </label></div>
                                  <div className='col-md-2' style={{ display: this.state.showAgenda }}>
                                      <DatePicker
                                        className='form-control'
                                        minDate={moment()}
                                        dateFormat='DD/MM/YYYY'
                                        selected={this.state.dataAgend}
                                        onChange={this.dateHandleChange}
                                        required
                                      />
                                  </div>
                                  <div className='col-md-2' style={{ display: this.state.showAgenda }}>
                                    <div className='input-group'>
                                      {this.tempo()}
                                    </div>
                                  </div>
                                </div>
                                {true && (
                                  <div
                                    className='row'
                                    style={{ marginTop: 15, paddingLeft: 20 }}
                                  >
                                    <div className='form-group'>
                                      <label
                                        className='col-md-2 control-label'
                                        style={{
                                          marginTop: 5,
                                          textAlign: 'right'
                                        }}
                                      >
                                        Qtde. de Carros:
                                      </label>
                                      <div className='col-md-8'>
                                        <div>
                                          <div
                                            className='input-group'
                                            style={{ width: 150 }}
                                          >
                                            <input
                                              value={this.state.qtdCorridas}
                                              className='form-control'
                                              maxLength='2'
                                              readOnly='true'
                                              type='text'
                                            />
                                            <div className='input-group-btn'>
                                              <button
                                                type='button'
                                                className='btn default'
                                                onClick={() => {
                                                  let number =
                                                    this.state.qtdCorridas
                                                  if (
                                                    number <
                                                    Number(
                                                      this.state.limitecarros
                                                    )
                                                  )
                                                    number++
                                                  else
                                                    number = Number(
                                                      this.state.limitecarros
                                                    )
                                                  this.setState({
                                                    qtdCorridas: number
                                                  })
                                                }}
                                              >
                                                <i className='fa fa-angle-up'></i>
                                              </button>
                                              <button
                                                type='button'
                                                className='btn default'
                                                onClick={() => {
                                                  let number =
                                                    this.state.qtdCorridas
                                                  if (number > 1) number--
                                                  else number = 1
                                                  this.setState({
                                                    qtdCorridas: number
                                                  })
                                                }}
                                              >
                                                <i className='fa fa-angle-down'></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {true &&
                                <div className='form-group form-md-line-radio'>
                                  <label
                                    className='col-md-2 control-label'
                                    htmlFor='form_control_1'
                                  >
                                    &nbsp;
                                  </label>
                                  <div className='col-md-10 md-checkbox-inline'>
                                    <div className='md-checkbox has-success'>
                                      <input
                                        id='stFavorito'
                                        checked={this.state.favCheck}
                                        onChange={(e) =>
                                          this.setState({
                                            favCheck: e.target.checked
                                          })
                                        }
                                        className='md-check'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='stFavorito'
                                        style={{ color: cor1 }}
                                      >
                                        <span className='inc'></span>
                                        <span
                                          className='check'
                                          style={{ backgroundColor: cor1 }}
                                        ></span>
                                        <span
                                          className='box'
                                          style={{ borderColor: cor1 }}
                                        ></span>
                                        Adicionar aos Favoritos?
                                      </label>
                                    </div>
                                  </div>
                                </div>}

                                {this.state.pagtoSelectedApi !== undefined && (
                                  <div className='form-actions margin-top-10'>
                                    <div className='row'>
                                      <div className='col-md-offset-2 col-md-10'>
                                        {false && (
                                          <button
                                            type='button'
                                            className='btn default'
                                            onClick={() =>
                                              this.props.history.push({
                                                pathname: '/solicitar',
                                                state: {
                                                  dados: {
                                                    endereco:
                                                      this.state.address,
                                                    latlng:
                                                      this.state.origemLatLng
                                                  }
                                                }
                                              })
                                            }
                                          >
                                            <i className='fa fa-undo'></i>&nbsp;
                                            Voltar
                                          </button>
                                        )}
                                        &nbsp;
                                        {!this.state.favCheck &&
                                          this.state.podepedir && (
                                            <button
                                              onClick={() => this.sendTaxi()}
                                              className='btn blue'
                                              style={{
                                                color: cor2,
                                                backgroundColor: cor1,
                                                borderColor: cor1
                                              }}
                                            >
                                              <i className='fa fa-paper-plane-o'></i>
                                              &nbsp; Solicitar
                                            </button>
                                          )}
                                        {this.state.favCheck &&
                                          this.state.podepedir && (
                                            <button
                                              onClick={() => this.sendTaxi()}
                                              className='btn blue'
                                              style={{
                                                color: cor2,
                                                backgroundColor: cor1,
                                                borderColor: cor1
                                              }}
                                            >
                                              <i className='fa fa-paper-plane-o'></i>
                                              &nbsp; Solicitar e Adicionar
                                              Endereço aos Favoritos
                                            </button>
                                          )}
                                        {!this.state.podepedir && (
                                          <button
                                            className='btn blue'
                                            style={{
                                              color: cor2,
                                              backgroundColor: cor1,
                                              borderColor: cor1
                                            }}
                                          >
                                            <i className='fa fa-paper-plane-o'></i>
                                            &nbsp; Solicitando, Aguarde!
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.showColapseMap ? 'col-md-1' : 'col-md-4'
                    }
                    style={{
                      borderTopColor: 'black',
                      borderTopWidth: 1,
                      borderTopStyle: 'solid'
                    }}
                  >
                    {
                      // showColapseSolicitante: true,
                      // showColapseOrigem: false,
                      // showColapseMap: true,
                      // showColapseModalidade: true,
                      // showColapsePagamento: true,
                      // this.showHideSolicitante = this.showHideSolicitante.bind(this);
                      // this.showHideOrigem = this.showHideOrigem.bind(this);
                      // this.showHideMap = this.showHideMap.bind(this);
                      // this.showHideModalidade = this.showHideModalidade.bind(this);
                      // this.showHidePagamento = this.showHidePagamento.bind(this);
                      // showHideDestino
                    }

                    {this.state.showColapseMap && (
                      <React.Fragment>
                        <div
                          className={'form-group form-md-line-input'}
                          onClick={() => this.showHideMap()}
                        >
                          <div
                            className='col-md-11'
                            style={{
                              fontSize: 20,
                              writingMode: 'vertical-rl',
                              color: '#ffffff'
                            }}
                          >
                            <b>Mapa</b>
                          </div>
                          <div className='col-md-1'>
                            <Icon
                              name={'AiOutlineLeft'}
                              size={22}
                              color={'white'}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {!this.state.showColapseMap && (
                      <React.Fragment>
                        <div
                          className={'form-group form-md-line-input'}
                          onClick={() => this.showHideMap()}
                        >
                          <div
                            className='col-md-11'
                            style={{ fontSize: 20, color: '#ffffff' }}
                          >
                            <b>Mapa</b>
                          </div>
                          <div className='col-md-1'>
                            <Icon
                              name={'AiOutlineRight'}
                              size={22}
                              color={'white'}
                            />
                          </div>
                        </div>
                        <GoMap
                          width={400}
                          height={600}
                          markO={this.state.markerLocal}
                          mark1={this.state.markerDest}
                          mark4={this.state.motoristas}
                          c_points={this.state.c_points}
                          c_points1={this.state.d_points}
                          cores={this.props.childProps.cores}
                        ></GoMap>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          keyboard
          show={this.state.showfavmodal}
          onHide={this.modalFavoritoHide}
          container={this}
          bsSize='large'
          aria-labelledby='contained-modal-title'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className='fa fa-star-o'></i>&nbsp; Endereços Favoritos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className='portlet-title'
              style={{ marginBottom: 10, color: '#d8030b' }}
            >
              <div className='caption'>
                <span className='caption-subject bold uppercase'>
                  <i className='fa fa-star-o'></i>&nbsp; Favoritos
                </span>
                &nbsp;&nbsp;
                <button
                  className='btn red'
                  onClick={() =>
                    this.setState({
                      showEditFavoritos: true,
                      novo: true,
                      editar: false,
                      selected: {
                        telefone: this.state.telefone
                          ? this.state.telefone
                          : '',
                        passageiro: this.state.passageiro
                          ? this.state.passageiro
                          : '',
                        identificacao: this.state.passageiro
                          ? this.state.passageiro
                          : '',
                        origem_endereco: '',
                        origem_numero: '',
                        origem_cidade: '',
                        referencia: '',
                        complemento: '',
                        destino_endereco: '',
                        destino_numero: '',
                        destino_cidade: '',
                        formadepagamento: 10,
                        origem_latitude: null,
                        origem_longitude: null,
                        destino_latitude: null,
                        destino_longitude: null
                      }
                    })
                  }
                >
                  Novo
                </button>
              </div>
            </div>
            {console.log('favoritos', this.state.favoritos)}
            <ReactTable
              data={this.state.favoritos}
              previousText='Anterior'
              nextText='Próxima'
              loadingText='Carregando...'
              noDataText='Nenhum dado encontrado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              pageSize={
                this.state.favoritos.length !== 0
                  ? this.state.favoritos.length
                  : 4
              }
              getTdProps={(state, props, column) => {
                return {
                  style: {
                    cursor: 'pointer'
                  },
                  onClick: async (e) => {
                    if (column.Header !== '') {
                      console.log('props.original', props.original)

                      this.setState({ loaded: false })
                      this.setState({
                        showEditFavoritos: false,
                        showfavmodal: false
                      })
                      await this.setFavorito(props.original, 999)
                    }
                  }
                }
              }}
              columns={[
                {
                  Header: (
                    <div className='header-table-left'>
                      <i className='fa fa-user'></i>&nbsp;Apelido
                    </div>
                  ),
                  accessor: 'identificacao',
                  width: 100
                },
                {
                  Header: (
                    <div className='header-table-left'>
                      <i className='fa fa-briefcase'></i>&nbsp;Origem
                    </div>
                  ),
                  accessor: 'origem_endereco'
                },
                {
                  Header: (
                    <div className='header-table-left'>
                      <i className='fa fa-map-marker'></i>&nbsp;Destino
                    </div>
                  ),
                  accessor: 'destino_endereco'
                },
                {
                  Header: '',
                  width: 62,
                  filterable: false,
                  sortable: false,
                  style: {
                    cursor: 'pointer'
                  },
                  Cell: (props) => (
                    <div>
                      <button
                        type='button'
                        onClick={() => {
                          let favorito = {
                            telefone: props.row._original.telefone,
                            passageiro: props.row._original.passageiro,
                            identificacao: props.row._original.identificacao,
                            origem_endereco:
                              props.row._original.origem_endereco,
                            origem_numero: props.row._original.origem_numero,
                            origem_cidade: props.row._original.origem_cidade,
                            referencia: props.row._original.referencia,
                            destino_endereco: props.row._original
                              .destino_endereco
                              ? props.row._original.destino_endereco
                              : '',
                            destino_numero: props.row._original.destino_numero
                              ? props.row._original.destino_numero
                              : '',
                            destino_cidade: props.row._original.destino_cidade
                              ? props.row._original.destino_cidade
                              : '',
                            formadepagamento: 10,
                            origem_latitude:
                              props.row._original.origem_latitude,
                            origem_longitude:
                              props.row._original.origem_longitude,
                            destino_latitude: props.row._original
                              .destino_latitude
                              ? props.row._original.destino_latitude
                              : null,
                            destino_longitude: props.row._original
                              .destino_longitude
                              ? props.row._original.destino_longitude
                              : null
                          }

                          this.setState({
                            showEditFavoritos: true,
                            selected: favorito,
                            novo: false,
                            editar: true,
                            origemLatLng: {
                              lat: props.row._original.origem_latitude,
                              lng: props.row._original.origem_longitude
                            },
                            destinoLatLng: {
                              lat: props.row._original.destino_latitude
                                ? props.row._original.destino_latitude
                                : null,
                              lng: props.row._original.destino_longitude
                                ? props.row._original.destino_longitude
                                : null
                            }
                          })
                        }}
                        className='btn btn-xs default purple'
                      >
                        <i
                          className='fa fa-edit'
                          style={{ color: 'white' }}
                        ></i>
                      </button>{' '}
                      &nbsp;
                      <button
                        type='button'
                        onClick={() => {
                          Dialog.setOptions({
                            defaultOkLabel: 'Sim',
                            defaultCancelLabel: 'Não',
                            primaryClassName: 'btn-danger '
                          })
                          this.dialog.show({
                            body: (
                              <div>
                                Você tem certeza que deseja deletar esse
                                favorito?
                                <br />
                                <span style={{ color: 'red', fontSize: 12 }}>
                                  <b>
                                    Obs: Ao deletar esse favorito, todos os
                                    dados dele serão perdidos!
                                  </b>
                                </span>
                              </div>
                            ),
                            actions: [
                              Dialog.CancelAction(() => {}),
                              Dialog.OKAction(() => {
                                this.deleteFavorito(props.row._original.id)
                              })
                            ],
                            bsSize: 'large',
                            onHide: (dialog) => {
                              dialog.hide()
                            }
                          })
                        }}
                        className='btn btn-xs default black'
                      >
                        <i
                          className='fa fa-trash-o'
                          style={{ color: 'black' }}
                        ></i>
                      </button>
                    </div>
                  )
                }
              ]}
              defaultPageSize={this.state.favoritos.length}
              showPagination={false}
              className='-striped -highlight'
            />
          </Modal.Body>
        </Modal>

        <Dialog
          ref={(el) => {
            this.dialog = el
          }}
        />
        <DadosIUGU
          show={this.state.showCartao}
          modalHide={this.hideDadosIUGU.bind(this)}
          setDadosIugu={this.setDadosIugu.bind(this)}
          dialog={this.dialog}
          dialogPai={Dialog}
        />
        <Modal
          keyboard
          show={this.state.showEditFavoritos}
          onHide={this.modalEditHide}
          container={this}
          bsSize='large'
          aria-labelledby='contained-modal-title'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className='fa fa-star-o'></i>&nbsp; Cadastro de Endereços
              Favoritos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-horizontal'>
              <div className='form-body'>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>
                    Apelido para Endereço
                  </label>
                  <div className='col-md-10'>
                    <div className='input-icon left'>
                      <i className='fa fa-star-half-o'></i>
                      <input
                        className='form-control'
                        placeholder='Apelido para Endereço'
                        value={this.state.selected.identificacao}
                        onChange={(e) =>
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              identificacao: e.target.value
                            }
                          })
                        }
                        type='text'
                        style={{ paddingLeft: 30 }}
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                </div>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>Telefone</label>
                  <div className='col-md-10'>
                    <div className='input-icon left'>
                      <i className='fa fa-phone'></i>
                      <InputMask
                        value={this.state.selected.telefone}
                        className='form-control'
                        mask={this.state.telefoneMask}
                        maskChar='_'
                        placeholder='No. Telefone'
                        onChange={(e) => {
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              telefone: e.target.value
                            }
                          })
                          if (/^.....9/.test(e.target.value)) {
                            this.setState({ telefoneMask: '(99) 99999-9999' })
                          } else {
                            this.setState({ telefoneMask: '(99) 9999-9999' })
                          }
                        }}
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                </div>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>
                    {true ? 'Passageiro' : 'Contato'}
                  </label>
                  <div className='col-md-10'>
                    <div className='input-icon left'>
                      <i className='fa fa-user'></i>
                      <input
                        className='form-control'
                        placeholder={
                          true
                            ? 'Nome do Passageiro'
                            : 'Nome do Contato no Remetente'
                        }
                        value={this.state.selected.passageiro}
                        onChange={(e) =>
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              passageiro: e.target.value
                            }
                          })
                        }
                        type='text'
                        style={{ paddingLeft: 30 }}
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                </div>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>Origem</label>
                  <div className='col-md-8'>
                    <div className='input-group has-success'>
                      <AutoComplete
                        address={this.state.options}
                        salvafavorito={this.setFavorito}
                        changeOptions={this.changeOptions.bind(this)}
                        endereco={this.state.selected.origem_endereco}
                        changeEndereco={this.changeEnderecoOrigem.bind(this)}
                        changeEnderecoExtra={this.changeEnderecoOrigemExtra.bind(
                          this
                        )}
                        title='Endereço*'
                        center={this.state.center}
                        altcenter={{
                          lat: this.state.empresa_latitude,
                          lng: this.state.empresa_longitude
                        }}
                      />
                    </div>
                  </div>
                  <label className='col-md-1 control-label'>Número</label>
                  <div className='col-md-1'>
                    <div className='input-group has-success'>
                      <input
                        className='form-control'
                        ref={'numeroOrigem'}
                        onFocus={(e) => e.target.select()}
                        value={this.state.selected.origem_numero}
                        onChange={(e) =>
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              origem_numero: e.target.value
                            }
                          })
                        }
                        onBlur={() => {
                          this.getLatLng()
                        }}
                        placeholder='No.'
                        type='text'
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>
                    Referência / Observação
                  </label>
                  <div className='col-md-10'>
                    <div className='input-group has-success'>
                      <span className='input-group-addon'>
                        <i className='fa fa-quote-left'></i>
                      </span>
                      <input
                        className='form-control'
                        value={this.state.selected.referencia}
                        onChange={(e) =>
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              referencia: e.target.value
                            }
                          })
                        }
                        placeholder='Referência do Endereço de Origem'
                        type='text'
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                </div>
                <div className='form-group form-md-line-input'>
                  <label className='col-md-2 control-label'>Destino</label>
                  <div className='col-md-10'>
                    <div
                      className='input-group has-success'
                      style={{ width: '100%' }}
                    >
                      <span
                        className='input-group-addon'
                        style={{
                          position: 'absolute',
                          padding: '12px 5px 7px 12px'
                        }}
                      >
                        <i className='fa fa-map-marker'></i>
                      </span>
                      <AutoComplete
                        address={this.state.options}
                        changeOptions={this.changeOptions.bind(this)}
                        endereco={this.state.selected.destino_endereco}
                        changeEndereco={this.changeEnderecoDestino.bind(this)}
                        changeEnderecoExtra={this.changeEnderecoDestinoExtra.bind(
                          this
                        )}
                        title='Endereço*'
                        center={this.state.center}
                        altcenter={{
                          lat: this.state.empresa_latitude,
                          lng: this.state.empresa_longitude
                        }}
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                  <label
                    className='col-md-1 control-label'
                    htmlFor='form_control_1'
                  >
                    Número
                  </label>
                  <div className='col-md-1'>
                    <div className='input-group has-success'>
                      <input
                        className='form-control'
                        ref={'numeroDestino'}
                        onFocus={(e) => e.target.select()}
                        value={
                          this.state.selected.destino_numero
                            ? this.state.selected.destino_numero
                            : ''
                        }
                        onChange={(e) =>
                          this.setState({
                            selected: {
                              ...this.state.selected,
                              destino_numero: e.target.value
                            }
                          })
                        }
                        onBlur={() => {
                          this.getLatLng()
                        }}
                        placeholder='No.'
                        type='text'
                      />
                      <div className='form-control-focus'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className='btn blue'
                onClick={() => this.salvarFavorito()}
              >
                Salvar
              </button>
              &nbsp;
              <button
                className='btn default'
                onClick={() => this.modalEditHide()}
              >
                Fechar
              </button>
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

export default Solicitar
