import React, { Component } from 'react';
import Icon from 'react-icons-wrapper';
import IconButton from './IconButton.js';
//import ReactDom from 'react-dom';

class TipoCorrida extends Component {

    constructor(props) {
      super(props);
      this.titulo = this.titulo.bind(this);
      this.escolhe = this.escolhe.bind(this);
      this.icone = this.icone.bind(this);
      this.showDetalhe = this.showDetalhe(this);
      this.state = {
          loading: false
      }
    }
    icone(ic) {
        console.log('icone',ic)
        switch (ic) {
            case 'carro':
                return <Icon name={'FaCar'} size={60} />;
                break;
            case 'bike':
                return <Icon name={'FaBicycle'} size={60} />;
                break;
            case 'moto':
                return <Icon name={'FaMotorcycle'} size={60} />;
                break;
            case 'taxi':
                return <Icon name={'FaTaxi'} size={60} />;
                break;
            case 'entrega':
                return <Icon name={'FaCar'} size={60} />;
                break;
            default:
                return null;
                break;
        }
    }
    escolhe() {
        this.props.escolhetipo(this.props.sigla,this.props.pgto);
    }
    titulo() {
        let sigla = this.props.sigla;
        let saida = 'Comum'
        if (sigla === 'cnl') {saida = 'Comum'}
        if (sigla === 'prm') {saida = 'Executivo'}
        if (sigla === 'c15') {saida = 'Desconto'}
        if (sigla === 'cdo') {saida = 'Adaptado'}
        if (sigla === 'mot') {saida = 'Motociclista'}
        if (sigla === 'vet') {saida = 'Convenio'}
        if (sigla === 'pop') {saida = 'Popular'}
        if (sigla === 'ldy') {saida = 'Lady'}

        return saida;
    }
    showDetalhe() {
    }
    render() {
        let classe = this.props.tiposelecionado === this.props.sigla ? 'pagamentoOKx' : 'pagamentoNOKx';
        let prices = this.props.prices;
        
        let sigla = this.props.sigla;
        let price = {};
        let saida = ''
        let showIndividual = true;
        let valorIndividual = 0;
        if (this.props.showsoma) {
            showIndividual = false;
        }
        let destinos = [];
        let valorCalculado = 0;
        let valorDesconto = 0;
        let percento_desconto = 0;
        let pagamento = this.props.pagamento ? this.props.pagamento : '112';
        console.log('prices pagtoSelected', prices)
        if (this.props.destinos) {
            destinos = this.props.destinos;
        }
        if (prices) {
            if (prices[sigla]) {
                console.log('prices[sigla]', prices[sigla])
                if (!showIndividual) {
                    for (let index = 0; index < destinos.length; index++) {
                        const element = destinos[index];
                        console.log('element', element)
                        if (element.precos) {
                            if(element.precos.prices) {
                                if (element.precos.prices[sigla]) {
                                    let priceusar = element.precos.prices[sigla].comissoes.c01;
                                    if (Number(pagamento) === Number(10)) {
                                        priceusar = element.precos.prices[sigla].comissoes.c01;
                                    }
                                    if (Number(pagamento) === Number(15)) {
                                        priceusar = element.precos.prices[sigla].comissoes.vis;
                                    }
                                    if (Number(pagamento) === Number(100)) {
                                        priceusar = element.precos.prices[sigla].comissoes.crd;
                                    }
                                    if (Number(pagamento) === Number(112)) {
                                        priceusar = element.precos.prices[sigla].comissoes.vet;
                                    }
                                    valorDesconto += priceusar.valor_desconto;
                                    valorCalculado += priceusar.valorComtaxas;
                                    percento_desconto = priceusar.percento_desconto;
                                }
                            }
                        }
                        
                    }
                }
                if (showIndividual) {
                    console.log(sigla, pagamento)
                    console.log(sigla, prices[sigla].comissoes)
                    let priceusar = prices[sigla].comissoes.c01;
                    if (Number(pagamento) === Number(10)) {
                        priceusar = prices[sigla].comissoes.c01;
                    }
                    if (Number(pagamento) === Number(15)) {
                        priceusar = prices[sigla].comissoes.vis;
                    }
                    if (Number(pagamento) === Number(100)) {
                        priceusar = prices[sigla].comissoes.crd;
                    }
                    if (Number(pagamento) === Number(112)) {
                        priceusar = prices[sigla].comissoes.vet;
                    }
                    valorDesconto = priceusar.valor_desconto;
                    valorCalculado = priceusar.valorComtaxas;
                    percento_desconto = priceusar.percento_desconto
                }

        
                price = prices[sigla];
                saida = <><div className={classe} style={{ flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center'}}>
                            <div style={{ alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                            <label style={{ width:'100%', textAlign:'center'}} onClick={() => this.escolhe()}>
                            {this.icone(this.props.icone)}
                            <div>{price.nome.length > 1 ? price.nome : this.titulo()}</div>
                            </label>
                            </div>
                            {Number(valorDesconto) > 0 &&
                            <div style={{ flex:1, flexDirection:'row', alignSelf: 'center', justifyContent: 'space-between', alignContent: 'center', alignItems:'center'}}>
                                <div style={{flex:1, display: 'inline-flex', alignContent: 'center', alignItems:'center'}}>
                                <label style={{ width:'100%', textAlign:'center', fontSize: 11, textDecorationLine: 'line-through'}} onClick={() => this.escolhe()}>
                                R$ {(Number(valorCalculado) / Number(1 - percento_desconto)).toFixed(2).toString().replace('.',',')}
                                </label></div>
                                <div style={{flex:1, display: 'inline-flex', alignContent: 'center', 
                                alignItems:'center', marginLeft: 10}}>
                                    <button type="button" className="btn green-meadow btn-circle" style={{fontSize: 11,}}
                              onClick={() => {this.escolhe()}}>{(Number(percento_desconto * 100)).toFixed(0).toString().replace('.',',')}%</button>                                
                                </div>
                                </div>}
                                <div style={{ flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                                <label style={{ width:'100%', textAlign:'center', fontSize: 14}} onClick={() => this.escolhe()}>
                                R$ {valorCalculado.toFixed(2).toString().replace('.',',')}
                                </label>
                                </div>
                            {this.props.mostrardetalhesuser &&<div style={{ flex:1, alignSelf: 'center', alignContent: 'stretch', width:'100%'}}>
                            <button style={{ width:'100%'}} className="btn default" onClick={() => this.props.showDetalhe(sigla,showIndividual)}>Detalhe {sigla}</button></div>}
                </div></>
                }
            }

        return saida;
    }
}
export default TipoCorrida;