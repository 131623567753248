import CoreService from "../../service/CoreService";
import moment from "moment";

export default class Service extends CoreService {
  async listar() {
    try {
      const response = await this.fetchOn(
        'corridas?t12_dthr_dembarques={"eq": null}&t12_st_corrida={"nall": ["C","A","M","T","H"]}'
      );
      const result = response.result;
      return result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }
}
