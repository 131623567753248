import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Line,
} from "recharts";
import ReactTable from "react-table";
//import { Panel, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import "react-table/react-table.css";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import Loader from "react-loader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./Bubbles.css";

class Tool extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.active === true && (
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid black",
              margin: 5,
              padding: 10,
            }}
          >
            {this.props.payload && this.props.payload[0] && (
              <p>{this.props.payload[0].payload.t12_nome}</p>
            )}
            {this.props.payload && this.props.payload[0] && (
              <p style={{ color: "#67b7dc" }}>
                Valor total:{" "}
                <NumberFormat
                  value={this.props.payload[0].value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            )}
            {this.props.payload && this.props.payload[1] && (
              <p style={{ color: "#fdd400" }}>
                Qtde de Corrida: {this.props.payload[1].value}{" "}
              </p>
            )}
            {this.props.payload && this.props.payload[2] && (
              <p style={{ color: "green" }}>
                Valor Médio:{" "}
                <NumberFormat
                  value={this.props.payload[2].value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />{" "}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
class Tool2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.active === true && (
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid black",
              margin: 5,
              padding: 10,
            }}
          >
            {this.props.payload && this.props.payload[0] && (
              <p>Dia {this.props.payload[0].payload.t12_dthr_corrida}</p>
            )}
            {this.props.payload && this.props.payload[0] && (
              <p style={{ color: "#67b7dc" }}>
                Valor total:{" "}
                <NumberFormat
                  value={this.props.payload[0].value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            )}
            {this.props.payload && this.props.payload[1] && (
              <p style={{ color: "#fdd400" }}>
                Qtde de Corrida: {this.props.payload[1].value}{" "}
              </p>
            )}
            {this.props.payload && this.props.payload[2] && (
              <p style={{ color: "green" }}>
                Valor Médio:{" "}
                <NumberFormat
                  value={this.props.payload[2].value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />{" "}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
class Graficos extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    //Change the current language to portuguese BR
    require("moment/locale/pt-br.js");
    moment.locale("pt-br");
    let user = this.props.childProps.user;
    let role = 9;
    console.log("user", user);
    if (user) {
      if (user.func_role) {
        role = user.func_role;
      }
    }
    return (
      <div className="page-content-wrapper">
        {user.func_role < 3 && (
          <div className="page-content">
            <div className="container">
              <div>
                <div
                  onClick={() => {
                    this.props.viewModel.setState({
                      showDates: !this.props.viewModel.state.showDates,
                    });
                  }}
                  className="tooltips btn btn-fit-height btn-sm green-haze btn-dashboard-daterange round7 bgColor2 fontColor5 hmargin5"
                >
                  <i className="icon-calendar"></i>&nbsp;&nbsp;
                  <i className="fa fa-angle-down"></i>&nbsp;
                  <span className="thin uppercase visible-lg-inline-block">
                    {moment(this.props.viewModel.state.dataInicial).format(
                      "DD/MM/YYYY"
                    ) +
                      " Até " +
                      moment(this.props.viewModel.state.dataFinal).format(
                        "DD/MM/YYYY"
                      )}
                  </span>
                  &nbsp;
                  <i className="fa fa-angle-down"></i>
                </div>
              </div>
              <br />
              <Loader loaded={this.props.viewModel.state.load}>
                <div className="page-content-inner">
                  <div className="mt-content-body">
                    <div className="row ng-scope" id="divIndiceQuantificativo">

                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 margin-bottom-10 ">
                        <div className="dashboard-stat blue-madison round7 hmargin5">
                          <div className="visual">
                            <i className="fa fa-briefcase fa-icon-medium"></i>
                          </div>
                          <div className="details">
                            <div className="number">
                              {/* mudar aqui a questão para pegar os valores, fazer algum map passando por todos os vouchers para pegar o valor */}
                              {this.props.viewModel.state.stats &&
                                this.props.viewModel.state.stats[0] && (
                                  <NumberFormat
                                    value={
                                      this.props.viewModel.state.stats[0]
                                        .corridaz_valorcalculado_sum
                                    }
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"R$"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                )}
                            </div>
                            <div className="desc">Soma Total</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <Link to="/corridas-concluidas">
                          <div className="dashboard-stat purple-soft round7 hmargin5">
                            <div className="visual">
                              <i className="fa fa-shopping-cart"></i>
                            </div>
                            <div className="details">
                              <div className="number">
                                {this.props.viewModel.state.stats &&
                                  this.props.viewModel.state.stats[0] &&
                                  this.props.viewModel.state.stats[0].count}
                              </div>
                              <div className="desc">Qtd de Vouchers</div>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="dashboard-stat green-haze round7 hmargin5">
                          <div className="visual">
                            <i className="fa fa-group fa-icon-medium"></i>
                          </div>
                          <div className="details">
                            <div className="number">
                              {this.props.viewModel.state.stats &&
                                this.props.viewModel.state.stats[0] && (
                                  <NumberFormat
                                    value={
                                      this.props.viewModel.state.stats[0]
                                        .corridaz_valorcalculado_avg
                                    }
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"R$"}
                                    decimalScale={2}
                                  />
                                )}
                            </div>
                            <div className="desc">Voucher Médio</div>
                          </div>
                        </div>

                      </div>

                    </div>
                    <div className="portlet">
                      {/* <div className="portlet-title">
                      <div className="caption">
                        <i className="icon-bar-chart "></i>
                        <span className="caption-subject  bold uppercase">NOME</span>&nbsp;
                      <span className="caption-helper">Gráficos &amp; Detalhamento</span>
                      </div>

                    </div> */}
                      <div className="portlet-body" style={{ height: "auto" }}>
                        <div className="row">
                          <div className="col-md-12">
                            <ComposedChart
                              width={965}
                              height={400}
                              data={this.props.viewModel.state.newvet1}
                            >
                              <XAxis dataKey="t12_nome" />
                              <YAxis />
                              <Tooltip content={<Tool />} />
                              <Legend verticalAlign="bottom" />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                name="Valor total"
                                dataKey="corridaz_valorcalculado_sum"
                                barSize={20}
                                fill="#67b7dc"
                              />
                              <Line
                                name="Qtde Corridas"
                                type="monotone"
                                dataKey="count"
                                stroke="#fdd400"
                              />
                              <Line
                                name="Valor médio"
                                type="monotone"
                                dataKey="corridaz_valorcalculado_avg"
                                stroke="green"
                              />
                            </ComposedChart>
                          </div>
                          <p style={{ marginLeft: 340 }}>Total: </p>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <ReactTable
                              data={this.props.viewModel.state.newvet1}
                              previousText="Anterior"
                              nextText="Próxima"
                              loadingText="Carregando..."
                              noDataText="Nenhum dado encontrado"
                              pageText="Página"
                              ofText="de"
                              rowsText="linhas"
                              getTdProps={(state, props, columns) => {
                                return {
                                  onClick: (e) => {
                                    if (props) {
                                      this.props.viewModel.escolhecorrida(
                                        props.row._original
                                      );
                                      this.props.viewModel.setState({
                                        open1: true,
                                      });
                                    }
                                  },
                                };
                              }}
                              columns={[
                                {
                                  Header: "Nome",
                                  accessor: "t12_nome",
                                },
                                {
                                  Header: "Quantidade",
                                  accessor: "count",
                                },
                                {
                                  Header: "Valor total",
                                  id: "corridaz_valorcalculado_sum",
                                  accessor: (d) => (
                                    <NumberFormat
                                      value={d.corridaz_valorcalculado_sum}
                                      displayType={"text"}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      prefix={"R$"}
                                    />
                                  ),
                                },
                                {
                                  Header: "Valor médio",
                                  id: "corridaz_valorcalculado_avg",
                                  accessor: (d) => (
                                    <NumberFormat
                                      value={d.corridaz_valorcalculado_avg}
                                      displayType={"text"}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      prefix={"R$"}
                                    />
                                  ),
                                },
                              ]}
                              defaultPageSize={5}
                              className="-striped -highlight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="portlet">
                      <div className="portlet-title">
                        <div className="caption">
                          <i className="icon-bar-chart "></i>
                          <span className="caption-subject  bold uppercase">
                            CORRIDA POR DIA
                          </span>
                          &nbsp;
                          {/* <span className="caption-helper">Gráficos &amp; Detalhamento</span> */}
                        </div>
                      </div>
                      <div className="portlet-body" style={{ height: "auto" }}>
                        <div className="row">
                          <ComposedChart
                            width={965}
                            height={400}
                            data={this.props.viewModel.state.newvet2}
                          >
                            <XAxis dataKey="t12_dthr_corrida" />
                            <YAxis />
                            <Tooltip content={<Tool2 />} />
                            <Legend verticalAlign="bottom" />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Bar
                              name="Valor total"
                              dataKey="corridaz_valorcalculado_sum"
                              barSize={20}
                              fill="#67b7dc"
                            />
                            <Line
                              name="Qtde Corridas"
                              type="monotone"
                              dataKey="count"
                              stroke="#fdd400"
                            />
                            <Line
                              name="Valor médio"
                              type="monotone"
                              dataKey="corridaz_valorcalculado_avg"
                              stroke="green"
                            />
                          </ComposedChart>
                          <p style={{ marginLeft: 340 }}>Total: </p>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <ReactTable
                              pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                              data={this.props.viewModel.state.newvet2}
                              previousText="Anterior"
                              nextText="Próxima"
                              loadingText="Carregando..."
                              noDataText="Nenhum dado encontrado"
                              pageText="Página"
                              ofText="de"
                              rowsText="linhas"
                              getTdProps={(state, props, columns) => {
                                return {
                                  onClick: (e) => {
                                    if (props) {
                                      this.props.viewModel.escolhecorrida2(
                                        props.row._original
                                      );
                                      this.props.viewModel.setState({
                                        open2: true,
                                      });
                                    }
                                  },
                                };
                              }}
                              columns={[
                                {
                                  Header: "Dia",
                                  accessor: "t12_dthr_corrida",
                                },
                                {
                                  Header: "Quantidade",
                                  accessor: "count",
                                },
                                {
                                  Header: "Valor total",
                                  id: "corridaz_valorcalculado_sum",
                                  accessor: (d) => (
                                    <NumberFormat
                                      value={d.corridaz_valorcalculado_sum}
                                      displayType={"text"}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      prefix={"R$"}
                                    />
                                  ),
                                },
                                {
                                  Header: "Valor médio",
                                  id: "corridaz_valorcalculado_avg",
                                  accessor: (d) => (
                                    <NumberFormat
                                      value={d.corridaz_valorcalculado_avg}
                                      displayType={"text"}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      prefix={"R$"}
                                    />
                                  ),
                                },
                              ]}
                              defaultPageSize={5}
                              className="-striped -highlight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {false && (
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="icon-bar-chart "></i>
                            <span className="caption-subject  bold uppercase">
                              HORA DO DIA
                            </span>
                            &nbsp;
                            <span className="caption-helper">
                              Gráficos &amp; Detalhamento
                            </span>
                          </div>
                        </div>
                        <div
                          className="portlet-body"
                          style={{ height: "auto" }}
                        >
                          <div className="row">
                            <ComposedChart
                              width={965}
                              height={400}
                              data={this.props.viewModel.state.newvet3}
                            >
                              <XAxis dataKey="t12_dthr_corrida" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="bottom" />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                name="Valor total"
                                dataKey="corridaz_valorcalculado_sum"
                                barSize={20}
                                fill="#67b7dc"
                              />
                              <Line
                                name="Qtde Corridas"
                                type="monotone"
                                dataKey="count"
                                stroke="#fdd400"
                              />
                              <Line
                                name="Valor médio"
                                type="monotone"
                                dataKey="corridaz_valorcalculado_avg"
                                stroke="green"
                              />
                            </ComposedChart>
                            <p style={{ marginLeft: 340 }}>Total: </p>
                          </div>
                          <div className="row">
                            <div className="col-md-7">
                              <ReactTable
                                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                                data={this.props.viewModel.state.newvet3}
                                previousText="Anterior"
                                nextText="Próxima"
                                loadingText="Carregando..."
                                noDataText="Nenhum dado encontrado"
                                pageText="Página"
                                ofText="de"
                                rowsText="linhas"
                                getTdProps={(state, props, columns) => {
                                  return {
                                    onClick: (e) => {
                                      if (props) {
                                        this.props.viewModel.escolhecorrida3(
                                          props.row._original
                                        );
                                        this.props.viewModel.setState({
                                          open3: true,
                                        });
                                      }
                                    },
                                  };
                                }}
                                columns={[
                                  {
                                    Header: "Hora",
                                    accessor: "t12_dthr_corrida",
                                  },
                                  {
                                    Header: "Valor total",
                                    id: "corridaz_valorcalculado_sum",
                                    accessor: (d) => (
                                      <NumberFormat
                                        value={d.corridaz_valorcalculado_sum}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"R$"}
                                      />
                                    ),
                                  },
                                  {
                                    Header: "Valor médio",
                                    id: "corridaz_valorcalculado_avg",
                                    accessor: (d) => (
                                      <NumberFormat
                                        value={d.corridaz_valorcalculado_avg}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"R$"}
                                      />
                                    ),
                                  },
                                ]}
                                defaultPageSize={5}
                                className="-striped -highlight"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="daterangepicker dropdown-menu opensleft show-calendar"
                      style={{
                        display: this.props.viewModel.state.showDates
                          ? "block"
                          : "none",
                        top: "46.5%",
                        left: "auto",
                      }}
                    >
                      <div className="ranges">
                        <ul>
                          <li
                            value="0"
                            className={
                              this.props.viewModel.state.dateSelected === 0
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(0);
                            }}
                          >
                            Ontem
                          </li>
                          <li
                            value="1"
                            className={
                              this.props.viewModel.state.dateSelected === 1
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(1);
                            }}
                          >
                            Últimos 7 Dias
                          </li>
                          <li
                            value="2"
                            className={
                              this.props.viewModel.state.dateSelected === 2
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(2);
                            }}
                          >
                            Últimos 30 Dias
                          </li>
                          <li
                            value="3"
                            className={
                              this.props.viewModel.state.dateSelected === 3
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(3);
                            }}
                          >
                            Este Mês
                          </li>
                          <li
                            value="4"
                            className={
                              this.props.viewModel.state.dateSelected === 4
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(4);
                            }}
                          >
                            Último Mês
                          </li>
                          <li
                            value="5"
                            className={
                              this.props.viewModel.state.dateSelected === 5
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              this.props.viewModel.showDatesSelector(5, true);
                            }}
                          >
                            Intervalo de Datas
                          </li>
                        </ul>
                        <div
                          className="range_inputs"
                          style={{
                            display: this.props.viewModel.state.showCalendars
                              ? "inherit"
                              : "none",
                          }}
                        >
                          <div className="daterangepicker_start_input">
                            <DatePicker
                              inline
                              className="form-control"
                              selectsStart
                              dateFormat="DD/MM/YYYY"
                              startDate={this.props.viewModel.state.dataInicial}
                              endDate={this.props.viewModel.state.dataFinal}
                              maxDate={this.props.viewModel.state.dataFinal}
                              selected={this.props.viewModel.state.dataInicial}
                              onChange={
                                this.props.viewModel.dateInicialHandleChange
                              }
                            />
                          </div>
                          <div className="daterangepicker_end_input">
                            <DatePicker
                              inline
                              className="form-control"
                              selectsStart
                              dateFormat="DD/MM/YYYY"
                              startDate={this.props.viewModel.state.dataInicial}
                              endDate={this.props.viewModel.state.dataFinal}
                              maxDate={this.props.viewModel.state.dataFinal}
                              selected={this.props.viewModel.state.dataFinal}
                              onChange={
                                this.props.viewModel.dateFinalHandleChange
                              }
                            />
                          </div>
                          {/* //CHAMAR FUNÇAO DOS DADOS DOS GRAFICOS NO ONCLICK DO FILTRAR */}
                          <button
                            onClick={() => {
                              this.props.viewModel.setState({
                                showDates: false,
                                showCalendars: false,
                              });
                              this.props.viewModel.getDados();
                              this.props.viewModel.setState({ load: false });
                            }}
                            className="applyBtn btn btn-sm blue"
                          >
                            Filtrar
                          </button>
                          &nbsp;
                          <button
                            onClick={() =>
                              this.props.viewModel.setState({
                                showDates: false,
                                showCalendars: false,
                              })
                            }
                            className="cancelBtn btn btn-sm default"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>

                    {false && (
                      <div className="portlet light">
                        <div className="portlet-title">
                          <div className="caption">
                            <i className="icon-bar-chart "></i>
                            <span className="caption-subject  bold uppercase">
                              Código Promocional
                            </span>
                            &nbsp;
                            <span className="caption-helper">
                              Gráficos &amp; Detalhamento
                            </span>
                          </div>
                        </div>
                        <div
                          className="portlet-body"
                          style={{ height: "auto" }}
                        >
                          <div className="row">
                            <ComposedChart
                              width={965}
                              height={400}
                              data={this.props.viewModel.state.newvet4}
                            >
                              <XAxis dataKey="t12_dthr_corrida" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="bottom" />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                name="Valor total"
                                dataKey="corridaz_valorcalculado_sum"
                                barSize={20}
                                fill="#67b7dc"
                              />
                              <Line
                                name="Qtde Corridas"
                                type="monotone"
                                dataKey="count"
                                stroke="#fdd400"
                              />
                              <Line
                                name="Valor médio"
                                type="monotone"
                                dataKey="corridaz_valorcalculado_avg"
                                stroke="green"
                              />
                            </ComposedChart>
                            <p style={{ marginLeft: 340 }}>Total: </p>
                          </div>
                          <div className="row">
                            <div className="col-md-7">
                              <ReactTable
                                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                                data={this.props.viewModel.state.newvet4}
                                previousText="Anterior"
                                nextText="Próxima"
                                loadingText="Carregando..."
                                noDataText="Nenhum dado encontrado"
                                pageText="Página"
                                ofText="de"
                                rowsText="linhas"
                                getTdProps={(state, props, columns) => {
                                  return {
                                    onClick: (e) => {
                                      if (props) {
                                        this.props.viewModel.escolhecorrida4(
                                          props.row._original
                                        );
                                        this.props.viewModel.setState({
                                          open4: true,
                                        });
                                      }
                                    },
                                  };
                                }}
                                columns={[
                                  {
                                    Header: "Hora",
                                    accessor: "t12_dthr_corrida",
                                  },
                                  {
                                    Header: "Valor total",
                                    id: "corridaz_valorcalculado_sum",
                                    accessor: (d) => (
                                      <NumberFormat
                                        value={d.corridaz_valorcalculado_sum}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"R$"}
                                      />
                                    ),
                                  },
                                  {
                                    Header: "Valor médio",
                                    id: "corridaz_valorcalculado_avg",
                                    accessor: (d) => (
                                      <NumberFormat
                                        value={d.corridaz_valorcalculado_avg}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"R$"}
                                      />
                                    ),
                                  },
                                ]}
                                defaultPageSize={5}
                                className="-striped -highlight"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="daterangepicker dropdown-menu opensleft show-calendar"
                          style={{
                            display: this.props.viewModel.state.showDates
                              ? "block"
                              : "none",
                            top: "46.5%",
                            left: "auto",
                          }}
                        >
                          <div className="ranges">
                            <ul>
                              <li
                                value="0"
                                className={
                                  this.props.viewModel.state.dateSelected === 0
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(0);
                                }}
                              >
                                Ontem
                              </li>
                              <li
                                value="1"
                                className={
                                  this.props.viewModel.state.dateSelected === 1
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(1);
                                }}
                              >
                                Últimos 7 Dias
                              </li>
                              <li
                                value="2"
                                className={
                                  this.props.viewModel.state.dateSelected === 2
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(2);
                                }}
                              >
                                Últimos 30 Dias
                              </li>
                              <li
                                value="3"
                                className={
                                  this.props.viewModel.state.dateSelected === 3
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(3);
                                }}
                              >
                                Este Mês
                              </li>
                              <li
                                value="4"
                                className={
                                  this.props.viewModel.state.dateSelected === 4
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(4);
                                }}
                              >
                                Último Mês
                              </li>
                              <li
                                value="5"
                                className={
                                  this.props.viewModel.state.dateSelected === 5
                                    ? "active"
                                    : ""
                                }
                                onClick={(e) => {
                                  this.props.viewModel.showDatesSelector(
                                    5,
                                    true
                                  );
                                }}
                              >
                                Intervalo de Datas
                              </li>
                            </ul>
                            <div
                              className="range_inputs"
                              style={{
                                display: this.props.viewModel.state
                                  .showCalendars
                                  ? "inherit"
                                  : "none",
                              }}
                            >
                              <div className="daterangepicker_start_input">
                                <DatePicker
                                  inline
                                  className="form-control"
                                  selectsStart
                                  dateFormat="DD/MM/YYYY"
                                  startDate={
                                    this.props.viewModel.state.dataInicial
                                  }
                                  endDate={this.props.viewModel.state.dataFinal}
                                  maxDate={this.props.viewModel.state.dataFinal}
                                  selected={
                                    this.props.viewModel.state.dataInicial
                                  }
                                  onChange={
                                    this.props.viewModel.dateInicialHandleChange
                                  }
                                />
                              </div>
                              <div className="daterangepicker_end_input">
                                <DatePicker
                                  inline
                                  dateFormat="DD/MM/YYYY"
                                  className="form-control"
                                  selectsStart
                                  startDate={
                                    this.props.viewModel.state.dataInicial
                                  }
                                  endDate={this.props.viewModel.state.dataFinal}
                                  maxDate={this.props.viewModel.state.dataFinal}
                                  selected={
                                    this.props.viewModel.state.dataFinal
                                  }
                                  onChange={
                                    this.props.viewModel.dateFinalHandleChange
                                  }
                                />
                              </div>
                              {/* //CHAMAR FUNÇAO DOS DADOS DOS GRAFICOS NO ONCLICK DO FILTRAR */}
                              <button
                                onClick={() => {
                                  this.props.viewModel.setState({
                                    showDates: false,
                                    showCalendars: false,
                                  });
                                  this.props.viewModel.getDados();
                                  this.props.viewModel.setState({
                                    load: false,
                                  });
                                }}
                                className="applyBtn btn btn-sm blue"
                              >
                                Filtrar
                              </button>
                              &nbsp;
                              <button
                                onClick={() =>
                                  this.props.viewModel.setState({
                                    showDates: false,
                                    showCalendars: false,
                                  })
                                }
                                className="cancelBtn btn btn-sm default"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Loader>
            </div>
          </div>
        )}
        <p className="fontColor5">Version: {process.env.REACT_APP_VERSION}</p>

        <Modal
          keyboard
          show={this.props.viewModel.state.open1}
          onHide={this.props.viewModel.modalHide1}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <div>
                <ReactTable
                  manual
                  pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                  data={this.props.viewModel.state.showvet1}
                  previousText="Anterior"
                  className="custom-modal"
                  nextText="Próxima"
                  loadingText="Carregando..."
                  noDataText="Nenhum dado encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="linhas"
                  columns={[
                    {
                      Header: "Voucher",
                      accessor: "numero_voucher",
                    },
                    {
                      Header: "Nº da corrida",
                      accessor: "t12_num_corrida",
                    },
                    {
                      Header: "Origem",
                      accessor: "origem",
                      width: 300,
                    },
                    {
                      Header: "Data agendamento",
                      accessor: "t12_dthr_agendamento",
                      width: 150,
                      Cell: (props) => (
                        <div>
                          {props.original.t12_dthr_agendamento &&
                            moment(props.original.t12_dthr_agendamento).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                        </div>
                      ),
                    },
                    {
                      Header: "Data inicio",
                      accessor: "t12_dthr_embarques",
                      width: 150,
                      Cell: (props) => (
                        <div>
                          {props.original.t12_dthr_embarques &&
                            moment(props.original.t12_dthr_embarques).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                        </div>
                      ),
                    },
                    {
                      Header: "Data fim",
                      accessor: "t12_dthr_dembarques",
                      width: 150,
                      Cell: (props) => (
                        <div>
                          {props.original.t12_dthr_dembarques &&
                            moment(props.original.t12_dthr_dembarques).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                        </div>
                      ),
                    },
                    {
                      Header: "Nome solicitante",
                      accessor: "voucher_usuarios_nome",
                    },
                    {
                      Header: "Nome passageiro",
                      accessor: "t12_nome",
                    },
                    {
                      Header: "Email",
                      accessor: "voucher_usuarios_email",
                      width: 200,
                    },
                    {
                      Header: "Matricula",
                      accessor: "voucher_funcionario_matricula",
                      width: 200,
                    },
                    {
                      Header: "Destino",
                      accessor: "taxi333$enderecodestino",
                      width: 300,
                    },
                    {
                      Header: "Forma de pagamento",
                      accessor: "t12_cd_tp_pgto_a",
                    },
                    {
                      Header: "km",
                      accessor: "t12_dthr_corrida",
                    },
                    {
                      Header: "Valor",
                      accessor: "valorcalculado",
                      Cell: (props) => (
                        <div>
                          <NumberFormat
                            value={props.original.valorcalculado}
                            displayType={"text"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$"}
                          />
                        </div>
                      ),
                    },
                    {
                      Header: "Nome motorista",
                      accessor: "t08_motoristas_t08_nomemotorista",
                      width: 200,
                    },
                    {
                      Header: "Carro",
                      accessor: "modelo",
                    },
                    {
                      Header: "Placa",
                      accessor: "t07_carro_t07_placa_detran",
                      width: 150,
                    },
                    {
                      Header: "Ano",
                      accessor: "t07_carro_t07_ano_fabricacao",
                    },
                    {
                      Header: "Categoria",
                      accessor: "t12_dthr_corrida",
                    },
                  ]}
                  defaultPageSize={5}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.viewModel.criaExcel(
              this.props.viewModel.state.showvet1
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          keyboard
          show={this.props.viewModel.state.open2}
          onHide={this.props.viewModel.modalHide2}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <ReactTable
                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                data={this.props.viewModel.state.showvet2}
                previousText="Anterior"
                nextText="Próxima"
                className="custom-modal"
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                columns={[
                  {
                    Header: "Voucher",
                    accessor: "numero_voucher",
                  },
                  {
                    Header: "Nº da corrida",
                    accessor: "t12_num_corrida",
                  },
                  {
                    Header: "Origem",
                    accessor: "origem",
                    Cell: (props) => (
                      <div style={{ width: 150 }}>{props.original.origem}</div>
                    ),
                  },
                  {
                    Header: "Data agendamento",
                    accessor: "t12_dthr_agendamento",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_agendamento &&
                          moment(props.original.t12_dthr_agendamento).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data inicio",
                    accessor: "t12_dthr_embarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_embarques &&
                          moment(props.original.t12_dthr_embarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data fim",
                    accessor: "t12_dthr_dembarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_dembarques &&
                          moment(props.original.t12_dthr_dembarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Nome solicitante",
                    accessor: "voucher_usuarios_nome",
                  },
                  {
                    Header: "Nome passageiro",
                    accessor: "t12_nome",
                  },
                  {
                    Header: "Email",
                    accessor: "voucher_usuarios_email",
                  },
                  {
                    Header: "Matricula",
                    accessor: "voucher_funcionario_matricula",
                  },
                  {
                    Header: "Destino",
                    accessor: "taxi333$enderecodestino",
                  },
                  {
                    Header: "Forma de pagamento",
                    accessor: "t12_cd_tp_pgto_a",
                  },
                  {
                    Header: "km",
                    accessor: "t12_dthr_corrida",
                  },
                  {
                    Header: "Valor",
                    accessor: "valorcalculado",
                    Cell: (props) => (
                      <div>
                        <NumberFormat
                          value={props.original.valorcalculado}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$"}
                        />
                      </div>
                    ),
                  },
                  {
                    Header: "Nome motorista",
                    accessor: "t08_motoristas_t08_nomemotorista",
                  },
                  {
                    Header: "Carro",
                    accessor: "modelo",
                  },
                  {
                    Header: "Placa",
                    accessor: "t07_carro_t07_placa_detran",
                  },
                  {
                    Header: "Ano",
                    accessor: "t07_carro_t07_ano_fabricacao",
                  },
                  {
                    Header: "Categoria",
                    accessor: "t12_dthr_corrida",
                  },
                ]}
                defaultPageSize={5}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          keyboard
          show={this.props.viewModel.state.open3}
          onHide={this.props.viewModel.modalHide3}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <ReactTable
                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                data={this.props.viewModel.state.showvet3}
                previousText="Anterior"
                nextText="Próxima"
                style={{ width: "100%" }}
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                columns={[
                  {
                    Header: "Voucher",
                    accessor: "numero_voucher",
                  },
                  {
                    Header: "Nº da corrida",
                    accessor: "t12_num_corrida",
                  },
                  {
                    Header: "Origem",
                    accessor: "origem",
                  },
                  {
                    Header: "Data agendamento",
                    accessor: "t12_dthr_agendamento",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_agendamento &&
                          moment(props.original.t12_dthr_agendamento).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data inicio",
                    accessor: "t12_dthr_embarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_embarques &&
                          moment(props.original.t12_dthr_embarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data fim",
                    accessor: "t12_dthr_dembarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_dembarques &&
                          moment(props.original.t12_dthr_dembarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Nome solicitante",
                    accessor: "voucher_usuarios_nome",
                  },
                  {
                    Header: "Nome passageiro",
                    accessor: "t12_nome",
                  },
                  {
                    Header: "Email",
                    accessor: "voucher_usuarios_email",
                  },
                  {
                    Header: "Matricula",
                    accessor: "voucher_funcionario_matricula",
                  },
                  {
                    Header: "Destino",
                    accessor: "taxi333$enderecodestino",
                  },
                  {
                    Header: "Forma de pagamento",
                    accessor: "t12_cd_tp_pgto_a",
                  },
                  {
                    Header: "km",
                    accessor: "t12_dthr_corrida",
                  },
                  {
                    Header: "Valor",
                    accessor: "valorcalculado",
                    Cell: (props) => (
                      <div>
                        <NumberFormat
                          value={props.original.valorcalculado}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$"}
                        />
                      </div>
                    ),
                  },
                  {
                    Header: "Nome motorista",
                    accessor: "t08_motoristas_t08_nomemotorista",
                  },
                  {
                    Header: "Carro",
                    accessor: "modelo",
                  },
                  {
                    Header: "Placa",
                    accessor: "t07_carro_t07_placa_detran",
                  },
                  {
                    Header: "Ano",
                    accessor: "t07_carro_t07_ano_fabricacao",
                  },
                  {
                    Header: "Categoria",
                    accessor: "t12_dthr_corrida",
                  },
                ]}
                defaultPageSize={5}
                className="-striped -highlight"
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          keyboard
          show={this.props.viewModel.state.open4}
          onHide={this.props.viewModel.modalHide4}
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <ReactTable
                pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                data={this.props.viewModel.state.showvet4}
                previousText="Anterior"
                nextText="Próxima"
                style={{ width: "100%" }}
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                columns={[
                  {
                    Header: "Voucher",
                    accessor: "numero_voucher",
                  },
                  {
                    Header: "Nº da corrida",
                    accessor: "t12_num_corrida",
                  },
                  {
                    Header: "Origem",
                    accessor: "origem",
                  },
                  {
                    Header: "Data agendamento",
                    accessor: "t12_dthr_agendamento",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_agendamento &&
                          moment(props.original.t12_dthr_agendamento).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data inicio",
                    accessor: "t12_dthr_embarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_embarques &&
                          moment(props.original.t12_dthr_embarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Data fim",
                    accessor: "t12_dthr_dembarques",
                    Cell: (props) => (
                      <div>
                        {props.original.t12_dthr_dembarques &&
                          moment(props.original.t12_dthr_dembarques).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                      </div>
                    ),
                  },
                  {
                    Header: "Nome solicitante",
                    accessor: "voucher_usuarios_nome",
                  },
                  {
                    Header: "Nome passageiro",
                    accessor: "t12_nome",
                  },
                  {
                    Header: "Email",
                    accessor: "voucher_usuarios_email",
                  },
                  {
                    Header: "Matricula",
                    accessor: "voucher_funcionario_matricula",
                  },
                  {
                    Header: "Destino",
                    accessor: "taxi333$enderecodestino",
                  },
                  {
                    Header: "Forma de pagamento",
                    accessor: "t12_cd_tp_pgto_a",
                  },
                  {
                    Header: "km",
                    accessor: "t12_dthr_corrida",
                  },
                  {
                    Header: "Valor",
                    accessor: "valorcalculado",
                    Cell: (props) => (
                      <div>
                        <NumberFormat
                          value={props.original.valorcalculado}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$"}
                        />
                      </div>
                    ),
                  },
                  {
                    Header: "Nome motorista",
                    accessor: "t08_motoristas_t08_nomemotorista",
                  },
                  {
                    Header: "Carro",
                    accessor: "modelo",
                  },
                  {
                    Header: "Placa",
                    accessor: "t07_carro_t07_placa_detran",
                  },
                  {
                    Header: "Ano",
                    accessor: "t07_carro_t07_ano_fabricacao",
                  },
                  {
                    Header: "Categoria",
                    accessor: "t12_dthr_corrida",
                  },
                ]}
                defaultPageSize={5}
                className="-striped -highlight"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Graficos;
