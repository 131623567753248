import CoreService from "../service/CoreService";

export default class Service extends CoreService {
  async sendEmailResetPassword(email) {
    let result = await this.fetchOn(
      "voucher_usuarios/request_password_reset/" + email,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return result.result;
  }

  async loginPortal(email, password) {
    let result = await this.fetchOn("login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    return result.result;
  }
}
