import React, { Component } from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';

export default class TextBox extends Component {

    constructor(props) {
      super(props);
    }
    render() {
        return (<>
        <div>
        <input className="form-control" 
        placeholder={this.props.placeholder} 
        value={this.props.value} 
        onChange={e => this.props.onChange(e)} 
        onBlur={e => this.props.onBlur(e)}
        type="text" 
        />
        {this.props.error &&
            <div className={"alert alert-danger"} style={{padding: 3, marginBottom: 2, textAlign: 'center'}}>{this.props.error}</div>
        }
        </div>
        </>)
    }
}