import React, { Component } from "react";
import WS from "../../../services/webservice";

export default class ViewModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertDisplay: "display-hide",
      alertText: "Usuário ou Senha inválidos",
      password: "",
      samePassword: "",
      mudada: false,
    };

    this.webservice = new WS();

    this.mudarSenha = this.mudarSenha.bind(this);
  }

  mudarSenha() {
    let s = this.state;

    if (s.password === "") {
      this.setState({ alertDisplay: "alert-danger", alertText: "Por favor preencha sua nova senha!" });
      return;
    }
    if (s.samePassword === "") {
      this.setState({ alertDisplay: "alert-danger", alertText: "Por favor preencha sua confirmação de senha!" });
      return;
    }
    if (s.password !== s.samePassword) {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "As duas senhas são diferentes! Elas devem ser iguais!",
      });
      return;
    }

    this.webservice.resetPassword(s.password, this.props.match.params.id).then((result) => {
      console.log(result);
      this.setState({
        mudada: true,
        alertDisplay: "alert-success",
        alertText: "Nova senha criada com sucesso! Aguarde você já será redirecionado!",
      });
      this.timerId = setInterval(() => {
        this.props.history.push("/");
      }, 3000);
    });
  }
}
