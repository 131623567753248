import React, { Component } from "react";
import SubHeader from "../../header/SubHeaderView.js";
// import ViewModel from "./controllers/ViewModel.js"
import EmpregadosV2View from "./views/EmpregadosV2.js";

export default class EmpregadosV2 extends Component {
  constructor(props) {
    super(props);
    //   this.viewModel = new ViewModel();
    //   this.viewModel.mirrorState = (s) => this.setState(s);
    //   this.viewModel.props = props;
  }

  componentDidMount() {
    // this.viewModel.fetchData(this.props.childProps.user);
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Relatórios" textoCentral="Funcionários" />
        <div className="page-content">
          <div className="container">
            <EmpregadosV2View {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
