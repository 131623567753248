import CoreService from "../service/CoreService";

export default class Service extends CoreService {

    async sendEmailResetPassword(email) {
        let result = await this.fetchOff('voucher_usuarios/request_password_reset/' + email, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
    
        return result.result;
      }

      async loginPortal(email, password) {
        let result = await this.fetchOff('login', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        })
    
        return result.result;
      }

      async getPreCadastro(cnpj) {
        let result = await this.fetchOff("precadastroempresa/consulta?cnpj=" + cnpj)
        return result.result;
      }


      async enviarPreCadastro(central, cnpj, tipoparticipante, razaosocial, cep, rua, numero, complemento, bairro, cidade, uf,telefone, email, cpfresponsavel, nomeresponsavel, codigoindicacao) {
        let result = await this.fetchOff('precadastroempresa', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            central: central.toString().trim(),
            cnpj: cnpj.toString().replace(/[^+\d]/g, '').trim(),
            Tipo_de_Participante: tipoparticipante.toString().trim(),
            razao_social: razaosocial.toString().trim(),
            cep: cep.toString().trim(),
            rua: rua.toString().trim(),
            numero: numero.toString().toString().trim(),
            complemento: complemento.toString().trim(),
            bairro: bairro.toString().trim(),
            cidade: cidade.toString().trim(),
            uf: uf.toString().trim(),
            telefone: telefone.toString().trim(),
            email: email.toString().toLowerCase().trim(),
            nomeresponsavel: nomeresponsavel.toString().trim(),
            cpfresponsavel: cpfresponsavel.toString().replace(/[^+\d]/g, '').trim(),
            codigoindicacao: ""
          })
        })
    
        return result.result;
      }
}
