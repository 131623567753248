import CoreModel from "../service/CoreModel";
import Service from "./Service";
var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      alertDisplay: "display-hide",
      alertText: "Usuário ou Senha inválidos",
      email: "",
      e_mail: "",
      password: "",
      cnpj: "",
      razao: "",
      telefone: "",
      cep: "",
      numero: "",
      endereco: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      registryNome: "",
      registryTelefone: "",
      telefoneMask: "(99) 9999-9999",
      registryEmail: "",
      registryPassword: "",
      registryConfirmPass: "",
      cpfresponsavel: "",
      nomeresponsavel: "",
      showModal: false,
      login: true,
      cadastro: true,
      agent: {},
    };

    this.sendResetPassEmail = this.sendResetPassEmail.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

onChangeEmail(e) {
  this.setState({ email: e.target.value })
}

backToLogin() {
  this.setState({ login: true, alertDisplay: "display-hide" })
}
  onChangePassword(e) {
    this.setState({ password: e.target.value })
  }

  async loginUser(loginCallback, historyPush) {
    this.setState({ alertDisplay: "display-hide" });

    let result = await this.service.loginPortal(
      this.state.email,
      this.state.password
    );
    if (result) {
      loginCallback(result);
      historyPush(this.state.password);

    } else {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Usuário ou Senha inválidos",
      });
    }
  }

  forgotPassword() {
    this.setState({ login: false })
  }

  modalHide() {
    this.setState({ showModal: false });
  }

  async sendResetPassEmail() {
    if (this.state.email === "") {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Por favor preencha seu email!",
      });
      return;
    }

    this.service.sendEmailResetPassword(this.state.email).then((result) => {
      console.log(result);
      this.setState({
        alertDisplay: "alert-success",
        alertText: "Email enviado com sucesso!",
      });
    });
  }
}
