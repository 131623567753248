import { url } from "../../go-versao.js";
const serverUrl = url;
const server1 = "portal/";
const serverUrl2 = url + server1;

export default class CoreService {
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  async getSubTipo(tipo, filtered, order) {
    if (!filtered) {
      filtered = [];
    }
    let filtros = filtered.map((m, i) => m.id + "=" + m.value).join("&");
    if (order) {
      order = "&order=" + order;
    } else {
      order = "";
    }
    let pageSize = 200;
    let response = await this.fetchOn(
      tipo + "?per_page=" + pageSize + "&" + filtros + order
    );
    return response.result;
  }
  callEndpointWithPayload(method, parameters) {
    let payload = {
      method: method,
      parameters: parameters,
    };

    let token = this.getCookie("portal-token");
    let centralID = this.getCookie("central-id");
    let empresa = this.getCookie("empresa-id");

    return fetch(serverUrl + "client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
        "x-central-id": centralID,
        "x-empresa-id": empresa,
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json());
  }

  async getCentralInfosCores(domain) {
    try {
      let useDomain = !!domain ? domain : "gosolucoes";
      let result = await this.fetchOff(
        "precadastroempresa/subdominios?central=" + useDomain
      );
      if (!!result.result) {
        return result.result;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  eraseCookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  async fetchOn(extension, object = { headers: {} }) {
    try {
      let token = this.getCookie("portal-token");
      let centralID = this.getCookie("central-id") || 1;
      let empresaID = this.getCookie("empresa-id");

      object.headers = {
        ...object.headers,
        "x-access-token": token,
        "x-central-id": centralID,
        "x-empresa-id": empresaID,
      };
      //console.log(serverUrl2 + extension);
      let result = await fetch(serverUrl2 + extension, object);
      let count = result.headers.get("X-Total-Count");
      let response = await result.json();

      return { result: response, count: count };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async getCentral(id) {
    let result = await this.fetchOn("central/" + id);
    result = result.result;
    return result;
  }
  async getEmpresas() {
    let result = await this.fetchOn(
      "voucher_empresa/?per_page=200&order=razaosocial&ativo=true"
    );
    return result.result;
  }
  async getUserById(id) {
    let result = await this.fetchOn("voucher_usuarios_v2/" + id);
    if (result.result) {
      return result.result;
    }
    return null;
  }
  async getFuncById(id) {
    if (!id) {
      return {};
    }

    let empresa = await this.getCookie("empresa-id");
    let result = await this.fetchOn(
      "voucher_empresa/" +
        empresa +
        "/funcionario?usuario=" +
        id +
        "&empresa=" +
        empresa
    );
    return result.result;
  }
  async getEmpresa(id) {
    let result = await this.fetchOn("voucher_empresa/" + id);
    return result.result;
  }
  async getCentrais() {
    let result = await this.fetchOn(
      "central/?per_page=200&order=nomefantasia&ativo=true"
    );
    return result.result;
  }
  async fetchOff(extension, object = { headers: {} }) {
    let token = "";
    let centralID = 1;
    let empresaID = 1;
    object.headers = {
      ...object.headers,
      "x-access-token": token,
      "x-central-id": centralID,
      "x-empresa-id": empresaID,
    };

    let result = await fetch(serverUrl + extension, object);
    let count = result.headers.get("X-Total-Count");
    let response = await result.json();

    return { result: response, count: count };
  }

  async getTipo(tipo, id_tipo, sorted, page, pageSize, filtered) {
    let response;
    let filtros = filtered
      .map(
        (m) =>
          m.id.replace("voucher_usuarios_", "voucher_usuarios.") + "=" + m.value
      )
      .join("&");
    if (id_tipo !== "") {
      response = await this.fetchOn(tipo + "/" + id_tipo);
    } else if (sorted.length > 0) {
      let order = sorted
        .map(
          (m) =>
            (m.desc ? "-" : "") +
            (m.id.search("voucher_usuarios")
              ? m.id.replace("voucher_roles_", "voucher_roles.")
              : m.id.replace("voucher_usuarios_", "voucher_usuarios."))
        )
        .join(",");
      //console.log("teste corrida", order)
      response = await this.fetchOn(
        tipo +
          "?per_page=" +
          pageSize +
          "&page=" +
          page +
          "&order=" +
          order +
          "&" +
          filtros
      );
    } else {
      response = await this.fetchOn(
        tipo + "?per_page=" + pageSize + "&page=" + page + "&" + filtros
      );
    }

    return response;
  }

  async getTipo2(tipo, id_tipo, sorted, page, pageSize, filtered) {
    let response;
    let filtros = filtered.map((m) => m.id + "=" + m.value).join("&");
    if (id_tipo !== "") {
      response = await this.fetchOn(tipo + "/" + id_tipo);
    } else if (sorted.length > 0) {
      let order = sorted
        .map(
          (m) =>
            (m.desc ? "-" : "") +
            (m.id.search("voucher_usuarios")
              ? m.id.replace("voucher_roles_", "voucher_roles.")
              : m.id.replace("voucher_usuarios_", "voucher_usuarios."))
        )
        .join(",");
      response = await this.fetchOn(
        tipo +
          "&per_page=" +
          pageSize +
          "&page=" +
          page +
          "&order=" +
          order +
          "&" +
          filtros
      );
    } else {
      response = await this.fetchOn(
        tipo + "&per_page=" + pageSize + "&page=" + page + "&" + filtros
      );
    }

    return response.result;
  }

  async getTipo3(tipo) {
    let response = await this.fetchOn(tipo);
    return response.result;
  }

  async deleteTipo(tipo, id_tipo) {
    let result = await this.fetchOn(tipo + "/" + id_tipo, { method: "DELETE" });

    return result.result;
  }

  async criarTipo(tipo, tipo_object) {
    let result = await this.fetchOn(tipo + "/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...tipo_object,
      }),
    });

    return result.result;
  }

  async alterarTipo(tipo, id_tipo, tipo_object) {
    let result = await this.fetchOn(tipo + "/" + id_tipo, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...tipo_object,
      }),
    });

    return result;
  }

  async getDepartamentoslist(id) {
    let result = await this.fetchOn(
      "voucher_empresa/" + id + "/departamentolist"
    );

    return result.result;
  }

  async getFunclist(id) {
    let result = await this.fetchOn(
      "voucher_empresa/" + id + "/funcionariolist"
    );

    // console.log("---------------------------> Resultado da busca por funcionários", result);

    return result.result;
  }

  async editUser(objDados) {
    let dados = {
      email: objDados.email,
      username: objDados.email,
      telefone: objDados.telefone,
      nome: objDados.nome,
      email_corrida: objDados.email_corrida,
      email_corrida_resp: objDados.email_corrida_resp,
      email_corrida_acomp: objDados.email_corrida_acomp,
      email_corrida_acomp_resp: objDados.email_corrida_acomp_resp
    }
    if (objDados.password) {
      dados.password = password;
    }

    // console.log('---------------CORESERVICE ID RECEBIDO----------------------->', objDados)

    try {
      let result = await this.fetchOn('voucher_usuarios_v2/' + objDados.userId, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados)
      })
  
      return result.result;
    } catch (error) {
      return null
    }
    
  }

  async editSenhaUser(senha, id) {
    let result = await this.fetchOn('voucher_usuarios_v2/' + id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: senha,
        nova_senha: false,
      })
    })

    return result.result
  }
  async saveFuncFavorito(func, fav) {
    if (fav === 0) { fav = null }
    let empresa = this.getCookie('empresa-id');
    let result = await this.fetchOn('voucher_empresa/' + empresa + '/funcionario/' + func, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        favorito_id: fav,
      })
    })

    return result.result;
  }

  async getFavoritosNew() {
    //console.log('getFavoritosNew')
    let result = await this.fetchOn('favoritos');
    return result.result;
  }
}