import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import NumberFormat from "react-number-format";
import moment from "moment";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 0}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);

class ListDepartamento extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { data, pages, loading, pageSize, currentPage } =
      this.props.viewModel.state;

    return (
      <div>
        <ReactTable
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.props.viewModel.fetchData}
          onPageChange={this.props.viewModel.onPageChange}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: "No.",
              accessor: "t12_num_corrida",
            },
            {
              Header: "Data",
              accessor: "t12_dthr_corridas",
              Cell: (props) => (
                <div>
                  {console.log("row", props.original)}
                  {moment(props.original.t12_dthr_corridas).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </div>
              ),
            },
            {
              Header: "Passageiro",
              accessor: "t12_nome",
            },
            {
              Header: "Endereço",
              accessor: "t12_endereco",
            },
            {
              Header: "Valor",
              id: "valorcalculado",
              accessor: (d) => (
                <NumberFormat
                  value={d.valorcalculado}
                  displayType={"text"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$"}
                />
              ),
            },
            {
              Header: "Centro de custo",
              accessor: "voucher_departamento_identificacao",
            },
            {
              Header: "Descrição",
              accessor: "voucher_departamento_descricao",
            },
            {
              Header: "Status",
              accessor: "ztatus3",
            },
          ]}
          defaultPageSize={pageSize}
          page={currentPage}
          className="-striped -highlight round7"
          style={{
            border: "none",
          }}
          getTheadProps={() => {
            return {
              className: "round7",
              style: {
                backgroundColor: "white",
                color: "black",
              },
            };
          }}
          getTheadFilterProps={() => {
            return { className: "round7" };
          }}
          getTheadFilterTrProps={() => {
            return { className: "round7" };
          }}
          getTbodyProps={() => {
            return { className: "round7" };
          }}
          getTdProps={() => {
            return { className: "alignCenter" };
          }}
          getTheadThProps={() => {
            return {
              style: {
                fontFamily: '"Roboto", sans-serif',
                fontWeight: "bold",
              },
            };
          }}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: (e) => {
                this.props.viewModel.escolhecorrida(rowInfo.original);
              },
              className: "round7",
            };
          }}
          PaginationComponent={CustomPaginationComponent}
        />
      </div>
    );
  }
}

export default ListDepartamento;
