import CoreModel from "../service/CoreModel";
import Service from "./Service";

import { cnpj, cpf } from "cpf-cnpj-validator";
import cep from "cep-promise";
var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      msgerrocnjp: '',
      alertDisplay: 'display-hide',
      alertText: 'Usuário ou Senha inválidos',
      email: '',
      e_mail: '',
      password: '',
      cnpj: '',
      razao: '',
      telefone: '',
      cep: '',
      numero: '',
      endereco: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      error: {},
      cnpjerror: false,
      registryNome: '',
      registryTelefone: '',
      telefoneMask: '(99) 9999-9999',
      registryEmail: '',
      registryPassword: '',
      registryConfirmPass: '',
      cpfresponsavel: '',
      nomeresponsavel: '',
      showModal: false,
      login: false,
      cadastro: false,
      agent: {},
    };
    
    this.precadastro = this.precadastro.bind(this);
    this.checkCnpf = this.checkCnpf.bind(this);
    this.checkCpf = this.checkCpf.bind(this);
    this.justCheckText = this.justCheckText.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.validaCep = this.validaCep.bind(this);

  }


  async checkCnpf() {
    let valor = this.state.cnpj;
    let erros = this.state.error;
    let ok = true;
    delete erros.cnpj;

    if (ok && !valor) {
      ok = false;
      erros.cnpj = "Campo Obrigatório";
    }
    valor = valor.replace(/[^+\d]/g, "");
    if (ok && valor.length < 5) {
      ok = false;
      erros.cnpj = "Campo Obrigatório";
    }
    if (ok && !cnpj.isValid(valor)) {
      ok = false;
      erros.cnpj = "CNPJ inválido";
    }
    if (ok) {
      let exist = await this.service.getPreCadastro(valor);
      if (!exist) {
        ok = false;
        erros.cnpj = "Campo Obrigatório";
      } else {
        if (exist.encontrado) {
          ok = false;
          erros.cnpj = "CNPJ já cadastrado, entre em contato com o atendimento";
        }
      }
    }
    this.setState({ error: erros });
  }
  async checkCpf() {
    let valor = this.state.cpfresponsavel;
    let erros = this.state.error;
    let ok = true;
    delete erros.cpfresponsavel;
    if (ok && !valor) {
      ok = false;
      erros.cpfresponsavel = "Campo Obrigatório";
    }
    valor = valor.replace(/[^+\d]/g, "");
    if (ok && valor.length < 5) {
      ok = false;
      erros.cpfresponsavel = "Campo Obrigatório";
    }
    if (ok && !cpf.isValid(valor)) {
      ok = false;
      erros.cpfresponsavel = "CPF inválido";
    }
    this.setState({ error: erros });
  }
  justCheckText(campo) {
    let valor = this.state[campo];
    let erros = this.state.error;
    let ok = true;
    delete erros[campo];
    if (ok && !valor) {
      ok = false;
      erros[campo] = "Campo Obrigatório";
    }
    if (ok && valor.length < 2) {
      ok = false;
      erros[campo] = "Campo Obrigatório";
    }
    this.setState({ error: erros });
  }
  modalHide() {
    this.setState({ showModal: false });
  }
  validaCep(calcula) {
    if (!calcula) {
      calcula = false;
    }
    let vcep = this.state.cep;
    let error = this.state.error;
    if (vcep.length < 9) {
      error.cep = "CEP inválido";
    } else {
      delete error.cep;
    }
    this.setState({
      error: error,
    });
    if (calcula) {
      cep(vcep).then((result) => {
        if (result.cep) {
          console.log("cep", result);
          this.setState({
            uf: result.state,
            cidade: result.city,
            bairro: result.neighborhood,
            endereco: result.street,
          });
        }
      });
    }
  }
  async precadastro() {
    let cadastro = true;

    this.checkCnpf();
    this.checkCpf();
    this.validaCep(false);
    this.justCheckText("razao");
    this.justCheckText("endereco");
    this.justCheckText("numero");
    this.justCheckText("bairro");
    this.justCheckText("cidade");
    this.justCheckText("uf");
    this.justCheckText("telefone");
    this.justCheckText("nomeresponsavel");
    this.justCheckText("e_mail");

    let erros = this.state.error;

    if (Object.keys(erros).length > 0) {
      cadastro = false;
    }

    if (cadastro) {
      let retorno = await this.service.enviarPreCadastro(
        this.props.central ? this.props.central : 3,
        this.state.cnpj,
        "pre cadastro",
        this.state.razao,
        this.state.cep,
        this.state.endereco,
        this.state.numero,
        this.state.complemento,
        this.state.bairro,
        this.state.cidade,
        this.state.uf,
        this.state.telefone,
        this.state.e_mail,
        this.state.cpfresponsavel,
        this.state.nomeresponsavel
      );

      if (retorno) {
        this.navigateTo("/");
      } else {
        alert("Ocorreu um erro");
      }
    }
  }
}
