import React, { Component } from "react";
import { Modal, Image } from "react-bootstrap";

import ViewModel from "./ViewModel";
import { bool } from "yup";


class Login extends Component {
  constructor(props) {
    super(props);

    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
    this.loginUser = this.loginUser.bind(this);
    this.sendResetPassEmail = this.sendResetPassEmail.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.historyPush = this.historyPush.bind(this);
    this.loginCallback = this.loginCallback.bind(this); 
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  componentDidMount() {
    this.viewModel.getTokenCallBack(this.props.loginUser)
  }

  async sendResetPassEmail() {
    this.viewModel.sendResetPassEmail();
  }

  async loginUser() {
    this.viewModel.loginUser();
  }

  forgotPassword() {
    this.viewModel.forgotPassword();
  }



  modalHide() {
    this.viewModel.modalHide()
  }

  loginUser() {
    this.viewModel.loginUser(this.loginCallback, this.historyPush);
  }

onChangeEmail(e) {
    this.viewModel.onChangeEmail(e);
}

  loginCallback(result) {
    if (!!this.props.loginUser) {
      this.props.loginUser(result);
    }
  }

  onChangePassword(e) {
    this.viewModel.onChangePassword(e);
  }

backToLogin() {
    this.viewModel.backToLogin();
}

  historyPush(password) {
    if (!!this.props.history) {
      this.props.history.push({
        pathname: "/",
        state: { senha: password },
      });
    }
  }

  render() {
    if (this.viewModel.getState().login) {
      return (<>
        <div className="loginBack loginBack2"></div>
        <div
          className="page-md login zControl"
        >
          <div className="logo">   
            {!!this.props.icone ? (
              <Image src={this.props.icone} style={{ width: 255 }}></Image>
            ) : (
              <></>
            )}
          </div>
          <div className="content bgColor4">
            <div className="login-form" style={{ display: "block" }}>
              <div className="fontColor3" style={{fontSize: 20, fontWeight: "bold", textAlign: 'center'}}>Seja bem vindo(a)</div>
              <div className="fontColor1" style={{fontSize: 14, fontWeight: "normal", textAlign: 'center'}}>Digite seu login para acessar</div>
              <div className={"alert " + this.viewModel.getState().alertDisplay}>
                <button
                  className="close"
                  onClick={this.backToLogin}
                ></button>
                <span>{this.viewModel.getState().alertText}</span>
              </div>
              <div className="form-group">
                <label className="control-label fontColor1">
                  Usuário
                </label>
                <div className="input-icon">
                  <i className="fa fa-user"></i>
                  <input
                    className="form-control placeholder-no-fix round7 bgColor4"
                    value={this.viewModel.getState().email}
                    onChange={this.onChangeEmail}
                    placeholder="E-mail"
                    type="text"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label fontColor1">
                  Senha
                </label>
                <button
                    className="link-button pull-right fontColor1"
                    onClick={this.forgotPassword}
                  >
                    Esqueci minha senha
                  </button>
                <div className="input-icon">
                  <i className="fa fa-lock"></i>
                  <input
                    className="form-control placeholder-no-fix round7 bgColor4"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.loginUser();
                      }
                    }}
                    value={this.viewModel.getState().password}
                    onChange={this.onChangePassword}
                    placeholder="Senha"
                    type="password"
                  />
                </div>
              </div>
              <div className="form-actions">
                <div className="clearfix">
                  <button
                    style={{
                      width: "100%",
                      borderRadius: 5,
                    }}
                    className="btn bgColor2 fontColor5 round7"
                    onClick={this.loginUser}
                  >
                    <i className="fa fa-check"></i> Acessar
                  </button>
                </div>
                <div className="clearfix">
                  
                </div>
              </div>
              <div className="fontColor1">
                Não possui conta?{" "}
                <button
                  type="button"
                  className="link-button pull-right fontColor1"
                  onClick={() =>
                    this.props.history.push({ pathname: "/cadastro" })
                  }
                >
                  Clique aqui
                </button>
              </div>
              <br />
            </div>
          </div>
        </div></>
      );
    } else {
      return (<>
      <div className="loginBack loginBack2"></div>
        <div
          className="page-md login zControl"
        >
          <div className="logo">
            {!!this.props.icone ? (
              <Image src={this.props.icone} style={{ width: 255 }}></Image>
            ) : (
              <></>
            )}
          </div>
          <div className="content bgColor4">
            <div className="register-form" style={{ display: "block" }}>
      
            <div className="fontColor3" style={{fontSize: 20, fontWeight: "bold", textAlign: 'center'}}>Esqueceu a senha?</div>
            <div className="fontColor1" style={{fontSize: 14, fontWeight: "regular", textAlign: 'center'}}>Digite seu e-mail para redefinir sua senha</div>
              <div className={"alert " + this.viewModel.getState().alertDisplay}>
                <button
                  className="close"
                  onClick={this.backToLogin}
                ></button>
                <span>{this.viewModel.getState().alertText}</span>
              </div>
              <div className="form-group">
                <label className="control-label fontColor1">
                  Email
                </label>
                <div className="input-icon">
                  <input
                    className="form-control placeholder-no-fix round7 bgColor4"
                    value={this.viewModel.getState().email}
                    onChange={this.onChangeEmail}
                    placeholder="Enviar e-mail"
                    type="text"
                  />
                </div>
              </div>

              <div className="form-actions">
                <button
                  className="btn bgColor1 fontColor4 round7"
                  onClick={this.backToLogin}
                >
                  <i className="m-icon-swapleft"></i> Voltar
                </button>
                <button
                  className="btn pull-right bgColor2 fontColor5 round7"
                  onClick={this.sendResetPassEmail}
                >
                  Enviar Email&nbsp;
                  <i className="m-icon-swapright m-icon-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div></>
      );
    }
  }
}
export default Login;
