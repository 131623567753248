import React, { Component } from 'react';
import Map from 'pigeon-maps';
import Overlay from 'pigeon-overlay';
import turfBbox from '@turf/bbox';
import { featureCollection as turfFeatureCollection, point as turfPoint } from '@turf/helpers';
import geoViewport from '@mapbox/geo-viewport';
import { ReactComponent as Marker } from './assets/marker.svg';
import Icon from 'react-icons-wrapper';

const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + (x + y + z) % 3)
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png`
  },
  stamen: (x, y, z, dpr) => {
    return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.jpg`
  },
  wmflabs: (x, y, z) => {
    return `https://tiles.wmflabs.org/bw-mapnik/${z}/${x}/${y}.png`
  }
  // streets: mapbox('streets-v10', MAPBOX_ACCESS_TOKEN),
  // satellite: mapbox('satellite-streets-v10', MAPBOX_ACCESS_TOKEN),
  // outdoors: mapbox('outdoors-v10', MAPBOX_ACCESS_TOKEN),
  // light: mapbox('light-v9', MAPBOX_ACCESS_TOKEN),
  // dark: mapbox('dark-v9', MAPBOX_ACCESS_TOKEN)
}



const Line = ({ mapState: { width, height }, latLngToPixel, coordsArray, style  }) => {
  
  


  //red
  let lines = []
  console.log('coordsArray',coordsArray)
  if (coordsArray.r) {
    if (coordsArray.r.length > 0) {
      let pixel = latLngToPixel([coordsArray.r[0].lon,coordsArray.r[0].lat])
      for (let i = 1; i < coordsArray.r.length; i++) {
        let pixel2 = latLngToPixel([coordsArray.r[i].lon,coordsArray.r[i].lat])
        lines.push(<line key={'r' + i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={{ stroke: coordsArray.r[i].cor, strokeWidth: 2 }} />)
        pixel = pixel2
      }
    }
  }
  if (coordsArray.g) {
    if (coordsArray.g.length > 0) {

    let pixel = latLngToPixel([coordsArray.g[0].lon,coordsArray.g[0].lat])
    for (let i = 1; i < coordsArray.g.length; i++) {
      let pixel2 = latLngToPixel([coordsArray.g[i].lon,coordsArray.g[i].lat])
      lines.push(<line key={'b' + i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={{ stroke: coordsArray.g[i].cor, strokeWidth: 2 }} />)
      pixel = pixel2
    }
  }}
  if (coordsArray.b) {
    if (coordsArray.b.length > 0) {

    let pixel = latLngToPixel([coordsArray.b[0].lon,coordsArray.b[0].lat])
    for (let i = 1; i < coordsArray.b.length; i++) {
      let pixel2 = latLngToPixel([coordsArray.b[i].lon,coordsArray.b[i].lat])
      lines.push(<line key={'b' + i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={{ stroke: coordsArray.b[i].cor, strokeWidth: 2 }} />)
      pixel = pixel2
    }
  }
  }
  if (coordsArray.o) {
    let orange = [];
    if (coordsArray.o.length > 0) {
    let pixel = latLngToPixel([coordsArray.o[0].lon,coordsArray.o[0].lat])
    for (let i = 1; i < coordsArray.o.length; i++) {
      let pixel2 = latLngToPixel([coordsArray.o[i].lon,coordsArray.o[i].lat])
      orange.push(<line key={'o' + i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={{ stroke: coordsArray.o[i].cor, strokeWidth: 2 }} />)
      lines.push(<line key={'o' + i} x1={pixel[0]} y1={pixel[1]} x2={pixel2[0]} y2={pixel2[1]} style={{ stroke: coordsArray.o[i].cor, strokeWidth: 2 }} />)
      pixel = pixel2
    }
  }
  }
  return (
    <svg width={width} height={height} style={{ top: 0, left: 0 }}>
      {lines}
    </svg>
  )
}

class GoMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centro: {lat:-25, lng: -49},
      carrega: false,
      carregando: false
    }
    this.arrumaCoordenada = this.arrumaCoordenada.bind(this);
    this.arrumaArrayMarcadores = this.arrumaArrayMarcadores.bind(this);
    this.centerZoomFromLocations = this.centerZoomFromLocations.bind(this);
    this.escolhe = this.escolhe.bind(this);
    this.escolhe2 = this.escolhe2.bind(this);
    this.retorna = this.retorna.bind(this);
    this.retornainic = this.retornainic.bind(this);
    this.handleonClick = this.handleonClick.bind(this)
    this.centerZoomControl = this.centerZoomControl.bind(this)
    this.itensRef = React.createRef();

  }
  arrumaCoordenada(latlng) {
    let saida = [];
    if (latlng.lat) {saida.push(latlng.lat);} 
    if (latlng.latitude) {saida.push(latlng.latitude);} 
    if (latlng.lng) {saida.push(latlng.lng);}
    if (latlng.lon) {saida.push(latlng.lon);}
    if (latlng.longitude) {saida.push(latlng.longitude);}
    return saida;
  }
  arrumaArrayMarcadores(marcadores) {
    let saida = [];
    if (marcadores) {
      for (let index = 0; index < marcadores.length; index++) {
        const element = marcadores[index];
        let item = {
          position: this.arrumaCoordenada(element),
          color: element.color ? element.color : null,
          tipocolaborador: element.tipocolaborador ? element.tipocolaborador : 0,
          text: element.label ? element.label : element.text ? element.text : null,
          objeto: element
        }
        if (item.position.length > 1) {
          saida.push(item)
        }
        if (element.tipocolaborador==='2') {
          saida.push({
            position: this.arrumaCoordenada(element),
            color: element.color ? element.color : null,
            tipocolaborador: '22',
            text: element.label ? element.label : element.text ? element.text : null,
            objeto: element
          })
        }
      }
    }
    return saida;
  }
  escolhe(ponto) {
    this.setState({
      centro: ponto,
      carrega: true
    })
    setTimeout(function () { this.retorna(); }.bind(this), 5000);
  }
  escolhe2(ponto) {
    this.setState({
      centro: ponto,
      carrega: true
    })
    setTimeout(function () { this.retorna(); }.bind(this), 1000);
  }
  retornainic() {
    this.setState({
      centro: {},
      carrega: false,
      carregando: true
    })
  }
  componentDidMount() {
    this.controleMapa = setInterval(function () { this.centerZoomControl(); }.bind(this), 5000);
    setTimeout(function () { this.escolhe2({lat:-25, lng: -49}); }.bind(this), 1000);
  }
  retorna() {
    this.setState({
      centro: {},
      carrega: false
    })
  }
  handleonClick(marcador) {
    if (this.props.MarcadorClick) {
      this.props.MarcadorClick(marcador.objeto)
    }
  }
  centerZoomFromLocations (locations, width, height) {
    const points = locations.map(location => turfPoint(location.position))
    const features = turfFeatureCollection(points)
    const bounds = turfBbox(features)
    let zoomtemp = 17;
    const { center, zoom } = geoViewport.viewport(bounds, [width, height])
    console.log('centro',[center, zoom])
    if (!isNaN(zoom)) {zoomtemp = zoom}
    return {
      center: center,
      zoom: Math.min(zoomtemp,16)
    }
  }
  centerZoomControl() {
    const offsetHeight = this.itensRef.current?.offsetHeight;
    let position = this.props.position ? this.arrumaCoordenada(this.props.position) : this.arrumaCoordenada({lat:-25, lng: -49});
    let minZoom = this.props.minZoom ? this.props.minZoom : 7;
    let maxZoom = this.props.maxZoom ? this.props.maxZoom : 19;
    let defaultZoom = this.props.defaultZoom ? this.props.defaultZoom : 16;
    let width = null;
    if (this.props.width) {
      if (isNaN(this.props.width)) {
        if (this.props.width.indexOf('%') >= 0) {
          width = parseFloat(this.props.width)/100 * window.innerWidth;
        } else {
          width = window.innerWidth ? window.innerWidth : 400;
        }
      } else {
        width = this.props.width;
      }
    } else {
      width = window.innerWidth ? window.innerWidth : 400;
    }
    let height = null;
    if (this.props.height) {
      if (isNaN(this.props.height)) {
        if (this.props.height.indexOf('%') >= 0) {
          height = parseFloat(this.props.height)/100 * window.innerHeight;
        } else {
          height = window.innerHeight ? window.innerHeight : 400;
        }
      } else {
        height = this.props.height;
      }
    } else {
      height = window.innerHeight ? window.innerHeight : 400;
    }
    height = height - (offsetHeight ? offsetHeight : 0);
    let center = position;
    let zoom = defaultZoom;
    let marcadores = [];
    let marcadores2 = [];
    let marcadores3 = [];
    let marcadores4 = [];

    let marcadoresZoom = [];

    marcadoresZoom = [...marcadoresZoom, ...this.arrumaArrayMarcadores(this.props.markO)]
    marcadoresZoom = [...marcadoresZoom, ...this.arrumaArrayMarcadores(this.props.mark1)]
    marcadoresZoom = [...marcadoresZoom, ...this.arrumaArrayMarcadores(this.props.mark2)]
    marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.markO)]
    marcadores = [...marcadores, ...this.arrumaArrayMarcadores(this.props.mark1)]
    marcadores2 = [...marcadores2, ...this.arrumaArrayMarcadores(this.props.mark2)]
    marcadores3 = [...marcadores3, ...this.arrumaArrayMarcadores(this.props.mark3)]
    marcadores4 = [...marcadores4, ...this.arrumaArrayMarcadores(this.props.mark4)]

    let pontos = {};
    if (this.props.c_points) {
      pontos.r = this.props.c_points.map((a,b) => {return {lat:a[0], lon:a[1], cor:'red'}})
    }
    if (this.props.c_points1) {
      pontos.b = this.props.c_points1.map((a,b) => {return {lat:a[0], lon:a[1], cor:'blue'}})
    }
    if (this.props.c_points2) {
      pontos.g = this.props.c_points2.map((a,b) => {return {lat:a[0], lon:a[1], cor:'green'}})
    }
    if (this.props.c_points3) {
      pontos.o = this.props.c_points3.map((a,b) => {return {lat:a[0], lon:a[1], cor:'orange'}})
    }
    //console.log('marcadores2 2',this.props.mark2)
    //console.log('marcadores2 3',marcadores2)
    if (marcadoresZoom.length > 0) {
    let temp = this.centerZoomFromLocations(marcadoresZoom,width,height)
     center = temp.center;
     zoom = temp.zoom;
    }
    if (this.state.carrega) {
      center = this.state.centro;
      zoom = 16;
    }
    let defaultcolor = this.props.defaultcolor;
    console.log('centerZoomControl',{
      center: center,
      zoom: zoom,
      minZoom: minZoom,
      maxZoom: maxZoom,
      defaultZoom: defaultZoom,
      width: Math.abs(width),
      height: Math.abs(height),
      marcadores: marcadores,
      marcadores2: marcadores2,
      marcadores3: marcadores3,
      marcadores4: marcadores4,
      pontos: pontos,
      carregando: true,
      defaultcolor: defaultcolor
    })
    this.setState({
      center: center,
      zoom: zoom,
      minZoom: minZoom,
      maxZoom: maxZoom,
      defaultZoom: defaultZoom,
      width: Math.abs(width),
      height: Math.abs(height),
      marcadores: marcadores,
      marcadores2: marcadores2,
      marcadores3: marcadores3,
      marcadores4: marcadores4,
      pontos: pontos,
      carregando: true,
      defaultcolor: defaultcolor
    })
  }
  render() {
    let defaultcolor = this.props.defaultcolor;

    return (<div>
      {this.state.carregando && <div>
      <Map 
    center={this.state.center} 
    minZoom={this.state.minZoom}
    maxZoom={this.state.maxZoom}
    provider={providers['osm']}
    zoom={this.state.zoom} 
    defaultZoom={this.state.defaultZoom} 
    width={this.state.width} 
    height={this.state.height}>
    
    {this.state.marcadores.map((m, i) => {
      // return (
      //   <Marker anchor={m.position} payload={1} onClick={({ event, anchor, payload }) => {}} />
      // )
      return (
        <Overlay anchor={m.position} offset={[16, 32]} key={i}>
          <Marker onClick={() => {this.handleonClick(m)}} fill={m.color ? m.color : defaultcolor ? defaultcolor : 'red'} width={32} height={32} alt='' />
        </Overlay>
      )
    })}
    {this.state.marcadores2.map((m, i) => {
      // return (
      //   <Marker anchor={m.position} payload={1} onClick={({ event, anchor, payload }) => {}} />
      // )
      return (
        <Overlay anchor={m.position} offset={[16, 32]} key={i}>
          <Marker onClick={() => {this.handleonClick(m)}} fill={m.color ? m.color : defaultcolor ? defaultcolor : 'blue'} width={32} height={32} alt='' />
        </Overlay>
      )
    })}
    {this.state.marcadores3.map((m, i) => {
      // return (
      //   <Marker anchor={m.position} payload={1} onClick={({ event, anchor, payload }) => {}} />
      // )
      return (
        <Overlay anchor={m.position} offset={[16, 32]} key={i}>
          <Marker onClick={() => {this.handleonClick(m)}} fill={m.color ? m.color : defaultcolor ? defaultcolor : 'green'} width={32} height={32} alt='' />
        </Overlay>
      )
    })}
    {this.state.marcadores4.map((m, i) => {
      // return (
      //   <Marker anchor={m.position} payload={1} onClick={({ event, anchor, payload }) => {}} />
      // )
      if (m.tipocolaborador === '2') {
        return (
          <Overlay anchor={m.position} offset={[16, 32]} key={i}>
            <Icon name={'FaMapMarker'} size={32} color={'gold'} />
          </Overlay>
        )
      } else if (m.tipocolaborador === '22') {
        return (
          <Overlay anchor={m.position} offset={[8, 28]} key={i}>
            <Icon name={'FaCar'} size={16} color={'black'} />
          </Overlay>
        )
      } else {
        return (
          <Overlay anchor={m.position} offset={[16, 32]} key={i}>
            <Marker onClick={() => {this.handleonClick(m)}} fill={m.color ? m.color : defaultcolor ? defaultcolor : 'gold'} width={32} height={32} alt='' />
          </Overlay>
        )
      }
    })}
    <Line coordsArray={this.state.pontos} />
    

  </Map>
  {!this.props.EscondeAtalhos &&
  <div ref={this.itensRef}>
  {this.state.marcadores.map((m, i) => {
      return (
        <div 
        className='round7'
        style={{flex:1, flexDirection:'column', display: 'inline-flex', padding: 7, margin: 5, alignContent: 'center', alignItems:'center', backgroundColor:'lightblue'}} onClick={() => this.escolhe(m.position)}>
          <label style={{flex:1, width:'100%', textAlign:'center'}} >
          {m.label ? m.label : m.text ? m.text : i+1}
          </label>
          </div>
      )
    })}</div>}
    </div>}
  </div>)
  }

}
export default GoMap;