import React, { Component } from "react";
import {
  Button,
  Modal,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
} from "react-bootstrap";

class View extends Component {
  constructor(props) {
    super(props);

    this.campos = [
      { ref: "nome", label: "Identificação", type: "text", placeHolder: "" },
      {
        ref: "endereco",
        label: "Endereço",
        type: "autocomplete",
        placeHolder: "",
        autocomplete: "autocomplete",
      },
      {
        ref: "autocomplete",
        label: "autocomplete",
        type: "hidden",
        placeHolder: "",
      },
      {
        ref: "usuario",
        label: "usuario",
        type: "hidden",
        placeHolder: "",
        default: this.props.childProps.user.id,
      },
      { ref: "uuid", label: "uuid", type: "hidden", placeHolder: "" },
      {
        ref: "complemento",
        label: "Complemento",
        type: "text",
        placeHolder: "",
      },
      { ref: "coordenada", label: "Observação", type: "text", placeHolder: "" },
      {
        ref: "tagtipe",
        label: "Tipo",
        type: "selectint",
        placeHolder: "",
        listtype: "tagtipe",
        default: 0,
      },
    ];

    this.subtipos = [
      {
        tipo: "tagtipe",
        p: "object",
        array: [
          { id: 0, text: "Normal" },
          { id: 1, text: "Casa" },
          { id: 2, text: "Trabalho" },
        ],
      },
    ];
  }

  componentDidMount() {
    this.props.viewModel.orgSubLists(this.subtipos);
  }

  render() {
    const { successMessage, errorMessage, showModal, selecionado } =
      this.props.viewModel.state;

    return (
      <Modal
        show={showModal}
        bsSize="large"
        onHide={() =>
          this.props.viewModel.setState({ showModal: false, selecionado: {} })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Endereço Favorito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid style={{ padding: "15px" }}>
            {this.props.viewModel.renderFields(this.campos)}

            <div>
              <Button
                onClick={() =>
                  this.props.viewModel.saveSelecionado(this.campos)
                }
              >
                Salvar
              </Button>
              <Button
                onClick={() =>
                  this.props.viewModel.setState({
                    showModal: false,
                    selecionado: {},
                  })
                }
              >
                Cancelar
              </Button>
            </div>
          </Grid>
        </Modal.Body>
      </Modal>
    );
  }
}

export default View;
