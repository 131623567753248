import React, { Component } from "react";
import WS from "../../../services/webservice.js";

class ResetSenhaView extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    clearInterval(this.props.viewModel.state.timerId);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="page-md login">
        <div className="logo">
          <img src={require("../../../images/vaide10iconeroxo.png")} width={255} alt="" />
        </div>
        <div className="content rounded7 bgColor4 fontColor5 ">
          <div className="register-form hmargin5" style={{ display: "block" }}>
            <div class={"alert " + this.props.viewModel.state.alertDisplay}>
              <button
                className="close"
                onClick={() => this.props.viewModel.setState({ alertDisplay: "display-hide" })}
              ></button>
              <span>{this.props.viewModel.state.alertText}</span>
            </div>
            {!this.props.viewModel.state.mudada && (
              <div>
                <div className="form-group rowAjust">
                  <label className="control-label visible-ie8 visible-ie9">Nova Senha</label>
                  <div className="input-icon">
                    <i className="fa fa-user"></i>
                    <input
                      className="form-control placeholder-no-fix"
                      placeholder="Nova Senha"
                      value={this.props.viewModel.state.password}
                      onChange={(e) => this.props.viewModel.setState({ password: e.target.value })}
                      type="password"
                    />
                  </div>
                </div>
                <div className="form-group rowAjust">
                  <label className="control-label visible-ie8 visible-ie9">Confirmar Senha</label>
                  <div className="input-icon">
                    <i className="fa fa-user"></i>
                    <input
                      className="form-control placeholder-no-fix"
                      placeholder="Confirmar Senha"
                      value={this.props.viewModel.state.samePassword}
                      onChange={(e) => this.props.viewModel.setState({ samePassword: e.target.value })}
                      type="password"
                    />
                  </div>
                </div>
                <div className="form-actions">
                  <button
                    className="btn round7 bgColor2 fontColor5 hmargin5 "
                    onClick={() => this.props.viewModel.mudarSenha()}
                  >
                    Mudar Senha&nbsp;
                    <i className="m-icon-swapright m-icon-white"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="copyright">2018 © GO!</div>
        <div className="backstretch">
          <img alt="" src={require("../../../images/BG login.jpeg")} />
        </div>
      </div>
    );
  }
}
export default ResetSenhaView;
