import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { fitBounds } from 'google-map-react/utils';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import WS from '../services/webservice';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Dialog from 'react-bootstrap-dialog-v2'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from "react-to-print";
import { Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import GoMap from './GoMap.js';

import red_marker from '../images/red-marker.png';
import blue_marker from '../images/blue-marker.png';

// const MarkerComponent = ({ img_src }) => <div><img alt="marker" src={img_src} className="markers" /></div>;

class DetalhesCorrida extends Component {

  static defaultProps = {
    center: { lat: -25.475015, lng: -49.2911143 },
    zoom: 15
  };

  constructor(props) {
    super(props);

    this.state = {
      corrida: [],
      obs: '',
      tipo_cancel: [],
      showModalAgenda: false,
      center: { lat: -25.475015, lng: -49.2911143 },
      zoom: 15,
      horaAgend: moment(),
      dataAgend: moment(),
      markerLocal: [{
        lat: -25.475015,
        lng: -49.2911143,
        img_src: red_marker,
      }],
      markerDest: [{
        lat: '',
        lng: '',
        img_src: blue_marker,
      }],
      showExportar: 'none',
    };

    this.webservice = new WS();
    this.tempo = this.tempo.bind(this);
    this.gettipo = this.gettipo.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCenter = this.getCenter.bind(this);
    this.agendaCorrida = this.agendaCorrida.bind(this);
    this.cancelCorrida = this.cancelCorrida.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this)
  }
  dateHandleChange(date) {
    this.setState({
      dataAgend: date,
      horaAgend: moment()
    });
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  handleValueChange = (value) => {
    this.setState({ horaAgend: value })
  }
  handleClose() {
    this.setState({ showModalAgenda: false });
  }
  tempo() {
    let hora = Number(moment().hour())
    let minuto = Number(moment().minute())


    return (
      < TimePicker
        defaultValue={moment().hour(hora).minute(minuto)}
        onChange={this.handleValueChange}
        showSecond={false}
        value={this.state.horaAgend}
        allowEmpty={false}
      />
    )
  }
  handleShow() {
    //this.state.corrida[0].datahoraagendamento
    let dagend = moment(this.state.corrida[0].datahoraagendamento);
    this.setState({ showModalAgenda: true, obs: this.state.corrida[0].t12_obs, dataAgend:dagend});
  }
  componentWillMount() {
    
    if (this.props.location.state && this.props.location.state.corrida) {
      console.log('this.props.location.state.corrida',this.props.location.state.corrida)
      let corridashow = this.props.location.state.corrida;
      this.setState({
        corrida: this.state.corrida.concat([this.props.location.state.corrida]),
        markerLocal: [{
          lat: this.props.location.state.corrida.latitude,
          lng: this.props.location.state.corrida.longitude,
          text: this.props.location.state.corrida.endereco,
          img_src: red_marker,
        }],
        center: {
          lat: this.props.location.state.corrida.latitude,
          lng: this.props.location.state.corrida.longitude
        },
      },
        () =>
          this.props.history.replace({
            pathname: '/detalhes-corrida',
            state: {}
          })
      );
          if (corridashow.latitudedestino) {
            this.setState({
              markerDest: [{
                lat: corridashow.latitudedestino,
                lng: corridashow.longitudedestino,
                text: this.props.location.state.corrida.enderecodestino,
                img_src: red_marker,
              }]
            })
          }
    }
  }

  componentDidMount() {
    if (this.state.markerDest[0].lat !== '') { this.getCenter(); }
    this.gettipo()

  }

  async gettipo() {
    let result = await this.webservice.fetch2('tipo_cancelamento')
    let jresult = await result.json()
    this.setState({
      tipo_cancel: jresult,
    })
  }

  cancelar(motivo, id) {
    console.log("motivo2", motivo)
    if (id !== "") {
      this.webservice.cancelTaxi(this.state.corrida[0].id, motivo, id).then( result=>{
        Dialog.resetOptions();
              this.dialog.show({
                body: 'Corrida Cancelada!!',
                actions: [
                  Dialog.OKAction(() => {
                    this.props.history.push("/corridas-agendadas")
                  }),
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                },
              })
      })
    } else {
      alert("Escreve o motivo do cancelamento")
    }
  }

  handleChange() {
    this.setState({ selection: this.inputEl.value });
  }
  agendaCorrida() {
    var dataCompleta = null;
    let id = this.state.corrida[0].id;
      let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
      let hour = this.state.horaAgend.format("HH:mm");

      dataCompleta = moment(date + 'T' + hour + ':00').utc().format("YYYY-MM-DD HH:mm");
    this.webservice.updateAgenda(dataCompleta, id, this.state.obs).then(result => {
      Dialog.resetOptions();
            this.dialog.show({
              body: 'Alteração realizada com sucesso!!',
              actions: [
                Dialog.OKAction(() => {
                  this.props.history.push("/corridas-agendadas")
                }),
              ],
              bsSize: 'small',
              onHide: (dialog) => {
                dialog.hide()
              },
            })
    })
  }
  cancelCorrida() {
    Dialog.setOptions({
      defaultOkLabel: 'Sim',
      defaultCancelLabel: 'Não',
      primaryClassName: 'btn-danger '
    });
    if (this.state.corrida.length > 0) {
      this.dialog.show({
        body: <div><form>
          <FormGroup controlId="formControlsSelectMultiple">
            <ControlLabel>Motivo cancelamento</ControlLabel>
            <FormControl componentClass="select" onSelect={this.handleChange} onChange={this.handleChange} inputRef={el => this.inputEl = el} >
              <option value=""></option>
              {this.state.tipo_cancel.map(c => {
                return (
                  <option  value={c.id}>{c.descricao}</option>
                )
              })}

            </FormControl>
          </FormGroup>
          <FormGroup
            controlId="formBasicText"
          >
            {console.log("ioioi", this.state.tipo_cancel)}
            <ControlLabel>Observações: </ControlLabel>
            <FormControl
              type="text"
              componentClass="textarea"
              value={this.state.value2}
              placeholder="Escreva o texto"
              onChange={e => this.setState({ value2: e.target.value })}
            />

          </FormGroup>
        </form>
          <Button onClick={() => this.cancelar(this.state.value2, this.state.selection)}>Enviar</Button></div>,
        actions: [
          Dialog.CancelAction(() => { }),
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        },
      })
      return;
    }
  }

  getCenter() {
    const bounds = {
      ne: {
        lat: this.state.markerLocal[0].lat,
        lng: this.state.markerLocal[0].lng,
      },
      sw: {
        lat: this.state.markerDest[0].lat,
        lng: this.state.markerDest[0].lng,
      }
    }

    if (this.state.markerLocal[0].lat > bounds.ne.lat) {
      bounds.ne.lat = this.state.markerLocal[0].lat;
    }
    if (this.state.markerLocal[0].lng > bounds.ne.lng) {
      bounds.ne.lng = this.state.markerLocal[0].lng;
    }
    if (this.state.markerLocal[0].lat < bounds.sw.lat) {
      bounds.sw.lat = this.state.markerLocal[0].lat;
    }
    if (this.state.markerLocal[0].lng < bounds.sw.lng) {
      bounds.sw.lng = this.state.markerLocal[0].lng;
    }

    if (this.state.markerDest[0].lat !== '') {
      if (this.state.markerDest[0].lat > bounds.ne.lat) {
        bounds.ne.lat = this.state.markerDest[0].lat;
      }
      if (this.state.markerDest[0].lng > bounds.ne.lng) {
        bounds.ne.lng = this.state.markerDest[0].lng;
      }
      if (this.state.markerDest[0].lat < bounds.sw.lat) {
        bounds.sw.lat = this.state.markerDest[0].lat;
      }
      if (this.state.markerDest[0].lng < bounds.sw.lng) {
        bounds.sw.lng = this.state.markerDest[0].lng;
      }
    }

    const size = {
      width: 1216, // Map width in pixels
      height: 270, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size)
    this.setState({
      center: center,
      zoom: zoom,
    })
  }

  render() {
    //Change the current language to portuguese BR
    require('moment/locale/pt-br.js');
    moment.locale('pt-br');

    let marker = [];
    for (let index = 0; index < this.state.markerLocal.length; index++) {
      const m = this.state.markerLocal[index];
      marker.push({ lat: m.lat, lng: m.lng, img_src: null, label: m.text })
    }
    for (let index = 0; index < this.state.markerDest.length; index++) {
      const m = this.state.markerDest[index];
      marker.push({ lat: m.lat, lng: m.lng, img_src: null, label: m.text })
    }




    return (
      <div className="page-content-wrapper">

        <div className="page-content">
          <div className="container">
            <ul className="page-breadcrumb breadcrumb">
              <li>
                <i className="fa fa-clock-o"></i>&nbsp;
                <Link to="/corridas-agendadas">Corridas Agendadas</Link>&nbsp;
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <i className="fa fa-quote-left"></i>&nbsp;
                <Link to="/detalhes-corrida">Corrida Detalhada</Link>
              </li>
            </ul>
            <div className="page-content-inner" ref={el => (this.componentRef = el)}>
              <div className="mt-content-body">
                <div className="portlet light ng-scope">
                  <div className="portlet-body">
                    <div id="divBodyCorridaDet">
                      <div className="invoice ng-scope" ng-app="MetronicApp" ng-controller="CorridaDetalheController">
                        <div className="row invoice-logo">
                          {/* <div className="col-xs-6 invoice-logo-space">
                            <img id="imgLogoTopo" className="img-responsive" alt="" />
                          </div> */}
                          <div id="divTopo">
                            <p style={{ textAlign: 'left' }}>
                              {this.state.corrida[0] && ("#" + this.state.corrida[0].id + " / " + (this.state.corrida[0].datahoraagendamento ? moment(this.state.corrida[0].datahoraagendamento).format("DD MMM YYYY").toUpperCase() : moment(this.state.corrida[0].datahora).format("DD MMM YYYY").toUpperCase()))}
                              <span className="muted">
                              Detalhamento da Corrida<br />
                              </span>
                            </p>
                            <div style={{ textAlign: 'left' }}>
                              <strong>
                                <font size="4">
                                  {this.props.childProps.central.nomefantasia}
                                </font>
                              </strong>
                              <br />
                              {"CNPJ: " + this.props.childProps.central.cnpj}
                            </div>
                            <p></p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-xs-4">
                            <div className="portlet gren">
                              <div className="portlet-title">
                                <div className="caption">
                                  <i className="fa fa-map-marker"></i>Corrida:
                                </div>
                              </div>
                              <div className="portlet-body">
                                <ul className="list-unstyled">
                                  {/* <li>
                            <strong>Solicitação:</strong> <span id="dsTipoOrigem">Portal na Web</span>
                          </li> */}
                                  <li>
                                    <strong>Telefone:</strong> <span id="nrTelefone">{this.state.corrida[0].telefone}</span>
                                  </li>
                                  <li>
                                    <strong>Passageiro:</strong> <span id="dsNomePassageiro">{this.state.corrida[0].cliente}</span>
                                  </li>
                                  {this.state.corrida[0].voucher_departamento_identificacao &&
                                  <li>
                                    <strong>Centro de Custo:</strong> <span id="dsNomePassageiro">{this.state.corrida[0].voucher_departamento_identificacao}</span>
                                  </li>}
                                  {this.state.corrida[0].voucher_departamento_descricao &&
                                  <li>
                                    <strong>Descrição:</strong> <span id="dsNomePassageiro">{this.state.corrida[0].voucher_departamento_descricao}</span>
                                  </li>}
                                  <li>
                                    <strong>Origem:</strong> <span id="dsEnderecoOrigem">{this.state.corrida[0].endereco ?? this.state.corrida[0].endereco} {this.state.corrida[0].numero ?? this.state.corrida[0].numero}</span>
                                  </li>
                                  <li>
                                    <strong>Referência:</strong> <span id="dsReferenciaOrigem">{this.state.corrida[0].t12_obs}</span>
                                  </li>
                                  <li>
                                    <strong>Destino:</strong> <span id="dsEnderecoDestino">{this.state.corrida[0].enderecodestino !== ", , " ? this.state.corrida[0].enderecodestino : "N/D"}</span>
                                  </li>
                                  <li>
                                    <strong>Data:</strong> <span id="dsData">{this.state.corrida[0].datahoraagendamento ? moment(this.state.corrida[0].datahoraagendamento).format("DD/MM HH:mm") : moment(this.state.corrida[0].datahora).format("DD/MM HH:mm")}</span>
                                  </li>
                                  <li>
                                    <strong>QRU:</strong> <span id="nrChamado">{this.state.corrida[0].id}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-4">
                            <div className="portlet gren" id="divPagamento" >
                              <div className="well">
                                <div className="row static-info align-reverse" id="divVlEstimado" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Valor Estimado R$:</div>
                                  <div className="col-xs-3 value">
                                    <span id="vlEstimado">0,00</span>
                                  </div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD1" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">KM:</div>
                                  <div className="col-xs-3 value" id="divCDV1">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD3">
                                  <div className="col-xs-8 name">Valor Corrida R$:</div>
                                  <div className="col-xs-3 value" id="divCDV3">{this.state.corrida[0].valorcalculado ? this.state.corrida[0].valorcalculado.toFixed(2).toString().replace('.', ',') : '0,00'}</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD9" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Valor Desconto R$:</div>
                                  <div className="col-xs-3 value" id="divCDV9">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD10" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Deduções de Compartilhamento R$:</div>
                                  <div className="col-xs-3 value" id="divCDV10">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD11" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Deduções Compartilhamento KM:</div>
                                  <div className="col-xs-3 value" id="divCDV11">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD5" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Pedágio R$:</div>
                                  <div className="col-xs-3 value" id="divCDV5">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD6" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Estacionamento R$:</div>
                                  <div className="col-xs-3 value" id="divCDV6">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse" id="divCD12" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Rodoanel:</div>
                                  <div className="col-xs-3 value" id="divCDV12">R$0,00</div>
                                </div>
                                <div className="row static-info align-reverse">
                                  <div className="col-xs-3 name">&nbsp;</div>
                                  <div className="col-xs-8 value">_____________________________</div>
                                </div>
                                <div className="row static-info align-reverse" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">Total/Devido:</div>
                                  <div className="col-xs-3 value">R$<span id="vlCorrida">0,00</span></div>
                                </div>
                                <div className="row static-info align-reverse" style={{ display: 'none' }}>
                                  <div className="col-xs-8 name">KM Final:</div>
                                  <div className="col-xs-3 value"><span id="vlKM">0,000</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-4">
                          <GoMap
                                  width={350}
                                  height={500}
                                  markO={marker}
                                ></GoMap>
                          </div>
                        </div>
                        {false && <div className="row">
                          <div className="col-xs-6">
                            <div className="portlet gren">
                              <div className="portlet-title">
                                <div className="caption">
                                  <i className="fa fa-taxi"></i>
                                  Motorista:
                                </div>
                              </div>
                              <div className="portlet-body">
                                <div className="profile-sidebar">
                                  <div className="portlet light profile-sidebar-portlet">
                                    <div className="profile-userpic">
                                      <img src={require("../images/foto-moto.jpeg")} id="imgMotoFoto" style={{ height: 100, width: 100 }} className="img-responsive" alt="" />
                                    </div>
                                    <div className="profile-usertitle">
                                      <div className="profile-usertitle-name" style={{ fontSize: 14 }} id="dsMotoNome">
                                        Ainda não informado
                                      </div>
                                      <div className="profile-usertitle-job" style={{ fontSize: 12 }}>
                                        Motorista
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="todo-tasklist">
                                    <div className="todo-tasklist-item todo-tasklist-item-border-green">
                                      <ul className="list-unstyled">
                                        <li>
                                          Empresa:
                                          <span id="dsMotoCooperativa">N/D</span>
                                        </li>
                                        <li>
                                          No.Prefixo:
                                          <span id="dsMotoPrefixo" className="todo-tasklist-badge badge badge-roundless">N/D</span>
                                        </li>
                                        <li>
                                          Fone:
                                          <span id="dsMotoTelefone">N/D</span>
                                        </li>
                                        <li>
                                          Modelo do carro:
                                          <span id="dsMotoVeiculoModelo">N/D</span>
                                        </li>
                                        <li>
                                          Ano:
                                          <span id="nrVeiculoAnoModelo">N/D</span>
                                        </li>
                                        <li>
                                          Categoria:
                                          <span id="dsMotoVeiculoCategoria">Comum</span>
                                        </li>
                                        <li>
                                          Placa:
                                          <span id="dsMotoVeiculoPlaca">N/D</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}
                        <div className="row">
                          <div className="col-md-12">
                            <ReactTable
                              //manual
                              //data={this.state.corridas}
                              //pages={this.state.pages}
                              //loading={this.state.loading}
                              //onFetchData={this.fetchDataTimeOut}
                              data={this.state.corrida}
                              previousText='Anterior'
                              nextText='Próxima'
                              loadingText='Carregando...'
                              noDataText='Nenhum dado encontrado'
                              pageText='Página'
                              ofText='de'
                              rowsText='linhas'
                              columns={[
                                {
                                  Header: <span className="header-table-left">#</span>,
                                  accessor: "",
                                  width: 30,
                                  Cell: props => <div>1</div>
                                },
                                {
                                  Header: <span className="header-table-left">Lead Time</span>,
                                  accessor: "",
                                  Cell: props => <div>00:00:00</div>
                                },
                                {
                                  id: "dataAgenda",
                                  Header: <span className="header-table-left">Data/Hora</span>,
                                  accessor: d => d.datahoraagendamento ? moment(d.datahoraagendamento).format("DD/MM HH:mm") : moment(d.datahora).format("DD/MM HH:mm"),
                                },
                                {
                                  Header: <span className="header-table-left">Remetente</span>,
                                  accessor: "cliente",
                                  width: 220,
                                },
                                {
                                  id: "status",
                                  Header: <span className="header-table-left">Status</span>,
                                  accessor: d => d.datahoraagendamento ? "Corrida Agendada" : d.dataHoraRecebido ? "Corrida em Andamento" : d.dataHoraQRL ? "Corrida Finalizada" : "Corrida Pendente",
                                  width: 180,
                                },
                                {
                                  id: "obs",
                                  Header: <span className="header-table-left">Observação</span>,
                                  accessor: d => d.datahoraagendamento ? "Corrida Agendada p/ " + moment(d.datahoraagendamento).format("DD/MM HH:mm") : d.observacoes,
                                  width: 300,
                                },
                              ]}
                              defaultPageSize={1}
                              showPagination={false}
                              className="-striped -highlight"
                            />
                          </div>
                        </div>


                        {this.state.showModalAgenda &&
                          <div className="portlet gren" id="divPagamento" >
                            <div className="portlet-title">
                              <div className="caption">Alterar Agendamento</div>
                              </div>
                              <div className="well">
                                <div className="form-group form-md-line-input">
                              <label className="col-md-2 control-label" htmlFor="form_control_1">Agendado para:</label>
                              <div className="col-md-2">
                            {this.state.corrida[0].datahoraagendamento ? moment(this.state.corrida[0].datahoraagendamento).format("DD/MM HH:mm") : moment(this.state.corrida[0].datahora).format("DD/MM HH:mm")}
                          </div></div>
                            <div className="form-group form-md-line-input" id="divAgendamento">
                              <label className="col-md-2 control-label" htmlFor="form_control_1">Alterar para:</label>
                              <div className="col-md-2">
                                <div className="input-group has-success date date-picker" data-date-format="dd/mm/yyyy" data-date-start-date="">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="DD/MM/YYYY"
                                    minDate={moment()}
                                    selected={this.state.dataAgend}
                                    onChange={this.dateHandleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="input-group">
                                  {this.tempo()}
                                </div>
                              </div>
                            </div>
                            <div className="form-group form-md-line-input">
                          <label className="col-md-2 control-label" htmlFor="form_control_1">Observação:</label>
                          <div className="col-md-9">
                        <input className="form-control" value={this.state.obs} onChange={e => this.setState({ obs: e.target.value })} placeholder="Referência do Endereço de Origem" type="text" />

                      </div></div>
                            <div className="form-group form-md-line-input" id="divAgendamento">
                              <button type="button" className="btn btn-lg red-flamingo hidden-print margin-bottom-5"
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty('color', 'white', 'important');
                                  }
                                }}
                                onClick={() => { this.agendaCorrida() }}>
                                ALTERAR
                              </button>&nbsp;
                            </div>
                            </div>
                          </div>}

                        <div className="row">
                          <div className="col-xs-12 invoice-block" style={{ whiteSpace: 'nowrap' }}>
                            <br />
                            <button type="button" className="btn btn-lg yellow-crusta hidden-print margin-bottom-5" style={{ display: 'none' }}>
                              Refazer Rateio <i className="fa fa-reply-all"></i>
                            </button>&nbsp;
                            <button type="button" className="btn btn-lg red-flamingo hidden-print margin-bottom-5"
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty('color', 'white', 'important');
                                }
                              }}
                              onClick={() => { this.handleShow() }}>
                              Alterar Agendamento
                            </button>&nbsp;
                            <button type="button" className="btn btn-lg red-flamingo hidden-print margin-bottom-5"
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty('color', 'white', 'important');
                                }
                              }}
                              onClick={() => { this.cancelCorrida() }}>
                              Cancelar Corrida <i className="fa fa-frown-o"></i>
                            </button>&nbsp;
                            <button type="button" className="btn btn-lg purple hidden-print margin-bottom-5" style={{ display: 'none' }}>
                              Recibo Eletrônico <i className="fa fa-qrcode"></i>
                            </button>&nbsp;
                            <button type="button" className="btn btn-lg blue hidden-print margin-bottom-5"
                              ref={(el) => {
                                if (el) {
                                  el.style.setProperty('color', 'white', 'important');
                                }
                              }}
                              onClick={() => this.printDiv("pageToPrint")}>
                              Imprimir <i className="fa fa-print"></i>
                            </button>
                            <ReactToPrint
                              trigger={() => <div>Print this out!</div>}
                              content={() => this.componentRef}
                            />
                            <Dialog ref={(el) => { this.dialog = el }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default DetalhesCorrida;
