import React, { Component } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactTable from "react-table";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import Workbook from "react-excel-workbook-emptystring";

import WS from "../../../../services/webservice.js";

import Loader from "react-loader";

const CustomPaginationComponent = ({ page, pages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(0)}
      disabled={page === 0}
    >
      {"<<"} Primeira
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page - 1)}
      disabled={page === 0}
    >
      {"<"} Anterior
    </div>{" "}
    <span>
      Página {page + 1} de {pages}
    </span>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(page + 1)}
      disabled={page === pages - 1}
    >
      Próxima {">"}
    </div>{" "}
    <div
      className="btn round7 bgColor2 fontColor5 hmargin5"
      onClick={() => onPageChange(pages - 1)}
      disabled={page === pages - 1}
    >
      Última {">>"}
    </div>
  </div>
);
class VouchersFaturadosV2View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFinal: moment(),
      dataInicial: moment().startOf("month"),
      showExportar: "none",
      vouchers: [],
      auth: [],
      excel: [],
      pdf: false,
    };

    this.dateInicialHandleChange = this.dateInicialHandleChange.bind(this);
    this.dateFinalHandleChange = this.dateFinalHandleChange.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchData2 = this.fetchData2.bind(this);
    this.criaExcel = this.criaExcel.bind(this);

    this.webservice = new WS();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {}

  async fetchData2(dados, instance) {
    // show the loading overlay
    this.setState({ dados, instance });
    this.setState({ loading: true });
    //let empresa = this.webservice.getCookie('empresa-id');
    // fetch your data
    let order = dados.sorted;
    let mysize = 200;
    if (order.length <= 0) {
      order = [{ id: "t12_num_corrida", desc: true }];
    }
    let pages = 1;
    let result;
    result = await this.webservice.getTipo2(
      'corridas?faturamentovoucher_id={"ne": null}&voucher_eletronico_validado={"eq": true}&t12_st_corrida={"eq":"E"}&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }',
      "",
      order,
      pages,
      mysize,
      dados.filtered
    );
    //let count = result.headers.get('X-Total-Count');
    result = await result.json();

    this.setState({
      excel: result,
      loading: false,
    });
  }

  async fetchData(dados, instance) {
    // show the loading overlay
    this.setState({ dados, instance });
    this.setState({ loading: true });
    //let empresa = this.webservice.getCookie('empresa-id');
    // fetch your data
    let order = dados.sorted;
    //let mysize = 200;
    if (order.length <= 0) {
      order = [{ id: "t12_num_corrida", desc: true }];
    }
    let pages = dados.page + 1;
    let result;
    result = await this.webservice.getTipo2(
      'corridas?faturamentovoucher_id={"ne": null}&voucher_eletronico_validado={"eq": true}&t12_st_corrida={"eq":"E"}&t12_dthr_corrida={"gte": " ' +
        moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") +
        ' " ,"lte": "' +
        moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") +
        ' " }',
      "",
      order,
      pages,
      dados.pageSize,
      dados.filtered
    );
    let count = result.headers.get("X-Total-Count");
    result = await result.json();

    // let resultx;
    // resultx = await this.webservice.getTipo2('corridas?faturamentovoucher_id={"ne": null}&voucher_eletronico_validado={"eq": true}&t12_st_corrida={"eq":"E"}&t12_dthr_corrida={"gte": " ' + moment(this.state.dataInicial).format("YYYY-MM-DD 00:00") + ' " ,"lte": "' + moment(this.state.dataFinal).format("YYYY-MM-DD 23:59") + ' " }', '', order, 1, mysize, dados.filtered);
    //   //let count = result.headers.get('X-Total-Count');
    // resultx = await resultx.json();

    this.setState({
      auth: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false,
    });
  }

  criaExcel() {
    //return (<></>)
    return (
      <Workbook element={<button type="button" className="link-button" href onClick={(e) => { e.preventDefault(); }} style={{ margin: 5 }}><i className="fa fa-file-excel-o"></i>Excel Completo</button>}>
        <Workbook.Sheet data={this.state.excel} name="Vouchers a Faturar">
          <Workbook.Column label="Número Corrida" value="t12_num_corrida" width={20} />
          <Workbook.Column label="Hora da Corrida" value={row => moment(row.t12_dthr_corrida).format("DD/MM/YYYY HH:mm")} width={20} />
          <Workbook.Column label="Telefone" value="t12_fone" width={20} />
          <Workbook.Column label="Endereço Origem" value="t12_endereco" width={20} />
          <Workbook.Column label="Número Origem" value="t12_numero" width={20} />
          <Workbook.Column label="Cliente" value="t12_nome" width={20} />
          <Workbook.Column label="Centro de Custo" value="voucher_departamento_identificacao" width={20} />
          <Workbook.Column label="Descrição" value="voucher_departamento_descricao" width={20} />
          <Workbook.Column label="Observação" value="t12_obs" width={20} />
          <Workbook.Column label="Observação" value="t12_complemento" width={20} />
          <Workbook.Column label="Enredeço Destino" value="taxi333$enderecodestino" width={20} />
          <Workbook.Column label="Nome Motorista" value="t08_motoristas_t08_nomemotorista" width={20} />
          <Workbook.Column label="Valor" value="valorcalculado" width={20} />
          <Workbook.Column label="Voucher Avulso" value="voucher_avulso" width={20} />
          <Workbook.Column label="Placa Carro" value="t07_carro_t07_placa_detran" width={20} />
          <Workbook.Column label="Modelo Carro" value="modelo" width={20} />
          <Workbook.Column label="Cancelamento" value="tipocancelamento_obs" width={20} />
        </Workbook.Sheet>
      </Workbook>
    )
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => {
        this.fetchData(dados, instance);
      }, 300),
    });
  }

  dateInicialHandleChange(date) {
    this.setState({
      dataInicial: date,
    });
  }

  //Save the date selected from DatePicker
  dateFinalHandleChange(date) {
    this.setState({
      dataFinal: date,
    });
  }

  render() {
    //Change the current language to portuguese BR
    require("moment/locale/pt-br.js");
    moment.locale("pt-br");

    return (
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div className="portlet">
                      <div
                        className="portlet-title"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="table-actions-wrapper"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="form-inline ng-pristine ng-valid">
                            <label>Período:&nbsp;&nbsp;</label>
                            <div className="input-group input-large date-picker input-daterange">
                              <DatePicker
                                className="form-control"
                                maxDate={this.state.dataFinal}
                                selected={this.state.dataInicial}
                                dateFormat="DD/MM/YYYY"
                                onChange={this.dateInicialHandleChange}
                              />
                              <span
                                className="input-group-addon"
                                style={{ border: 0, background: 0 }}
                              >
                                Até
                              </span>
                              <DatePicker
                                className="form-control"
                                minDate={this.state.dataInicial}
                                maxDate={moment()}
                                dateFormat="DD/MM/YYYY"
                                selected={this.state.dataFinal}
                                onChange={this.dateFinalHandleChange}
                              />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <button
                              onClick={() => {
                                this.fetchData(
                                  this.state.dados,
                                  this.state.instance
                                );
                              }}
                              className="btn round7 bgColor2 fontColor5 hmargin5"
                            >
                              <i className="fa fa-check"></i>
                              Pesquisar
                            </button>
                          </div>
                        </div>
                        <div
                          className="actions"
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn round7 bgColor2 fontColor5 hmargin5"
                              onClick={() => {
                                this.fetchData2(
                                  this.state.dados,
                                  this.state.instance
                                );
                                this.setState({
                                  showExportar:
                                    this.state.showExportar === "none"
                                      ? "block"
                                      : "none",
                                  pdf: true,
                                });
                              }}
                            >
                              <i className="fa fa-share"></i>&nbsp;
                              <span className="hidden-480">Exportar</span>&nbsp;
                              <i className="fa fa-angle-down"></i>
                            </button>
                            <ul
                              className="dropdown-menu pull-right"
                              style={{ display: this.state.showExportar }}
                            >
                              <Loader loaded={!this.state.loading}>
                                <li style={{ padding: 5 }}>
                                  {this.criaExcel()}
                                </li>
                              </Loader>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="portlet-body">
                        <ReactTable
                          manual
                          data={this.state.auth}
                          pages={this.state.pages}
                          pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                          loading={this.state.loading}
                          onFetchData={this.fetchDataTimeOut}
                          previousText="Anterior"
                          nextText="Próxima"
                          loadingText="Carregando..."
                          noDataText="Nenhum dado encontrado"
                          pageText="Página"
                          ofText="de"
                          rowsText="linhas"
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                          }
                          columns={[
                            {
                              Header: "No. Fatura",
                              accessor: "faturamentovoucher_id",
                            },
                            {
                              Header: "Data",
                              filterable: false,
                              accessor: "t12_dthr_corrida",
                              width: 125,
                              Cell: (props) => (
                                <div>
                                  {!props.original.t12_dthr_agendamento &&
                                    moment(
                                      props.original.t12_dthr_corrida
                                    ).format("DD/MM/YYYY HH:mm")}
                                </div>
                              ),
                            },
                            {
                              Header: "Inicio",
                              filterable: false,
                              accessor: "t12_dthr_embarques",
                              width: 50,
                              Cell: (props) => (
                                <div>
                                  {props.original.t12_dthr_embarques &&
                                    moment(
                                      props.original.t12_dthr_embarques
                                    ).format("HH:mm")}
                                </div>
                              ),
                            },
                            {
                              Header: "Fim",
                              filterable: false,
                              accessor: "t12_dthr_dembarques",
                              width: 50,
                              Cell: (props) => (
                                <div>
                                  {props.original.t12_dthr_dembarques &&
                                    moment(
                                      props.original.t12_dthr_dembarques
                                    ).format("HH:mm")}
                                </div>
                              ),
                            },
                            {
                              Header: "Passageiro",
                              accessor: "t12_nome",
                              width: 150,
                            },
                            {
                              Header: "Centro de Custo",
                              accessor: "voucher_departamento_identificacao",
                              width: 150,
                            },
                            {
                              Header: "Trajeto",
                              filterable: false,
                              width: 350,
                              Cell: (props) => (
                                <div>
                                <b>Origem:</b> {props.original.t12_endereco} <br />
                                <b>Observação/Complemento:</b> {props.original.t12_obs} {props.original.t12_complemento} <br />
                                <b>Destino:</b> {props.original.taxi333$enderecodestino} </div>
                              ),
                            },
                            // {
                            //   Header: "VTR",
                            //   filterable: false,
                            //   accessor: "vtr",
                            // },
                            // {
                            //   Header: "KM",
                            //   filterable: false,
                            //   accessor: "km",
                            //   Cell: props => {
                            //     if (props.original.dados_corrida && props.original.dados_corrida.distance) {
                            //       return (
                            //         <div>
                            //           <NumberFormat value={props.original.dados_corrida.distance / 1000} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                            //
                            //         </div>
                            //
                            //       )
                            //     }
                            //
                            //   }
                            // },
                            {
                              Header: "Valor",
                              filterable: false,
                              id: "valorcalculado",
                              accessor: (d) => (
                                <NumberFormat
                                  value={d.valorcalculado}
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  prefix={" "}
                                />
                              ),
                            },
                          ]}
                          defaultPageSize={5}
                          className="-striped -highlight round7"
                          PaginationComponent={CustomPaginationComponent}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VouchersFaturadosV2View;
