import { useFormik } from "formik";
import * as yup from "yup";
import { realizarCadastro } from "../api";
import History from "../../service/History.js"

const validationSchema = yup.object({
      cpf: yup.string().required("Campo obrigatório"),
      nome_completo: yup.string().required("Campo obrigatório"),
      telefone: yup.string().required("Campo obrigatório"),
      email: yup.string().email().required("Campo obrigatório"),
      senha: yup.string().required("Campo obrigatório"),
      cidadeatuacao: yup.string().required("Campo obrigatório"),
      chave_pix: yup.string().required("Campo obrigatório"),
      carro_placa_do_detran: yup.string().required("Campo obrigatório"),
      fotobase64: yup.string()
});

function usePreCadastroController(central) {
  const onSubmit = async (values) => {
    try {
      const response = await realizarCadastro(values, central);
      History.push("/");
    } catch (error) {
      console.log("Erro ao realizar cadastro.", error);
      History.push("/");
    }
  };

  const formik = useFormik({
    initialValues: {
      cpf: "",
      nome_completo: "",
      telefone: "",
      email: "",
      chave_pix: "",
      carro_placa_do_detran: "",
      senha: "",
      fotobase64: "",
      cidadeatuacao: ""
    },
    validationSchema,
    onSubmit,
  });

  return { formik };
}

export default usePreCadastroController;
