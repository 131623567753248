import CoreModel from "../../service/CoreModel";
import Service from "./Service";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      fullData: [],
      data: [],
      pages: null,
      loading: true,
      currentPage: 0,
      pageSize: 10,
      calendar: [] || null,
      identificacao: "",
      descricao: "",
      valorlimit: 0,
      successMessage: "",
      errorMessage: "",
      showModal: false,
      departamentoSelecionado: null,
      editData: { identificacao: "", descricao: "", valorlimit: "" },
    };
  }

  handleEditInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      editData: { ...this.state.editData, [name]: value },
    });
  };

  handleEditClick = (departamento) => {
    this.setState({
      showModal: true,
      departamentoSelecionado: departamento,
      editData: {
        identificacao: departamento.identificacao,
        descricao: departamento.descricao,
        valorlimit: departamento.valorlimit,
      },
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { identificacao, descricao, valorlimit, calendar } = this.state;
    let calendarObj;
    try {
      calendarObj = typeof calendar === "string" ? JSON.parse(calendar) : calendar;
    } catch (e) {
      console.error("Erro ao parsear calendar:", e);
      this.setState({
        errorMessage: "Formato inválido para o campo calendar.",
      });
      return;
    }
    const dataParaEnviar = {
      identificacao,
      descricao,
      valorlimit: valorlimit,
      calendar: calendarObj,
      funcionarios,
    };
    try {
      const response = await this.service.criar(dataParaEnviar);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newItem = response;
      this.setState((prevState) => ({
        fullData: [...prevState.fullData, newItem],
        data: [...prevState.data, newItem].slice(0, prevState.pageSize),
        successMessage: "Departamento criado com sucesso!",
        errorMessage: "",
        showModal: false,
        identificacao: "",
        descricao: "",
        valorlimit: 0,
        funcionarios: [],
      }));
    } catch (error) {
      console.error("Erro ao criar departamento:", error.message);
      this.setState({
        errorMessage: error.message || "Erro ao criar departamento",
        successMessage: "",
      });
    }
  };

  fetchData = async () => {
    this.setState({ loading: true });
    try {
      const fullData = await this.service.listar();
      this.setState({
        showModal: false,
        fullData,
        data: fullData.slice(0, this.state.pageSize),
        pages: Math.ceil(fullData.length / this.state.pageSize),
        loading: false,
      });
    } catch (error) {
      console.log("Erro ao listar:", error);
      this.setState({ error, loading: false });
    }
  };

  handleUpdate = async (dadosAtualizados, id) => {
    if (!dadosAtualizados.calendar) {
      dadosAtualizados.calendar = null;
    }
    try {
      if (!!id) {
        await this.service.atualizar({ ...dadosAtualizados, id: id });
      } else {
        await this.service.criar(dadosAtualizados);
      }

      await this.fetchData();
    } catch (error) {
      console.error("Erro ao atualizar departamento:", error.message);
    }
  };

  handleDelete = async (id) => {
    try {
      await this.service.deletar(id);
      this.fetchData();
    } catch (error) {
      console.error("Erro ao deletar departamento:", error.message);
    }
  };

  toggleCreateDepartamento = () => {
    this.setState({
      showModal: true,
      departamentoSelecionado: {},
      editData: {
        identificacao: "",
        descricao: "",
        valorlimit: 0,
      },
    });
  };

  onPageChange = (pageIndex) => {
    const { fullData, pageSize } = this.state;
    const startRow = pageIndex * pageSize;
    const endRow = startRow + pageSize;
    this.setState({
      currentPage: pageIndex,
      data: fullData.slice(startRow, endRow),
    });
  };
}
