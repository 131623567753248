import React, { Component } from "react"
import SubHeader from "../../header/SubHeaderView.js"
import ViewModel from "./controllers/ViewModel.js"
import CentroDeCustoV2View from "./views/CentroDeCustoV2.js"

export default class CentroDeCustoV2 extends Component {
  constructor(props) {
    super(props);
    // this.viewModel = new ViewModel();
    // this.viewModel.mirrorState = (s) => this.setState(s);
    // this.viewModel.props = props;
  }

  componentDidMount() {
    // this.viewModel.fetchData(this.props.childProps.user);
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader textoEsquerda="Relatórios" textoCentral="Centro de Custo" />
        <div className="page-content">
          <div className="container">
            <CentroDeCustoV2View {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
