import React, { Component } from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import './../../../components/Bubbles.css'
import { fitBounds } from 'google-map-react/utils';
import moment from 'moment';
import WS from '../../../services/webservice.js';
import Loader from 'react-loader';
import NumberFormat from 'react-number-format';
import { Col, Row, Image, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import polyline from '@mapbox/polyline';
import Rating from 'react-star-rating-meter';
import AvaliacaoStar from './../../../components/avaliacaostar.js';
import GoMap from './../../../components/GoMap.js';

class AcompanhamentoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      teste: false,
      avapendente: null,
      motorista: {
        latitude: null,
        longitude: null,
      },
      nota: 0,
      dest: {
        latitude: -25.47,
        longitude: -49.29,
      },
      orig: {
        latitude: -25.47,
        longitude: -49.29,
      },
      value: "",
      center: {
        latitude: -25.47,
        longitude: -49.29,
      },
      defaultcenter: {
        lat: -25.47,
        lng: -49.29,
      },
      zoom: 16,
      marquerVerde: [],
      marquerAzul: [],
      rotaVerde:[],
      rotaAzul: [],
      rotaVermelha: [],
      rotaLaranja: [],
    };
    this.webservice = new WS();
    this.getRandom = this.getRandom.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getMot = this.getMot.bind(this);
    this.setPos = this.setPos.bind(this);
    this.avaliacorrida = this.avaliacorrida.bind(this);
    this.salvaAvaliacao = this.salvaAvaliacao.bind(this);
    this.decodefunc = this.decodefunc.bind(this);
  }
  avaliacorrida() {
    console.log('corrida','abrir')
    console.log('corrida 2',this.state.dados)
    this.setState({
      avapendente: this.state.dados
    })
  }
  salvaAvaliacao() {
    this.setState({
      avapendente: null
    })
  }
  setPos() {
    this.setState({
      orig: {
        latitude: this.state.dados.latitude_origem,
        longitude: this.state.dados.longitude_origem,
      },
      dest: {
        latitude: this.state.dados.latitude_destino,
        longitude: this.state.dados.longitude_destino,
      }
    })
    this.webservice.directions(this.state.dados.latitude_origem, this.state.dados.longitude_origem, this.state.dados.latitude_destino, this.state.dados.longitude_destino).then(resposta => {
      //console.log('drivers rota 2', resposta);
      if (resposta) {
        if (resposta.result) {
          if (resposta.result.routes[0]) {
            if (resposta.result.routes[0].geometry) {
              if (resposta.result.routes[0].geometry.coordinates) {
                let geometrics = resposta.result.routes[0].geometry.coordinates;
                this.setState({
                  rotaVermelha: geometrics
                })
              }
            }
          }
        }
      }
    })
  }
  async getRandom() {
    let resultado = await this.webservice.getRandom(this.props.match.params.id)
    if (resultado) {
      this.setState({
        dados: resultado,
        teste: true,
      }, () => { 
        this.setPos(); 
        if (this.state.dados.rotapoints) { this.decodefunc() }
      
      })
    } else {
      this.setState({
        dados: null,
      })
    }
    //let fim = await this.webservice.postMotAvalRandom(this.props.match.params.id)
    console.log("resultado", resultado)
  }

  decodefunc() {
    this.setState({
      rotaLaranja: this.state.dados.rotapoints,
    })
  }

  async getMot() {
    let resultado
    if (this.state.dados.datahoraqrv) {
      console.log("entrei")
      clearInterval(this.timerId)
      clearInterval(this.timerId2)
    } else {
      if (this.props.match.params.id) {
          console.log("OIOIO")
          resultado = await this.webservice.getMotRandom(this.props.match.params.id)
          console.log('getMoto 1',resultado)
          if (resultado.latitude) {
          let marquerAzul = [];
          let marquerVerde = [];
          if (this.state.dados.datahoraqrl) {
            marquerAzul= [{ lat: resultado.latitude, lng: resultado.longitude, img_src: null, label: 'Motorista' }];
            marquerVerde= [];
            this.webservice.directions(resultado.latitude, resultado.longitude, this.state.dados.latitude_destino, this.state.dados.longitude_destino).then(resposta => {
              //console.log('drivers rota 2', resposta);
              if (resposta) {
                if (resposta.result) {
                  if (resposta.result.routes[0]) {
                    if (resposta.result.routes[0].geometry) {
                      if (resposta.result.routes[0].geometry.coordinates) {
                        let geometrics = resposta.result.routes[0].geometry.coordinates;
                        this.setState({
                          rotaVerde: [],
                          rotaAzul: geometrics
                        })
                      }
                    }
                  }
                }
              }
            })
          } else {
            marquerAzul= [];
            marquerVerde= [{ lat: resultado.latitude, lng: resultado.longitude, img_src: null, label: 'Motorista' }];
            this.webservice.directions(resultado.latitude, resultado.longitude, this.state.dados.latitude_origem, this.state.dados.longitude_origem).then(resposta => {
              //console.log('drivers rota 1', resposta);
              if (resposta) {
                if (resposta.result) {
                  if (resposta.result.routes[0]) {
                    if (resposta.result.routes[0].geometry) {
                      if (resposta.result.routes[0].geometry.coordinates) {
                        let geometrics = resposta.result.routes[0].geometry.coordinates;
                        this.setState({
                          rotaVerde: geometrics,
                          rotaAzul: []
                        })
                      }
                    }
                  }
                }
              }
            })
          }
          console.log('getMoto 2',[resultado,marquerAzul,marquerVerde])
          this.setState({
            motorista: resultado,
            marquerVerde: marquerVerde,
            marquerAzul: marquerAzul,
            latlng: {
              latitude: resultado.latitude,
              longitude: resultado.longitude
            }
          }, console.log('testando', this.state.motorista))}
        }
      console.log('center',this.state.dados)
    }
  }
  componentDidMount() {
    this.timerId = setInterval(this.getMot, 5000)
    this.timerId2 = setInterval(this.getRandom, 15000)
    this.getRandom()
  }


  handleClick(rating, label, event) {
    this.setState({ nota: rating });
  }

  sendNote(link, msg, nota) {
    let resultado = this.webservice.postMotAvalRandom(link, nota, msg);
    alert("Obrigado pela avaliação")
    this.getRandom()
    return resultado
  }

  componentWillUnmount() {
    this.clearInterval(this.timerId)
  }

  render() {
    let marker = []

    if (this.state.orig) {marker.push({ lat: this.state.orig.latitude, lng: this.state.orig.longitude, img_src: null, label: this.state.dados.endereco_origem })}
    if (this.state.dest) {marker.push({ lat: this.state.dest.latitude, lng: this.state.dest.longitude, img_src: null, label: this.state.dados.endereco_destino })}
    if (!this.state.dados) {
      return (<h1>LINK INVALIDO </h1>)
    } else {
      return (
        <div style={{ overflowX: 'hidden' }}>
          {this.state.dados && <div style={{ paddingLeft: 10 }}>
            <h3> ID : {this.state.dados.id}{/*this.props.match.params.id*/}</h3>
            <Row className="show-grid">
              <Col xs={12} md={9} style={{ backgroundColor: '#888888', padding: 0, minHeight: 500 }}>
                <Loader loaded={this.state.teste}>
                <GoMap
                                  width={"90%"}
                                  height={500}
                                  markO={marker}
                                  mark2={this.state.marquerAzul}
                                  mark3={this.state.marquerVerde}
                                  c_points={this.state.rotaVermelha}
                                  c_points1={this.state.rotaAzul}
                                  c_points2={this.state.rotaVerde}
                                  c_points3={this.state.rotaLaranja}
                                ></GoMap>
                </Loader>
              </Col>
              <Col xs={12} md={3} className="shadow" style={{ backgroundColor: '#F0F0F0', minHeight: 500 }}>
                <Card body className="cartoes" >
                  <CardTitle tag="h3" className="title_border" style={{ paddingBottom: 20 }}>Cliente</CardTitle>
                  <CardText><b>Nome: </b>{this.state.dados.cliente} <br />
                    <b>Telefone: </b> {this.state.dados.telefone} <br />
                    {console.log("state pagamento", this.state.dados.tipo_pagto)}
                    <b>Forma de Pagamento: </b> {this.state.dados.tipo_pagto && this.state.dados.tipo_pagto.map(m => { return m })} <br />
                    <b>Observações </b> {this.state.dados.observacoes} <br />
                    <b>Valor final </b> <NumberFormat value={this.state.dados.valorcalculado} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$'} /> <br /></CardText>

                </Card>
                <Card body className="cartoes" >
                  <CardTitle tag="h3" className="title_border" style={{ paddingBottom: 20 }}>Corrida</CardTitle>
                  <CardText><b>Endereço de origem: </b> {this.state.dados.endereco_origem} <br />
                    <b>Endereço de destino: </b> {this.state.dados.endereco_destino} <br />
                    <b>Hora do chamado: </b> {moment(this.state.dados.datahora).format("DD/MM/YY HH:mm")} <br />
                    <b>Hora do embarque: </b> {this.state.dados.datahoraqrl ? moment(this.state.dados.datahoraqrl).format("DD/MM/YY HH:mm") : ""} <br />
                    <b>Hora do desembarque: </b> {this.state.dados.datahoraqrv ? moment(this.state.dados.datahoraqrv).format("DD/MM/YY HH:mm") : ""} <br />
                  </CardText>

                </Card>
                <Card body className="cartoes" >
                  <CardTitle tag="h3" className="title_border" style={{ paddingBottom: 20 }}>Motorista</CardTitle>
                  <CardText>
                    
                  <Row ><Col  xs={12} md={3}>
                            {this.state.dados.fotobase64 ? 
                                <Image style={{margin: 10, width: 70, height: 70, borderRadius:15}} src={this.state.dados.fotobase64}/>
                                :null
                            }
                        </Col><Col  xs={12} md={9}>
                    <b>Nome: </b> {this.state.dados.nome_motorista} <br />
                    <b>Placa: </b>{this.state.dados.placa} <br />
                    <b>Modelo: </b>{this.state.dados.carro} <br /></Col></Row>

                  </CardText>

                </Card>
                {!this.state.dados.star_avaliacao && this.state.dados.datahoraqrv &&
                <Button onClick = {this.avaliacorrida}>
                  <div>Avaliar solicitação</div>
                </Button>}
                {/*criar campo falso para ver se ja foi enviado a nota ou nao*/
                  !this.state.dados.star_avaliacao && this.state.dados.datahoraqrv && false &&
                  <Card body className="cartoes" >
                    <CardTitle tag="h3" className="title_border" style={{ paddingBottom: 20 }}>Avaliação</CardTitle>
                    <CardText><b>Nota: </b>
                      <Rating
                        label={"noMeter"}
                        showMeter={false}
                        starSelectColor={'#f9e613'}
                        starSize={35}
                        getRating={this.handleClick}
                        onHover={this.handleHover}
                      /> <br />
                      <form>
                        <FormGroup
                          controlId="formBasicText"

                        >
                          {console.log("ioioi", this.state.value)}
                          <ControlLabel>Comentario: </ControlLabel>
                          <FormControl
                            type="text"
                            componentClass="textarea"
                            value={this.state.value}
                            placeholder="Escreva o texto"
                            onChange={e => this.setState({ value: e.target.value })}
                          />

                        </FormGroup>
                      </form>
                      <Button onClick={() => this.sendNote(this.props.match.params.id, this.state.value, this.state.nota)}>Enviar</Button>
                    </CardText>

                  </Card>
                }
              </Col>

            </Row>
          </div>
          }
          <AvaliacaoStar corrida={this.state.avapendente} save={this.salvaAvaliacao} />
        </div>

      )
    }
  }
}

export default AcompanhamentoView
