import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import "../styles/CustomFormControl.css";
import cep from "cep-promise";

const CustomFormControlCep = ({
  label,
  name,
  type,
  formik,
  placeholder,
  helpText,
  bSsize,
}) => {
  const validationState = formik.errors[name] ? "error" : null;

  return (
    <FormGroup key={"fg"+name} controlId={`form${name}`} validationState={validationState}>
      <ControlLabel key={"cl"+name} className="custom-label">{label}</ControlLabel>
      <FormControl key={"fc"+name}
        className="custom-form-control"
        type={type}
        name={name}
        onChange={(e) => {
          let text = e.target.value;
                    text = text.replace(/[^+\d]/g, "");
                    text = text.slice(0, 8);

                    let saida = "";
                    if (text.length > 5) {
                      saida = text.substring(0, 5) + "-";
                      saida += text.substring(5, 8);
                    } else {
                      saida = text;
                    }
            formik.setFieldValue(name, saida)
            
            if (saida.length > 8) {
              cep(saida).then((result) => {
                if (result.cep) {

                  formik.setFieldValue('uf', result.state)
                  formik.setFieldValue('cidade', result.city)
                  formik.setFieldValue('bairro', result.neighborhood)
                  formik.setFieldValue('rua', result.street)
                }
              });
            }
        }}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={placeholder}
        bSsize={bSsize}
      />
      {helpText && <HelpBlock key={"hb1"+name}>{helpText}</HelpBlock>}
      {formik.errors[name] && <HelpBlock key={"hb2"+name}>{formik.errors[name]}</HelpBlock>}
    </FormGroup>
  );
};

export default CustomFormControlCep;
