import CoreService from "../../service/CoreService";
import moment from "moment";

export default class Service extends CoreService {
  async listar(dataHoraFim, func, role) {
    console.log("Service listar", dataHoraFim, func, role);
    if (!dataHoraFim) {
      dataHoraFim = moment().add("days", 180).startOf("month");
    }
    if (!func) {
      return {};
    }
    if (!role) {
      return {};
    }
    try {
      const response = await this.fetchOn(
        'corridas?dataHoraFim=' + dataHoraFim + '&func_id=' + func + '&t12_st_corrida=A'
      );

      const result = response.result;
      return result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }
}
