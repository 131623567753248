import CoreService from "../../service/CoreService";

export default class Service extends CoreService {
  async postTipo(tipo, acao) {
    try {
      const response = await this.fetchOn(`manage_tipo_pagamento`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pgto: tipo, acao: acao }),
      });
      return response.result;
    } catch (error) {
      console.error("Erro ao alterar tipo:", error);
      throw error;
    }
  }

  async fetchList() {
    try {
      const response = await this.fetchOn(`tipo_pagamento`);
      return result.result;
    } catch (error) {
      console.error("Erro ao listar:", error);
      throw error;
    }
  }
}
